import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../../components/Generales/ModalCargando';

export default class ModalAgregarRetraso extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreRetraso: '',
      NombreValidado:false,
      NombreGuardado:'',
      _id: '',
      errorNombreRetraso: false,
      colorNotificacion: 'success',
      mensaje: '',
      DesactivarBoton:false,
      br: false,
      Show:false
    }
    this.registrarRetraso = this.registrarRetraso.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreRetraso: this.props.NombreRetraso,
        NombreGuardado:this.props.NombreRetraso,
        _id: this.props._id
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarRetraso() {
    this.setState({Show:true,DesactivarBoton:true})
    var valor1 = 0;
    if (this.state.NombreRetraso.trim() === '') {
      this.setState({ errorNombreRetraso: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido",Show:false,DesactivarBoton:false});
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombreRetraso: false });
      valor1 = 0;
    }
    
    //Validar si hay campos requeridos
    if (valor1 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Retrasos/Retrasos/ActualizarRetrasos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({_id: this.state._id, NombreRetraso: this.state.NombreRetraso})
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,Show:false,DesactivarBoton:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreRetraso: '', errorNombreRetraso: false ,Show:false,DesactivarBoton:false});
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Retrasos/Retrasos/RegistrarRetrasos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje ,Show:false,DesactivarBoton:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreRetraso: '', errorNombreRetraso: false,Show:false,DesactivarBoton:false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }
  ValidarNombre(){
    //Validar si es una actualización
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreRetraso) {
        fetch(Globales.Url + 'Retrasos/Retrasos/ValidarRetraso', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreRetraso: this.state.NombreRetraso})
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreRetraso: true,DesactivarBoton:true,NombreValidado:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreRetraso: false,DesactivarBoton:false,NombreValidado:true });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
        } else {
        this.setState({ errorNombreRetraso: false,DesactivarBoton:false,NombreValidado:true});
      }
    } else {
      fetch(Globales.Url + 'Retrasos/Retrasos/ValidarRetraso', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreRetraso: this.state.NombreRetraso })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreRetraso: true,DesactivarBoton:true,NombreValidado:false});
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreRetraso: false,DesactivarBoton:false,NombreValidado:true});
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Motivo Retraso"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onBlur:this.ValidarNombre.bind(this),
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreRetraso,
                  name: "NombreRetraso",
                  id: "NombreRetraso",
                  type: "text",
                  error: this.state.errorNombreRetraso
                }}
              />
              <GridItem>
              <Button disabled={this.state.DesactivarBoton} tabIndex="0" size="sm" color="info" className="pull-right" round onClick={this.registrarRetraso.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>                
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Cargando
                    show={this.state.Show}
                    Cuerpo="Cargando"
                />
      </div>
    );
  }
}