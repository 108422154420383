import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx";
import Navbar from "components/Navbars/Navbar.jsx";
import logoadministracion from "assets/img/menuPrincipal/administracion.png";
import logoinventario from "assets/img/menuPrincipal/inventario.png";
import logoproduccion from "assets/img/menuPrincipal/produccion.png";
import logoproductos from "assets/img/menuPrincipal/productos.png";
import logoreportes from "assets/img/menuPrincipal/reportes.png";
import logoventas from "assets/img/menuPrincipal/ventas.png";
import logowebuser from "assets/img/menuPrincipal/webuser.png";
import Cargando from '../../components/Generales/ModalCargando';
import routes from "routes/routesAdministracion.js";
import rutasInventario from 'routes/routesInventario.js';
import rutasProduccion from 'routes/routesProduccion.js';
import rutasReportes from 'routes/routesReportes.js';
import rutasAtencionUsuarios from 'routes/routesAtencionUsuarios.js';

import Snackbar from "components/Snackbar/Snackbar.jsx";
import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

const style = {
  ...imagesStyles
};
const cookie = cookies.getAll()


class SectionCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      rutasAdministracion: [],
      rutasProduccion: [],
      rutasInventario: [],
      rutasReportes: [],
      rutasAtencionUsuarios: [],
      MostrarModalCargando:true
    };
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentDidMount() {
    if (cookies.get('mensaje') !== undefined) {
      this.setState({ mensaje:   cookies.get('mensaje'), colorNotificacion: 'success',MostrarModalCargando:true })
      window.location.reload()
      this.showNotification();
      cookies.remove('mensaje', { path: '' });
    }else{
      this.alertTimeout = setTimeout(
        function () {
          this.setState({MostrarModalCargando:false   });
        }.bind(this),
        1000
      );
    }
  }

  showNotification() {  
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false,MostrarModalCargando:false   });
        this.setState(x);
      }.bind(this),
      2000
    );
  }

  componentWillMount() {

    this.setState({
      MostrarModalCargando:true,
      rutasAdministracion: routes,
      rutasInventario: rutasInventario,
      rutasProduccion: rutasProduccion,
      rutasReportes: rutasReportes,
      rutasAtencionUsuarios: rutasAtencionUsuarios,
    });
  }

  CambiarNavegacion() {
    window.location.href = '#/admin/Administracion';
  }

  CambiarNavegacionProduccion() {
    window.location.href = '#/prod';
  }

  CambiarNavegacionReportes() {
    window.location.href = '#/report';
  }

  CambiarNavegacionInventario() {
    window.location.href = '#/inventario';
  }

  CambiarNavegacionAtencionUsuarios() {
    window.location.href = '#/atencionUsuarios';
  }

  render() {

    const { classes, ...rest } = this.props;
    return (
      <div className="animated fadeIn">
        <Navbar
          routes={routes}
        />
        <Container>
          <div className="row mt-5">
            <div hidden={this.state.rutasAdministracion.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6' >
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Administración</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavegacion.bind(this)}>
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoadministracion} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Administración general de la aplicación</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasInventario.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6 pull-rigth'>
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Inventario</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavegacionInventario.bind(this)} >
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoinventario} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Control de inventario</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>

            </div>
            <div hidden={this.state.rutasProduccion.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6'>
              <Card className=" card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Producción</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavegacionProduccion.bind(this)}  >
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoproduccion} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Control de Producción</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasReportes.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6'>
              <Card className="card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Reportes</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavegacionReportes.bind(this)}  >
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoreportes} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Reportes administrativos</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
            <div hidden={this.state.rutasAtencionUsuarios.length > 0 ? false : true} className='form-group col-lg-4 col-md-4 col-sm-6'>
              <Card className="card-accent-success">
                <CardHeader className="text-white" style={{ backgroundColor: "#6D6A6B" }}>
                  <center><h5>Atención</h5></center>
                </CardHeader>
                <div class="hovereffect">
                  <a style={{ cursor: "pointer" }} onClick={this.CambiarNavegacionAtencionUsuarios.bind(this)}  >
                    <CardBody   >
                      <center><img class="img-responsive" width='25%' src={logoreportes} alt="" /></center>
                      <div class="overlay"></div>
                      <center className="mt-3"><a style={{ textDecoration: 'none' }}>Atención a Usuarios</a></center>
                    </CardBody>
                  </a>
                </div>
              </Card>
            </div>
          </div>
        </Container>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Cargando
                    show={this.state.MostrarModalCargando}
                    Cuerpo="Cargando"
                />
      </div >
    )
  }
}


export default withStyles(style)(SectionCards);