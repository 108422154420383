import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Cargando from '../../../../components/Generales/ModalCargando';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';

export default class ModalAgregarEstatusPedido extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      NombreMateria: '',
      DescripcionMateria: '',
      idUnidadMedida: '',
      _id: '',
      UnidadesMedidas: [],
      errorNombre: false,
      errorDescripcion: false,
      errorIdUnidadMedida: false,
      colorNotificacion: 'success',
      mensaje: '',
      MostrarModal:false,
      br: false,
      disable: false
    }
    this.registrarMateriaPrima = this.registrarMateriaPrima.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  

  componentDidMount() {
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreMateria: this.props.NombreMateria,
        NombreGuardado: this.props.NombreMateria,
        DescripcionMateria: this.props.DescripcionMateria,
        idUnidadMedida: this.props.idUnidadMedida,
      })
    }
    this.setState({ UnidadesMedidas: this.props.UnidadesMedidas });
  }

  //Obtener las unidades de medida
  obtenerAllUnidadesMedida() {
    fetch(Globales.Url + 'UnidadMedida/UnidadMedida/')
      .then(res => res.json())
      .then(data => {

        if (data.error) {
          this.setState({ idUnidadMedida: [] });
        } else {
          this.setState({ UnidadesMedidas: data.mensaje });
        }
      });
  }

  componentWillUnmount() {
  }

  registrarMateriaPrima() {
    document.getElementById("btnGuardar").setAttribute("disabled",true);
    this.setState({disable:true,MostrarModal:true});
    this.state.disable = true;
    var valor1 = 0;
    var valor2 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreMateria.trim() == '' || this.state.errorNombre == true) {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido",disable:false,MostrarModal:false});
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false,MostrarModal:false });
      valor1 = 0;
    }
    console.log(this.state.idUnidadMedida.trim(),"uni")
    if (this.state.idUnidadMedida.trim() == '') {
      console.log("1")
      this.setState({ errorIdUnidadMedida: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido",disable:false,MostrarModal:false });
      this.showNotification();
      valor2 = 1;
    } else {
      console.log("2")
      this.setState({ errorIdUnidadMedida: false ,disable:false,MostrarModal:false});
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'MateriaPrima/MateriaPrima/ActualizarMateriaPrima', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.props._id, NombreMateria: this.state.NombreMateria, DescripcionMateria: this.state.DescripcionMateria, idUnidadMedida: this.state.idUnidadMedida })
        }
        ).then(res => res.json())
          .then(data => {

            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje ,disable:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreMateria: '',disable:false,MostrarModal:false, DescripcionMateria: '', idUnidadMedida: '', errorNombre: false, errorDescripcion: false, errorIdUnidadMedida: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'MateriaPrima/MateriaPrima/RegistrarMateriaPrima', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {


            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje ,disable:false,MostrarModal:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreMateria: '',disable:false,MostrarModal:false, DescripcionMateria: '', idUnidadMedida: '', errorNombre: false, errorDescripcion: false, errorIdUnidadMedida: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  ValidarNombre(){
    this.setState({ disable:true });
    //Validar si es una actualización
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreMateria) {
        fetch(Globales.Url + 'MateriaPrima/MateriaPrima/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreMateria: this.state.NombreMateria })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorN: true,disable:true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false,disable:false });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombre: false,disable:false});
      }
    } else {
      fetch(Globales.Url + 'MateriaPrima/MateriaPrima/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreMateria: this.state.NombreMateria })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true,disable:true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false,disable:false});
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreMateria') {
      
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Materia Prima"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                onBlur:this.ValidarNombre.bind(this),
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreMateria,

                  name: "NombreMateria",
                  id: "NombreMateria",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <FormControl
                fullWidth
                className={classes.selectFormControl}
                error={this.state.errorIdUnidadMedida}
              >
                <InputLabel htmlFor="cbbUnidadMedida" className={classes.selectLabel}>Unidad de Medida</InputLabel>
                <Select
                  value={this.state.idUnidadMedida}
                  onChange={this.handleSimple}
                  name="idUnidadMedida"
                  id="cbbUnidadMedida"
                  inputProps={{
                  }}
                >
                  {this.state.UnidadesMedidas.map(unidadMedida =>
                    <MenuItem value={unidadMedida._id}>{unidadMedida.NombreUnidad}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <CustomInput
                labelText="Descripción Materia Prima"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.DescripcionMateria,
                  name: "DescripcionMateria",
                  id: "DescripcionMateria",
                  type: "text",
                  error: this.state.errorDescripcion
                }}
              />
              <GridItem>
                <Button tabIndex="0" id="btnGuardar" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarMateriaPrima.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}