import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Cargando from '../../../../components/Generales/ModalCargando'
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';

export default class ModalAgregarCajasEnvio extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      _id: '',
      NombreCajaEnvio: '',
      idMateriaPrima: '',
      Alto: '',
      Ancho: '',
      Largo: '',
      PesoCaja: '',
      MostrarModal:false,
      Rangos: '',
      RangoInicial:'',
      RangoFinal:'',
      errorNombreCajaEnvio: false,
      erroridMateriaPrima: false,
      errorAlto: false,
      errorAncho: false,
      errorLargo: false,
      errorPesoCaja: false,
      Editable:'',
      errorRangoInicial: false,
      errorRangofinal: false,
      materiaPrima: [],
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disable: false
    }
    this.registraCajaEnvio = this.registraCajaEnvio.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this); 
    this.closeModal = this.closeModal.bind(this);
    this.ObtenerRangoInicialPorMateriaPrima = this.ObtenerRangoInicialPorMateriaPrima.bind(this)
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    //console.log(this.props)
    if (this.props.op == 1) {
      this.setState({ materiaPrima: this.props.materiaPrima },()=>{ 
        this.setState({
          NombreCajaEnvio: this.props.NombreCajaEnvio,
          _id: this.props._id,
          idMateriaPrima: this.props.idMateriaPrima,
          Alto: this.props.Alto,
          Ancho: this.props.Ancho,
          Largo: this.props.Largo,
          PesoCaja: this.props.PesoCaja,
          RangoInicial: this.props.RangoInicial,
          RangoFinal:this.props.RangoFinal,
          NombreGuardado: this.props.NombreCajaEnvio,
          MostrarModal:(this.props.MostrarModal == null?false:false),
        });
      });
     
    }
    this.setState({ materiaPrima: this.props.materiaPrima })
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);

  }

 ObtenerRangoInicialPorMateriaPrima(idMateriaPrima){
    fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ObtenerRangoInicialPorMateriaPrima', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
      body:JSON.stringify({idMateriaPrima:idMateriaPrima})
    })
      .then(res => res.json())
      .then(data => {
      this.setState({
        RangoInicial:data.data
      })
      });
  }
  registraCajaEnvio() {
    console.log(this.state)
    this.setState({
      MostrarModal:true,
      disable:true
    });
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    var valor6 = 0;
    var valor7 = 0;
    //Para validar los decimales
    var regSoloDecimales =  /^\d{1,9}(\.\d{1,3})?$/;
    if (this.state.NombreCajaEnvio.trim() === '') {
      this.setState({ errorNombreCajaEnvio: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido",MostrarModal:false,disable:false });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombreCajaEnvio: false });
      valor1 = 0;
    }
    if (this.state.idMateriaPrima.trim() === '') {
      this.setState({ erroridMateriaPrima: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido',MostrarModal:false,disable:false });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ erroridMateriaPrima: false });
      valor2 = 0;
    }
    if (this.state.Alto === '' || this.state.Alto == 0 || !regSoloDecimales.test(this.state.Alto)) {
      this.setState({ errorAlto: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido',MostrarModal:false,disable:false });
      this.showNotification();
      valor3 = 1;
    } else {
      this.setState({ errorAlto: false });
      valor3 = 0;
    }
    if (this.state.Ancho=== '' || this.state.Ancho == 0 || !regSoloDecimales.test(this.state.Ancho)) {
      this.setState({ errorAncho: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido',MostrarModal:false,disable:false });
      this.showNotification();
      valor4 = 1;
    } else {
      this.setState({ errorAncho: false });
      valor4 = 0;
    }
    if (this.state.Largo === ''|| this.state.Largo == 0 || !regSoloDecimales.test(this.state.Largo)) {
      this.setState({ errorLargo: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido',MostrarModal:false,disable:false });
      this.showNotification();
      valor5 = 1;
    } else {
      this.setState({ errorLargo: false });
      valor5 = 0;
    }
    if (this.state.PesoCaja === '' || this.state.PesoCaja == 0 || !regSoloDecimales.test(this.state.PesoCaja)) {
      this.setState({ errorPesoCaja: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido',MostrarModal:false,disable:false });
      this.showNotification();
      valor6 = 1;
    } else {
      this.setState({ errorPesoCaja: false });
      valor6 = 0;
    }
    if (this.state.RangoInicial<0) {
      this.setState({ errorRangoInicial: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido',MostrarModal:false,disable:false });
      this.showNotification();
      valor7 = 1;
    } else {
      this.setState({ errorRangoInicial: false });
      valor7 = 0;
    }
    if (this.state.RangoFinal<0 || this.state.RangoFinal < this.state.RangoInicial) {
      var mensaje = "";
      if( this.state.RangoFinal <= this.state.RangoInicial){
        mensaje="Rango final debe ser mayor o igual al inicial"
      }else{
        mensaje="Complete el campo requerido"
      }
      this.setState({ errorRangofinal: true, colorNotificacion: 'danger', mensaje: mensaje,MostrarModal:false,disable:false });
      this.showNotification();
      valor7 = 1;
    } else {
      this.setState({ errorRangofinal: false,MostrarModal:false,disable:false });
      valor7 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ActualizarCajaEnvio', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            console.log(data)
            if (data.error) {
              if (data.tipo) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCajaEnvio: true,MostrarModal:false,disable:false });
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,MostrarModal:false,disable:false });
                this.showNotification();
              }
            } else {
              this.setState({ colorNotificacion: 'success', NombreCajaEnvio: '',MostrarModal:false,disable:false, idMateriaPrima: '', Alto: '', Ancho: '', Largo: '', PesoCaja: '', Rangos: '', errorNombreCajaEnvio: false, erroridMateriaPrima: false, errorAlto: false, errorAncho: false, errorLargo: false, errorPesoCaja: false, errorRangos: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'CajasEnvio/CajasEnvio/RegistrarCajaEnvio', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) { 
              if(data.nombreNoDisponible == true){
                this.setState({ errorNombreCajaEnvio: true,MostrarModal:false, colorNotificacion: 'danger', mensaje:data.mensaje,disable:false });
                this.showNotification();
              }else{
                if (data.tipo) {
                  this.setState({ colorNotificacion: 'danger',MostrarModal:false, mensaje: data.mensaje, errorNombreCajaEnvio: true,disable:false });
                  this.showNotification();
                } else {
                  this.setState({ colorNotificacion: 'danger',MostrarModal:false, mensaje: data.mensaje,disable:false });
                  this.showNotification();
                } 
              }
            } else {
                console.log(data)
                this.setState({ colorNotificacion: 'success',MostrarModal:false,mensaje:data.mensaje,disable:false, NombreCajaEnvio: '', idMateriaPrima: '', Alto: '', Ancho: '', Largo: '', PesoCaja: '', RangoInicial: '',RangoFinal:'', errorNombreCajaEnvio: false, erroridMateriaPrima: false, errorAlto: false, errorAncho: false, errorLargo: false, errorPesoCaja: false, errorRangos: false });
                this.closeModal(data.mensaje, this.state.colorNotificacion);
                this.showNotification();
              
              /*if(data.error == true){
                this.setState({ colorNotificacion: 'danger',MostrarModal:false,mensaje:data.mensaje,disable:false, NombreCajaEnvio: '', idMateriaPrima: '', Alto: '', Ancho: '', Largo: '', PesoCaja: '', RangoInicial: '',RangoFinal:'', errorNombreCajaEnvio: false, erroridMateriaPrima: false, errorAlto: false, errorAncho: false, errorLargo: false, errorPesoCaja: false, errorRangos: false });
                this.showNotification();
              }else{
                this.setState({ colorNotificacion: 'success',MostrarModal:false,mensaje:data.mensaje,disable:false, NombreCajaEnvio: '', idMateriaPrima: '', Alto: '', Ancho: '', Largo: '', PesoCaja: '', RangoInicial: '',RangoFinal:'', errorNombreCajaEnvio: false, erroridMateriaPrima: false, errorAlto: false, errorAncho: false, errorLargo: false, errorPesoCaja: false, errorRangos: false });
                this.showNotification();
              }*/
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    )
  }

  handleChange(e) {
    
    // Almacenar la informacion en una variable
    const { name, value } = e.target;
    var regSoloDecimales =  /^\d{1,9}(\.\d{1,3})?$/;
    // Validar que control se esta evaluando
    switch (name) {
        case 'Alto':
            // Mostrar el error si existe         
            this.setState({ errorAlto: !regSoloDecimales.test(value) })                                                                           
            break;   
        case 'Ancho':
            // Mostrar el error si existe                          
            this.setState({ errorAncho: !regSoloDecimales.test(value) })                                                                           
           break;  
        case 'Largo':
              // Mostrar el error si existe                          
              this.setState({ errorLargo: !regSoloDecimales.test(value) })                                                                           
              break;  
        case 'PesoCaja':
            // Mostrar el error si existe                          
            this.setState({ errorPesoCaja: !regSoloDecimales.test(value) })                                                                           
            break;    
        default:          
    }  
    if(name == "Alto" || name == "Ancho" || name == "Largo"){
      if(value.length <=3){
        this.setState({
          [name]: value
        });
      }
    }else{
      this.setState({
        [name]: value
      });
    }
  }

  handleKeyPress(e){
    const keyValue = e.key;
    if (!/^\d+$/.test(keyValue) && keyValue !== '.') {
      this.setState({ colorNotificacion: 'danger', mensaje: "Solo se permiten valores numéricos", errorNombreCajaEnvio: false, disable: false });
      this.showNotification();
    }
  }


  handleSimple = event => {
    if(event.target.name =="idMateriaPrima"){
      var rangoInicialPorMateriaPrima  = this.ObtenerRangoInicialPorMateriaPrima(event.target.value)
      this.setState({ 
        [event.target.name]: event.target.value,
        RangoInicial: rangoInicialPorMateriaPrima
       });
    }else{
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  validarNombre = event => {
    //Validar si es una actualización
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== event.target.value) {
        fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCajaEnvio: event.target.value,_id:this.state._id })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCajaEnvio: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreCajaEnvio: false, disable: false });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombreCajaEnvio: false, disable: false });
      }
    } else {
      fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreCajaEnvio: event.target.value })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCajaEnvio: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreCajaEnvio: false, disable: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput

                    labelText="Nombre Caja de Envío"
                    // tabindex={0}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      tabindex: "4",
                      required: true,
                      onChange: this.handleChange.bind(this),
                      // onBlur:this.validarNombre.bind(this),
                      value: this.state.NombreCajaEnvio,
                      name: "NombreCajaEnvio",
                      id: "NombreCajaEnvio",
                      type: "text",
                      error: this.state.errorNombreCajaEnvio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    // tabindex="1"                    
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.erroridMateriaPrima}
                    className="mt-4"
                  >
                    <InputLabel
                      className={classes.selectLabel}>
                      Materia Prima
                        </InputLabel>
                    <Select
                      value={this.state.idMateriaPrima}
                      onChange={this.handleSimple}
                      name="idMateriaPrima"
                      id="cbbMateriaPrima"
                      inputProps={{
                      }}
                    >
                      {this.state.materiaPrima.map(materia =>
                        <MenuItem   value={materia._id}>{materia.NombreMateria}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    // tabindex="2"
                    tabindex={3}
                    labelText="Alto (cm)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      tabindex: "5",
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Alto,
                      name: "Alto",
                      id: "Alto",
                      type: "number",
                      onKeyPress:this.handleKeyPress.bind(this),
                      error: this.state.errorAlto
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    // tabindex="3"
                    labelText="Ancho (cm)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Ancho,
                      name: "Ancho",
                      id: "Ancho",
                      type: "number",
                      onKeyPress:this.handleKeyPress.bind(this),
                      error: this.state.errorAncho
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    // tabindex="4"
                    labelText="Largo (cm)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Largo,
                      name: "Largo",
                      id: "Largo",
                      type: "number",
                      onKeyPress:this.handleKeyPress.bind(this),
                      error: this.state.errorLargo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    // tabindex="5"
                    labelText="Peso Caja (kg)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.PesoCaja,
                      name: "PesoCaja",
                      id: "PesoCaja",
                      type: "number",
                      onKeyPress:this.handleKeyPress.bind(this),
                      error: this.state.errorPesoCaja
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    // tabindex="6"
                    labelText="Rango Inicial"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.RangoInicial,
                      name: "RangoInicial",
                      disabled:true,
                      id: "RangoInicial",
                      type: "number",
                      onKeyPress:this.handleKeyPress.bind(this),
                      error: this.state.errorRangoInicial
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    // tabindex="6"
                    labelText="Rango Final"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.RangoFinal,
                      name: "RangoFinal",
                      id: "RangoFinal",
                      type: "number",
                      onKeyPress :this.handleKeyPress.bind(this),
                      error: this.state.errorRangofinal
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridItem>
                {this.state.disable ? <Button tabindex="2" disabled={this.state.disable} size="sm" color="info" className="pull-right" round><Save />&nbsp;GUARDAR</Button> :
                  <Button tabindex="2" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registraCajaEnvio.bind(this)}><Save />&nbsp;GUARDAR</Button>}
                <Button tabindex="3" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}