import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import Danger from "components/Typography/Danger.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Cargando from '../../../../components/Generales/ModalCargando';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import { Save, Clear, Edit, Close, AddBox, AttachFile, Check, Warning, Publish } from '@material-ui/icons';
import withStyles from "@material-ui/core/styles/withStyles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ModalAnt from 'antd/lib/modal/Modal';
import ListaCapasModelo from "./ListaCapasModelo.jsx"

import Loader from 'react-loader-spinner'


import { SwatchesPicker } from 'react-color'
import { EnviarMail, ContenidoMail, getInfoImagen } from '../../../../components/Mail/EnvioMail';
import CookiesU from 'universal-cookie';
import mongoose from 'mongoose';

const cookies = new CookiesU();

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function getBase64_2(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}


class AgregarProducto extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ImagenSubida: false,
      NombreImagenProducto:"",
      TexturaSubida: false,     
      TexturaProducto:null,
      NombreTextura:"",
      TempleteRecorteSubida: false,
      ImagenProducto: null,
      TempleteRecorte: null,     
      NombreTempleteRecorte:"",
      Mangas: true,
      checked: [0],
      VerBotonAutorizarGeneral: false,
      idGrupoActualizar: '',
      Tallas: [],
      VerModalAdvertencia: false,
      idGrupo: '',
      ActualizarGrupo: false,
      TabIndex: 0,
      fileNames: "",
      files: null,
      previewVisible: false,
      previewImage: '',
      NombreGrupo: '',
      GruposMaterial: [],
      MostrarModal: false,
      simpleSelect: [],
      NombreProducto: '',
      TipoProducto: '',
      Descripcion: '',
      Tamano: '',
      idCaracteristica: '',
      ValorCaracteristica: '',
      idAtributo: '',
      idModelo: null,
      idMateriaPrima: '',
      idMaterial: '',
      ValorMaterial: '',
      CostoMaterial: 0,
      CostoMaquila: null,
      TipoPrecio: '',
      PorcentajeUtilidad: 0.00,
      PrecioPublico: '',
      PrecioSinIva: '',
      AtributoDesplegable: '',
      PorcentajeComision: '',
      ImporteComision: '',
      Peso: '',
      NombreMaterial: '',
      ImporteUtilidad: '',
      _id: '',
      precio: '',
      valor: '-',
      valorIva: 0.00,
      tipoDesplegable: [],
      preciosDesplegable: {},
      cantidad: '',
      ValorColor: '',
      PorcentajeUtilidadSistema: '',
      CorreoAutorizado: '',
      errorNombreGrupo: false,
      errorNombreProducto: false,
      errorTipoProducto: false,
      errorNombreImagenes: false,
      errorDescripcion: false,
      erroridCaracteristica: false,
      erroridAtributo: false,
      erroridMateriaPrima: false,
      erroridMaterial: false,
      errorCostoMaterial: false,
      errorCostoMaquila: false,
      errorTipoPrecio: false,
      errorPorcentajeUtilidad: false,
      errorPrecioPublico: false,
      errorPorcentajeComision: false,
      errorImporteUtilidad: false,
      errorPeso: false,
      errorCantidad: false,
      errorPrecioAtributo: false,
      errorPrecioCaracteristica: false,
      errorSeleccionCaracteristicas: false,
      errorValorCaracteristica: false,
      errorValorColor: false,
      errorValorMaterial: false,
      errorTamano: false,
      errorTemplate: false,
      errorTextura: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disPorcentaje: false,
      NombreUnidad: '',
      errorImagen: false,
      errorImagenTextura: false,
      //Schemas
      Caracteristicas: [],
      Atributos: [],
      MateriaPrima: [],
      Material: [],
      UnidadMedida: [],
      caracteristicasAgregadas: [],
      atributosAgregados: [],
      materialesAgregados: [],
      displayColorPicker: false,
      tipoAtributo: null,
      disableDesplegable: true,
      arrayPrecioDesplegable: {},
      ValorColor: '',
      precioColor: '',
      Precios: false,
      DisPrecioPublico: false,
      mostraImporte: false,
      disabledBtnGuardar: false,
      valMaterial: true,
      disable: false,
      VerBotonAutorizar: false,
      Categorias: [],
      idCategoria: '',
      AutorizarProducto: null,
      ProductoNuevo: false,
    }
    global.PorcentajeUtilidad = 0;
    global.idMateriaPrima = '';
    global.cotosMaterial = 0;
    global.agarraPlis = '';
    global.arrayIdsMateriales = [];
    global.MaterialesPorMateriaPrima = [];
    global.valueImg = '';
    this.registrarProducto = this.registrarProducto.bind(this);
    this.RegistrarNuevoProductoInicial = this.RegistrarNuevoProductoInicial.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.ActualizarInformacionPrecio = this.ActualizarInformacionPrecio.bind(this);
    this.escFunction = this.escFunction.bind(this);
    // this.obtenerImagenes = this.obtenerImagenes.bind(this);
    this.EventoTabla = this.EventoTabla.bind(this);
    this.handleChangeGrupos = this.handleChangeGrupos.bind(this);
    this.getFiles = this.getFiles.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    if (this.props.TabIndex == 5) {
      this.setState({
        VerBotonAutorizar: true,
        AutorizarProducto: this.props.AutorizarProducto
      })
    }
    if (this.props.op == 1) {
      global.PorcentajeUtilidad = this.props.PorcentajeUtilidad;
      // img.src= Globales.url+ this.props.RutasImagenes[0];
      this.setState({
        valMaterial: false,
        _id: this.props._id,
        NombreProducto: this.props.NombreProducto,
        idCategoria: this.props.idCategoria,
        TipoProducto: this.props.TipoProducto,
        TabIndex: this.props.TabIndex,
        idGrupo: this.props.GruposMaterial.length > 0 ? this.props.GruposMaterial[0].id : null,
        Descripcion: this.props.Descripcion,
        caracteristicasAgregadas: this.props.caracteristicasAgregadas,
        idModelo: this.props.idModelo,
        idMateriaPrima: this.props.idMateriaPrima,
        CostoMaquila: this.props.CostoMaquila,
        Peso: this.props.Peso,
        Tamano: this.props.Tamaño,
        NombreGuardado: this.props.NombreProducto,
        GruposMaterial: this.props.GruposMaterial,
        Mangas: this.props.Mangas,
        /**IMAGENES */
        ImagenProducto: this.props.ImagenProducto == null ? null : "data:image/png;base64," + this.props.ImagenProducto,
        TexturaProducto: this.props.TexturaProducto == null ? null : "data:image/png;base64," + this.props.TexturaProducto,
        NombreImagenProducto: this.props.NombreImagenProducto,
        NombreTextura: this.props.NombreTextura,
        NombreTempleteRecorte: this.props.NombreTempleteRecorte

      }, () => {
        if (this.props.GruposMaterial.length > 0) {
          this.handleChangeGrupos(this.props.GruposMaterial[0].id)
        }       
      });

      //ATRIBUTOS
      var atributosAgre = this.props.atributosAgregados
      //Validar que sea tipo desplegable
      atributosAgre.map(atributo => {
        // Validar que sea desplegable
        if (atributo.ValorColor !== '') { //Color
          this.state.atributosAgregados.push({ idAtributo: atributo.idAtributo, NombreAtributo: atributo.NombreAtributo, ValorColor: atributo.ValorColor });
        } else {
          this.state.atributosAgregados.push({ idAtributo: atributo.idAtributo, NombreAtributo: atributo.NombreAtributo, ValorColor: atributo.ValorColor, ValorDesplegable: atributo.ValorDesplegable });
        }
      });

      var idUnidad = "";
      //Recorriendo las materias primas para obtener el ID de unidad de medida
      this.props.MateriaPrima.map(materiasPrimas => {
        if (this.props.idMateriaPrima === materiasPrimas._id) {
          idUnidad = materiasPrimas.idUnidadMedida;
        }
      });

      this.props.Material.map(material => {
        if (material.idMateriaPrima === this.props.idMateriaPrima) {
          global.MaterialesPorMateriaPrima.push({ idMaterial: material._id, NombreMaterial: material.NombreMaterial });
        }
      });

      //Recorrriendo las unidades de medida para obtener el nombre
      this.props.UnidadMedida.map(unidades => {
        if (idUnidad === unidades._id) {
          this.state.NombreUnidad = unidades.NombreUnidad
        }
      });
    }
    if (this.props.op == 3) {
      this.setState({
        valMaterial: false,
        NombreProducto: this.props.NombreProducto,
        TipoProducto: this.props.TipoProducto,
        TabIndex: this.props.TabIndex,
        //TEMPORAL
        Descripcion: this.props.Descripcion,
        caracteristicasAgregadas: this.props.caracteristicasAgregadas,
        idModelo: this.props.idModelo,
        idMateriaPrima: this.props.idMateriaPrima,
        //   materialesAgregados: this.props.materialesAgregados,
        //  CostoMaterial: this.props.CostoMaterial,
        CostoMaquila: this.props.CostoMaquila,
        // TipoPrecio: this.props.TipoPrecio,
        // PorcentajeUtilidad: this.props.PorcentajeUtilidad,
        // PrecioPublico: this.props.PrecioPublico,
        // PorcentajeComision: this.props.PorcentajeComision,
        // ImporteUtilidad: this.props.ImporteUtilidad,
        Peso: this.props.Peso,
        //   Tamano: this.props.Tamaño,
        NombreGuardado: this.props.NombreProducto,
        //    ImporteComision: this.props.ImporteComision,
        GruposMaterial: this.props.GruposMaterial

        // valorIva:this.props.IVA
      });

      //ATRIBUTOS
      var atributosAgre = this.props.atributosAgregados
      //Validar que sea tipo desplegable
      atributosAgre.map(atributo => {
        // Validar que sea desplegable
        if (atributo.ValorColor !== '') { //Color
          this.state.atributosAgregados.push({ idAtributo: atributo.idAtributo, NombreAtributo: atributo.NombreAtributo, ValorColor: atributo.ValorColor });
        } else {
          this.state.atributosAgregados.push({ idAtributo: atributo.idAtributo, NombreAtributo: atributo.NombreAtributo, ValorColor: atributo.ValorColor, ValorDesplegable: atributo.ValorDesplegable });
        }
      });
    
      var idUnidad = "";
      //Recorriendo las materias primas para obtener el ID de unidad de medida
      this.props.MateriaPrima.map(materiasPrimas => {
        if (this.props.idMateriaPrima === materiasPrimas._id) {
          idUnidad = materiasPrimas.idUnidadMedida;
        }
      });
      this.props.Material.map(material => {

        if (material.idMateriaPrima === this.props.idMateriaPrima) {
          global.MaterialesPorMateriaPrima.push({ idMaterial: material._id, NombreMaterial: material.NombreMaterial });
        }
      });

      //Recorrriendo las unidades de medida para obtener el nombre
      this.props.UnidadMedida.map(unidades => {
        if (idUnidad === unidades._id) {
          this.state.NombreUnidad = unidades.NombreUnidad
        }
      });
    }
    global.idMateriaPrima = this.props.idMateriaPrima;
    this.setState({
      Caracteristicas: this.props.Caracteristicas,
      Atributos: this.props.Atributos,
      MateriaPrima: this.props.MateriaPrima,
      Material: this.props.Material,
      UnidadMedida: this.props.UnidadMedida,
      Empresa: this.props.Empresa,
      valorIva: this.props.op == 1 || this.props.op == 3 ? this.props.IVA : Object.values(this.props.Empresa[0])[14],
      PorcentajeUtilidadSistema: Object.values(this.props.Empresa[0])[15],
      CorreoAutorizado: this.props.Empresa[0].CorreoAutorizacionPrecios
    });
    //Agregar en un arreglo los Id de materia prima existentes en material
    this.props.Material.map(mat => {
      global.arrayIdsMateriales.push(mat.idMateriaPrima)
    });
    //Indica que es un producto nuevo, mostrar la ventada unicamente básica
    if (this.props.op == 0) {
      this.setState({ ProductoNuevo: true })
    }
  }

  mostrarEdicion(nuevoPro) {
    // img.src= Globales.url+ this.props.RutasImagenes[0];
    this.setState({
      valMaterial: false,
      _id: nuevoPro._id,
      ProductoNuevo: false
    }, () => {
      //this.handleChangeGrupos(this.props.GruposMaterial[0].id)

    })
  }

  componentWillMount() {
    this.ObtenerCategorias();
    this.ObtenerTallas();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  ObtenerTallas() {
    fetch(Globales.Url + 'Tallas/ObtenerTallas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
          this.setState({
            Tallas: data.data
          })
        }
      })
  }

  ObtenerCategorias() {
    fetch(Globales.Url + 'Modelos/Categoria/ObtenerCategoriasPrincipales', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
          this.setState({
            Categorias: data
          })
        }
      })
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }

  handleChange(e) {

    var name = ""; var value = "";
    if (typeof (e) == "object") {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "CostoMaquila";
      value = e;

    }

    if (name == "PorcentajeUtilidad") {
      if (Number(value) < 0) {
        return;
      }
      if (Number(value) > 99) {
        return;
      }
    }
    this.setState({
      [name]: value
    }, () => {
      /**PRECIO DIRECTO */
      console.log(this.state.TabIndex)
      if (this.state.TabIndex == 5 || typeof (e) == "string") {
        //|| typeof (e) == "string") {
        if (this.state.disPorcentaje == true) {
          this.state.PorcentajeUtilidad = '';
          this.state.importeUtilidad = '';
          this.state.valorIva = Object.values(this.props.Empresa[0])[14];
          var precioSinIva = (Number(this.state.valorIva) / 100) + 1
          precioSinIva = Number(this.state.PrecioPublico) / Number(precioSinIva)
          var iva = (Number(this.state.PrecioPublico) - Number(precioSinIva)).toFixed(2)
          var porcentajeUtilidad = Number(this.state.CostoMaterial) + Number(this.state.CostoMaquila)
          porcentajeUtilidad = Number(porcentajeUtilidad) / Number(precioSinIva)
          porcentajeUtilidad = (1 - Number(porcentajeUtilidad))
          porcentajeUtilidad = Number(porcentajeUtilidad) * 100
          porcentajeUtilidad = Math.round(porcentajeUtilidad)
          var importeUtilidad = Number(porcentajeUtilidad) / 100
          importeUtilidad = Number(precioSinIva) * Number(importeUtilidad)
          importeUtilidad = Math.round(importeUtilidad * 100) / 100
          this.setState({
            PorcentajeUtilidad: porcentajeUtilidad,
            ImporteUtilidad: importeUtilidad,
            valorIva: iva,
            PrecioSinIva: (Number(precioSinIva)).toFixed(2)
          }, () => {

            var comision = Number(this.state.PrecioPublico) * (Number(this.state.PorcentajeComision) / 100)
            this.setState({
              ImporteComision: comision.toFixed(2)
            }, () => {
              /**aqui merenges */
              this.state.GruposMaterial.map(grupo => {
                if (this.state.idGrupo == grupo.id) {
                  grupo["PorcentajeUtilidad"] = parseFloat(this.state.PorcentajeUtilidad);
                  grupo["ImporteUtilidad"] = parseFloat(this.state.ImporteUtilidad);
                  grupo["valorIva"] = parseFloat(this.state.valorIva);
                  grupo["PrecioSinIva"] = parseFloat(this.state.PrecioSinIva);
                  grupo["ImporteComision"] = parseFloat(this.state.ImporteComision);
                  grupo["PorcentajeComision"] = parseFloat(this.state.PorcentajeComision);
                  grupo["TipoPrecio"] = "PrecioDirecto";
                  grupo["PrecioPublico"] = parseFloat(this.state.PrecioPublico);
                  grupo["CostoMaquila"] = parseFloat(this.state.CostoMaquila);

                }
              })
            })
          });
        } else {
          /**PORCENTAJE UTILIDAD */
          var valor1 = 0;
          var valor2 = 2;
          //Validar que hayan ingresado costo material y maquila    
          if (this.state.CostoMaterial === '') {
            this.setState({ colorNotificacion: 'danger', mensaje: 'Ingrese costo material', errorCostoMaterial: true });
            this.showNotification();
            valor1 = 1;
          } else {
            this.setState({ errorCostoMaterial: false })
            valor1 = 0;
          }
          if (this.state.CostoMaquila === '') {
            this.setState({ colorNotificacion: 'danger', mensaje: 'Ingrese costo maquila', errorCostoMaquila: true });
            this.showNotification();
            valor2 = 1;
          } else {
            this.setState({ errorCostoMaquila: false })
            valor2 = 0;
          }
          if (Number(valor1) + Number(valor2) === 0) {
            this.state.PrecioPublico = '';
            this.state.valorIva = Object.values(this.props.Empresa[0])[14]
            var costo = Number(this.state.CostoMaterial) + Number(this.state.CostoMaquila)
            var porcentajeCosto = 100 - Number(this.state.PorcentajeUtilidad)
            var subtotal = (Number(costo) / (Number(porcentajeCosto) / 100))
            var iva = (Number(subtotal) * (Number(this.state.valorIva) / 100)).toFixed(2);
            var precioPublico = Number(subtotal) + Number(iva);
            var importeUtilidad = Number(this.state.PorcentajeUtilidad) / 100;
            importeUtilidad = Number(subtotal) * Number(importeUtilidad);
            importeUtilidad = Math.round(importeUtilidad * 100) / 100;
            // precioPublico = Math.ceil(Number(precioPublico) / 5) * 5
            // var ivaNuevo = (Number(precioPublico) * (Number(this.state.valorIva) / 100)).toFixed(2);
            // var PrecioNuevo = Number(precioPublico) - Number(ivaNuevo);
            this.setState({
              PrecioPublico: precioPublico.toFixed(2),
              valorIva: iva,
              PrecioSinIva: (Number(subtotal)).toFixed(2),
              ImporteUtilidad: importeUtilidad
            }, () => {

              var comision = Number(this.state.PrecioPublico) * (Number(this.state.PorcentajeComision) / 100)
              this.setState({
                ImporteComision: comision.toFixed(2)
              }, () => {
                this.state.GruposMaterial.map(grupo => {
                  if (this.state.idGrupo == grupo.id) {
                    grupo["ImporteUtilidad"] = parseFloat(this.state.ImporteUtilidad);
                    grupo["PrecioPublico"] = parseFloat(this.state.PrecioPublico);
                    grupo["valorIva"] = parseFloat(this.state.valorIva);
                    grupo["PrecioSinIva"] = parseFloat(this.state.PrecioSinIva);
                    grupo["ImporteComision"] = parseFloat(this.state.ImporteComision);
                    grupo["PorcentajeComision"] = parseFloat(this.state.PorcentajeComision);
                    grupo["PorcentajeUtilidad"] = parseFloat(this.state.PorcentajeUtilidad);
                    grupo["TipoPrecio"] = "PorcentajeUtilidad";
                    grupo["CostoMaquila"] = parseFloat(this.state.CostoMaquila);
                  }
                })

              })
            });

          }


        }
      }
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });

    var idUnidad = "";
    if (event.target.name === "idMateriaPrima") {
      // global.idMateriaPrima=event.target.value;

      if (this.state.GruposMaterial.length == 0) {
        global.MaterialesPorMateriaPrima = [];
        this.setState({ materialesAgregados: [] })

        //Recorriendo las materias primas para obtener el ID de unidad de medida
        this.state.MateriaPrima.map(materiasPrimas => {
          if (event.target.value === materiasPrimas._id) {
            idUnidad = materiasPrimas.idUnidadMedida;
          }
        });
        //Obteniendo los materiales relacionados a la materia prima seleccionada
        this.state.Material.map(material => {
          if (material.idMateriaPrima === event.target.value) {
            global.MaterialesPorMateriaPrima.push({ idMaterial: material._id, NombreMaterial: material.NombreMaterial });
          }
        });
        //Recorrriendo las unidades de medida para obtener el nombre
        this.state.UnidadMedida.map(unidades => {
          if (idUnidad === unidades._id) {
            this.state.NombreUnidad = unidades.NombreUnidad
          }
        });
        //Validar que exista material
        var indicadorExistencia = global.arrayIdsMateriales.indexOf(event.target.value)

        if (indicadorExistencia === -1) {
          this.setState({ colorNotificacion: 'danger', mensaje: 'La materia prima seleccionada no cuenta con ningún material registrado, verifique', disabledBtnGuardar: true, valMaterial: true });
          this.showNotification();
        } else {
          this.setState({ disabledBtnGuardar: false, valMaterial: false });
        }
      } else {
        this.setState({

          VerModalAdvertencia: true
        })
      }

    }
  }

  handleTipoPrecio = event => {
    this.setState({ [event.target.name]: event.target.value });
    //Cambiar valores de state
    if (event.target.value === 'PorcentajeUtilidad') {
      this.setState({
        Precios: true,
        mostraImporte: false,
        disPorcentaje: false,
        DisPrecioPublico: true,
        PrecioPublico: '',
        PorcentajeComision: '',
        ImporteComision: '',
        PorcentajeUtilidad: '',
        valorIva: Object.values(this.props.Empresa[0])[14]
      })

    } else if (event.target.value === 'PrecioDirecto') {
      this.setState({
        Precios: true,
        mostraImporte: true,
        DisPrecioPublico: false,
        disPorcentaje: true,
        PrecioPublico: '',
        PorcentajeComision: '',
        ImporteComision: '',
        PorcentajeUtilidad: '',
        ImporteUtilidad: '',
        valorIva: Object.values(this.props.Empresa[0])[14]
      });
    }
  }

  obtenerTipoAtributo(idAtributo) {
    var tipo = [];
    this.state.Atributos.map(atributo => {
      if (idAtributo == atributo._id) {
        tipo = atributo.Tipo
      }
    });
    return tipo;
  }

  handleSimpleAtributos = event => {
    //Obteniendo el tipo de atributo que es
    var tipo = this.obtenerTipoAtributo(event.target.value);
    //Validación del tipo de atributo
    if (tipo.length === 0) { //Tipo Color
      var n = null;
      this.state.atributosAgregados.map(a => {
        if (event.target.value === a.idAtributo) {
          v = true;
        }
      });
      //Validar si ya se agregó el atributo
      if (v) {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Ya esta agregado el atributo' });
        this.showNotification();
      } else {
        this.setState({ displayColorPicker: !this.state.displayColorPicker, [event.target.name]: event.target.value, tipoDesplegable: [], tipoAtributo: true });
      }
    } else { //Tipo Desplegable
      var v = null;
      this.state.atributosAgregados.map(a => {
        if (event.target.value === a.idAtributo) {
          v = true;
        }
      });
      //Validar si ya se agregó el atributo
      if (v) { //Ya se agregó el atributo
        this.setState({ colorNotificacion: 'danger', mensaje: 'Ya esta agregado el atributo' });
        this.showNotification();
      } else { //No se agregado el atributo
        this.setState({ [event.target.name]: event.target.value, disableDesplegable: true, tipoDesplegable: this.obtenerValoresDesplegables(event.target.value), tipoAtributo: false });
      }
    }
  }

  obtenerValoresDesplegables(idAtributo) {
    var arrayDesplegable = [];
    this.state.Atributos.map(atri => {
      if (idAtributo === atri._id) {
        arrayDesplegable = atri.Tipo[0].Desplegable[0].Valor
      }
    });
    return arrayDesplegable;
  }

  obtenerNombre(id, indicador) {
    var nombre = '';
    if (indicador === 1) { //Características
      this.state.Caracteristicas.map(car => {
        if (id == car._id) {
          nombre = car.NombreCaracteristica
        }
      });
    } else if (indicador === 2) { //Atributos
      this.state.Atributos.map(atributo => {
        if (id == atributo._id) {
          nombre = atributo.NombreAtributo
        }
      });
    } else { //Material
      this.state.Material.map(mat => {
        if (mat._id === id) {
          nombre = mat.NombreMaterial
        }
      });
    }
    return nombre;
  }

  handleChangeComplete = (color) => {
    this.setState({ ValorColor: color.hex });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  calculosCostoMaterial() {
    var costo = 0;
    var costoFinal = 0;
    var cantidad = 0;
    this.state.materialesAgregados.map(material => {
      //Obteniendo la cantidad de material
      cantidad = material.ValorMaterial

      //sumando el costo final
      costoFinal = Number(costoFinal) + Number(costo);
    });
    //Asignando el costo final
    this.setState({ CostoMaterial: Number(costoFinal) });
  }

  handlePorcentajeUtilidad = event => {
    var valor1 = 0;
    var valor2 = 2;
    //Validar que hayan ingresado costo material y maquila    
    if (this.state.CostoMaterial === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Ingrese costo material', errorCostoMaterial: true });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorCostoMaterial: false })
      valor1 = 0;
    }
    if (this.state.CostoMaquila === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Ingrese costo maquila', errorCostoMaquila: true });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ errorCostoMaquila: false })
      valor2 = 0;
    }
    if (Number(valor1) + Number(valor2) === 0) {
      this.state.PrecioPublico = '';
      this.state.valorIva = Object.values(this.props.Empresa[0])[14]
      var costo = Number(this.state.CostoMaterial) + Number(this.state.CostoMaquila)
      var porcentajeCosto = 100 - Number(event.target.value)
      var subtotal = Number(costo) / (Number(porcentajeCosto) / 100)
      var iva = (Number(subtotal) * (Number(this.state.valorIva) / 100)).toFixed(2);
      var precioPublico = Number(subtotal) + Number(iva)
      // precioPublico = Math.ceil(Number(precioPublico) / 5) * 5
      this.setState({ PrecioPublico: precioPublico.toFixed(2), valorIva: iva });
    }
  }

  handlePrecioPublico = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.state.PorcentajeUtilidad = '';
    this.state.importeUtilidad = '';
    this.state.valorIva = Object.values(this.props.Empresa[0])[14];
    var precioSinIva = (Number(this.state.valorIva) / 100) + 1
    precioSinIva = Number(event.target.value) / Number(precioSinIva)
    var iva = (Number(event.target.value) - Number(precioSinIva)).toFixed(2)
    var porcentajeUtilidad = Number(this.state.CostoMaterial) + Number(this.state.CostoMaquila)
    porcentajeUtilidad = Number(porcentajeUtilidad) / Number(precioSinIva)
    porcentajeUtilidad = (1 - Number(porcentajeUtilidad))
    porcentajeUtilidad = Number(porcentajeUtilidad) * 100
    porcentajeUtilidad = Math.round(porcentajeUtilidad)
    var importeUtilidad = Number(porcentajeUtilidad) / 100
    importeUtilidad = Number(precioSinIva) * Number(importeUtilidad)
    importeUtilidad = Math.round(importeUtilidad * 100) / 100
    this.setState({ PorcentajeUtilidad: porcentajeUtilidad, ImporteUtilidad: importeUtilidad, valorIva: iva });
  }

  handlePrecioPublicoOnBlur = event => {
    this.state.PorcentajeUtilidad = '';
    this.state.importeUtilidad = '';
    this.state.valorIva = Object.values(this.props.Empresa[0])[14];
    var precioSinIva = (Number(this.state.valorIva) / 100) + 1
    precioSinIva = Number(event.target.value) / Number(precioSinIva)
    var iva = (Number(event.target.value) - Number(precioSinIva)).toFixed(2)
    var porcentajeUtilidad = Number(this.state.CostoMaterial) + Number(this.state.CostoMaquila)
    porcentajeUtilidad = Number(porcentajeUtilidad) / Number(precioSinIva)
    porcentajeUtilidad = (1 - Number(porcentajeUtilidad))
    porcentajeUtilidad = Number(porcentajeUtilidad) * 100
    porcentajeUtilidad = Math.round(porcentajeUtilidad)
    var importeUtilidad = Number(porcentajeUtilidad) / 100
    importeUtilidad = Number(precioSinIva) * Number(importeUtilidad)
    importeUtilidad = Math.round(importeUtilidad * 100) / 100
    this.setState({ PorcentajeUtilidad: porcentajeUtilidad, ImporteUtilidad: importeUtilidad, valorIva: iva });
  }

  mostrarAtributosDesplegables(arrayDesplegable) {
    //Iniciando variable
    var valores = '';
    //Validar si tiene valor la variable
    if (arrayDesplegable !== undefined) {
      //Modificando el valor
      valores = arrayDesplegable.toString()
    }
    //Regresando la variabkle
    return valores
  }

  //PESTAÑA ATRIBUTOS
  agregarAtributos(idAtributo, ValorColor, AtributoDesplegable) {
    this.setState({
      MostrarModal: true,
      disabled: true
    });
    var valor1 = 0;
    var valor2 = 0;
    //Validar que hayan seleccionado un atributo
    if (idAtributo === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Selecciona un atributo', erroridAtributo: true, MostrarModal: false, disabled: false });
      this.showNotification();
      valor1 = 1;
    } else {
      valor1 = 0
      this.setState({ erroridAtributo: false });
    }
    //Obteniendo que tipo de atributo es
    if (this.obtenerTipoAtributo(idAtributo).length > 0) {
      //Validando que hayan seleccionado por lo menos un atributo
      if (AtributoDesplegable === '') {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Selecciona un atributo', errorSeleccionCaracteristicas: true, MostrarModal: false, disabled: false });
        this.showNotification();
        valor2 = 1;
      } else {
        valor2 = 0
        this.setState({ errorSeleccionCaracteristicas: false });
      }
    } else {
      //Validando que el valor del color no esta vació
      if (ValorColor === '') {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Selecciona un atributo', errorValorColor: true, MostrarModal: false, disabled: false });
        this.showNotification();
        valor2 = 1;
      } else {
        valor2 = 0
        this.setState({ errorValorColor: false });
      }
    }
    if (valor1 + valor2 === 0) {
      //Validación del tipo de atributo
      if (this.obtenerTipoAtributo(idAtributo).length === 0 && idAtributo != '') { //Tipo Color
        var valCodigoColor = 0;
        if (this.state.ValorColor === '') { //Validación que no venga vació
          this.setState({ colorNotificacion: 'danger', mensaje: 'Complete el campo color', MostrarModal: false, disabled: false });
          this.showNotification();
          valCodigoColor = 1;
        }
        //Validación para agregar los atributos
        if (valCodigoColor === 0) { //Los Campos no estan vacios
          var registroAtributo = [];
          this.state.atributosAgregados.map(atri => {
            registroAtributo.push(atri.idAtributo);
          });
          //Validación que el atributo no se agrege doble
          if (registroAtributo.indexOf(idAtributo) === -1) { //No se ha agregado el atributo
            //Nuevo objeto con los valores asignados
            var arrayAtributo = { idAtributo: idAtributo, NombreAtributo: this.obtenerNombre(idAtributo, 2), ValorColor: ValorColor };
            //Agregando el nuevo objeto a los atributos agregados
            this.state.atributosAgregados.push(arrayAtributo);
            //Limpiando los inputs
            this.setState({ idAtributo: '', ValorColor: '', disableDesplegable: true, colorNotificacion: 'success', mensaje: 'Se agregó el atributo', tipoAtributo: false, MostrarModal: false, disabled: false });
            this.showNotification();
          } else { //Ya se agregó el atributo
            this.setState({ colorNotificacion: 'danger', mensaje: 'Ya esta agregado el atributo', MostrarModal: false, disabled: false });
            this.showNotification();
          }
        }
      } else { //Tipo Desplegable     
        // Validar que se agrego por lo menos un valor        
        if (AtributoDesplegable !== "") {  //Agregó valores
          var registroDesplegable = []
          this.state.atributosAgregados.map(a => {
            registroDesplegable.push(a.idAtributo)
          });
          //Validar si el atributo ya fue agregado
          var valExistencia = registroDesplegable.indexOf(idAtributo)
          if (valExistencia === -1) { //No ha sido agregado
            //Agregando el atributo a los atributos del producto            
            this.state.atributosAgregados.push({ idAtributo: idAtributo, NombreAtributo: this.obtenerNombre(idAtributo, 2), ValorColor: '', ValorDesplegable: AtributoDesplegable })
            //Limpiando los inputs
            this.setState({ MostrarModal: false, disabled: false, idAtributo: '', ValorColor: '', disableDesplegable: true, colorNotificacion: 'success', mensaje: 'Se agregó el atributo', tipoDesplegable: [], AtributoDesplegable: '' });
            this.showNotification();
          } else { //Ya se agrego este atributo
            this.setState({ colorNotificacion: 'danger', MostrarModal: false, disabled: false, mensaje: 'Ya esta agregado el atributo' });
            this.showNotification();
          }
        } else { //No agregó valores
          this.setState({ colorNotificacion: 'danger', MostrarModal: false, disabled: false, mensaje: 'Agregue por lo menos un valor' });
          this.showNotification();
        }
      }
    }
  }

  editarAtributo(idAtributo) {
    var posicionAtributo = this.state.atributosAgregados.findIndex(f => f.idAtributo == idAtributo)
    var objectAtributos = this.state.atributosAgregados[posicionAtributo]
    // Validar que tipo de atributo es
    if (Object.values(objectAtributos).length === 4) { //Desplegable     
      this.setState({ idAtributo: idAtributo, ValorColor: Object.values(objectAtributos)[2], tipoDesplegable: this.obtenerValoresDesplegables(idAtributo), tipoAtributo: false, AtributoDesplegable: Object.values(objectAtributos)[3] });
      // Object.values(objectAtributos)[3].map(addValor => {
      //   this.state.simpleSelect.push(addValor)
      // });
      this.state.atributosAgregados.splice(posicionAtributo, 1);
      this.setState({ disableDesplegable: true })
    } else { //Color
      this.setState({ idAtributo: idAtributo, ValorColor: Object.values(objectAtributos)[2], tipoAtributo: true })
      this.state.atributosAgregados.splice(posicionAtributo, 1);
      this.setState({ disableDesplegable: true })
    }
  }

  eliminarAtributo(idAtributo) {
    this.state.atributosAgregados.splice(this.state.atributosAgregados.findIndex(f => f.idAtributo == idAtributo), 1);
    this.setState({ colorNotificacion: 'success', mensaje: 'Se eliminó el atributo' });
  }

  //PESTAÑA CARACTERÍSTICA
  agregarCaracteristica(idCaracteristica, ValorCaracteristica) {
    var valor1 = 0;
    var valor2 = 0;
    //Validar que se haya escogido una característica y valor
    if (idCaracteristica === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Selecciona una característica', erroridCaracteristica: true });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ erroridCaracteristica: false });
      valor1 = 0;
    }
    if (ValorCaracteristica === 0 || ValorCaracteristica === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Agregue valor a la característica', errorValorCaracteristica: true });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorValorCaracteristica: false });
      valor1 = 0;
    }
    if (valor1 + valor2 === 0) {
      var array1 = []
      this.state.caracteristicasAgregadas.map(car => {
        array1.push(car.idCaracteristica, car.NombreCaracteristica, car.ValorCaracteristica)
      });
      //Validar si ya esta agregada la característica
      if (array1.indexOf(idCaracteristica) >= 0) {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Ya esta agregada esta característica', erroridCaracteristica: true });
        this.showNotification();
      } else {
        var arrayCaracteristica = { idCaracteristica: idCaracteristica, NombreCaracteristica: this.obtenerNombre(idCaracteristica, 1), ValorCaracteristica: ValorCaracteristica };
        this.state.caracteristicasAgregadas.push(arrayCaracteristica);
        this.setState({ idCaracteristica: '', ValorCaracteristica: 0, colorNotificacion: 'success', mensaje: 'Se agregó correctamente la característica', erroridCaracteristica: false });
        this.showNotification();
      }
    }
  }

  editarCaracteristica(idCaracteristica) {

    var posicionCaracteristica = this.state.caracteristicasAgregadas.findIndex(f => f.idCaracteristica == idCaracteristica)
    var objectCaracteristica = this.state.caracteristicasAgregadas[posicionCaracteristica]
    this.setState({ idCaracteristica: objectCaracteristica.idCaracteristica, ValorCaracteristica: objectCaracteristica.ValorCaracteristica });
    this.state.caracteristicasAgregadas.splice(posicionCaracteristica, 1);
  }

  eliminarCaracteristica(idCaracteristica) {
    var posicionCaracteristica = this.state.caracteristicasAgregadas.findIndex(f => f.idCaracteristica == idCaracteristica)
    this.state.caracteristicasAgregadas.splice(posicionCaracteristica, 1);
    this.setState({ colorNotificacion: 'warning', mensaje: 'Se eliminó la característica' });
    this.showNotification();
  }

  //PESTAÑA DE MATERIALES
  agregarMaterial(idMaterial, ValorMaterial) {
    var valor1 = 0;
    var valor2 = 0;
    //Validación de id que no venga vacio
    if (idMaterial === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Seleccione un material', erroridMaterial: true })
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ erroridMaterial: false });
      valor1 = 0;
    }
    //Validación de precio que no venga vacio
    if (ValorMaterial === '') {
      this.setState({ colorNotificacion: 'danger', mensaje: 'Ingrese el valor del material', errorValorMaterial: true })
      this.showNotification();
      valor2 = 1;
    } else {

      this.setState({ errorValorMaterial: false });
      valor2 = 0;
    }
    //Validación si no estan vacios el id y precio
    if (valor1 + valor2 === 0) {
      var array1 = []
      this.state.materialesAgregados.map(material => {
        array1.push(material.idMaterial)
      });
      //Validar si ya esta agregada la característica
      if (array1.indexOf(idMaterial) >= 0) {
        this.setState({ colorNotificacion: 'danger', mensaje: 'Ya esta agregado este material', erroridMaterial: true });
        this.showNotification();
        // this.calculosCostoMaterial();
      } else {
        var arrayMateriales = { idMaterial: idMaterial, NombreMaterial: this.obtenerNombre(idMaterial, 3), ValorMaterial: ValorMaterial };
        this.state.materialesAgregados.push(arrayMateriales);
        /**quite idMateriaPrima:""  , NombreUnidad: '',en la liena de abajo */
        this.setState({ idMaterial: '', ValorMaterial: '', colorNotificacion: 'success', mensaje: 'Se agregó correctamente el material', erroridMaterial: false });
        this.calculosCostoMaterial();
        this.showNotification();
      }
    }
  }

  editarMaterial(idMaterial) {
    var posicionMaterial = this.state.materialesAgregados.findIndex(f => f.idMaterial == idMaterial)
    var objectMaterial = this.state.materialesAgregados[posicionMaterial]
    this.setState({ idMaterial: idMaterial, ValorMaterial: Object.values(objectMaterial)[2] })
    this.state.materialesAgregados.splice(posicionMaterial, 1);
    this.calculosCostoMaterial();
  }

  eliminarMaterial(idMaterial) {
    this.state.materialesAgregados.splice(this.state.materialesAgregados.findIndex(f => f.idMaterial == idMaterial), 1);
    this.setState({ colorNotificacion: 'success', mensaje: 'Se eliminó el material' });
    this.calculosCostoMaterial();
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.preview,
      previewVisible: true,
    });
  };
  

  registrarProducto() {
    console.log("llego si ")
    if (this.state.ProductoNuevo == true) {
      this.RegistrarNuevoProductoInicial();
    } else {
      this.ActualizarProducto();
    }
  }

  ActualizarProducto() {

    //Obtener las imagenes 

    this.setState({
      MostrarModal: true,
      disabled: true
    });
    console.log(this.state)
    var valido = true;
      if (this.state.NombreProducto.trim() == '') {
        this.setState({ errorNombreProducto: true, colorNotificacion: 'danger', mensaje: "Complete el nombre del producto", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorNombreProducto: false, MostrarModal: false, disabled: false });
      }
      if (this.state.Descripcion.trim() == '') {
        this.setState({ errorDescripcion: true, colorNotificacion: 'danger', mensaje: "Complete la descripción", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorDescripcion: false });
      }
      if (this.state.TipoProducto.trim() == '') {
        this.setState({ errorTipoProducto: true, colorNotificacion: 'danger', mensaje: "Seleccione un tipo de producto", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorTipoProducto: false });        
      }     
      if (this.state.ImagenProducto == null) {
        this.setState({ errorImagen: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorImagen: false });      
      }
      if (this.state.TexturaProducto == null && this.state.NombreTextura == undefined) {
        this.setState({ errorTextura: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      }
      else {
        this.setState({ errorTextura: false });      
      }
      if (this.state.caracteristicasAgregadas.length === 0) {
        this.setState({ colorNotificacion: 'danger', mensaje: "Agregue por lo menos una caracteristica", erroridCaracteristica: true, MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ erroridAtributo: false, errorPrecioAtributo: false, MostrarModal: false, disabled: false });        
      }
      if (this.state.Tamano.trim() == '') {
        this.setState({ errorTamano: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorTamano: false, MostrarModal: false, disabled: false });        
      }
      if (this.state.idMateriaPrima!=null && this.state.idMateriaPrima.trim() == '') {
        this.setState({ erroridMateriaPrima: true, colorNotificacion: 'danger', mensaje: "Seleccione una materia prima", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ erroridMateriaPrima: false, MostrarModal: false, disabled: false });        
      }
      if (this.state.GruposMaterial.length == 0) {
        this.setState({ erroridMaterial: true, colorNotificacion: 'danger', mensaje: "Agregue por lo menos un grupo de materiales", errorCantidad: true, MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ erroridMaterial: false, errorCantidad: false });        
      }
      if (this.state.CostoMaterial === null) {
        this.setState({ errorCostoMaterial: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorCostoMaterial: false });        
      }
      if (this.state.CostoMaquila === null) {
        this.setState({ errorCostoMaquila: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorCostoMaquila: false });        
      }
      if (this.state.TipoPrecio.trim() == '') {
        this.setState({ errorTipoPrecio: true, colorNotificacion: 'danger', mensaje: "Seleccione un tipo de precio", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorTipoPrecio: false });        
      }
      //Validar el tipo de precio
      if (this.state.TipoPrecio === 'PorcentajeUtilidad') {
        if ((this.state.PorcentajeUtilidad.toString()).trim() === '') {
          this.setState({ errorPorcentajeUtilidad: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
          this.showNotification();
          valido = false;
        } else {
          this.setState({ errorPorcentajeUtilidad: false });
        }
      } else if (this.state.TipoPrecio === 'PrecioDirecto') {
        this.setState({ errorPorcentajeUtilidad: false });
        if (this.state.PrecioPublico.toString().trim() === '') {
          this.setState({ errorPrecioPublico: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido" });
          this.showNotification();
          valido = false;
        } else {
          this.setState({ errorPrecioPublico: false });          
        }
      }
      if ((this.state.PorcentajeComision.toString()).trim() === '') {
        this.setState({ errorPorcentajeComision: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorPorcentajeComision: false });        
      }
      if (this.state.Peso!=null && this.state.Peso.toString() === '') {
        this.setState({ errorPeso: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
        valido = false;
      } else {
        this.setState({ errorPeso: false });        
      }


      if (this.state.idGrupo === "") {
        valido = false;
        this.setState({ errorGrupo: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disabled: false });
        this.showNotification();
      }
    
    /**validar si todos los grupos ya fueron capturados */
    var grupoValido = true;
    this.state.GruposMaterial.map(mat => {
      if (mat.ImporteComision != undefined) {
        if (mat.PorcentajeUtilidad < this.state.PorcentajeUtilidadSistema) {
          mat["Autorizado"] = false;
        } else {
          mat["Autorizado"] = true;
        }
        if (mat.TipoPrecio == "PorcentajeUtilidad") {
          if (mat.PorcentajeUtilidad == 0) {
            grupoValido = false;
          }
        }
        if (mat.TipoPrecio == "PrecioDirecto") {
          if (mat.PrecioPublico == 0) {
            grupoValido = false;
          }
        }
      } else {
        grupoValido = false;
      }
    })

    if (grupoValido == false) {
      this.setState({ colorNotificacion: 'danger', mensaje: "Faltan datos en grupos de materiales", MostrarModal: false, disabled: false });
      this.showNotification();
    }

    //Validar si hay campos requeridos
    if (valido && grupoValido) 
    {
      this.setState({
        MostrarModal: true
      })
      fetch(Globales.Url + 'Productos/Productos/ActualizarProducto', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({
          _id: this.props._id,
          NombreProducto: this.state.NombreProducto,
          TipoProducto: this.state.TipoProducto,
          Descripcion: this.state.Descripcion,
          Tamano: this.state.Tamano,
          ImagenProducto: this.state.ImagenProducto,
          NombreImagenProducto: this.state.NombreImagenProducto,
          TexturaProducto: this.state.TexturaProducto,
          NombreTextura: this.state.NombreTextura,
          TempleteRecorte: this.state.TempleteRecorte,
          NombreTempleteRecorte: this.state.NombreTempleteRecorte,
          caracteristicasAgregadas: this.state.caracteristicasAgregadas,
          atributosAgregados: this.state.atributosAgregados,
          idModelo: this.state.idModelo,
          idMateriaPrima: this.state.idMateriaPrima,
          materialesAgregados: this.state.materialesAgregados,
          CostoMaterial: this.state.CostoMaterial,
          CostoMaquila: this.state.CostoMaquila,
          TipoPrecio: this.state.TipoPrecio,
          PorcentajeUtilidad: this.state.PorcentajeUtilidad,
          PrecioPublico: this.state.PrecioPublico,
          PorcentajeComision: this.state.PorcentajeComision,
          ImporteComision: this.state.ImporteComision,
          ImporteUtilidad: this.state.ImporteUtilidad,
          Peso: this.state.Peso,
          PorcentajeUtilidadSistema: this.state.PorcentajeUtilidadSistema,
          PrecioSinIva: this.state.PrecioSinIva,
          IVA: this.state.valorIva,
          idCategoria: this.state.idCategoria,
          AutorizarProducto: this.state.AutorizarProducto,
          GruposMaterial: this.state.GruposMaterial
        })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModal: false, disabled: false });
            this.showNotification();
          } else {

            //Enviar mail de notificación
            //EnviarMail(this.state.CorreoAutorizado, 'Verificación de Producto', Contenido);
            var Contenido = ContenidoMail(data.data, this.state.valorIva, cookies.get("NombreUsuario"))
            
            //EnviarMail(this.state.Empresa[0].CorreoAutorizacionPrecios, 'Verificación de Producto', Contenido); //descomentar 
            
            //Validar el porcentaje de utilidad
            if (data.data.Autorizado === true) { //Es menor el porcentanje de utilidad del producto que del sistema

              var Contenido = ContenidoMail(data.data, this.state.valorIva, cookies.get("NombreUsuario"))
              //EnviarMail(this.state.Empresa[0].CorreoAutorizacionPrecios, 'Verificación de Producto', Contenido); //descomentar
              this.setState({ colorNotificacion: 'success', MostrarModal: false, disabled: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            } else {
              this.setState({ colorNotificacion: 'success', MostrarModal: false, disabled: false });

              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          }
        })
        .catch(err => console.error(err));
    }
  }

  RegistrarNuevoProductoInicial() {
    this.setState({
      MostrarModal: true,
      disabled: true
    });
    var valido = true;
    /**validaciones perronas meza 2020 */
    if (this.state.NombreProducto.trim() == '') {
      this.setState({ errorNombreProducto: true, colorNotificacion: 'danger', mensaje: "Complete el nombre del producto", MostrarModal: false, disabled: false });
      this.showNotification();
      valido = false;
    } else {
      this.setState({ errorNombreProducto: false, MostrarModal: false, disabled: false });
    }
    if (this.state.Descripcion.trim() == '') {
      this.setState({ errorDescripcion: true, colorNotificacion: 'danger', mensaje: "Complete la descripción", MostrarModal: false, disabled: false });
      this.showNotification();
      valido = false;
    } else {
      this.setState({ errorDescripcion: false });
    }
    if (this.state.TipoProducto.trim() == '') {
      this.setState({ errorTipoProducto: true, colorNotificacion: 'danger', mensaje: "Seleccione un tipo de producto", MostrarModal: false, disabled: false });
      this.showNotification();
      valido = false;
    } else {
      this.setState({ errorTipoProducto: false });
    }
    if (valido) {
      fetch(Globales.Url + 'Productos/Productos/RegistrarProductoNuevoProducto', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }, body: JSON.stringify({
          NombreProducto: this.state.NombreProducto,
          TipoProducto: this.state.TipoProducto,
          Descripcion: this.state.Descripcion,
          Tamano: this.state.Tamano,
          idCategoria: this.state.idCategoria,
        })
      }).then(res => res.json()).then(data => {
        if (data.error) {
          this.setState({ colorNotificacion: 'danger', MostrarModal: false, disabled: false, mensaje: data.mensaje });
          this.showNotification();
        } else {
          this.setState({ colorNotificacion: 'success', MostrarModal: false, disabled: false });
          this.closeModal(data.mensaje, this.state.colorNotificacion);
          //Mostrar la vista de edicion para el producto
          this.mostrarEdicion(data);
        }
      }).catch(err => console.error(err));
    }
  }

  CrearGrupoMateriales() {
    /**1 REGISTRO, 2 ACTUALIZAR */
    /**validar datos de nuevo grupo */
    var validoNombre = true;
    var validoMateriales = true;
    var materialesState = [];
    this.state.materialesAgregados.map(m => {
      materialesState.push(m.idMaterial);
    })


    this.state.GruposMaterial.map(grupo => {
      if (this.state.ActualizarGrupo == true) {
        if (this.state.NombreGrupoTemporal != grupo.NombreGrupo) {
          if (grupo.NombreGrupo == this.state.NombreGrupo) {
            validoNombre = false;
          }
        }

      } else {
        if (grupo.NombreGrupo == this.state.NombreGrupo) {
          validoNombre = false;
        }
      }

      var materialesGrupo = [];

      if (this.state.ActualizarGrupo == true) {
        if (grupo.NombreGrupo != this.state.NombreGrupoTemporal) {
          grupo.Materiales.map(m => {
            materialesGrupo.push(m.idMaterial)
          })
        }

      } else {
        grupo.Materiales.map(m => {
          materialesGrupo.push(m.idMaterial)
        })
      }

      if (materialesState.toString() === materialesGrupo.toString()) {

        validoMateriales = false;
      }


    })

    var costoMaterial = 0;
    this.state.materialesAgregados.map(m => {

      this.state.Material.map(c => {

        if (m.idMaterial == c._id) {
          costoMaterial = costoMaterial + (c.CostoUnidad * parseFloat(m.ValorMaterial))
        }
      })
    })


    var Grupo = { NombreGrupo: this.state.NombreGrupo, Materiales: this.state.materialesAgregados, CostoMaterial: costoMaterial };


    if (validoNombre == true && validoMateriales == true && this.state.NombreGrupo != "") {

      if (this.state.ActualizarGrupo == true) {
        this.state.GruposMaterial.map(m => {
          if (this.state.idGrupoActualizar == m.id) {
            m.NombreGrupo = Grupo.NombreGrupo;
            m.Materiales = Grupo.Materiales;
            m.CostoMaterial = Grupo.CostoMaterial;

          }
        })
        this.state.GruposMaterial.map((m, index) => {


          m["id"] = new mongoose.Types.ObjectId().toHexString();
        })
        this.ActualizarInformacionPrecio();

      } else {
        this.state.GruposMaterial.push(Grupo);
        this.state.GruposMaterial.map((m, index) => {


          m["id"] = new mongoose.Types.ObjectId().toHexString();
        })

      }

      var msj = this.state.ActualizarGrupo ? "Grupo actualizado" : "Grupo creado"
      this.setState({
        colorNotificacion: 'success', mensaje: msj,
        materialesAgregados: [],
        NombreGrupo: "",
        errorNombreGrupo: false,
        ActualizarGrupo: false,
        idGrupoActualizar: ''
      }, () => {
        this.setState({
          idGrupoActualizar: ''
        })
        if (this.state.ActualizarGrupo == true) {

        }


      })

      this.showNotification();
    } else {
      if (this.state.NombreGrupo == "") {
        this.setState({ colorNotificacion: 'warning', mensaje: "Ingrese un nombre", errorNombreGrupo: true });
        this.showNotification();
        return;
      }
      if (validoNombre == false) {
        this.setState({ colorNotificacion: 'warning', mensaje: "Ingrese un nombre diferente" });
        this.showNotification();
        return;
      }
      if (validoMateriales == false) {
        this.setState({ colorNotificacion: 'warning', mensaje: "Grupo de materiales repetido" });
        this.showNotification();
        return;
      }
    }



  }

  ActualizarInformacionPrecio() {
    this.state.GruposMaterial.map(m => {
      this.handleChangeGrupos(m.id);
      this.setState({
        idGrupo: m.id,
      }, () => {
        this.handleChange(m.CostoMaterial.toString());

      })

    })

  }

  EditarGrupos(Opcion, grupo) {
    /**1 EDITAR 2 ELIMINAR */
    if (Opcion == 1) {
      this.setState({
        materialesAgregados: grupo.Materiales,
        NombreGrupo: grupo.NombreGrupo,
        NombreGrupoTemporal: grupo.NombreGrupo,
        ActualizarGrupo: true,
        idGrupoActualizar: grupo.id
      })
    }
    if (Opcion == 2) {
      var o = this.state.GruposMaterial.findIndex(f => f.id == grupo.id);
      this.state.GruposMaterial.splice(o, 1);
      this.state.GruposMaterial.map((m, index) => {
        m["id"] = mongoose.Types.ObjectId();
      })
      this.setState({ colorNotificacion: 'danger', mensaje: "Grupo eliminado" });
      this.showNotification();

    }
  }
  EventoTabla(tab) {
    this.setState({
      TabIndex: tab
    })
  }

  handleChangeGrupos(e) {

    var nombre = "";
    var valor = "";

    if (typeof (e) == "string") {

      nombre = "idGrupo";
      valor = e;
    } else {
      nombre = e.target.name;
      valor = e.target.value;
    }

    this.state.PrecioPublico = '';
    this.state.valorIva = '';
    this.state.PrecioSinIva = '';
    this.state.ImporteComision = '';
    this.state.PorcentajeComision = '';

    this.state.PorcentajeUtilidad = '';
    this.state.ImporteUtilidad = '';
    this.state.PrecioSinIva = '';
    this.state.CostoMaquila = '';


    this.state.GruposMaterial.map(grupo => {
      if (grupo.id == valor) {
        this.state.CostoMaterial = grupo.CostoMaterial
        /**directo */
        if (grupo.TipoPrecio == "PorcentajeUtilidad") {
          this.state.PrecioPublico = grupo.PrecioPublico;
          this.state.valorIva = grupo.valorIva;
          this.state.PrecioSinIva = grupo.PrecioSinIva;
          this.state.ImporteComision = grupo.ImporteComision;
          this.state.PorcentajeComision = grupo.PorcentajeComision;
          this.state.PorcentajeUtilidad = grupo.PorcentajeUtilidad;
          this.state.disPorcentaje = false;
          this.state.CostoMaquila = grupo.CostoMaquila;
          this.state.ImporteUtilidad = grupo.ImporteUtilidad;


          this.state.Precios = true;
          this.state.mostraImporte = false;
          this.state.disPorcentaje = false;
          this.state.DisPrecioPublico = true;
          this.state.TipoPrecio = grupo.TipoPrecio;
        } else if (grupo.TipoPrecio == "PrecioDirecto") {
          /**utilidad */
          this.state.PrecioPublico = grupo.PrecioPublico;
          this.state.PorcentajeUtilidad = grupo.PorcentajeUtilidad;
          this.state.ImporteUtilidad = grupo.ImporteUtilidad;
          this.state.valorIva = grupo.valorIva;
          this.state.PrecioSinIva = grupo.PrecioSinIva;
          this.state.ImporteComision = grupo.ImporteComision;
          this.state.PorcentajeComision = grupo.PorcentajeComision;
          this.state.disPorcentaje = true;
          this.state.CostoMaquila = grupo.CostoMaquila;
          this.state.ImporteUtilidad = grupo.ImporteUtilidad;



          this.state.Precios = true;
          this.state.mostraImporte = true;
          this.state.DisPrecioPublico = false;
          this.state.disPorcentaje = true;
          this.state.TipoPrecio = grupo.TipoPrecio;
        }
      }

    })

    this.setState({
      [nombre]: valor
    })

  }
  EventoAdvertenciaMaterial(opcion) {

    if (opcion == true) {
      this.setState({
        GruposMaterial: [],
        materialesAgregados: [],
        VerModalAdvertencia: false
      })

      var idUnidad = "";
      global.MaterialesPorMateriaPrima = [];
      this.setState({ materialesAgregados: [] })

      //Recorriendo las materias primas para obtener el ID de unidad de medida
      this.state.MateriaPrima.map(materiasPrimas => {
        if (this.state.idMateriaPrima === materiasPrimas._id) {
          idUnidad = materiasPrimas.idUnidadMedida;
        }
      });
      //Obteniendo los materiales relacionados a la materia prima seleccionada
      this.state.Material.map(material => {
        if (material.idMateriaPrima === this.state.idMateriaPrima) {

          global.MaterialesPorMateriaPrima.push({ idMaterial: material._id, NombreMaterial: material.NombreMaterial });
        }
      });
      //Recorrriendo las unidades de medida para obtener el nombre
      this.state.UnidadMedida.map(unidades => {
        if (idUnidad === unidades._id) {
          this.state.NombreUnidad = unidades.NombreUnidad
        }
      });
      //Validar que exista material
      var indicadorExistencia = global.arrayIdsMateriales.indexOf(this.state.idMateriaPrima)

      if (indicadorExistencia === -1) {
        this.setState({ colorNotificacion: 'danger', mensaje: 'La materia prima seleccionada no cuenta con ningún material registrado, verifique', disabledBtnGuardar: true, valMaterial: true });
        this.showNotification();
      } else {
        this.setState({ disabledBtnGuardar: false, valMaterial: false });
      }
    } else {

      this.setState({
        VerModalAdvertencia: false,
        idMateriaPrima: global.idMateriaPrima,
      })
    }
    // global.idMateriaPrima="";
  }

  AutorizarProducto(Tipo, idGrupo) {
    this.setState({
      MostrarModal: true
    })
    fetch(Globales.Url + 'Productos/Productos/AutorizarProducto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ _id: this.props._id, Tipo: Tipo, idGrupo: idGrupo, Usuario: cookies.get("NombreUsuario") })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {
          this.setState({
            MostrarModal: false
          })
          this.closeModal(data.mensaje, "danger");
        } else {
          this.setState({ MostrarModal: false });
          this.closeModal(data.mensaje, 'success');
        }
      })
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      this.state.Mangas = true;
    } else {
      newChecked.splice(currentIndex, 1);
      this.state.Mangas = false;
      this.setState({
        ArrayModelo3D: [],
        ArrayTextura: [],      

      })
      document.getElementById("Textura").value = "";
    }



    this.setState({
      checked: newChecked,
    })
  }

  getFiles(event) {

    var origen = event.target.name;
    this.setState({
      [origen + "Subida"]: true
    })
    var preview = document.getElementById(origen + "1");
    var file = document.getElementById(origen).files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      var base64 = reader.result;
      if (origen == "Imagen") {
        preview.src = base64;        
        this.setState({ NombreImagenProducto: file.name, ImagenProducto: base64 });
      }      
      if (origen == "Textura") {
        this.setState({NombreTextura: file.name, TexturaProducto: base64 });
      }
      if (origen == "TempleteRecorte") {
        this.setState({NombreTempleteRecorte: file.name, TempleteRecorte: base64 });
      }
      this.setState({
        [origen + "Subida"]: false
      }, () => {
      })
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      preview.src = "";
    }
  }


  render() {
    const { classes } = this.props;
    const popover = {
      position: 'fixed',
      zIndex: '100',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const { previewVisible, previewImage } = this.state;
    //Definir las opciones de los tab para validar si es uno nuevo
    let ContenidoTabsProductoNuevo = this.state.ProductoNuevo == false ?
      <GridContainer>

      </GridContainer>
      : null;

    return (

      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" style={{ width: '900px' }} size="xl">
          <Cargando
            show={this.state.MostrarModal}
            Cuerpo="Cargando"
          />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardBody>
                  <CustomTabs
                    EventoTabla={this.EventoTabla}
                    value={this.state.TabIndex}
                    plainTabs
                    headerColor="info"
                    tabs={this.state.ProductoNuevo == false ? [
                      {
                        tabName: "Básico",
                        tabContent: (
                          <div>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <CustomInput
                                  labelText="Nombre Producto"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.NombreProducto,
                                    name: "NombreProducto",
                                    id: "NombreProducto",
                                    type: "text",
                                    error: this.state.errorNombreProducto
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <CustomInput
                                  labelText="Descripción"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.Descripcion,
                                    name: "Descripcion",
                                    id: "Descripcion",
                                    type: "text",
                                    error: this.state.errorDescripcion
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.errorTipoProducto} className="mt-4">
                                  <InputLabel>Tipo de producto</InputLabel>
                                  <Select value={this.state.TipoProducto} onChange={this.handleSimple} name="TipoProducto" id="TipoProducto">
                                    <MenuItem disabled>Tipo de producto</MenuItem>
                                    <MenuItem value="Estandar">Estándar</MenuItem>
                                    <MenuItem value="Paquete">Paquete</MenuItem>
                                  </Select>
                                </FormControl>
                              </GridItem>

                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.errorTipoProducto} className="mt-4">
                                  <InputLabel>Categoría</InputLabel>
                                  <Select value={this.state.idCategoria} onChange={this.handleSimple} name="idCategoria" id="idCategoria">
                                    <MenuItem disabled>Categoría</MenuItem>
                                    {
                                      this.state.Categorias.map(m =>
                                        <MenuItem value={m._id}>{m.NombreCategoriaPrincipal} </MenuItem>

                                      )
                                    }
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={6}>

                                <FormControl fullWidth error={this.state.errorTamano} className="mt-4">
                                  <InputLabel>Tamaño (alias)</InputLabel>
                                  <Select value={this.state.Tamano} onChange={this.handleChange.bind(this)} name="Tamano" id="Tamano">
                                    <MenuItem disabled>Tamaño</MenuItem>
                                    {
                                      this.state.Tallas.map(m =>
                                        <MenuItem value={m._id}>{m.NombreTalla} </MenuItem>

                                      )
                                    }
                                  </Select>
                                </FormControl>


                              </GridItem>
                            </GridContainer>

                            <div className={classes.space30} />

                          </div>
                        )
                      },
                      {
                        tabName: "Modelo 3D",
                        tabContent: (
                          <div>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {
                                      this.state.errorImagen ? <Danger>
                                        <h5>Imagen</h5>
                                      </Danger> : <h5>Imagen</h5>}
                                    <input id="Imagen" name="Imagen" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />
                                    {
                                      this.state.ImagenSubida == true ?
                                        <label for="Imagen"><Publish />
                                          Subiendo &nbsp; <Loader style={{ float: 'right' }} visible={true} type="Oval" color="#212121" height={12} width={12} />
                                        </label>
                                        :
                                        <label for="Imagen"><Publish />
                                          Seleccione
                                        </label>
                                    }
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <img width="125" height="125" id="Imagen1" src={this.state.ImagenProducto !=null ? this.state.ImagenProducto : ""} alt="Esperando imagen..." />
                                    <label style={{ color: 'black', maxWidth: '170px' }}>{this.state.NombreImagenProducto}</label>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                {this.state.errorTextura ?
                                  <div className={classes.typo}>
                                    <Danger>
                                      <h5>Textura</h5>
                                    </Danger> </div> :
                                  <div className={classes.typo}>
                                    <h5>Textura</h5>
                                  </div>
                                }
                                <div id="prueba">
                                  <input id="Textura" name="Textura" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />
                                  {
                                    this.state.TexturaSubida == true ?
                                      <label for="Textura"><Publish />
                                        Subiendo
                                        &nbsp; <Loader style={{ float: 'right' }} visible={true} type="Oval" color="#212121" height={12} width={12} />
                                      </label>
                                      :
                                      <label for="Textura"><Publish /> Seleccione</label>
                                  }
                                  <label style={{ color: 'black' }}>{this.state.NombreTextura}</label>
                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                {this.state.errorTempleteRecorte ?
                                  <div className={classes.typo}>
                                    <Danger>
                                      <h5>Template Recorte</h5>
                                    </Danger> </div> :
                                  <div className={classes.typo}>
                                    <h5>Template Recorte</h5>
                                  </div>
                                }
                                <div id="prueba1">
                                  <input id="TempleteRecorte" name="TempleteRecorte" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />
                                  {
                                    this.state.TempleteRecorteSubida == true ?
                                      <label for="TempleteRecorte"><Publish />
                                        Subiendo
                                        &nbsp; <Loader style={{ float: 'right' }} visible={true} type="Oval" color="#212121" height={12} width={12} />
                                      </label>
                                      :
                                      <label for="TempleteRecorte"><Publish /> Seleccione</label>
                                  }
                                  <label style={{ color: 'black' }}>{this.state.NombreTempleteRecorte}</label>
                                </div>
                              </GridItem>
                              {/* <GridItem xs={12} sm={12} md={4} lg={4}>
                                <FormControlLabel
                                  className="mt-4"
                                  control={
                                    <Checkbox
                                      style={{ color: "gray" }}
                                      color="default"
                                      tabIndex={-1}
                                      onClick={() => this.handleToggle(0)}
                                      checked={this.state.checked.indexOf(0) !== -1 ? true : false}
                                    // checkedIcon={<CamisaIcono />}
                                    // icon={<CamisaIcono />}
                                    // classes={{ checked: classes.checked, root: classes.checkRoot }}
                                    />
                                  }
                                  style={{ color: "gray" }}
                                  label={" Diseño con mangas"}

                                />
                              </GridItem> */}
                            </GridContainer>
                            <ListaCapasModelo 
                              idProducto={this.state._id}
                            />
                          </div>
                        )
                      },                      
                      {
                        tabName: "Atributos",
                        tabContent: (
                          <div>
                            <GridContainer justify='center'>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <FormControl fullWidth error={this.state.erroridAtributo} className={'mt-4'}>
                                  <InputLabel>Atributo</InputLabel>
                                  <Select value={this.state.idAtributo} onChange={this.handleSimpleAtributos} name="idAtributo" id="idAtributo" >
                                    <MenuItem disabled>Atributos</MenuItem>
                                    {this.state.Atributos.map(atributos =>
                                      <MenuItem value={atributos._id}>{atributos.NombreAtributo}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                {this.state.tipoAtributo === true ?
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomInput
                                      labelText="Color"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        required: true,
                                        onChange: this.handleChange,
                                        value: this.state.ValorColor,
                                        name: "ValorColor",
                                        id: "ValorColor",
                                        type: "text",
                                        disabled: this.state.disableDesplegable,
                                        error: this.state.errorValorColor
                                      }}
                                    />
                                    {this.state.displayColorPicker ? <div style={popover}>
                                      <div style={cover} onClick={this.handleClose} />
                                      <SwatchesPicker onChangeComplete={this.handleChangeComplete} />
                                    </div> : null}
                                  </GridItem>
                                  :
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <FormControl fullWidth error={this.state.errorSeleccionCaracteristicas} className={'mt-4'}>
                                      <InputLabel>Seleccione atributo</InputLabel>
                                      <Select value={this.state.AtributoDesplegable} onChange={this.handleChange} name="AtributoDesplegable" id="AtributoDesplegable" >
                                        <MenuItem disabled>Atributos</MenuItem>
                                        {this.state.tipoDesplegable.map(atributos =>
                                          <MenuItem value={atributos}>{atributos}</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>

                                  </GridItem>
                                }
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <Button size="sm" color="success" className="pull-right mt-5" round onClick={() => this.agregarAtributos(this.state.idAtributo, this.state.ValorColor, this.state.AtributoDesplegable)}><AddBox />Agregar</Button>
                              </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card >
                                  <CardHeader color="info" text>
                                    <h4>Atributos Agregados</h4>
                                  </CardHeader>
                                  <CardBody>
                                    {this.state.atributosAgregados.length === 0 ?
                                      <GridContainer justify='center'>
                                        <GridItem>
                                          <h5>No hay atributos agregados</h5>
                                        </GridItem>
                                      </GridContainer>
                                      :
                                      <div className="table-wrapper-scroll-y">
                                        <table className="col-xs-12 col-sm-12 col-md-12">
                                          <thead>
                                            <th className="center">Atributo</th>
                                            <th className="center">Valor Color</th>
                                            <th className="center">Valor Desplegable</th>
                                            <th className="pull-right">Acciones</th>
                                          </thead>
                                          <tbody>
                                            {this.state.atributosAgregados.map(atributo => {
                                              return (
                                                <tr key={atributo.idAtributo}>
                                                  <td>{atributo.NombreAtributo}</td>
                                                  <td>{atributo.ValorColor}</td>
                                                  <td>{atributo.ValorDesplegable}</td>
                                                  {/* <td>{this.mostrarAtributosDesplegables(atributo.ValorDesplegable)}</td> */}
                                                  <td className="pull-right">
                                                    <Button color="warning" round justIcon size="sm" onClick={() => this.editarAtributo(atributo.idAtributo)}><Edit /></Button>
                                                    <Button color="danger" round justIcon size="sm" onClick={() => this.eliminarAtributo(atributo.idAtributo)}><Close /></Button>
                                                  </td>
                                                </tr>
                                              )

                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    }
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        )
                      },
                      {
                        tabName: "Características",
                        tabContent: (
                          <div>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <FormControl fullWidth error={this.state.erroridCaracteristica} className="mt-4">
                                  <InputLabel>Característica</InputLabel>
                                  <Select value={this.state.idCaracteristica} onChange={this.handleSimple} name="idCaracteristica" id="idCaracteristica">
                                    <MenuItem disabled>Característica</MenuItem>
                                    {this.state.Caracteristicas.map(caracteristica =>
                                      <MenuItem value={caracteristica._id}>{caracteristica.NombreCaracteristica}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <CustomInput
                                  labelText="Valor"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange,
                                    value: this.state.ValorCaracteristica,
                                    name: "ValorCaracteristica",
                                    id: "ValorCaracteristica",
                                    type: "text",
                                    error: this.state.errorValorCaracteristica
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <Button size="sm" color="success" className="pull-right mt-5" round onClick={() => this.agregarCaracteristica(this.state.idCaracteristica, this.state.ValorCaracteristica)}><AddBox />Agregar</Button>
                              </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Card >
                                  <CardHeader color="info" text>
                                    <h4>Características Agregadas</h4>
                                  </CardHeader>
                                  <CardBody>
                                    {this.state.caracteristicasAgregadas.length === 0 ?
                                      <GridContainer justify='center'>
                                        <GridItem>
                                          <h5>No hay características agregadas</h5>
                                        </GridItem>
                                      </GridContainer>
                                      :
                                      <div className="table-wrapper-scroll-y">
                                        <table className="col-xs-12 col-sm-12 col-md-12">
                                          <thead>
                                            <th className="center">Característica</th>
                                            <th className="center">Valor</th>
                                            <th className="pull-right">Acciones</th>
                                          </thead>
                                          <tbody>
                                            {this.state.caracteristicasAgregadas.map(car => {
                                              return (
                                                <tr key={car.idCaracteristica}>
                                                  <td>{car.NombreCaracteristica}</td>
                                                  <td>{car.ValorCaracteristica}</td>
                                                  <td className="pull-right">
                                                    <Button color="warning" round justIcon size="sm" onClick={() => this.editarCaracteristica(car.idCaracteristica)}><Edit /></Button>
                                                    <Button color="danger" round justIcon size="sm" onClick={() => this.eliminarCaracteristica(car.idCaracteristica)}><Close /></Button>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </div>}
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        )
                      },
                      {
                        tabName: "Composición",
                        tabContent: (
                          <div>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.erroridMateriaPrima} className="mt-4">
                                  <InputLabel>Materia Prima</InputLabel>
                                  <Select value={this.state.idMateriaPrima} onChange={this.handleSimple} name="idMateriaPrima" id="idMateriaPrima">
                                    <MenuItem disabled>Materia Prima</MenuItem>
                                    {this.state.MateriaPrima.map(materiaPrima =>
                                      <MenuItem value={materiaPrima._id}>{materiaPrima.NombreMateria}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <CustomInput
                                  labelText="Unidad de Medida"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange,
                                    value: this.state.NombreUnidad,
                                    name: "NombreUnidad",
                                    id: "valor",
                                    type: "text",
                                    disabled: true
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <FormControl disabled={this.state.valMaterial} fullWidth error={this.state.erroridMaterial} className="mt-4">
                                  <InputLabel>Material</InputLabel>
                                  <Select value={this.state.idMaterial} onChange={this.handleSimple} name="idMaterial" id="idMaterial">
                                    <MenuItem disabled>Material</MenuItem>
                                    {global.MaterialesPorMateriaPrima.map(material =>
                                      <MenuItem value={material.idMaterial}>{material.NombreMaterial}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <CustomInput
                                  labelText="Cantidad"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: this.state.valMaterial,
                                    required: true,
                                    onChange: this.handleChange,
                                    value: this.state.ValorMaterial,
                                    name: "ValorMaterial",
                                    id: "ValorMaterial",
                                    type: "number",
                                    error: this.state.errorValorMaterial
                                  }}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4}>
                                <Button disabled={this.state.disabledBtnGuardar} size="sm" color="success" className="pull-right mt-5" round onClick={() => this.agregarMaterial(this.state.idMaterial, this.state.ValorMaterial)}><AddBox />Agregar</Button>
                              </GridItem>
                            </GridContainer>
                            <GridContainer >
                              <GridItem xs={12} sm={12} md={7} lg={7}>
                                <Card >
                                  <CardHeader color="info" text>
                                    <h4>Materiales Agregadas</h4>
                                  </CardHeader>
                                  <CardBody>
                                    {this.state.materialesAgregados.length === 0 ?
                                      <GridContainer justify='center'>
                                        <GridItem>
                                          <h5>No hay materiales agregados</h5>
                                        </GridItem>
                                      </GridContainer>
                                      :
                                      <div className="table-wrapper-scroll-y">
                                        <table className="col-xs-12 col-sm-12 col-md-12">
                                          <thead>
                                            <th className="center">Material</th>
                                            <th className="center">Cantidad</th>
                                            <th className="pull-right">Acciones</th>
                                          </thead>
                                          <tbody>
                                            {this.state.materialesAgregados.map(material => {
                                              return (
                                                <tr key={material.idMaterial}>
                                                  <td>{material.NombreMaterial}</td>
                                                  <td>{material.ValorMaterial}</td>
                                                  <td className="pull-right">
                                                    <Button color="warning" round justIcon size="sm" onClick={() => this.editarMaterial(material.idMaterial)}><Edit /></Button>
                                                    <Button color="danger" round justIcon size="sm" onClick={() => this.eliminarMaterial(material.idMaterial)}><Close /></Button>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>

                                        <div>
                                          <GridContainer>
                                            <GridItem xs={12} sm={12} md={7} lg={7}>
                                              <CustomInput
                                                labelText="Nombre grupo"
                                                formControlProps={{
                                                  fullWidth: true
                                                }}
                                                inputProps={{
                                                  // disabled: this.state.valMaterial,
                                                  required: true,
                                                  onChange: this.handleChange,
                                                  value: this.state.NombreGrupo,
                                                  name: "NombreGrupo",
                                                  id: "NombreGrupo",
                                                  type: "text",
                                                  error: this.state.errorNombreGrupo
                                                }}
                                              />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3} lg={3}>

                                              <Button onClick={() => this.CrearGrupoMateriales()} className="mt-5" color="success" round size="sm" >
                                                {this.state.ActualizarGrupo ? "Actualizar" : "Crear grupo"}
                                              </Button>

                                            </GridItem>
                                          </GridContainer>

                                        </div>
                                      </div>



                                    }

                                  </CardBody>
                                </Card>
                              </GridItem>

                              <GridItem xs={12} sm={12} md={5} lg={5}>
                                <Card >
                                  <CardHeader color="info" text>
                                    <h4>Grupos</h4>
                                  </CardHeader>
                                  <CardBody>
                                    {this.state.GruposMaterial.length === 0 ?
                                      <GridContainer justify='center'>
                                        <GridItem>
                                          <h5>No hay grupos</h5>
                                        </GridItem>
                                      </GridContainer>
                                      :
                                      <div className="table-wrapper-scroll-y">
                                        <table className="col-xs-12 col-sm-12 col-md-12">
                                          <thead>
                                            <th className="center">Nombre</th>
                                            <th className="pull-right">Acciones</th>
                                          </thead>
                                          <tbody>
                                            {this.state.GruposMaterial.map(material => {
                                              return (
                                                <tr >
                                                  <td>{material.NombreGrupo}</td>
                                                  <td className="pull-right">

                                                    <Button disabled={this.state.idGrupoActualizar === material.id ? true : false} color="warning" round justIcon size="sm" onClick={() => this.EditarGrupos(1, material)}><Edit /></Button>
                                                    <Button disabled={this.state.idGrupoActualizar === material.id ? true : false} color="danger" round justIcon size="sm" onClick={() => this.EditarGrupos(2, material)}><Close /></Button>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                        </table>
                                      </div>}

                                  </CardBody>
                                </Card>
                              </GridItem>

                            </GridContainer>

                          </div>
                        )
                      },
                      {
                        tabName: "Precio",
                        tabContent: (
                          <div>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={3} lg={3}>
                                <FormControl fullWidth error={this.state.errorGrupo} className="mt-4">
                                  <InputLabel>Grupos materiales</InputLabel>
                                  <Select value={this.state.idGrupo} onChange={this.handleChangeGrupos} name="idGrupo" id="idGrupo">
                                    {
                                      this.state.GruposMaterial.map(Grupo =>
                                        <MenuItem value={Grupo.id}>{Grupo.NombreGrupo}</MenuItem>

                                      )
                                    }
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={3} lg={3}>
                                <CustomInput
                                  labelText="Costo Material"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.CostoMaterial,
                                    name: "CostoMaterial",
                                    id: "CostoMaterial",
                                    type: "number",
                                    disabled: true,
                                    error: this.state.errorCostoMaterial
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={3} lg={3}>
                                <CustomInput
                                  labelText="Costo Maquila"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.CostoMaquila,
                                    name: "CostoMaquila",
                                    id: "CostoMaquila",
                                    type: "number",
                                    error: this.state.errorCostoMaquila
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={3} lg={3}>
                                <CustomInput
                                  labelText="Peso Gral. (KG)"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.Peso,
                                    name: "Peso",
                                    id: "Peso",
                                    type: "number",
                                    error: this.state.errorPeso
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.errorTipoPrecio} className="mt-4">
                                  <InputLabel>Tipo de Precio</InputLabel>
                                  <Select value={this.state.TipoPrecio} onChange={this.handleTipoPrecio} name="TipoPrecio" id="TipoPrecio">
                                    <MenuItem disabled>Tipo Precio</MenuItem>
                                    <MenuItem value="PorcentajeUtilidad">Porcentaje Utilidad</MenuItem>
                                    <MenuItem value="PrecioDirecto">Precio Directo</MenuItem>
                                  </Select>
                                </FormControl>
                              </GridItem>
                              {this.state.Precios ?
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                  <CustomInput
                                    labelText="Porcentaje Utilidad"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      required: true,
                                      onChange: this.handleChange.bind(this),
                                      onBlur: this.handlePorcentajeUtilidad,
                                      value: this.state.PorcentajeUtilidad,
                                      name: "PorcentajeUtilidad",
                                      id: "PorcentajeUtilidad",
                                      type: "number",
                                      error: this.state.errorPorcentajeUtilidad,
                                      disabled: this.state.disPorcentaje
                                    }}
                                  />
                                </GridItem>
                                : null}
                            </GridContainer>
                            {this.state.Precios ? <div>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                  <CustomInput
                                    labelText="Precio Público (IVA incluido)"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      required: true,
                                      onChange: this.handleChange.bind(this),
                                      // onBlur: this.handlePrecioPublicoOnBlur,
                                      value: this.state.PrecioPublico,
                                      name: "PrecioPublico",
                                      id: "PrecioPublico",
                                      type: "text",
                                      error: this.state.errorPrecioPublico,
                                      disabled: this.state.DisPrecioPublico
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                  <CustomInput
                                    labelText="Valor IVA"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      required: true,
                                      onChange: this.handleChange.bind(this),
                                      value: this.state.valorIva,
                                      name: "ValorIva",
                                      id: "ValorIva",
                                      type: "text",
                                      disabled: true
                                    }}
                                  />
                                </GridItem>
                                {/* {this.state.mostraImporte ? */}
                                <GridItem xs={12} sm={12} md={3} lg={3}>
                                  <CustomInput
                                    labelText="Importe Utilidad"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      disabled: true,
                                      required: true,
                                      onChange: this.handleChange.bind(this),
                                      value: this.state.ImporteUtilidad - this.state.ImporteComision,
                                      name: "ImporteUtilidad",
                                      id: "ImporteUtilidad",
                                      type: "number",
                                      error: this.state.errorImporteUtilidad
                                    }}
                                  />
                                </GridItem>
                                {/* : null} */}
                                <GridItem xs={12} sm={12} md={3} lg={3}>
                                  <CustomInput
                                    labelText="Porcentaje Comisión"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      required: true,
                                      onChange: this.handleChange.bind(this),
                                      value: this.state.PorcentajeComision,
                                      name: "PorcentajeComision",
                                      id: "PorcentajeComision",
                                      type: "number",
                                      error: this.state.errorPorcentajeComision
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3} lg={3}>
                                  <CustomInput
                                    labelText="Importe Comisión"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      required: true,
                                      disabled: true,
                                      onChange: this.handleChange.bind(this),
                                      value: this.state.ImporteComision,
                                      name: "ImporteComision",
                                      id: "ImporteComision",
                                      type: "number",
                                    }}
                                  />

                                </GridItem>
                                {/* {
                                  this.state.VerBotonAutorizarGeneral == false ?
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                      <Button onClick={() => this.AutorizarProducto(2,this.state.idGrupo)} size="sm" color="info" className="pull-right mt-5" round ><Warning />&nbsp;AUTORIZAR</Button>
                                    </GridItem> : null
                                } */}


                              </GridContainer>
                            </div> : null}
                          </div>
                        )
                      }
                    ]
                      :
                      [{
                        tabName: "Información Principal",
                        tabContent: (
                          <div>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <CustomInput
                                  labelText="Nombre Producto"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.NombreProducto,
                                    name: "NombreProducto",
                                    id: "NombreProducto",
                                    type: "text",
                                    error: this.state.errorNombreProducto
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <CustomInput
                                  labelText="Descripción"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    required: true,
                                    onChange: this.handleChange.bind(this),
                                    value: this.state.Descripcion,
                                    name: "Descripcion",
                                    id: "Descripcion",
                                    type: "text",
                                    error: this.state.errorDescripcion
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.errorTipoProducto} className="mt-4">
                                  <InputLabel>Tipo de producto</InputLabel>
                                  <Select value={this.state.TipoProducto} onChange={this.handleSimple} name="TipoProducto" id="TipoProducto">
                                    <MenuItem disabled>Tipo de producto</MenuItem>
                                    <MenuItem value="Estandar">Estándar</MenuItem>
                                    <MenuItem value="Paquete">Paquete</MenuItem>
                                  </Select>
                                </FormControl>
                              </GridItem>

                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.errorTipoProducto} className="mt-4">
                                  <InputLabel>Categoría</InputLabel>
                                  <Select value={this.state.idCategoria} onChange={this.handleSimple} name="idCategoria" id="idCategoria">
                                    <MenuItem disabled>Categoría</MenuItem>
                                    {
                                      this.state.Categorias.map(m =>
                                        <MenuItem value={m._id}>{m.NombreCategoriaPrincipal} </MenuItem>

                                      )
                                    }
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth error={this.state.errorTamano} className="mt-4">
                                  <InputLabel>Tamaño (alias)</InputLabel>
                                  <Select value={this.state.Tamano} onChange={this.handleChange.bind(this)} name="Tamano" id="Tamano">
                                    <MenuItem disabled>Tamaño</MenuItem>
                                    {
                                      this.state.Tallas.map(m =>
                                        <MenuItem value={m._id}>{m.NombreTalla} </MenuItem>

                                      )
                                    }
                                  </Select>
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <div className={classes.space30} />
                          </div>
                        )
                      }]
                    }
                  />
                  <GridItem>
                    {
                      this.state.VerBotonAutorizar != true ?
                        <Button size="sm" color="info" disable={this.state.disabled} className="pull-right" round onClick={this.registrarProducto.bind(this)}><Save />&nbsp;GUARDAR</Button>
                        :
                        <Button size="sm" color="info" disable={this.state.disabled} className="pull-right" round onClick={() => this.AutorizarProducto(1, "")}><Save />&nbsp;AUTORIZAR</Button>

                    }
                    <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
                  </GridItem>
                  <ModalAnt visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </ModalAnt>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>


          <Modal isOpen={this.state.VerModalAdvertencia} className="static-modal" size="sm">
            <ModalHeader>
              Advertencia
            </ModalHeader>
            <ModalBody>
              Al cambiar de materia prima se perderán los grupos de materiales, ¿Desea continuar?
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="warning" className="pull-right" round onClick={() => this.EventoAdvertenciaMaterial(false)} ><Clear />&nbsp;CANCELAR</Button>
              <Button size="sm" color="success" className="pull-right" round onClick={() => this.EventoAdvertenciaMaterial(true)} ><Check />&nbsp;ACEPTAR</Button>
            </ModalFooter>
          </Modal>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(AgregarProducto);