import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { Modal } from 'reactstrap';
import ModalFoliosGuias from './ModalAgregarFolioGuias'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import cookies from 'js-cookie';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "components/CustomButtons/Button.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Table from '@material-ui/core/Table';
import Radio from '@material-ui/core/Radio';
import imageBackground from "assets/img/sidebar-3.jpg";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Select from "@material-ui/core/Select";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from "components/Card/Card.jsx";
import SwipeableViews from 'react-swipeable-views';
import CardHeader from "components/Card/CardHeader.jsx";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalAgregarUsuarioAdministrativo from "views/Modulos/Administracion/Usuarios/ModalAgregarUsuarioAdministrativo.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";


class usuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            RecoleccionSolcitada: 0,
            Productos: [],
            idsPorActualizar: [],
            valueSelect: 0,
            VentasPorActualizar:[],
            MostrarNotificacion: false,
            colorNotificacion: '',
            MostrarNotificacion: false,
            mensajeNotificacion: '',
            showModalSolicitarRecoleccion: false,
            FolioFiltro: "",
            NombreClienteFiltro: '',
            checksTodos:[],
            MostrarCargando:true
        };
        this.handleChange = this.handleChange.bind(this);
        this.ObtenerComprasConcluidas = this.ObtenerComprasConcluidas.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.solicitarRecoleccion = this.solicitarRecoleccion.bind(this);
        this.filtrarComprasRecoleccion = this.filtrarComprasRecoleccion.bind(this);
        this.abrirModalSolicitudRecoleccion = this.abrirModalSolicitudRecoleccion.bind(this);
        this.handleChangeRadioTodos = this.handleChangeRadioTodos.bind(this);
    }
    componentDidMount() {
        this.ObtenerComprasConcluidas();
    };
    ObtenerComprasConcluidas() {
        fetch(Globales.Url + 'Compras/ObtenerComprasConcluidas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) {

                } else {
                    this.setState({
                        Productos: data.data,
                        MostrarCargando:false
                    });
                }
            });
    };

    solicitarRecoleccion(e) {
        console.log(e)
        if (!e) {
            this.setState({ showModalSolicitarRecoleccion: false });
            return;
        }else{
            this.setState({MostrarCargando:true,
                showModalSolicitarRecoleccion: false});
            fetch(Globales.Url + 'Carrito/Carrito/GenerarEnvio', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({ idsCompras: e, idUsuarioRegistro: cookies.get("id") })
            })
                .then(res => res.json())
                .then(data => {
                    console.log("--------------este es labels--------------------")
                    console.log(data)
                    if(data.error == true){
                        this.setState({
                            colorNotificacion: 'danger',
                            MostrarNotificacion: true,
                            mensajeNotificacion:data.mensaje,
                            MostarModalDias: false,
                            MostrarCargando:false,
                            showModalSolicitarRecoleccion: false
                        });
                        this.alertTimeout = setTimeout(
                            function () {
                                this.setState({
                                    MostrarNotificacion: false,
                                    colorNotificacion: '',
                                    MostrarNotificacion: false,
                                    MostrarCargando:false,
                                    mensajeNotificacion: '',
                                    valueSelect: 0,
                                    showModalSolicitarRecoleccion: false
                                });
                            }.bind(this),
                            2000
                        );
                    }else{
                        this.setState({
                            colorNotificacion: 'success',
                            MostrarCargando:false,
                            MostrarNotificacion: true,
                            mensajeNotificacion: (this.state.idsPorActualizar.length > 1 ? 'Recolecciones solicitadas' : 'Recoleccion solicitada'),
                            MostarModalDias: false,
                        });
                        this.alertTimeout = setTimeout(
                            function () {
                                this.setState({
                                    MostrarNotificacion: false,
                                    colorNotificacion: '',
                                    MostrarNotificacion: false,
                                    MostrarCargando:false,
                                    mensajeNotificacion: '',
                                    valueSelect: 0,
                                    showModalSolicitarRecoleccion: false
                                });
                            }.bind(this),
                            2000
                        );
        
                        this.state.idsPorActualizar.forEach((idName) => {
                            document.getElementsByName(idName)[0].setAttribute("checked", false)
                        });
                        this.setState({
                            idsPorActualizar: []
                        }, () => {
                            this.ObtenerComprasConcluidas();
                        });
                    }
                    
                });
        }
        
    }

    handleChangeRadio(event) {
        var arrayStateIdsPorActualzar = this.state.idsPorActualizar;
        if (arrayStateIdsPorActualzar.includes(event.target.name) == false) {
            arrayStateIdsPorActualzar.push(event.target.name);
            this.setState({
                idsPorActualizar: arrayStateIdsPorActualzar
            });
            document.getElementsByName(event.target.name)[0].setAttribute("checked", false)
        } else {
            var arrayNuevo = []
            for (var keyArray = 0; keyArray < arrayStateIdsPorActualzar.length; keyArray++) {
                if (event.target.name == arrayStateIdsPorActualzar[keyArray]) {
                } else {
                    arrayNuevo.push(arrayStateIdsPorActualzar[keyArray]);
                }
            }
            this.setState({
                idsPorActualizar: arrayNuevo
            })
        }
    }

    handleChangeRadioTodos(event) {
        var evento = event;
            if (this.state.checksTodos.includes(event.target.id) == true) {
                var idsProductos = [];
                this.state.Productos.map(producto => {
                    if (this.state.idsPorActualizar.includes(producto._id) == false) {
                        idsProductos.push(producto._id);
                    }
                });
                this.setState({
                    idsPorActualizar: idsProductos
                }, () => {
                    var newCheckTodos = [];
                    for (var keyRadio = 0; keyRadio < this.state.checksTodos.length; keyRadio++) {
                        if (Number.parseInt(this.state.checksTodos[keyRadio]) != this.state.TabSeleccionada + 1) {
                            newCheckTodos.push(this.state.checksTodos[keyRadio] - 1)
                        }
                    }
                    this.setState({ checksTodos: newCheckTodos }, () => {
                    })
                })
            } else  {
                var idsProductos = this.state.idsPorActualizar;
                this.state.Productos.map(producto => {
                    if (this.state.idsPorActualizar.includes(producto._id) == false) {
                        idsProductos.push(producto._id);
                    }
                });
                var newCheckTodos = this.state.checksTodos;
                newCheckTodos.push(event.target.id)
                this.setState({
                    idsPorActualizar: idsProductos,
                    checksTodos: newCheckTodos
                }, () => {
                })
            }
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    filtrarComprasRecoleccion() {
        fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/FiltrarComprasConEstatusDePedidoRecoleccion', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ Folio: this.state.FolioFiltro, NombreCliente: this.state.NombreClienteFiltro })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    Productos: data.data
                });
            });
    }
    abrirModalSolicitudRecoleccion() {
        var venta = new Array();
        for(var idVenta of this.state.idsPorActualizar){
            for(var Venta of this.state.Productos){
                if(Venta._id == idVenta){
                    venta.push(Venta)
                }
            }
        }
        this.setState({
            VentasPorActualizar: venta
        },()=>{
            this.setState({
                showModalSolicitarRecoleccion: true,
            })
        });
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Solicitudes de guía</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem>
                                        <CustomInput
                                            labelText="Folio"
                                            formControlProps={{

                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FolioFiltro,
                                                name: "FolioFiltro",
                                                id: "FolioFiltro",
                                                type: "text",
                                                error: this.state.errorFolioFiltro
                                            }}
                                            className="mt-5"
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <CustomInput
                                            labelText="Nombre del cliente"
                                            formControlProps={{
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.NombreClienteFiltro,
                                                name: "NombreClienteFiltro",
                                                id: "NombreClienteFiltro",
                                                type: "text",
                                                error: this.state.errorNombreClienteFiltro
                                            }}
                                            className="mt-5"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={this.filtrarComprasRecoleccion.bind(this)}><Search />Buscar</Button>
                                        <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.setState({
                                            RecoleccionSolcitada: 0,
                                            Productos: [],
                                            idsPorActualizar: [],
                                            valueSelect: 0,
                                            MostrarNotificacion: false,
                                            colorNotificacion: '',
                                            MostrarNotificacion: false,
                                            mensajeNotificacion: '',
                                            FolioFiltro: "",
                                            NombreClienteFiltro: ''
                                        }, () => {
                                            this.ObtenerComprasConcluidas();
                                        })}><Clear />Limpiar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div hidden={(this.state.Productos.length > 0 ? false : true)}>
                    <GridContainer justify="center"  >
                        <GridItem xs={12} sm={12} md={10}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.RecoleccionSolcitada}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab label="Guías por generar" onClick={() => this.setState({ RecoleccionSolcitada: 0, idsPorActualizar: [] })} />
                                    <Tab label="Guías generadas" onClick={() => this.setState({ RecoleccionSolcitada: 1, idsPorActualizar: [] })} />
                                </Tabs>
                            </AppBar>
                            <GridItem>
                                <GridContainer>
                                    <br />
                                    <br />
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            <TableRow fullWidth>
                                                {/* <TableCell style={{ backgroundColor: '#777575', color: 'white' }} align="center">Detalle</TableCell> */}

                                                <TableCell fullWidth style={{ backgroundColor: '#777575', color: 'white', textAlign: "center", width: "6000px" }}>
                                                    <GridContainer>
                                                        <GridItem hidden={(this.state.RecoleccionSolcitada == 0? false:true)}>

                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        checked={false}
                                                                        onClick={this.handleChangeRadioTodos.bind(this)}
                                                                        name="radioTodos"
                                                                        id={1}
                                                                        aria-label='A'
                                                                        icon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioUnchecked}
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <FiberManualRecord className={classes.radioChecked} />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label
                                                                }}
                                                                label={"Todos"}
                                                                style={{ color: "white" }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={8} lg={8} >
                                                                            <b style={{ textAlign: "center" }}>Pedidos e información</b>
                                                                        </GridItem>
                                                    </GridContainer>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.Productos.map((producto) => {
                                                if (this.state.RecoleccionSolcitada == producto.RecoleccionSolicitada) {
                                                    return (<GridContainer>
                                                        <GridItem xs={1} sm={1} md={1} lg={1}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        checked={this.state.idsPorActualizar.includes(producto._id)}
                                                                        onClick={this.handleChangeRadio.bind(this)}
                                                                        value={(this.state.idsPorActualizar.includes(producto._id) ? true : false)}
                                                                        name={producto._id}
                                                                        disabled={(this.state.RecoleccionSolcitada == 1 ? true : false)}
                                                                        aria-label='A'
                                                                        icon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioUnchecked}
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <FiberManualRecord className={classes.radioChecked} />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label
                                                                }}
                                                                label={producto.FolioPedido}
                                                                style={{ color: "black" }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={11} lg={11}>
                                                            <ExpansionPanel>
                                                                <ExpansionPanelSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    style={{ paddingRight: "100px" }}
                                                                    fullWidth
                                                                >
                                                                    <GridContainer >
                                                                        <GridItem >
                                                                            <div>
                                                                                Cliente:
                                                                            </div>
                                                                        </GridItem>
                                                                        <label className="pull-rigth">{producto.idCliente.Nombre}</label>
                                                                        <GridItem >
                                                                            Fecha de compra:
                                                                                </GridItem>
                                                                        <label className="pull-rigth">{new Date(producto.FechaDeCompra).toLocaleString('en-US', { timeZone: 'UTC' })}</label>
                                                                        <GridItem>
                                                                            <div hidden={(this.state.RecoleccionSolcitada == 1 ? false : true)}>
                                                                                Fecha de solicitud de recolección:
                                                                                &nbsp;
                                                                                <label className="pull-rigth">{new Date(producto.FechaSolicitudRecoleccion).toLocaleString('en-US', { timeZone: 'UTC' })}</label>
                                                                            </div>
                                                                        </GridItem>
                                                                    </GridContainer>
                                                                </ExpansionPanelSummary>
                                                                {/* <div style={{ backgroundColor: "white" }}>
                                                                    <ExpansionPanelDetails>
                                                                        <GridContainer >
                                                                            <GridItem>
                                                                                Hola
                                                                             </GridItem>
                                                                        </GridContainer>
                                                                    </ExpansionPanelDetails>
                                                                </div> */}
                                                            </ExpansionPanel>
                                                        </GridItem>
                                                    </GridContainer>)
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                    <br />
                                </GridContainer>
                            </GridItem>
                        </GridItem>
                    </GridContainer>
                </div>
                <div hidden={(this.state.Productos.length == 0 ? false : true)}>
                    <GridContainer justify="center">
                        <GridItem>
                            <h4><b>{(this.state.Productos.length == 0 ? "No se encontró información" : "Cargando")}</b></h4>
                        </GridItem>
                    </GridContainer>
                </div>
                <GridContainer hidden={(this.state.idsPorActualizar.length > 0 && this.state.RecoleccionSolcitada == 0 ? false : true)} style={{ marginBottom: 80 }}>
                    <div style={{ backgroundColor: "#CFCFCF", height: 70, position: 'fixed', bottom: 0, left: 0, width: '100%', right: 20, paddingLeft: "50px" }} className="border-top border-dark">
                        <div style={{ paddingLeft: "20em" }}>
                            <InputLabel  >Opción</InputLabel>
                            <Select onClick={this.handleChange.bind(this)} value={this.state.valueSelect} name="valueSelect" id="selectOpcion">
                                <MenuItem value={0}>Solicitar recolección</MenuItem>
                            </Select>
                            <b>Cantidad: {this.state.idsPorActualizar.length}</b>
                            <Button onClick={this.abrirModalSolicitudRecoleccion.bind(this)}
                                size="sm" type="submit" className="pull-right mb-5" color="success" round style={{ marginRight: 10, marginTop: -10 }} >
                                {(this.state.idsPorActualizar.length == 1 ? <Done /> : <DoneAll />)} {this.state.idsPorActualizar.length == 1 ? "Aplicar" : "Aplicar a varios"}</Button>
                        </div>
                    </div>
                </GridContainer>

                <div>
                    {/* <ModalPregunta
                        Titulo={"Solicitar recolección"}
                        Cuerpo={"¿Está seguro que desea solicitar recolección?"}
                        show={this.state.showModalSolicitarRecoleccion}
                        eventClick={this.solicitarRecoleccion.bind(this)}
                        headerColor="info"
                    /> */}
                    <ModalFoliosGuias
                        Ventas={this.state.VentasPorActualizar}
                        eventClick ={this.solicitarRecoleccion.bind(this)}
                        show={this.state.showModalSolicitarRecoleccion}

                    />
                </div>

                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensajeNotificacion}
                        open={this.state.MostrarNotificacion}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
                <Cargando
                    show={this.state.MostrarCargando}
                    Cuerpo="Cargando"
                />
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(usuario);