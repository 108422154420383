import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import FileBase64 from 'react-file-base64';
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear } from '@material-ui/icons';

class ModalAgregarObjeto extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreObjeto: '',
      idCategoria: '',
      Visibilidad: '',
      _id: '',
      objetos: [],
      rutaImagen: [{}],
      categoriaObjetos: [],
      errorNombre: false,
      errorIdCategoria: false,
      errorRutaImagen: false,
      errorVisibilidad: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false
    }
    this.registrarObjeto = this.registrarObjeto.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreObjeto: this.props.NombreObjeto,
        idCategoria: this.props.idCategoria,
        // rutaImagen: this.props.rutaImagen,
        Visibilidad: this.props.Visibilidad
      })
    }
    this.setState({ objetos: this.props.objetos, categoriaObjetos: this.props.categoriaObjetos });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarObjeto() {
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreObjeto.trim() == '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreObjeto: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    if (this.state.idCategoria.trim() == '') {
      this.setState({ errorIdCategoria: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", idCategoria: '' });
      valor2 = 1;
    } else {
      this.setState({ errorIdCategoria: false });
      valor2 = 0;
    }    
    if (this.state.rutaImagen.length > 0) {
      this.setState({ errorRutaImagen: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", rutaImagen: [{}] });
      valor3 = 1;
    } else {
      this.setState({ errorRutaImagen: false });
      valor3 = 0;
    }
    if (this.state.Visibilidad.trim() === '') {
      this.setState({ errorVisibilidad: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido', Visibilidad: '' });
      valor4 = 1;
    } else {
      this.setState({ errorVisibilidad: false });
      valor4 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0 || valor2 > 0 || valor3 || valor4 > 0) {
      this.setState({ colorNotificacion: 'danger', mensaje: "Complete el campo requerido" });
      this.showNotification("tr");
    } else {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Objetos/Objetos/ActualizarObjeto', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({_id: this.props._id, NombreObjeto: this.state.NombreObjeto, idCategoria: this.state.idCategoria, Visibilidad: this.state.Visibilidad, rutaImagen: this.state.rutaImagen.base64})
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreObjeto: '', idCategoria: '', rutaImagen: '', Visibilidad: '', errorNombre: false, errorIdCategoria: false, errorRutaImagen: false, errorVisibilidad: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Objetos/Objetos/RegistrarObjeto', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({NombreObjeto: this.state.NombreObjeto, idCategoria: this.state.idCategoria, Visibilidad: this.state.Visibilidad, rutaImagen: this.state.rutaImagen.base64})
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreObjeto: '', idCategoria: '', rutaImagen: '', errorNombre: false, errorIdCategoria: false, errorRutaImagen: false, errorVisibilidad: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Objeto"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreObjeto,
                  name: "NombreObjeto",
                  id: "NombreObjeto",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <FormControl
                fullWidth
                error={this.state.errorIdCategoria}
              >
                <InputLabel>Categoría de Objeto</InputLabel>
                <Select
                  value={this.state.idCategoria}
                  onChange={this.handleSimple}
                  name="idCategoria"
                  id="idCategoria"
                  inputProps={{
                    error: this.state.errorIdCategoria
                  }}
                >
                  {this.state.categoriaObjetos.map(cat =>
                    <MenuItem value={cat._id}>{cat.NombreCategoria}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                error={this.state.errorVisibilidad}
              >
                <InputLabel>Estatus</InputLabel>
                <Select
                  value={this.state.Visibilidad}
                  onChange={this.handleSimple}
                  name="Visibilidad"
                  id="Visibilidad"
                  inputProps={{
                    error: this.state.Visibilidad
                  }}
                >
                  <MenuItem value='Visible'>Visible</MenuItem>
                  <MenuItem value='Oculto'>Oculto</MenuItem>
                </Select>
              </FormControl>
              <div className={classes.typo}>
                <h5>Imagen</h5>
              </div>
              <div>
                <FileBase64
                  multiple={true}
                  onDone={this.obtenerBaseImagen.bind(this)} />
              </div>
              <GridItem>
                <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
                <Button size="sm" color="info" className="pull-right" round onClick={this.registrarObjeto.bind(this)}><Save />&nbsp;GUARDAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAgregarObjeto);