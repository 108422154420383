import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

const Globales = {

  Url: process.env.NODE_ENV === 'production' ? 
  'https://ws.fabricanook.com/'


  //'http://3.235.201.128:5001/'
//  'http://34.238.174.80:5001/'


  : 'http://localhost:5001/',

  token: 'Bearer ' + cookies.get('token')/*.replace(/['"]+/g, '')*/,
  usuario: localStorage.getItem("Usuario")
}
export default Globales;       
// {
//   "data" : {
//       "id" : "63085",
//       "type" : "shipments",
//       "attributes" : {
//           "status" : "WAITING",
//           "created_at" : "2021-03-17T18:02:04.588-06:00",
//           "updated_at" : "2021-03-17T18:02:04.881-06:00"
//       },
//       "relationships" : {
//           "parcels" : {
//               "data" : [ 
//                   {
//                       "id" : "48790",
//                       "type" : "parcels"
//                   }
//               ]
//           },
//           "rates" : {
//               "data" : [ 
//                   {
//                       "id" : "356054",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356052",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356051",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356050",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356049",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356048",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356047",
//                       "type" : "rates"
//                   }, 
//                   {
//                       "id" : "356046",
//                       "type" : "rates"
//                   }
//               ]
//           },
//           "address_to" : {
//               "data" : {
//                   "id" : "124054",
//                   "type" : "addresses"
//               }
//           },
//           "address_from" : {
//               "data" : {
//                   "id" : "124053",
//                   "type" : "addresses"
//               }
//           },
//           "labels" : {
//               "data" : []
//           }
//       }
//   },
//   "included" : [ 
//       {
//           "id" : "48790",
//           "type" : "parcels",
//           "attributes" : {
//               "length" : "20.0",
//               "height" : "20.0",
//               "width" : "20.0",
//               "weight" : "3.0",
//               "mass_unit" : "KG",
//               "distance_unit" : "CM"
//           }
//       }, 
//       {
//           "id" : "356054",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:13.906-06:00",
//               "updated_at" : "2021-03-17T18:02:14.240-06:00",
//               "amount_local" : "124.0",
//               "currency_local" : "MXN",
//               "provider" : "CARSSA",
//               "service_level_name" : "Nacional",
//               "service_level_code" : "NACIONAL",
//               "service_level_terms" : null,
//               "days" : 10,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "124.0"
//           }
//       }, 
//       {
//           "id" : "356052",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:13.831-06:00",
//               "updated_at" : "2021-03-17T18:02:14.222-06:00",
//               "amount_local" : "251.0",
//               "currency_local" : "MXN",
//               "provider" : "UPS",
//               "service_level_name" : "UPS Express",
//               "service_level_code" : "EXPRESS_SAVER",
//               "service_level_terms" : null,
//               "days" : 5,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "251.0"
//           }
//       }, 
//       {
//           "id" : "356051",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:13.391-06:00",
//               "updated_at" : "2021-03-17T18:02:14.212-06:00",
//               "amount_local" : "190.0",
//               "currency_local" : "MXN",
//               "provider" : "REDPACK",
//               "service_level_name" : "Express",
//               "service_level_code" : "EXPRESS",
//               "service_level_terms" : null,
//               "days" : 2,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "190.0"
//           }
//       }, 
//       {
//           "id" : "356050",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:13.384-06:00",
//               "updated_at" : "2021-03-17T18:02:14.202-06:00",
//               "amount_local" : "121.0",
//               "currency_local" : "MXN",
//               "provider" : "REDPACK",
//               "service_level_name" : "Ecoexpress",
//               "service_level_code" : "ECOEXPRESS",
//               "service_level_terms" : null,
//               "days" : 5,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "121.0"
//           }
//       }, 
//       {
//           "id" : "356049",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:13.296-06:00",
//               "updated_at" : "2021-03-17T18:02:14.189-06:00",
//               "amount_local" : "204.0",
//               "currency_local" : "MXN",
//               "provider" : "ESTAFETA",
//               "service_level_name" : "Servicio Express",
//               "service_level_code" : "ESTAFETA_NEXT_DAY",
//               "service_level_terms" : null,
//               "days" : 2,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "204.0"
//           }
//       }, 
//       {
//           "id" : "356048",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:13.287-06:00",
//               "updated_at" : "2021-03-17T18:02:14.179-06:00",
//               "amount_local" : "181.0",
//               "currency_local" : "MXN",
//               "provider" : "ESTAFETA",
//               "service_level_name" : "Terrestre",
//               "service_level_code" : "ESTAFETA_STANDARD",
//               "service_level_terms" : null,
//               "days" : 5,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "181.0"
//           }
//       }, 
//       {
//           "id" : "356047",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:12.877-06:00",
//               "updated_at" : "2021-03-17T18:02:14.153-06:00",
//               "amount_local" : "232.0",
//               "currency_local" : "MXN",
//               "provider" : "FEDEX",
//               "service_level_name" : "Standard Overnight",
//               "service_level_code" : "STANDARD_OVERNIGHT",
//               "service_level_terms" : null,
//               "days" : 2,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "232.0"
//           }
//       }, 
//       {
//           "id" : "356046",
//           "type" : "rates",
//           "attributes" : {
//               "created_at" : "2021-03-17T18:02:12.828-06:00",
//               "updated_at" : "2021-03-17T18:02:14.113-06:00",
//               "amount_local" : "200.0",
//               "currency_local" : "MXN",
//               "provider" : "FEDEX",
//               "service_level_name" : "Fedex Express Saver",
//               "service_level_code" : "FEDEX_EXPRESS_SAVER",
//               "service_level_terms" : null,
//               "days" : 5,
//               "duration_terms" : null,
//               "zone" : null,
//               "arrives_by" : null,
//               "out_of_area" : false,
//               "out_of_area_pricing" : "0.0",
//               "total_pricing" : "200.0"
//           }
//       }, 
//       {
//           "id" : "124054",
//           "type" : "addresses",
//           "attributes" : {
//               "name" : "15222",
//               "company" : "-",
//               "address1" : "permanides 15 15",
//               "address2" : "vu",
//               "city" : "cul",
//               "province" : "MX",
//               "zip" : "82180",
//               "country" : "MXN",
//               "phone" : "1223123122",
//               "email" : "corrreo@oac.com",
//               "created_at" : "2021-03-17T18:02:04.498-06:00",
//               "updated_at" : "2021-03-17T18:02:04.498-06:00",
//               "reference" : null,
//               "province_code" : null,
//               "contents" : "Mercancía TERCO"
//           }
//       }, 
//       {
//           "id" : "124053",
//           "type" : "addresses",
//           "attributes" : {
//               "name" : "Terco Playeras SA de CV",
//               "company" : "srenvio",
//               "address1" : "Tamarindo 322",
//               "address2" : "Ignacio Allende",
//               "city" : "MXN",
//               "province" : "sn",
//               "zip" : "80010",
//               "country" : "MXN",
//               "phone" : "6671919190",
//               "email" : "tercoStyle@terco.com.mx",
//               "created_at" : "2021-03-17T18:02:04.370-06:00",
//               "updated_at" : "2021-03-17T18:02:04.370-06:00",
//               "reference" : null,
//               "province_code" : null,
//               "contents" : null
//           }
//       }
//   ]
// }
