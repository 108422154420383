import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import AgregarCapa from "views/Modulos/Administracion/Productos/AgregarCapaProducto.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Warning, FileCopy } from "@material-ui/icons";
import ReactLoading from "react-loading";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


class ListaCapasModelo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ListaCapasProducto: [],
            Titulo: "",
            showAgregar: false,
            mensajeNotificacion: "",
            colorNotificacion: "",
            idProducto: null,
            CargandoCapas: false,
            CapaEditar: null,
            idCapaEliminar: null,
            modalPregunta: false,
            br: false

        };
        this.eventoModalCapa = this.eventoModalCapa.bind(this);
        this.MostrarPreguntaEliminar = this.MostrarPreguntaEliminar.bind(this);
    }

    componentDidMount() {
        //Validar si es una edicion para obtener las capas del producto
        if (this.props.idProducto != null) {
            this.setState({ idProducto: this.props.idProducto }, () =>
                this.ObtenerCapasProducto())
        }
        else {

        }
    }

    ObtenerCapasProducto() {
        this.setState({ CargandoCapas: true })
        fetch(Globales.Url + 'Productos/Productos/ObtenerCapasPorProducto', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ idProducto: this.state.idProducto })
        }
        ).then(res => {
            global.response = res.status;
            res.json()
                .then(data => {
                    this.setState({ CargandoCapas: false })
                    if (data.error) { //Error al obtener los registros
                        this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
                        this.showNotification();
                    } else { //Obtuvo los registros correctamente
                        console.log(data)
                        this.setState({ ListaCapasProducto: data.data });
                    }
                })
                .catch(err => console.error(err));
        });
    }

    //Función que recupera valores para el modal de agregar
    eventoModalCapa = (mensaje, colorNotificacion) => {
        //Validar que el mensaje venga diferente de vacio
        if (mensaje !== 'esc') {
            this.setState({ mensajeNotificacion: mensaje, colorNotificacion: colorNotificacion })
            this.showNotification();
            //Obtener las capas
            this.ObtenerCapasProducto();
        }
        //Cambiar valores por default
        this.setState({ showAgregar: false, Titulo: "Nueva Capa" })
    }

    //Función para abrir el modal de agregar registro
    agregarCapa = () => {
        this.setState({ showAgregar: true, Titulo: "Nueva Capa" });
    }

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función de editar registro
    editarCapa(Capa) {
        this.setState({
            CapaEditar: Capa,
            showAgregar: true,
            Titulo: "Editar Capa",
        })
    }

    //Función para eliminar una capa
    eventoEliminarCapa(idCapa) {        
        this.setState({ modalPregunta: true, idCapaEliminar: idCapa });
    }

    //Función para habilitar el modal de eliminar
  MostrarPreguntaEliminar(e) {
    if (!e) {
      this.setState({ modalPregunta: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarCapa();
  }

   //Función de eliminar registro
   eliminarCapa() {
    fetch(Globales.Url + 'Productos/Productos/EliminarCapaProducto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idCapa: this.state.idCapaEliminar })
    }
    ).then(res => res.json())
      .then(data => {
          console.log(data)
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensajeNotificacion: data.mensaje, show: false, modalPregunta: false, idCapaEliminar: null });
        this.showNotification("tr");
        this.ObtenerCapasProducto();
      });
  }


    render() {
        let agregar;
        if (this.state.showAgregar == true) {
            agregar =
                <AgregarCapa
                    Titulo={this.state.Titulo}
                    idProducto={this.state.idProducto}
                    eventClick={this.eventoModalCapa}                   
                    showAgregar={this.state.showAgregar}
                    Capa={this.state.CapaEditar}
                />
        }
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify='center'>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        {/* <Button size="sm" color="success" className="pull-right mt-5" round onClick={() => this.agregarCapa()}><AddBox />Agregar Capa</Button> */}
                        <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.agregarCapa()}><AddCircle />Agregar</Button>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card >
                            <CardHeader color="info" text>
                                <h4>Capas del producto</h4>
                            </CardHeader>
                            <CardBody>
                                {this.state.ListaCapasProducto.length === 0 ?
                                    <GridContainer justify='center'>
                                        <GridItem>
                                            <h5>No hay capas agregadas</h5>
                                        </GridItem>
                                    </GridContainer>
                                    :
                                    <div className="table-wrapper-scroll-y">
                                        <table className="col-xs-12 col-sm-12 col-md-12">
                                            <thead>
                                                <th className="center">Nombre</th>
                                                <th className="center">Proporcion X</th>
                                                <th className="center">Proporcion Y</th>
                                                <th className="center">Posición X</th>
                                                <th className="center">Posición Y</th>
                                                <th className="center">Rotación</th>
                                                <th className="pull-right">Acciones</th>
                                            </thead>
                                            <tbody>
                                                {this.state.ListaCapasProducto.map(Capa => {
                                                    return (
                                                        <tr key={Capa._id}>
                                                            <td>{Capa.NombreCapa}</td>
                                                            <td>{Capa.ProporcionX}</td>
                                                            <td>{Capa.ProporcionY}</td>
                                                            <td>{Capa.PosicionX}</td>
                                                            <td>{Capa.PosicionY}</td>
                                                            <td>{Capa.GradosRotacion}</td>
                                                            <td className="pull-right">
                                                                <Tooltip id="close1" title="Editar" placement="top" classes={{ tooltip: classes.tooltip }}>
                                                                    <Button color="warning" round justIcon size="sm" onClick={() => this.editarCapa(Capa, 0)} ><Edit /></Button>
                                                                </Tooltip>
                                                                <Tooltip id="close1" title="Eliminar" placement="top" classes={{ tooltip: classes.tooltip }}>
                                                                    <Button color="danger" round justIcon size="sm" onClick={() => this.eventoEliminarCapa(Capa._id)}><Close /></Button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {
                                            this.state.CargandoCapas ?
                                                <GridContainer justify='center'>
                                                    <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                                                </GridContainer>
                                                : null
                                        }
                                    </div>
                                }

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {agregar}
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensajeNotificacion}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                <div id="">
                    <ModalPregunta
                        Titulo={"Eliminar Capa"}
                        Cuerpo={"¿Está seguro que desea eliminar la capa?"}
                        show={this.state.modalPregunta}
                        eventClick={this.MostrarPreguntaEliminar}
                    />                    
                </div>
            </div >
        )
    }
}

export default withStyles(headerLinksStyle)(ListaCapasModelo);