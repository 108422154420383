import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Danger from "components/Typography/Danger.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import FileBase64 from 'react-file-base64';
import { Save, Clear } from '@material-ui/icons';

export default class AgregarTipografia extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreTipografia: '',
      Fuente: [],
      _id: '',
      errorNombreTipografia: false,
      errorFuente: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      MostrarModal:false,
      disable: false
    }
    this.registrarTipografia = this.registrarTipografia.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreTipografia: this.props.NombreTipografia,
        NombreGuardado: this.props.NombreTipografia,
        _id: this.props._id
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarTipografia() {
    this.setState({
      MostrarModal:true,
      disable: true
    })
    var valor1 = 0;
    var valor2 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreTipografia.trim() == '') {
      this.setState({ errorNombreTipografia: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreTipografia: '',MostrarModal:false, disable: false });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombreTipografia: false });
      valor1 = 0;
    }
    if (this.state.Fuente.length === 0) {
      this.setState({ errorFuente: true, colorNotificacion: 'danger', mensaje: "Seleccione una fuente",MostrarModal:false, disable: false  });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ errorFuente: false });
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Tipografias/Tipografias/ActualizarTipografia', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.state._id, NombreTipografia: this.state.NombreTipografia, Fuente: this.state.Fuente.base64, NombreFuente: this.state.Fuente.name })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreTipografia: '', Fuente: [], errorNombreTipografia: false, errorFuente: false,MostrarModal:false, disable: false  });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Tipografias/Tipografias/RegistrarTipografia', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreTipografia: this.state.NombreTipografia, Fuente: this.state.Fuente.base64, NombreFuente: this.state.Fuente.name })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje ,MostrarModal:false, disable: false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreTipografia: '', Fuente: [], errorNombreTipografia: false, errorFuente: false,MostrarModal:false, disable: false  });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreTipografia') {
      //Validar si es una actualización
      if (this.props.op === 1) {
        //Validar si se ingresa otro nombre
        if (this.state.NombreGuardado !== value) {
          fetch(Globales.Url + 'Tipografias/Tipografias/ValidarNombre', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ NombreTipografia: value })
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreTipografia: true, disable: true });
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreTipografia: false, disable: false });
                this.showNotification();
              }
            })
            .catch(err => console.error(err));
        } else {
          this.setState({ errorNombreTipografia: false, disable: false });
        }
      } else {
        fetch(Globales.Url + 'Tipografias/Tipografias/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreTipografia: value })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreTipografia: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreTipografia: false, disable: false });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  obtenerBaseFuente(fuente) {
    var fue = fuente[0];
    this.setState({
      Fuente: fue
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Tipografía"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreTipografia,

                  name: "NombreTipografia",
                  id: "NombreTipografia",
                  type: "text",
                  error: this.state.errorNombreTipografia
                }}
              />
              <div className={'mt-4'}>
                {this.state.errorFuente ?
                  <Danger>
                    <h5>Fuente</h5>
                  </Danger> : <h5>Fuente</h5>}
                {/* Fuente */}
              </div>
              <div>
                <FileBase64
                  multiple={true}
                  onDone={this.obtenerBaseFuente.bind(this)} />
              </div>
              <GridItem>
                {this.state.disable ? <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round><Save />&nbsp;GUARDAR</Button> :
                  <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarTipografia.bind(this)}><Save />&nbsp;GUARDAR</Button>}
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}