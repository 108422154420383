import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Drawer from "@material-ui/core/Drawer";
import DateTime from 'react-datetime';
//
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalInformacion from "components/Generales/ModalInformacion";
import ModalAgregarInventario from './AgregarInventario';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility } from "@material-ui/icons";
import ReactLoading from "react-loading";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

class Inventario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idMateriaPrima: '',
      FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      idMaterial: '',
      Incremento: '',
      MaterialPorMateriaPrima: [],
      Comentarios: '',
      NombreUsuarioRegistro: '',
      Inventario: [],
      materiaPrima: [],
      Material: [],
      UnidadMedida: [],
      erroridMateriaPrima: false,
      erroridMaterial: false,
      TipoAjuste: "true",
      errorCantidad: false,
      errorIncremento: false,
      errorComentarios: false,
      _id: '',
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      MaterialSinEliminar:[],
      InventarioMostrar: [],
      entroFiltrar: false,
      notificacion: false,
      showComentario: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    global.Fin = 5;
    global.response = null;
    this.handleChange = this.handleChange.bind(this);
    this.eventoCerrar = this.eventoCerrar.bind(this)
    this.obtenerMateriaPrima = this.obtenerMateriaPrima.bind(this);
    this.filtrarAjusteDeInventario = this.filtrarAjusteDeInventario.bind(this);
  }

  componentDidMount() {
    this.obtenerMateriaPrima();
    this.obtenerInventarioFin();
    console.log(this.state)
  }
  componentWillMount() {
    this.obtenerSchemas();
      this.setState({
          FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
          FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),

      })
  }
  obtenerSchemas() {
    //Obtener Inventario
    fetch(Globales.Url + 'Inventario/Inventario/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Inventario: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Inventario: data.mensaje });
        }
      });

    //Obteniendo las materias primas
    fetch(Globales.Url + 'MateriaPrima/MateriaPrima/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ MateriaPrima: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ MateriaPrima: data.mensaje });
        }
      });

    //Obteniendo Inventario
    fetch(Globales.Url + 'Inventario/Inventario/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Inventario: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Inventario: data.mensaje });
        }
      });

    //Obtener unidades de medida
    fetch(Globales.Url + 'UnidadMedida/UnidadMedida/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ UnidadMedida: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ UnidadMedida: data.data });
        }
      });

    //Obtener material
    fetch(Globales.Url + 'Material/Material/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.error) { //Error al obtener los registros
          this.setState({ Material: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Material: data.data });
        }
      });

      fetch(Globales.Url + 'Material/Material/getMateriales', {
        method: 'GET',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
      })
        .then(res => res.json())
        .then(data => {
          if (data.error) { //Error al obtener los registros
            this.setState({ Material: [] });
          } else { //Obtuvo los registros correctamente
            this.setState({ MaterialSinEliminar: data.data });
          }
        });
  }

  obtenerMateriaPrima() {
    fetch(Globales.Url + 'MateriaPrima/MateriaPrima/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ materiaPrima: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ materiaPrima: data.mensaje });
        }
      });
  } ;

  //Obtener el total de registros que se indican
  obtenerInventarioFin() {
    fetch(Globales.Url + 'Inventario/Inventario/ListaInventario', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          console.log(data
          )
          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else { //Obtuvo los registros correctamente
            this.setState({ InventarioMostrar: data.data });
          }
        })
        .catch(err => console.error(err));
    });
  }

  //Obtiene los nombre de las materias primas
  obtenerNombresMateriasPrimas(idMateriaPrima) {
    var nombre = "";
    this.state.materiaPrima.map(materiasPrimas => {
      if (materiasPrimas._id == idMateriaPrima) {
        nombre = materiasPrimas.NombreMateria;
      }
    });
    return nombre;
  }

  //Obteniendo el nombre de la unidad de medida
  obtenerNombresUnidadesMedidas(idUnidadMedida) {
    var nombre = "";
    this.state.UnidadesMedidas.map(unidad => {
      if (unidad._id == idUnidadMedida) {
        nombre = unidad.NombreUnidad
      }
    })
    return nombre;
  }

  //Obteniendo el nombre de los materiales
  obtenerNombresMateriales(idMaterial) {
    var nombreMaterial = '';
    this.state.MaterialSinEliminar.map(material => {
      if (material._id === idMaterial) {
        nombreMaterial = material.NombreMaterial
      }
    });
    return nombreMaterial

  }

  //Función para abrir el modal de agregar registro
  openModalAgregarInventario = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Inventario", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalInvetario = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Inventario", NombreMaterial: "", op: 0 })
    this.obtenerInventarioFin();
    this.obtenerSchemas();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    if(name == "FechaInicial"){
      this.setState({ [name]: value });
      /*if(value <= this.state.FechaFinal){
          this.setState({ [name]: value });
      }*/
  }else if(name == "FechaFinal"){
    this.setState({ [name]: value });

      /*if(value >= this.state.FechaInicial){
          this.setState({ [name]: value });
      }*/
  }else{
    this.setState({ [name]: value });
    if (e.target.name === 'idMateriaPrima') {
      var idUnidad = "";
      var nombreUnidadMedida = "";
      var suma = 0;
      var arrayMaterialesPorMateriaPrima = [];
      //Obteniendo el nombre de la unidad de medida
      this.state.materiaPrima.map(materiaPrima => {
        if (e.target.value == materiaPrima._id) {
          idUnidad = materiaPrima.idUnidadMedida;
        }
      });
      //Recorrer los materiales de la materia prima seleccionada
      this.state.Material.map(material => {
        if (material.idMateriaPrima === e.target.value) {
          //Sumando el total de existencia de materiales de la materia prima seleccionada
          arrayMaterialesPorMateriaPrima.push(material)
        }
      });
      this.setState({ NombreUnidad: nombreUnidadMedida, ExistenciaMaterial: 0, ExistenciaMateriaPrima: suma, MaterialPorMateriaPrima: arrayMaterialesPorMateriaPrima, habilitarMaterial: false });
    }
  }  
    
  }

  // //Función para volver los valores por default
  limpiar() {
    console.log(cookies.get('id'));
    this.setState({ 
      NombreMaterial: '', 
      entroFiltrar: false, 
      idMaterial: '', 
      idMateriaPrima: '' ,
      FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    });
    global.Fin = 5;
    global.entrar = true;
    global.response = null;
    this.obtenerInventarioFin();
    console.log(this.state)
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.Inventario.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerInventarioFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.Inventario.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.Inventario.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerInventarioFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más ajustes de inventarios que mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }
  //Funcion para Observar el Comentario en la Tabla
  ObservarComentari(Comentario, nombreUsuarioRegistro) {
    this.setState({ Comentarios: Comentario, NombreUsuarioRegistro: nombreUsuarioRegistro }, () => this.setState({ showComentario: true }));

  }
  //Cerrar el Modal de Informacion
  eventoCerrar(e) {
    console.log("TCL: Inventario -> eventoCerrar -> e", e)
    if (!e) {
      this.setState({ showComentario: false, Comentarios: '' });
      return;
    }
  }

  filtrarAjusteDeInventario() {
    fetch(Globales.Url + 'Inventario/Inventario/FiltrarAjusteDeInventario', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
      body: JSON.stringify({
        idMateriaPrima: this.state.idMateriaPrima, 
        idMaterial: this.state.idMaterial, 
        TipoAjuste: this.state.TipoAjuste,
        FechaInicial:this.state.FechaInicial,
        FechaFinal:this.state.FechaFinal
      })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.error) { //Error al obtener los registros
          this.setState({ Inventario: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ InventarioMostrar: data.data });
        }
      });
  }


  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalAgregarInventario
          Titulo={this.state.Titulo}
          MateriaPrima={this.state.materiaPrima}
          Material={this.state.Material}
          UnidadMedida={this.state.UnidadMedida}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalInvetario}
          op={this.state.op}
          classes={this.props}
        />
    }
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Opciones de búsqueda</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <GridContainer>
                      <GridItem xs={20} sm={20} md={6} lg={3}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          error={this.state.erroridMateriaPrima}
                          className="pull-left mt-9"
                        >
                          <InputLabel
                            className={classes.selectLabel}>
                            Materia Prima
                        </InputLabel>
                          <Select
                            value={this.state.idMateriaPrima}
                            onChange={this.handleChange}
                            name="idMateriaPrima"
                            id="cbbMateriaPrima"
                            inputProps={{
                              fullWidth: true

                            }}
                          >
                            {this.state.materiaPrima.map(materia =>
                              <MenuItem value={materia._id}>{materia.NombreMateria}</MenuItem>
                            )}
                          </Select>

                        </FormControl>
                        <div>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={this.state.TipoAjuste === "true"}
                                onChange={this.handleChange}
                                value={"true"}
                                name="TipoAjuste"
                                aria-label='A'
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label='Incremento'
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={this.state.TipoAjuste === "false"}
                                onChange={this.handleChange}
                                value={"false"}
                                name="TipoAjuste"
                                aria-label='B'
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label='Decremento'
                          />
                        </div>
                      </GridItem>
                     

                      <GridItem xs={20} sm={20} md={6} lg={6}>
                        <FormControl
                          fullWidth 
                          className={classes.selectFormControl}
                          error={this.state.erroridMateriaPrima}
                          className="pull-left mt-9"
                        >
                          <InputLabel
                            className={classes.selectLabel}>
                            Material
                        </InputLabel>
                          <Select
                            value={this.state.idMaterial}
                            onChange={this.handleChange}
                            name="idMaterial"
                            id="cbbMateriaPrima"
                            inputProps={{
                              fullWidth: true

                            }}
                          >
                            {this.state.Material.map(materia =>
                              <MenuItem value={materia._id}>{materia.NombreMaterial}</MenuItem>
                            )}
                          </Select>

                        </FormControl>
                                        <CustomInput
                                            labelText="Fecha inicial"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaInicial,
                                                name: "FechaInicial",
                                                id: "FechaInicial",
                                                type: "date",
                                                error: this.state.errorFechaInicial,
                                            }}
                                        />
                                  
                                        <CustomInput
                                            labelText="Fecha final"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                                error: this.state.errorFechaFinal,
                                            }}
                                        />
                                    </GridItem>

                      
                      <GridItem xs={24} sm={24} md={10} lg={24} >
                        <Button size="sm" className="pull-left mt-6" color="info" round onClick={() => this.filtrarAjusteDeInventario()} ><Search />Buscar</Button>
                        &nbsp;
                        &nbsp;
                        <Button size="sm" type="submit" className="pull-left mt-6" color="danger" round onClick={this.limpiar.bind(this)}><Clear />limpiar</Button>
                        &nbsp;
                        &nbsp;
                        <Button size="sm" className="pull-left mt-6" color="success" round onClick={() => this.openModalAgregarInventario()} ><Edit />Ajuste Inventario</Button>
                        &nbsp;
                        &nbsp;
                        <Button size="sm" className="pull-left mt-6" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                      </GridItem>

                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de materiasPrimas */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className="pull-left">Ajustes de Inventario</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {global.response === 200 ?
                this.state.InventarioMostrar != undefined ?
                  <div className="table-wrapper-scroll-y">
                    <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>
                        <th className="center">Materia Prima</th>
                        <th className="center">Material</th>
                        <th className="center">Cantidad</th>
                        <th className="center">Fecha</th>
                        <th className="center">Acción</th>
                      </thead>
                      <tbody>
                          {
                            this.state.InventarioMostrar.map(inventario => {
                              return (
                                <tr key={inventario._id}>
                                  <td>{this.obtenerNombresMateriasPrimas(inventario.idMateriaPrima)}</td>
                                  <td>{this.obtenerNombresMateriales(inventario.idMaterial)}</td>
                                  <td>{inventario.Cantidad}</td>
                                  <td>{new Date(inventario.FechaRegistro).toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                                  <td>{inventario.Accion}</td>
                                  <td className="pull-right">
                                    <Button tabIndex="0" size="sm" color="info" round justIcon title="Ver más" onClick={() => this.ObservarComentari(inventario.Comentarios, inventario.idUsuario.NombreUsuario)}><Visibility /></Button>
                                  </td>
                                </tr>
                              )
                            }
                            )
                          }
                      </tbody>
                    </table>
                  </div>:<div style={{textAlign:"center"}}>
                    <h3>No hay información para mostrar</h3>
                  </div> :
                  <GridContainer justify='center'>
                    <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                  </GridContainer>}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Inventario"}
            Cuerpo={"¿Está seguro que desea eliminar el material"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <ModalInformacion
            Titulo={"Comentario"}
            Cuerpo={this.state.Comentarios}
            Usuario={this.state.NombreUsuarioRegistro}
            show={this.state.showComentario}
            eventClick={this.eventoCerrar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Inventario);
