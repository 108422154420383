import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalAgregarEstado from "views/Modulos/Administracion/Direccion/ModalAgregarEstado.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";

class Estad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreEstado: '',
      Titulo: '',
      estados: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombre: false,
      estadosMostrar: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 5;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarEstado = this.eliminarEstado.bind(this);
    this.filtrarEstado = this.filtrarEstado.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerEstadosFin();
    this.obtenerAllEstados();
  }

  //Obtener todos los registros guardados
  obtenerAllEstados() {
    fetch(Globales.Url + 'Direcciones/Estados')
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ estados: [] });
        } else { //Obtuvo los registros correctamente
          if(data != null){
            this.setState({ estados: data });
          }else{
            this.setState({ estados: [] });
          }
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerEstadosFin() {
    fetch(Globales.Url + 'Direcciones/Estados/ListaEstados', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Obtuvo los registros correctamente
          if(data.mensaje != null){
            this.setState({ estadosMostrar: data.mensaje });
          }else{
            this.setState({ estadosMostrar: [] });
          }
        }
      })
      .catch(err => console.error(err));
  }

  //Función de editar registro
  editarEstado(id, Nombre) {
    this.setState({ _id: id, NombreEstado: Nombre, op: 1, showAgregar: true, Titulo: "Editar Estado" });
  }

  //Función de eliminar registro
  eliminarEstado(id) {
    fetch(Globales.Url + 'Direcciones/Estados/BajaEstado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification("tr");
        this.obtenerEstadosFin();
        this.limpiar();
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarEstado(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openModalAgregarEstado = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Estado", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalEstado = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Estado", NombreEstado: "", op: 0 })
    this.obtenerEstadosFin();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para realizar el filtrado de búsqueda
  filtrarEstado() {
    var valor1 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreEstado.trim() == '') {
      this.setState({ errorNombreEstado: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", NombreEstado: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombreEstado: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) { //Hay campos requeridos
      this.showNotification();
    } else { //No hay campos requeridos
      fetch(Globales.Url + 'Direcciones/Estados/FiltrarEstadoNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {          
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.mensaje.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning",estadosMostrar: [], mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', estadosMostrar: data.mensaje, entroFiltrar: true })
          }
        })
        .catch(err => console.error(err));
    }
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreEstado: '', entroFiltrar: false });
    global.Fin = 5;
    this.obtenerEstadosFin();
    this.obtenerAllEstados();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.estados.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerEstadosFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.estados.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.estados.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerEstadosFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más estados para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalAgregarEstado
          Titulo={this.state.Titulo}
          NombreEstado={this.state.NombreEstado}
          _id={this.state._id}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalEstado}
          op={this.state.op}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Estados</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Búsqueda de estado"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreEstado,
                        name: "NombreEstado",
                        id: "NombreEstado",
                        type: "text",
                        error: this.state.errorNombreEstado
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" color="info" round className="pull-left mt-5" onClick={() => this.filtrarEstado()}><Search />Buscar</Button>
                    <Button size="sm" type="submit" color="danger" round className="pull-left mt-5" onClick={() => this.limpiar()}><Clear />limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalAgregarEstado()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de estados */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de Estados</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12">
                    <thead>
                      <th className="center">Nombre Estado</th>
                      <th className="pull-right">Acciones</th>
                    </thead>
                    <tbody>
                      {this.state.estadosMostrar.map(Estados => {
                        return (
                          <tr key={Estados._id}>
                            <td>{Estados.NombreEstado}</td>
                            <td className="pull-right">
                              <Button color="warning" round justIcon size="sm" onClick={() => this.editarEstado(Estados._id, Estados.NombreEstado)}><Edit /></Button>
                              <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(Estados._id)}><Close /></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Estado"}
            Cuerpo={"¿Está seguro que desea eliminar estado?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Estad);