import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import stylesComponentes from 'components/Generales/Styles/StyleBasics.css'
import CardBody from "components/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";  
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import AgregarCajasEnvio from "views/Modulos/Administracion/CajasEnvio/AgregarCajasEnvio.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";
import ReactLoading from "react-loading";

class CajasEnvio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreCajaEnvio: '',
      idMateriaPrima: '',
      Editar:'',
      Alto: '',
      Ancho: '',
      Largo: '',
      PesoCaja: '',
      Rangos: '',
      RangoInicial: '',
      showModalEliminar:false,
      RangoFinal: '',
      Titulo: '',
      cajasEnvio: [], 
      materiaPrima: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombreCajaEnvio: false,
      NombreMateriaPendiente: [],
      erroridMateriaPrima: false,
      errorAlto: false,
      materiaPrimaPendiente: false,
      errorAncho: false,
      errorLargo: false,
      errorPesoCaja: false,
      errorRangos: false,
      cajasEnvioMostrar: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    global.Fin = 5;
    global.response = null;
    // this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarCaja= this.eliminarCaja.bind(this);
    this.obtenerMateriaPrimaPendienteDeRegistro = this.obtenerMateriaPrimaPendienteDeRegistro.bind(this)
    this.filtrarCajasEnvio = this.filtrarCajasEnvio.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerMateriaPrimaPendienteDeRegistro();
    this.obtenerCajasEnvioFin();
    this.obtenerAllCajasEnvio();
    this.obtenerMateriaPrima();
  }

  //Obtener todos los registros guardados
  obtenerAllCajasEnvio() {
    fetch(Globales.Url + 'CajasEnvio/CajasEnvio/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ cajasEnvio: [] });
        } else { //Obtuvo los registros correctamente
          if(data.data != null){
            this.setState({ cajasEnvio: data.data });
          }else{
            this.setState({ cajasEnvio: [] });
          }
        }
      });
  }

  obtenerMateriaPrima() {
    this.obtenerMateriaPrimaPendienteDeRegistro();
    fetch(Globales.Url + 'MateriaPrima/MateriaPrima/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ materiaPrima: [] });
        } else { //Obtuvo los registros correctamente
          if(data.mensaje != null){
            this.setState({ materiaPrima: data.mensaje });
          }else{
            this.setState({ materiaPrima: [] });
          }
        }
      });

  }

  //Obtener el total de registros que se indican
  obtenerCajasEnvioFin() {
    fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ListaCajaEnvio', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => {
      global.response = res.status;
      res.json().then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Obtuvo los registros correctamente
          if(data.data.length > 0){
            this.setState({ cajasEnvioMostrar: data.data });
          }else{
            this.setState({ cajasEnvioMostrar: [] });
          }
        }
      })
        .catch(err => console.error(err));
    });
  }

  //Función de editar registro
  editarCajaEnvio(id, Nombre, idMateriaPrima, Alto, Ancho, Largo, PesoCaja, RangoI, RangoF,Editable) {
    console.log(idMateriaPrima)
    this.setState({ _id: id,Editar:Editable, NombreCajaEnvio: Nombre, idMateriaPrima: idMateriaPrima, Alto: Alto, Ancho: Ancho, Largo: Largo, PesoCaja: PesoCaja, RangoInicial: RangoI, RangoFinal: RangoF, op: 1, showAgregar: true, Titulo: "Editar Caja de Envío" });
  }

  //Función para abrir el modal de agregar registro
  openModalCajaEnvio = () => {
    this.setState({ showAgregar: true, Titulo: "Nueva caja de envío", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalCajasEnvio = async (mensaje, colorNotificacion) => {
    fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ValidarMateriaPrimaSinCaja', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }).then(res => res.json())
      .then(data => {
      
          this.setState({ showAgregar: false, Titulo: "Nueva caja de Envío", NombreCajaEnvio: "", op: 0 });
          this.obtenerCajasEnvioFin();
          this.obtenerMateriaPrimaPendienteDeRegistro()
          this.obtenerAllCajasEnvio();
          this.limpiar();
          //Validar que el mensaje venga diferente de vacio
          if (mensaje !== 'esc') {
            this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
            this.showNotification();
          }
        
      });
  }

  eliminarCaja(e){
    if(!e){
      this.setState({
        showModalEliminar:false,
        idCajaEliminar:null
      })
    }else{
      fetch(Globales.Url + 'CajasEnvio/CajasEnvio/BajaCajaEnvio', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        },
        body:JSON.stringify({_id:this.state.idCajaEliminar})
      }).then(res => res.json())
        .then(data => {
          this.setState({ colorNotificacion: 'success', mensaje: 'La caja de envío ha sido dada de baja',showModalEliminar:false });
          this.showNotification();
          this.alertTimeout = setTimeout(
            function () {
              this.obtenerCajasEnvioFin();
              this.obtenerMateriaPrimaPendienteDeRegistro();
              this.obtenerAllCajasEnvio();
              this.limpiar();
            }.bind(this),
            2000
          );
        });
    }
    
  }
  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para obtener el id del combo
  handleSimple = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  //Función para realizar el filtrado de búsqueda
  filtrarCajasEnvio() {
    var valor1 = 0;
    var valor2 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreCajaEnvio.trim() === '') {
      this.setState({ errorNombreCajaEnvio: true, colorNotificacion: 'danger', mensaje: "Complete los campos de búsqueda" });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombreCajaEnvio: false });
      valor1 = 0;
    }
    if (this.state.idMateriaPrima.trim() === '') {
      this.setState({ erroridMateriaPrima: true, colorNotificacion: 'danger', mensaje: 'Complete los campos de búsqueda' });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ erroridMateriaPrima: false });
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 === 0) { //No hay campos requeridos
      fetch(Globales.Url + 'CajasEnvio/CajasEnvio/FiltrarCajaEnvio', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.data.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning", cajasEnvioMostrar:[], mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', cajasEnvioMostrar: data.data, entroFiltrar: true, mensaje: data.mensaje })
            // this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }


  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreCajaEnvio: '', idMateriaPrima: '', entroFiltrar: false });
    global.entrar = true;
    global.Fin = 5;
    global.response = null;
    this.obtenerCajasEnvioFin();
    this.obtenerAllCajasEnvio();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.cajasEnvio.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerCajasEnvioFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.cajasEnvio.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.cajasEnvio.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerCajasEnvioFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más cajas de envío para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  //Función para obtener el nombre de la materia prima
  obtenerNombreMateriaPrima(id) {
    var nombre = '';
    //Recorrido de los registros de la materia prima
    this.state.materiaPrima.map(materia => {
      if (id === materia._id) {
        nombre = materia.NombreMateria
      }
    });
    //Regresando el nombre de la materia prima
    return nombre;
  }
  async obtenerMateriaPrimaPendienteDeRegistro() {
    fetch(Globales.Url + 'CajasEnvio/CajasEnvio/ValidarMateriaPrimaSinCaja', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.data == false) {
          console.log(data)
          this.state.materiaPrimaPendiente = true;
          this.state.NombreMateriaPendiente = data.NombreMateria
          this.setState({ NombreMateriaPendiente: data.NombreMateria });
        } else {

          // this.setState({materiaPrimaPendiente:false });
          this.state.materiaPrimaPendiente = false;

        }
      });
  }
  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <AgregarCajasEnvio
          Titulo={this.state.Titulo}
          NombreCajaEnvio={this.state.NombreCajaEnvio}
          idMateriaPrima={this.state.idMateriaPrima}
          RangoFinal={this.state.RangoFinal}
          RangoInicial={this.state.RangoInicial}
          Alto={this.state.Alto}
          Ancho={this.state.Ancho}
          Largo={this.state.Largo}
          PesoCaja={this.state.PesoCaja}
          Rangos={this.state.Rangos}
          _id={this.state._id}
          materiaPrima={this.state.materiaPrima}
          showAgregar={this.state.showAgregar}
          Editable={this.state.Editar}
          eventClick={this.eventoModalCajasEnvio}
          op={this.state.op}
          classes={this.props}
        />
    }
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={5} sm={5} md={10}>
            <GridContainer>
              <GridItem xs={10} sm={10} md={8}>
                <Card>
                  <CardHeader color="info" text>
                    <h4 className={classes.cardTitle}>Cajas de Envío</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={8}>
                        <CustomInput
                          labelText="Buscar caja de envío"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            required: true,
                            onChange: this.handleChange,
                            value: this.state.NombreCajaEnvio,
                            name: "NombreCajaEnvio",
                            id: "NombreCajaEnvio",
                            type: "text",
                            error: this.state.errorNombreCajaEnvio
                          }}
                          className="mt-5"
                        />

                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                          error={this.state.erroridMateriaPrima}
                          className="mt-4"
                        >
                          <InputLabel
                            className={classes.selectLabel}>
                            Materia Prima
                        </InputLabel>
                          <Select
                            value={this.state.idMateriaPrima}
                            onChange={this.handleSimple}
                            name="idMateriaPrima"
                            id="cbbMateriaPrima"
                            inputProps={{
                            }}
                          >
                            {this.state.materiaPrima.map(materia =>
                              <MenuItem value={materia._id}>{materia.NombreMateria}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={3}>
                        <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.filtrarCajasEnvio()}><Search />Buscar</Button>
                        <Button size="sm" type="submit" className="pull-right mt-5" color="danger" round onClick={() => this.limpiar()}><Clear />limpiar</Button>
                        <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalCajaEnvio()}><AddCircle />Agregar</Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={3} sm={3} md={4}>
                <Card>
                  <CardHeader color="info" text>
                    <h4 className={classes.cardTitle}>Materia prima sin caja</h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ height: "252px" }} id="cajas-envio-table-wrapper-scroll-y" className="cajas-envio-table-wrapper-scroll-y">
                      <table className="col-xs-12 col-sm-12 col-md-12">
                        <thead>
                          <th className="center">Nombre</th>
                        </thead>
                        <tbody>
                          {this.state.NombreMateriaPendiente.map(CajasEnvio => {
                            return (
                              <tr key={null}>
                                <td>{"• " + CajasEnvio.NombreMateria}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

          </GridItem>

        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de Cajas de Envío</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {global.response === 200 ?
                  <div className="table-wrapper-scroll-y">
                    <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>
                        <th className="center">Materia Prima</th>
                        <th className="center">Nombre Caja de Envío</th>
                        <th className="center">Peso Caja</th>
                        <th className="center">Rangos</th>
                        <th className="pull-right">Acciones</th>
                      </thead>
                      <tbody>
                        {this.state.cajasEnvioMostrar.map((CajasEnvio,key)=> {
                          return (
                            <tr key={CajasEnvio._id}>
                              <td>{this.obtenerNombreMateriaPrima(CajasEnvio.idMateriaPrima)}</td>
                              <td>{CajasEnvio.NombreCajaEnvio}</td>
                              <td>{CajasEnvio.PesoCaja}</td>
                              <td>{CajasEnvio.RangoInicial + "-" + CajasEnvio.RangoFinal}</td>
                              <td className="pull-right">
                                <Button color="warning" round justIcon size="sm" onClick={() => this.editarCajaEnvio(CajasEnvio._id, CajasEnvio.NombreCajaEnvio, CajasEnvio.idMateriaPrima, CajasEnvio.Alto, CajasEnvio.Ancho, CajasEnvio.Largo, CajasEnvio.PesoCaja, CajasEnvio.RangoInicial, CajasEnvio.RangoFinal
                                  ,(this.state.cajasEnvioMostrar.length-1 == key?false:true))}><Edit /></Button>
                                <Button disabled={!CajasEnvio.UltimoRegistro} color="danger" round justIcon size="sm"  onClick={()=>{
                                  this.setState({
                                    showModalEliminar:true,
                                    idCajaEliminar:CajasEnvio._id
                                  })
                                }}><Close /></Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <GridContainer justify='center'xs={12} sm={12} md={12}>
                    <Button size="sm" type="submit"  className="center mt-1" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                  </GridContainer>
                  </div>:
                  <GridContainer justify='center'>
                    <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                  </GridContainer>
                }
              </CardBody>
            </Card>
          </GridItem>
                <GridItem>  
                </GridItem>
        </GridContainer>
        <div id="">
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar caja de envío"}
            Cuerpo={"¿Está seguro que desea eliminar la caja de envío?"}
            show={this.state.showModalEliminar}
            eventClick={this.eliminarCaja}
          />
          {agregar}
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(CajasEnvio);  