import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Login from './views/Login/Login';
import MenuPrincipal from './views/MenuPrincipal/MenuPrincipal';
import CambioContrasena from './views/Login/CambiarContrasena';

// core components
import Produccion from 'layouts/Produccion.jsx'
import Admin from "layouts/Admin.jsx";
import Reportes from 'layouts/Reportes.jsx';
import Inventario from 'layouts/Inventario.jsx';
import AtencionUsuarios from 'layouts/AtencionUsuarios.jsx';
import rutasAdministracion from 'routes/routesAdministracion.js';
import rutasInventario from 'routes/routesInventario.js';
import rutasProduccion from 'routes/routesProduccion.js';
import rutasReportes from 'routes/routesReportes.js';




import "assets/css/material-dashboard-react.css?v=1.6.0";
import "components/Generales/Styles/StyleBasics.css";
import CookiesU from 'universal-cookie';
const cookies = new CookiesU();
cookies.remove()

const cookie = cookies.getAll()
//console.log(cookie);

class App extends Component {
  constructor(props, context) {
    super(props, context);
  }
  

  render() {
    if (Object.keys(cookie).length === 0) {
    } 
    return (

      <HashRouter>
        <Switch>          
          <Route path="/admin" component={Admin} />
          <Route path="/prod" component={Produccion} />
          <Route path="/report" component={Reportes} />
          <Route path="/inventario" name= "Administración de inventario" component={Inventario} />
          <Route path="/MenuPrincipal" name="Menu Principal" component={MenuPrincipal} />
          <Route path="/CambioContrasena" name="Cambiar Contraseña" component={CambioContrasena} />
          <Route path="/atencionUsuarios" name= "Atención a Usuarios" component={AtencionUsuarios} />
          <Route path="/" name="Login" component={Login} />
          <Route path="/Login" name="Login" component={Login} />          
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
