import React, { Component } from "react";
import { Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";


class ModalEditarUsuariosWeb extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      Usuario: "",
      Contrasena: "",
      ConfirmacionContrasena: "",
      NombreUsuario: "",
      Correo: "",
      Telefono: "",
      MostrarModal: false,
      TipoUsuario: "",
      _id: "",
      disabled: false,
      usuario: [],
      tiposUsuarios: [],
      errorUsuario: false,
      errorContrasena: false,
      errorConfirmacionContrasena: false,
      errorCorreo: false,
      errorNombreUsuario: false,
      errorCelular: false,
      errorTipoUsuario: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton: false,
      NombreGuardado: '',

    }
    this.registrarUsuario = this.registrarUsuario.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    console.log(this.props.usuarioWeb)
    if (this.props.op == 1) {
      this.setState({
        Usuario: this.props.Usuario,
        Contrasena: "",
        ConfirmacionContrasena: "",
        NombreUsuario: this.props.NombreUsuario,
        NombreGuardado: this.props.Usuario,
        Correo: this.props.Correo,
        Telefono: this.props.Telefono,
        TipoUsuario: this.props.TipoUsuario,
        _id: this.props._id,
      })
    }
    this.setState({ tiposUsuarios: this.props.tiposUsuarios });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarUsuario() {
    this.setState({
      disabled: true,
      MostrarModal: true
    })
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    var valor6 = 0;
    var valor7 = 0;
    //Validar que el input no este vacio
    if (this.state.Usuario.trim() === "") {
      this.setState({ errorUsuario: true, colorNotificacion: "danger", mensaje: "Ingrese el usuario", DesactivarBoton: false, disabled: false, MostrarModal: false });
      valor1 = 1;
      this.showNotification();
    } else {
      this.setState({ errorUsuario: false });
      valor1 = 0;
    }
    if (this.props.op != 1) {
      if (this.state.Contrasena.trim() === "") {
        this.setState({ errorContrasena: true, colorNotificacion: "danger", mensaje: "Ingrese la contraseña", DesactivarBoton: false, disabled: false, MostrarModal: false });
        valor2 = 1;
        this.showNotification();
      } else {
        this.setState({ errorContrasena: false });
        valor2 = 0;
      }
      if (this.state.ConfirmacionContrasena === "") {
        this.setState({ errorConfirmacionContrasena: true, colorNotificacion: "danger", mensaje: "Ingrese la confirmación de la contraseña", DesactivarBoton: false, disabled: false, MostrarModal: false });
        valor3 = 1;
        this.showNotification();
      } else {
        this.setState({ errorConfirmacionContrasena: false });
        valor3 = 0;
      }
    }
    if (this.state.NombreUsuario.trim() === "") {
      this.setState({ errorNombreUsuario: true, colorNotificacion: "danger", mensaje: "Ingrese el nombre de usuario", DesactivarBoton: false, disabled: false, MostrarModal: false });
      valor4 = 1;
      this.showNotification();
    } else {
      this.setState({ errorNombreUsuario: false });
      valor4 = 0;
    }
    if (this.state.Correo.trim() === "") {
      this.setState({ errorCorreo: true, colorNotificacion: "danger", mensaje: "Ingrese el correo", DesactivarBoton: false, disabled: false, MostrarModal: false });
      valor5 = 1;
      this.showNotification();
    } else {
      //Validar que el correo tenga un formato correcto
      var valCorreo = /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.state.Correo.trim());
      if (valCorreo) {
        this.setState({ errorCorreo: false });
        valor5 = 0;
      } else {
        this.setState({ errorCorreo: true, colorNotificacion: "danger", mensaje: "Formato incorrecto del correo", DesactivarBoton: false, disabled: false, MostrarModal: false });
        valor5 = 1;
        this.showNotification();
      }
    }
    const phone = parsePhoneNumberFromString(this.state.Telefono.trim(), "MX")
    if (this.state.Telefono.trim() === "") {
      this.setState({ errorCelular: true, colorNotificacion: "danger", mensaje: "Ingrese el número telefono", DesactivarBoton: false, disabled: false, MostrarModal: false });
      valor6 = 1;
      this.showNotification();
    } else {
      if (phone.isValid()) {
        this.setState({ errorCelular: false });
        valor6 = 0;
      } else {
        this.setState({ errorCelular: true, colorNotificacion: "danger", mensaje: "Verifique el número telefono", DesactivarBoton: false, disabled: false, MostrarModal: false });
        valor6 = 1;
        this.showNotification();
      }
    }
    if (this.state.TipoUsuario.trim() === "") {
      this.setState({ errorTipoUsuario: true, colorNotificacion: "danger", mensaje: "Seleccione el tipo de usuario", DesactivarBoton: false, disabled: false, MostrarModal: false });
      valor7 = 1;
      this.showNotification();
    } else {
      this.setState({ errorTipoUsuario: false });
      valor7 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 === 0) {
      this.setState({ DesactivarBoton: true })
      if (this.props.op == 1) {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ActualizarDatosUsuarioWeb', {
          method: "POST",
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: "danger", mensaje: data.mensaje, DesactivarBoton: false, disabled: false, MostrarModal: false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: "success", DesactivarBoton: false, disabled: false, MostrarModal: false, Usuario: "", Contrasena: "", ConfirmacionContrasena: "", NombreUsuario: "", Correo: "", Telefono: "", TipoUsuario: "", errorUsuario: false, errorContrasena: false, errorRutaImagen: false, errorCorreo: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  obtenerBaseImagen(imagen) {
    var img = imagen[0];
    this.setState({
      rutaImagen: img
    });
  }

  ValidarNombre() {
    //Validar si es una actualización
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.Usuario) {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ValidarUsuarioWeb', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ UsuarioWeb: this.state.Usuario })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorN: true, DesactivarBoton: true, disabled: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, DesactivarBoton: false, disabled: false });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombre: false, DesactivarBoton: false });
      }
    } else {
      fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ValidarUsuarioWeb', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ UsuarioWeb: this.state.Usuario })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, DesactivarBoton: true, disabled: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, DesactivarBoton: false, disabled: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Usuario"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onBlur: this.ValidarNombre.bind(this),
                      onChange: this.handleChange.bind(this),
                      value: this.state.Usuario,
                      name: "Usuario",
                      id: "Usuario",
                      type: "text",
                      error: this.state.errorUsuario
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Nombre Usuario"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.NombreUsuario,
                      name: "NombreUsuario",
                      id: "NombreUsuario",
                      type: "text",
                      error: this.state.errorNombreUsuario
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Contraseña"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Contrasena,
                      name: "Contrasena",
                      id: "Contrasena",
                      type: "password",
                      error: this.state.errorContrasena
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Confirmación Contraseña"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.ConfirmacionContrasena,
                      name: "ConfirmacionContrasena",
                      id: "ConfirmacionContrasena",
                      type: "password",
                      error: this.state.errorConfirmacionContrasena
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Correo Electrónico"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Correo,
                      name: "Correo",
                      id: "Correo",
                      type: "text",
                      error: this.state.errorCorreo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Número Telefono"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Telefono,
                      name: "Telefono",
                      id: "Telefono",
                      type: "number",
                      error: this.state.errorCelular
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    error={this.state.errorTipoUsuario}
                    className="mt-4"
                  >
                    <InputLabel>Tipo de Usuario</InputLabel>
                    <Select
                      value={this.state.TipoUsuario}
                      //  value = {global.NombreMateriaPrima}
                      onChange={this.handleSimple}
                      name="TipoUsuario"
                      id="TipoUsuario"
                      inputProps={{}}
                    >
                      <MenuItem disabled>Tipo de Usuario</MenuItem>
                      <MenuItem value="Cliente">Cliente</MenuItem>
                      <MenuItem value="Artista">Artista</MenuItem>
                    </Select>

                  </FormControl>

                </GridItem>
              </GridContainer>
              <GridItem>
                <Button disabled={this.state.disabled} size="sm" color="info" className="pull-right" round onClick={this.registrarUsuario.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick("esc")}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
            show={this.state.MostrarModal}
            Cuerpo="Cargando"
          />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalEditarUsuariosWeb);