import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import AgregarProducto from "views/Modulos/Administracion/Productos/AgregarProducto.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Warning, FileCopy } from "@material-ui/icons";
import ReactLoading from "react-loading";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";



class Productos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //CAMPOS
      Tallas: [],
      _id: '',
      idCategoria: '',
      NombreProducto: '',
      TipoProducto: '',
      Descripcion: '',
      caracteristicasAgregadas: [],
      atributosAgregados: [],
      idModelo: '',
      idMateriaPrima: '',
      materialesAgregados: [],
      CostoMaterial: 0,
      CostoMaquila: 0,
      TipoPrecio: '',
      PorcentajeUtilidad: 0,
      PrecioPublico: 0,
      PorcentajeComision: 0,
      ImporteComision: 0,
      IVA: 0,
      ImporteUtilidad: 0,
      Peso: 0,
      idCaracteristica: '',
      idAtributo: '',
      idMaterial: '',
      Estado: 0,

      /**ARCHIVOS */
      ImagenProducto:null,
      NombreImagenProducto: '',
      NombreTempleteRecorte: '',
      NombreTextura: '',

      //SCHEMAS   
      Productos: [],
      productosMostrar: [],
      Caracteristicas: [],
      Atributos: [],
      Modelos: [],
      MateriaPrima: [],
      Material: [],
      Empresa: [],
      UnidadMedida: [],

      Imagenes: [],
      Titulo: '',
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombreProducto: false,
      entroFiltrar: false,
      notificacion: false,
      AutorizarProducto: false,
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 100;
    global.entrar = true;
    global.response = null;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.eventoModalProducto = this.eventoModalProducto.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarProducto = this.eliminarProducto.bind(this);
    this.filtrarProducto = this.filtrarProducto.bind(this);
    this.handleEstado = this.handleEstado.bind(this)
    this.seleccionado = {};
  }

  componentDidMount() {

    this.ObtenerTallas();
  }


  ObtenerTallas() {
    fetch(Globales.Url + 'Tallas/ObtenerTallas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {
          this.setState({
            Tallas: data.data
          }, () => {
            this.obtenerProductosFin();
            this.obtenerAllSchemas();
          })
        }
      })
  }

  ObtenerNombreTamaño(id) {
    var nombre = "";
    this.state.Tallas.map(m => {
      if (m._id == id) {
        nombre = m.NombreTalla;
      }
    })
    return nombre;
  }

  //Obtener todos los registros guardados
  obtenerAllSchemas() {
    //Productos
    fetch(Globales.Url + 'Productos/Productos', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Productos: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Productos: data.mensaje });
        }
      });
    //Características
    fetch(Globales.Url + 'Caracteristicas/Caracteristicas', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Caracteristicas: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Caracteristicas: data.data });
        }
      });

    //Atributos
    fetch(Globales.Url + 'Atributos/Atributos', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Atributos: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Atributos: data.mensaje });
        }
      });

    //Modelos
    fetch(Globales.Url + 'Modelos/Modelos', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Modelos: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Modelos: data.data });
        }
      });

    //Materia Prima
    fetch(Globales.Url + 'MateriaPrima/MateriaPrima', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ MateriaPrima: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ MateriaPrima: data.mensaje });
        }
      });

    //Material
    fetch(Globales.Url + 'Material/Material', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Material: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Material: data.data });
        }
      });

    fetch(Globales.Url + 'UnidadMedida/UnidadMedida', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ UnidadMedida: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ UnidadMedida: data.data });
        }
      });

    //Empresa
    fetch(Globales.Url + 'Empresa/Empresa', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ Empresa: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ Empresa: data.data });
        }
      });
  }

  //Obtener el nombre de la materia prima
  obtenerNombreMateriaPrima(idMateria) {
    var nombre = '';
    this.state.MateriaPrima.map(materia => {
      if (idMateria == materia._id) {
        nombre = materia.NombreMateria
      }
    });
    //Regresando el nombre
    return nombre;
  }

  //Obtener el total de registros que se indican
  obtenerProductosFin() {
    fetch(Globales.Url + 'Productos/Productos/ListadoProductos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {

          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.setState({ productosMostrar: [] });
            this.showNotification();
          } else { //Obtuvo los registros correctamente
            console.log(data)
            data.mensaje.map(m => {
            var gruposAutorizados = 0;
              m.GruposMaterial.map(g => {
                if (g.Autorizado == true) {
                  gruposAutorizados++;
                }
              })
              if (gruposAutorizados == m.GruposMaterial.length) {
                m["Color"]=""
              }else{
                m["Color"]="red"
              }
            m["NumeroGrupos"]= gruposAutorizados + "/" + m.GruposMaterial.length;

            })
            this.setState({ productosMostrar: data.mensaje });
          }
        })
        .catch(err => console.error(err));
    });
  }

  /**DUPLICAR PRODUCTO */
  DuplicarProducto(Producto) {
    this.setState({
      NombreProducto: Producto.NombreProducto,
      TipoProducto: Producto.TipoProducto,
      Descripcion: Producto.Descripcion,
      caracteristicasAgregadas: Producto.caracteristicasAgregadas,
      atributosAgregados: Producto.atributosAgregados,
      idModelo: Producto.idModelo,
      idMateriaPrima: Producto.idMateriaPrima,
      materialesAgregados: Producto.materialesAgregados,
      CostoMaterial: Producto.CostoMaterial,
      CostoMaquila: Producto.CostoMaquila,
      TipoPrecio: Producto.TipoPrecio,
      PorcentajeUtilidad: Producto.PorcentajeUtilidad,
      PrecioPublico: Producto.PrecioPublico,
      PorcentajeComision: Producto.PorcentajeComision,
      ImporteComision: Producto.ImporteComision,
      ImporteUtilidad: Producto.ImporteUtilidad,
      Peso: Producto.Peso,
      GruposMaterial: Producto.GruposMaterial,
      // Tamaño: Producto.Tamano,
      IVA: Producto.IVA,
      Titulo: "Nuevo Producto", op: 3, TabIndex: 0, showAgregar: true
    })
  }

  //Función de editar registro
  editarProducto(Producto, opcion) {
    /**opcion == 0 edicion */
    /**opcion == 1 autorizacion de producto */
    if (opcion == 1) {
      this.state.TabIndex = 5;
      this.setState({ AutorizarProducto: true })
    } else {
      this.state.TabIndex = 0;

    }
    console.log("producto a editar--->", Producto)
    this.setState({
      _id: Producto._id,
      idCategoria: Producto.idCategoria,
      NombreProducto: Producto.NombreProducto,
      TipoProducto: Producto.TipoProducto,
      Descripcion: Producto.Descripcion,
      caracteristicasAgregadas: Producto.caracteristicasAgregadas,
      atributosAgregados: Producto.atributosAgregados,
      idModelo: Producto.idModelo,
      idMateriaPrima: Producto.idMateriaPrima,
      materialesAgregados: Producto.materialesAgregados,
      CostoMaterial: Producto.CostoMaterial,
      CostoMaquila: Producto.CostoMaquila,
      TipoPrecio: Producto.TipoPrecio,
      PorcentajeUtilidad: Producto.PorcentajeUtilidad,
      PrecioPublico: Producto.PrecioPublico,
      PorcentajeComision: Producto.PorcentajeComision,
      ImporteComision: Producto.ImporteComision,
      ImporteUtilidad: Producto.ImporteUtilidad,
      Peso: Producto.Peso,
      Tamaño: Producto.Tamano,
      IVA: Producto.IVA,
      op: 1,
      showAgregar: true,
      Titulo: "Editar Producto",

      ImagenProducto: Producto.ImagenProducto,
      TexturaProducto: Producto.TexturaProducto,
      NombreImagenProducto: Producto.NombreImagenProducto,
      NombreTextura: Producto.NombreTextura,
      NombreTempleteRecorte: Producto.NombreTempleteRecorte,

      GruposMaterial: Producto.GruposMaterial,
      Modelos:Producto.Modelos,
    })

  }

  //Función de eliminar registro
  eliminarProducto(id) {
    fetch(Globales.Url + 'Productos/Productos/BajaProducto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification("tr");
        this.obtenerProductosFin();
        this.limpiar();
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarProducto(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openModalAgregarProducto = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Producto", op: 0, TabIndex: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalProducto = (mensaje, colorNotificacion) => {
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
      this.limpiar();

      // this.obtenerAllSchemas();
      // this.obtenerProductosFin();
    }
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Producto", NombreProducto: "", op: 0 })

  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para realizar el filtrado de búsqueda
  filtrarProducto() {
    var valor1 = 0;
    //Validar que el input no este vacio
    
    // if(this.state.Estado != 0){

    // }else{
    //   if (this.state.NombreProducto.trim() == '') {
    //     this.setState({ errorNombreProducto: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", NombreProducto: '' });
    //     valor1 = 1;
    //   } else {
    //     this.setState({ errorNombreProducto: false });
    //     valor1 = 0;
    //   }
    // }

    
    //Validar si hay campos requeridos
    // if (valor1 == 0) { //Hay campos requeridos
    //   this.showNotification();
    // } else { //No hay campos requeridos
      fetch(Globales.Url + 'Productos/Productos/FiltrarAbanicoProductos', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({NombreProducto:this.state.NombreProducto, Estatus:this.state.Estado})
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.mensaje.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning", mensaje: "No hay ninguna coincidencia", entroFiltrar: true,productosMostrar:[] })
            this.showNotification();
          } else { //Filtrado exitoso
            data.mensaje.map(m => {
              var gruposAutorizados = 0;
  
                m.GruposMaterial.map(g => {
                  if (g.Autorizado == true) {
                    gruposAutorizados++;
                  }
                })
                if (gruposAutorizados == m.GruposMaterial.length) {
                  m["Color"]=""
                }else{
                  m["Color"]="red"
                }
              m["NumeroGrupos"]= gruposAutorizados + "/" + m.GruposMaterial.length;
  
              })
            this.setState({ colorNotificacion: 'success', productosMostrar: data.mensaje, entroFiltrar: true })
          }
        })
        .catch(err => console.error(err));
  //  }
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreProducto: '', entroFiltrar: false,Estado:0 });
    global.Fin = 5;
    global.entrar = true;
    global.response = null;
    this.obtenerProductosFin();
    this.obtenerAllSchemas();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      var mostrarMensaje = false;
      if(this.state.Productos.length <= global.Fin){
        mostrarMensaje = true;
      }
      if (num <= this.state.Productos.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerProductosFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.Productos.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.Productos.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerProductosFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (mostrarMensaje) {
        this.setState({ mensaje: 'No hay más Productos para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }
  handleEstado(e){
  this.setState({
    [e.target.name]:e.target.value
  });
    
  }



  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <AgregarProducto

          Titulo={this.state.Titulo}
          _id={this.state._id}
          idCategoria={this.state.idCategoria}
          NombreProducto={this.state.NombreProducto}
          TipoProducto={this.state.TipoProducto}
          Descripcion={this.state.Descripcion}
          caracteristicasAgregadas={this.state.caracteristicasAgregadas}
          atributosAgregados={this.state.atributosAgregados}
          idModelo={this.state.idModelo}
          idMateriaPrima={this.state.idMateriaPrima}
          materialesAgregados={this.state.materialesAgregados}
          CostoMaterial={this.state.CostoMaterial}
          CostoMaquila={this.state.CostoMaquila}
          TipoPrecio={this.state.TipoPrecio}
          PorcentajeUtilidad={this.state.PorcentajeUtilidad}
          PrecioPublico={this.state.PrecioPublico}
          PorcentajeComision={this.state.PorcentajeComision}
          ImporteUtilidad={this.state.ImporteUtilidad}
          Peso={this.state.Peso}
          Tamaño={this.state.Tamaño}
          Caracteristicas={this.state.Caracteristicas}
          Atributos={this.state.Atributos}
          Modelos={this.state.Modelos}
          MateriaPrima={this.state.MateriaPrima}
          Material={this.state.Material}
          UnidadMedida={this.state.UnidadMedida}
          Empresa={this.state.Empresa}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalProducto}
          TabIndex={this.state.TabIndex}
          ImporteComision={this.state.ImporteComision}
          IVA={this.state.IVA}
          op={this.state.op}
          NombreImagenProducto={this.state.NombreImagenProducto}
          ImagenProducto={this.state.ImagenProducto}
          NombreTempleteRecorte={this.state.NombreTempleteRecorte}
          NombreTextura={this.state.NombreTextura}
          AutorizarProducto={this.state.AutorizarProducto}
          GruposMaterial={this.state.GruposMaterial}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Productos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Búsqueda de producto"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreProducto,
                        name: "NombreProducto",
                        id: "NombreProducto",
                        type: "text",
                        error: this.state.errorNombreProducto
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormControl fullWidth error={this.state.errorTipoProducto} className="mt-4">
                      <InputLabel>Estado</InputLabel>
                      <Select value={this.state.Estado} onChange={this.handleEstado} name="Estado" id="Estado">
                        <MenuItem value={0}>Todos</MenuItem>
                        <MenuItem value={true}>Autorizado</MenuItem>
                        <MenuItem value={false}>Pendiente</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Button size="sm" type="submit" color="info" round className="pull-left mt-5" onClick={() => this.filtrarProducto()}><Search />Buscar</Button>
                    <Button size="sm" type="submit" color="danger" round className="pull-left mt-5" onClick={() => this.limpiar()}><Clear />limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalAgregarProducto()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de Productos */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className="pull-left">Lista de Productos</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {global.response === 200 ?
                  <div className="table-wrapper-scroll-y">
                    <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>
                        <th className="center">Nombre Producto</th>
                        <th className="center">Materia Prima</th>
                        <th className="center">Precio Público</th>
                        <th className="center">Peso</th>
                        <th className="center">Tamaño</th>
                        <th className="center">Autorizado</th>
                        <th className="pull-right">Acciones</th>
                      </thead>
                      <tbody>
                        {this.state.productosMostrar.map(Productos => {
                          return (
                            <tr key={Productos._id}>
                              <td>{Productos.NombreProducto}</td>
                              <td>{this.obtenerNombreMateriaPrima(Productos.idMateriaPrima)}</td>
                              <td>{Productos.PrecioPublico}</td>
                              <td>{Productos.Peso}</td>
                              <td>{this.ObtenerNombreTamaño(Productos.Tamano)}</td>
                              <td>
                                {
                                Productos.Color != "red" ? Productos.NumeroGrupos :
                                  <Tooltip id="close1" title="Autorizar" placement="right" classes={{ tooltip: classes.tooltip }}>
                                    <a onClick={() => this.editarProducto(Productos, 1)} style={{ color: "red", textDecoration: 'underline', cursor: 'pointer' }}>{Productos.NumeroGrupos} </a>

                                  </Tooltip>
                              }  


                                {/* <a onClick={() => this.editarProducto(Productos, 1)} style={{ color: "red", textDecoration: 'underline', cursor: 'pointer' }}>"aaaa" </a> */}

                              </td>
                              <td className="pull-right">
                                <Tooltip id="close1" title="Editar" placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <Button color="warning" round justIcon size="sm" onClick={() => this.editarProducto(Productos, 0)} ><Edit /></Button>

                                  {/* <Button color="warning" round justIcon size="sm"
                                 onClick={() => this.editarProducto(Productos._id, Productos.NombreProducto, Productos.TipoProducto, Productos.ArrayImagenes, Productos.Descripcion,
                                  Productos.caracteristicasAgregadas, Productos.atributosAgregados, Productos.idModelo, Productos.idMateriaPrima, Productos.materialesAgregados, 
                                  Productos.CostoMaterial, Productos.CostoMaquila, Productos.TipoPrecio, Productos.PorcentajeUtilidad, Productos.PrecioPublico, Productos.PorcentajeComision, 
                                  Productos.ImporteUtilidad, Productos.Peso)}><Edit /></Button> */}
                                </Tooltip>

                                <Tooltip id="close1" title="Duplicar producto" placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <Button color="info" round justIcon size="sm" onClick={() => this.DuplicarProducto(Productos)}><FileCopy /></Button>
                                </Tooltip>

                                <Tooltip id="close1" title="Eliminar" placement="top" classes={{ tooltip: classes.tooltip }}>
                                  <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(Productos._id)}><Close /></Button>
                                </Tooltip>






                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div> :
                  <GridContainer justify='center'>
                    <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                  </GridContainer>
                }

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Producto"}
            Cuerpo={"¿Está seguro que desea eliminar el producto?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Productos);