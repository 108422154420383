import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import Globales from "utils/Globales";
import datepicker from 'js-datepicker'
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import { Save, Clear } from '@material-ui/icons';
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";
import CookiesU from 'universal-cookie'
const cookies = new CookiesU();
class AgregarDescuentos extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      idMateriaPrima: '',
      idMaterial: '',
      Cantidad: '',
      Accion: '',
      MostrarModal:false,
      disabled:false,
      Comentarios: '',
      Inventario: [],
      InventarioMostrar: [],
      MateriaPrima: [],
      Material: [],
      UnidadMedida: [],
      MaterialPorMateriaPrima: [],
      NombreUnidad: '',
      ExistenciaMateriaPrima: 0,
      idUsuario:'',
      ExistenciaMaterial: 0,
      Cantidad: 0,
      TotalAccion: 0,
      erroridMateriaPrima: false,
      erroridMaterial: false,
      errorCantidad: false,
      errorExistenciaMateriaPrima: false,
      errorIncremento: false,
      errorComentarios: false,
      errorAccion: false,
      habilitarMaterial: true,
      habilitarAccion: true,
      errorTotalAccion: false,
      _id: '',
      colorNotificacion: 'success',
      mensaje: '',
      br: false
    }
    global.NombreUnidadMedida = '';
    global.ExistenciaMaterialInicio = 0;
    this.handleChange = this.handleChange.bind(this);
    this.handleSpinButtonChange  = this.handleSpinButtonChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }
  componentWillMount(){
    this.setState({
      MateriaPrima: this.props.MateriaPrima,
      Material: this.props.Material,
      UnidadMedida: this.props.UnidadMedida,
      idUsuario:cookies.get('id')
    });
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    
    
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'Cantidad') {
        let val = parseInt(value, 10);
        if (isNaN(val)) {
            this.setState({ 
                Cantidad: "" ,
                errorCantidad: true
            });
          } else {
            val = val >= 0 ? val : 0;
            this.setState({ 
                Cantidad: val,
                errorCantidad: false
            });
          }


/* 
      if (value === '' || parseFloat(value) < 0) {
        this.setState({
          [name]: value,
          errorCantidad: true,
        });
      } else {
        this.setState({
          [name]: value,
          errorCantidad: false
        });
        this.incrementarCantidad();
      } */
      //this.incrementarCantidad();
    } else {
      this.setState({
        [name]: value
      });
    }
  };
  

  handleSpinButtonChange = (valueAsNumber) => {
    if (valueAsNumber >= 0 || isNaN(valueAsNumber)) {
      this.setState({
        Cantidad: valueAsNumber.toString(),
        errorCantidad: false
      });
    }
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === 'idMateriaPrima') {
      var idUnidad = "";
      var nombreUnidadMedida = "";
      var suma = 0;
      var arrayMaterialesPorMateriaPrima = [];
      //Obteniendo el nombre de la unidad de medida
      this.props.MateriaPrima.map(materiaPrima => {
        if (event.target.value == materiaPrima._id) {
          idUnidad = materiaPrima.idUnidadMedida;
        }
      });
      //Recorrriendo las unidades de medida para obtener el nombre
      this.props.UnidadMedida.map(unidades => {
        if (idUnidad == unidades._id) {
          nombreUnidadMedida = unidades.NombreUnidad
        }
      });
      //Recorrer los materiales de la materia prima seleccionada
      this.props.Material.map(material => {
        if (material.idMateriaPrima === event.target.value) {
          //Sumando el total de existencia de materiales de la materia prima seleccionada
          suma = suma + material.Existencia
          //Agregando los materiales de la materia prima
          arrayMaterialesPorMateriaPrima.push(material)
        }
      });
      this.setState({ NombreUnidad: nombreUnidadMedida, ExistenciaMaterial:0 ,ExistenciaMateriaPrima: suma, MaterialPorMateriaPrima: arrayMaterialesPorMateriaPrima, habilitarMaterial: false });
    } else if (event.target.name === 'idMaterial') {
      //Recorrer los amteriales para obtener la existencia
      console.log(this.props.Material);
      this.props.Material.map(material => {
        if (event.target.value === material._id) {
          console.log(material.Existencia);
          this.setState({ ExistenciaMaterial: material.Existencia, habilitarAccion: false });
          global.ExistenciaMaterialInicio = material.Existencia;
        }
      })
    }
  }

  registrarInventario() {
   
    this.setState({
      MostrarModal:true,
      disabled:true,
    })
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    if (this.state.idMateriaPrima === '') {
      this.setState({ erroridMateriaPrima: true, colorNotificacion: 'danger', mensaje: 'Proporcione los datos requeridos', MostrarModal:false,disabled:false });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ erroridMateriaPrima: false });
      valor1 = 0;
    }
    if (this.state.idMaterial === '') {
      this.setState({ errorMaterialPorMateriaPrima: true, colorNotificacion: 'danger', mensaje: 'Proporcione los datos requeridos', MostrarModal:false,disabled:false });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ errorMaterialPorMateriaPrima: false });
      valor2 = 0;
    }
    if (this.state.Cantidad === 0 || this.state.Cantidad == "" || this.state.Cantidad < 0 ) {
      this.setState({ errorCantidad: true, colorNotificacion: 'danger', mensaje: 'Proporcione los datos requeridos', MostrarModal:false,disabled:false });
      this.showNotification();
      valor3 = 1;
    } else {
      this.setState({ errorCantidad: false });
      valor3 = 0;
    }
    if (this.state.Comentarios === '') {
      this.setState({ errorComentarios: true, colorNotificacion: 'danger', mensaje: 'Proporcione los datos requeridos', MostrarModal:false,disabled:false });
      this.showNotification();
      valor4 = 1;
    } else {
      this.setState({ errorComentarios: false });
      valor4 = 0;
    }
    if (this.state.Accion === '') {
      this.setState({ errorAccion: true, colorNotificacion: 'danger', mensaje: 'Proporcione los datos requeridos' , MostrarModal:false,disabled:false});
      this.showNotification();
      valor5 = 1;
    } else {
      this.setState({ errorAccion: false });
      valor5 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 + valor3 + valor4+valor5 === 0) {
      fetch(Globales.Url + 'Inventario/Inventario/AjusteInventario', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            if (data.tipo) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModal:false,disabled:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModal:false,disabled:false });
              this.showNotification();
            }
          } else {
            this.setState({ colorNotificacion: 'success', idMateriaPrima: '', MostrarModal:false,disabled:false, idMaterial: '', Cantidad: 0, Comentarios: '', erroridMateriaPrima: false, errorMaterialPorMateriaPrima: false, errorCantidad: false, errorComentarios: false });
            this.closeModal(data.mensaje, this.state.colorNotificacion);
          }
        })
        .catch(err => console.error(err));
    }
  }

    handleAccion = event => {
    this.setState({ [event.target.name]: event.target.value });
    //Validar que hayan agregado una cantidad
    if (this.state.Cantidad === 0) {
      //this.setState({ Accion: '', mensaje: 'Ingrese cantidad', colorNotificacion: 'danger', errorCantidad: true });
      this.setState({colorNotificacion: 'danger', errorCantidad: true });
      //this.showNotification();
    } else {
      this.setState({ errorCantidad: false });
      //Verificar que acción se seleccionó
      if (event.target.value === 'Incremento') {
        //this.incrementarCantidad();
      } else {
        //this.decrementarCantidad();
      }
    }
  }

  incrementarCantidad() {
    var uno = 0;
    var dos = 0;
    var tres = 0;
    if (this.state.idMateriaPrima === '') {
      this.setState({ mensaje: 'Seleccione una materia prima', colorNotificacion: 'danger', erroridMateriaPrima: true });
      this.showNotification();
      uno = 1;
    } else {
      this.setState({ erroridMateriaPrima: false });
      uno = 0;
    }
    if (this.state.idMaterial === '') {
      this.setState({ mensaje: 'Seleccione un material', colorNotificacion: 'danger', errorMaterialPorMateriaPrima: true });
      this.showNotification();
      dos = 1;
    } else {
      this.setState({ errorMaterialPorMateriaPrima: false });
      dos = 0;
    }
    if (this.state.Cantidad === 0) {
      //this.setState({ mensaje: 'Ingrese un cantidad', colorNotificacion: 'danger', errorCantidad: true });
      this.setState({errorCantidad: true });

      //this.showNotification();
      tres = 1;
    } else {
      this.setState({ errorCantidad: false });
      tres = 0;
    }
    if (uno + dos + tres === 0) {
      //Sumar la cantidad a la cantidad de existencia
      this.setState({ ExistenciaMaterial: Number.parseFloat(global.ExistenciaMaterialInicio) + Number.parseFloat(this.state.Cantidad) });
    }
  }

  decrementarCantidad() {
    var uno = 0;
    var dos = 0;
    var tres = 0;
    if (this.state.idMateriaPrima === '') {
      this.setState({ mensaje: 'Seleccione una materia prima', colorNotificacion: 'danger', erroridMateriaPrima: true });
      this.showNotification();
      uno = 1;
    } else {
      this.setState({ erroridMateriaPrima: false });
      uno = 0;
    }

    if (this.state.idMaterial === '') {
      this.setState({ mensaje: 'Seleccione un material', colorNotificacion: 'danger', errorMaterialPorMateriaPrima: true });
      this.showNotification();
      dos = 1;
    } else {
      this.setState({ erroridMaterial: false });
      dos = 0;
    }
    if (this.state.Cantidad === 0) {
      //this.setState({ mensaje: 'Ingrese un cantidad', colorNotificacion: 'danger', errorCantidad: true });
      this.setState({ colorNotificacion: 'danger', errorCantidad: true });
      //this.showNotification();
      tres = 1;
    } else {
      this.setState({ errorCantidad: false });
      tres = 0;
    }
    if (uno + dos + tres === 0) {
      //Restar la cantidad a la cantidad de existencia de material
      //Validar que la resta sea mayor o igual a la que ya tiene
      if (global.ExistenciaMaterialInicio >= this.state.Cantidad) {
        this.setState({ ExistenciaMaterial: Number.parseFloat(global.ExistenciaMaterialInicio) - Number.parseFloat(this.state.Cantidad) });
      } else {
        this.setState({ mensaje: 'Existencia insuficiente, imposible continuar', colorNotificacion: 'danger' });
        this.showNotification();
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.erroridMateriaPrima}
                    className="mt-4"
                  >
                    <InputLabel
                      className={classes.selectLabel}>
                      * Materia Prima
                        </InputLabel>
                    <Select
                      value={this.state.idMateriaPrima}
                      onChange={this.handleSimple}
                      name="idMateriaPrima"
                      inputProps={{
                      }}
                    >
                      <MenuItem disabled>Materia Prima</MenuItem>
                      {this.state.MateriaPrima.map(materiaPrima =>
                        <MenuItem value={materiaPrima._id}>{materiaPrima.NombreMateria}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    labelText="Unidad de Medida"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      onChange: this.handleSimple,
                      value: this.state.NombreUnidad,
                      type: "text",
                      error: this.state.errorNombreUnidad
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    labelText="Existencia de Materia Prima"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.ExistenciaMateriaPrima,
                      type: "number",
                      error: this.state.errorExistenciaMateriaPrima
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.errorMaterialPorMateriaPrima}
                    className="mt-4"
                  >
                    <InputLabel
                      className={classes.selectLabel}>
                      * Material
                        </InputLabel>
                    <Select
                      value={this.state.idMaterial}
                      onChange={this.handleSimple}
                      name='idMaterial'
                      inputProps={{
                        disabled: this.state.habilitarMaterial
                      }}
                    >
                      <MenuItem disabled>Material</MenuItem>
                      {this.state.MaterialPorMateriaPrima.map(material =>
                        <MenuItem value={material._id}>{material.NombreMaterial}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Existencia de Material"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.ExistenciaMaterial,
                      type: "number",
                      error: this.state.errorExistenciaMaterial
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="* Cantidad"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      onWheel: this.handleWheel,
                      value: this.state.Cantidad,
                      name: 'Cantidad',
                      type: "number",
                      min : "0",
                      step : "1",
                      onKeyPress: (event) => {
                        if (event.key === 'e' || event.key === '-' || event.target.value === ' ' ) {
                          event.preventDefault(); // Prevenir la acción si la tecla es "e"
                        }
                      },
                      error: this.state.errorCantidad,
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.errorAccion}
                    className="mt-4"
                  >
                    <InputLabel
                      className={classes.selectLabel}>
                      * Acción
                        </InputLabel>
                    <Select
                      value={this.state.Accion}
                      onChange={this.handleAccion}
                      name='Accion'
                      inputProps={{
                        disabled: this.state.habilitarAccion
                      }}
                    >
                      <MenuItem disabled>Acción</MenuItem>
                      <MenuItem value='Incremento'>Incrementar</MenuItem>
                      <MenuItem value='Decremento'>Decrementar</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="* Comentarios"
                    id="textarea-input"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.Comentarios,
                      name: 'Comentarios',
                      onChange: this.handleChange,
                      multiline: true,
                      rows: 3,
                      error: this.state.errorComentarios
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridItem>
                <Button tabIndex="0" size="sm" disabled={this.state.disabled} color="info" className="pull-right" round onClick={this.registrarInventario.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(javascriptStyles)(AgregarDescuentos);