import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import InformacionEmpresa from '../InformacionEmpresa.jsx';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ReporteDeComisiones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
     
            Usuarios: [],
            selected: '',
            colorNotificacion: '',
            mensaje: '',
            SelectMateriaPrima:0,
            Material:[],
            SelectMaterial:0,
            MateriaPrima:[],
            br: false,
            Inventario:[]
        };
        this.handleChange = this.handleChange.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.obtenerMateriaPrima = this.obtenerMateriaPrima.bind(this)
        this.obtenerReporteDeInventario = this.obtenerReporteDeInventario.bind(this);
        this.obtenerMaterialPorMateriaPrima = this.obtenerMaterialPorMateriaPrima.bind(this);
    }
    componentDidMount() {
         this.obtenerMateriaPrima();
    };

    obtenerReporteDeInventario() {
        fetch(Globales.Url + 'Reportes/ReporteDeInventario/ObtenerReporteDeInventario', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({idMateriaPrima:this.state.SelectMateriaPrima,idMaterial:this.state.SelectMaterial})
        }
        ).then(res => res.json())
            .then(data => {
                if(data.data.length == 0){
                    this.setState({
                        colorNotificacion:"danger",
                        mensaje:"No se encontró información"
                    });
                    this.showNotification();
                }else{
                    this.setState({
                        Inventario: data.data
                    });
                }
                
            });
    }

    obtenerMateriaPrima() {
        fetch(Globales.Url + 'MateriaPrima/MateriaPrima/', {
          method: 'GET',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
        })
          .then(res => res.json())
          .then(data => {
              this.setState({ MateriaPrima: data.mensaje });
          });
      }

    limpiar() {
        this.setState({
            SelectMateriaPrima:0,
            SelectMaterial:0,
            Inventario:[]
        }, () => {
        });
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    obtenerMaterialPorMateriaPrima(idMateriaPrima){
        fetch(Globales.Url + 'Material/Material/ObtenerMaterialPorMateriaPrima', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body:JSON.stringify({idMateriaPrima:idMateriaPrima})
          })
            .then(res => res.json())
            .then(data => {
                this.setState({ Material: data.data,    SelectMateriaPrima:idMateriaPrima
                                
                });
            });
        }
    
    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;

        if(name =="SelectMateriaPrima" && value != 0){
            this.obtenerMaterialPorMateriaPrima(value)

        }else if(name == "SelectTipoUsuario"){

            this.setState({ [name]: value });
        }else{
            this.setState({ [name]: value });
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Filtros</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem className="mt-5" xs={12} sm={12} md={3}>
                                        <FormControl
                                            fullWidth
                                            error={this.state.errorSelectTipoUsuario}
                                            className="mt-4"
                                        >
                                            <InputLabel>Materia Prima</InputLabel>
                                            <Select
                                                value={this.state.SelectMateriaPrima}
                                                onChange={this.handleChange}
                                                name="SelectMateriaPrima"
                                                id="SelectMateriaPrima"
                                                inputProps={{
                                                    error: this.state.errorSelectMateriaPrima
                                                }}
                                            >
                                                <MenuItem value={0}>Todos</MenuItem>
                                                {this.state.MateriaPrima.map(materia=>{
                                                    return (
                                                    <MenuItem value={materia._id}>{materia.NombreMateria}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem hidden={this.state.SelectMateriaPrima == 0} className="mt-5" xs={12} sm={12} md={3}>
                                        <FormControl
                                            fullWidth
                                            error={this.state.errorSelectTipoUsuario}
                                            className="mt-4"
                                        >
                                            <InputLabel>Material</InputLabel>
                                            <Select
                                                value={this.state.SelectMaterial}
                                                onChange={this.handleChange}
                                                name="SelectMaterial"
                                                id="SelectMaterial"
                                                inputProps={{
                                                    error: this.state.errorSelectMaterial
                                                }}
                                            >
                                                <MenuItem value={0}>Todos</MenuItem>
                                                {this.state.Material.map(materia=>{
                                                    return (
                                                    <MenuItem value={materia._id}>{materia.NombreMaterial}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem>
                                    <Button tabIndex="0" size="sm" color="info" className="mt-5" round onClick={this.obtenerReporteDeInventario}><Search />&nbsp;Buscar</Button>
                                    <Button tabIndex="0" size="sm" color="danger" className="mt-5" round onClick={this.limpiar}><Clear />&nbsp;Limpiar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Reporte</h4>
                            </CardHeader>
                            <CardBody>
                            <div hidden={(this.state.Inventario.length > 0 ? false : true)} >
                                    <div className="table-wrapper-scroll-y">
                                        <table id="DownloableTable" className="col-xs-12 col-sm-12 col-md-12">
                                        <InformacionEmpresa
                                                NombreReporte="Reporte de inventario"
                                                FechaInicial={this.state.FechaInicial}
                                                FechaFinal={this.state.FechaFinal}
                                            />
                                            <thead>
                                                <th className="center">Nombre del material</th>
                                                <th className="center">Nombre de materia prima</th>
                                                <th className="center">Existencia mínima</th>
                                                <th className="center">Existencia actual</th>
                                                
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Inventario.map(material => {
                                                        return (
                                                            <tr key={material._id}>
                                                                <td>{material.NombreMaterial}</td>
                                                                <td>{material.idMateriaPrima.NombreMateria}</td>
                                                                <td>{material.ExistenciaMinima}</td>
                                                                <td>{material.Existencia}</td>
                                                                
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div hidden={(this.state.Inventario.length == 0 ? false : true)}>
                                    <h3 style={{ textAlign: "center" }}>No hay información para mostrar</h3>
                                </div>
                                <div hidden={true}>
                                    <ReactHTMLTableToExcel
                                        id="btnDownloadExcel"
                                        className="download-table-xls-button"
                                        table="DownloableTable"
                                        filename={"Reporte de inventario " + new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                        sheet="tablexls" />
                                </div>
                                <div style={{ textAlign: "center" }} hidden={(this.state.Inventario.length > 0 ? false : true)}>
                                    <Button onClick={() => {
                                        document.getElementById('btnDownloadExcel').click()
                                    }} >
                                        Descargar EXCEL
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(ReporteDeComisiones);