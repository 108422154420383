// core components/views for Production layout
import ReporteDeComisiones from 'views/Modulos/Reportes/Reportes/ReporteDeComisiones.jsx';
import ReporteDeUsuarios from 'views/Modulos/Reportes/Reportes/ReporteDeUsuarios.jsx';
import ReporteDeInventario from 'views/Modulos/Reportes/Reportes/ReporteDeInventario.jsx';
import ReporteDeRetrasos from 'views/Modulos/Reportes/Reportes/ReporteDeRetrasos.jsx';
import ReporteDeVentas from 'views/Modulos/Reportes/Reportes/ReporteDeVentas.jsx';
import ReporteDeProductos from 'views/Modulos/Reportes/Reportes/ReporteDeProductos.jsx';
// @material-ui/icons
import { Streetview, Grain, FormatPaint, Assignment,AssignmentInd,Dns, ReportProblem, Store, MergeType, Home,Web, Menu } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();
cookies.addChangeListener()

var dashboardRoutes = [

  {
    path: "/reportes",
    name: "Reporte de pago de comisiones",
    icon: Assignment,
    component: ReporteDeComisiones,
    layout: "/report",
    idModulo:"5d0822d8198ea43abc040dcb"
  },
  {
    path: "/reporteUsuarios",
    name: "Reporte de usuarios",
    icon: AssignmentInd,
    component: ReporteDeUsuarios,
    layout: "/report",
    idModulo:"5d0822b5198ea43abc040dc8"
  },
  {
    path: "/reporteInventario",
    name: "Reporte de inventario",
    icon: Dns,
    component: ReporteDeInventario,
    layout: "/report",
    idModulo:"5d0822ab198ea43abc040dc7"
  },
  {
    path: "/reporteRetrasos",
    name: "Reporte de retrasos",
    icon: ReportProblem,
    component: ReporteDeRetrasos,
    layout: "/report",
    idModulo:"5d0822e5198ea43abc040dcc"
  },
  {
    path: "/reporteVentas",
    name: "Reporte de ventas",
    icon: Store,
    component: ReporteDeVentas,
    layout: "/report",
    idModulo:"5d0822be198ea43abc040dc9"
  },
  {
    path: "/reporteProductos",
    name: "Reporte de diseños",
    icon: FormatPaint,
    component: ReporteDeProductos,
    layout: "/report",
    idModulo:"5d0822ce198ea43abc040dca"
  }
];




var dashboardRoutesAccess = [];
for(var keyRoutes  = 0; keyRoutes < dashboardRoutes.length; keyRoutes++){
  if(cookies.get('TipoUsuario') != undefined){
    if(cookies.get('TipoUsuario').idModulos.includes(dashboardRoutes[keyRoutes].idModulo) == true){
      dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
    }
  } 
}


export default dashboardRoutesAccess;



