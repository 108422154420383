import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import InformacionEmpresa from '../InformacionEmpresa.jsx';

class ReporteDeComisiones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            colorNotificacion: '',
            mensaje: '',
            br: false,
            dataAutocomplete: [],
            Retrasos: [],
            FolioPedido:'',
            selected: [],
            tipoFiltro:false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.filtrarClientes = this.filtrarClientes.bind(this)
        this.obtenerReporteDeRetrasos = this.obtenerReporteDeRetrasos.bind(this);
        this.obtenerFechaConFormatoListado = this.obtenerFechaConFormatoListado.bind(this)
    }
    componentDidMount() {
        this.filtrarClientes()
    };

    obtenerReporteDeRetrasos() {
        fetch(Globales.Url + 'Reportes/ReporteDeRetrasos/ObtenerReporteRetrasoDePedidos', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ 
                FechaFinal: this.state.FechaFinal, FechaInicial: this.state.FechaInicial, idCliente: (this.state.selected.length > 0 && this.state.tipoFiltro == false ? this.state.selected[0].id : null),FolioPedido:(this.state.tipoFiltro== true?this.state.FolioPedido:null) 
            })
        }
        ).then(res => res.json())
            .then(data => {
                if(data.data.length == 0){
                    this.setState({
                        mensaje:"No se encontró información",
                        colorNotificacion:"danger",
                        Retrasos: []
                    });
                    this.showNotification();
                }else{
                    this.setState({
                        Retrasos: data.data
                    });
                }
            });
    }

    componentWillMount() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),

        })
    }
    limpiar() {
        this.setState({
            FolioPedido:'',
            selected: [],
            Retrasos: [],
            tipoFiltro:false,
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        });
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }
    filtrarClientes() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuarios', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ NombreUsuario: this.state.NombreUsuarioFiltro, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal })
        }
        ).then(res => res.json())
            .then(data => {
                var usuariosClientes = []
                data.data.map((usuario) => {
                    usuariosClientes.push({
                        id: usuario._id,
                        label: usuario.Nombre
                    })
                })
                this.setState({
                    Usuarios: [],
                    dataAutocomplete: usuariosClientes
                });
            });
    }

    obtenerFechaConFormatoListado(fecha){
        return new Date(new Date(fecha).setDate(new Date(fecha).getDate())).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
      }
    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        if (name == "FechaInicial") {
            if (value <= this.state.FechaFinal) {
                this.setState({ [name]: value });
            }
        } else if (name == "FechaFinal") {
            if (value >= this.state.FechaInicial) {
                this.setState({ [name]: value });
            }
        } else if(name == "CheckFiltro") {
           this.setState({
               "tipoFiltro":!this.state.tipoFiltro
           })
        }else{
            this.setState({ [name]: value });

        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Filtros</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem className="mt-5" xs={12} sm={12} md={3}>
                                        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.tipoFiltro
                                                        }
                                                        name="CheckFiltro"
                                                        onClick={this.handleChange}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                       
                                                    />
                                                }
                                                label={(this.state.tipoFiltro == true?"Por cliente":"Por folio de pedido")}
                                            />
                                        </div>
                                        <div hidden={this.state.tipoFiltro} style={{ marginTop: "-10px" }} >
                                            <Typeahead
                                                onChange={(selected) => {
                                                    this.setState({ selected });
                                                }}
                                                options={this.state.dataAutocomplete}
                                                selected={this.state.selected}
                                                placeholder="Cliente"
                                            />
                                        </div>
                                        <div  hidden={!this.state.tipoFiltro} style={{ marginTop: "-50px" }}>
                                        <CustomInput
                                            labelText="Folio de pedido"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FolioPedido,
                                                name: "FolioPedido",
                                                id: "FolioPedido",
                                                type: "text",
                                                error: this.state.errorFolioPedido,
                                            }}
                                            
                                        />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha inicial"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaInicial,
                                                name: "FechaInicial",
                                                id: "FechaInicial",
                                                type: "date",
                                                error: this.state.errorFechaInicial,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha final"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                                error: this.state.errorFechaFinal,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <Button tabIndex="0" size="sm" color="info" className="mt-5" round onClick={this.obtenerReporteDeRetrasos}><Search />&nbsp;Buscar</Button>
                                        <Button tabIndex="0" size="sm" color="danger" className="mt-5" round onClick={this.limpiar}><Clear />&nbsp;Limpiar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Reporte</h4>
                            </CardHeader>
                            <CardBody>
                                <div hidden={(this.state.Retrasos.length > 0 ? false : true)} >
                                    <div className="table-wrapper-scroll-y">
                                        <table id="DownloableTable" className="col-xs-12 col-sm-12 col-md-12">
                                        <InformacionEmpresa
                                                NombreReporte="Reporte de retrasos"
                                                FechaInicial={this.state.FechaInicial}
                                                FechaFinal={this.state.FechaFinal}
                                            />
                                            <thead>
                                                <th className="center">Folio de pedido</th>
                                                <th className="center">Nombre del cliente</th>
                                                <th className="center">Días de retraso</th>
                                                <th className="center">Motivo de retraso</th>
                                                <th className="center">Fecha</th>
                                                <th className="center">Usuario de registro</th>

                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Retrasos.map(retraso => {
                                                        return (
                                                            <tr key={retraso._id}>
                                                                <td>{retraso.Compra.FolioPedido}</td>
                                                                <td>{retraso.Cliente.Nombre}</td>
                                                                <td>{retraso.Dias}</td>
                                                                <td>{retraso.MotivoRetraso}</td>
                                                                <td>{this.obtenerFechaConFormatoListado(retraso.FechaRegistro)}</td>
                                                                <td>{retraso.Usuario.NombreUsuario}</td>
                                                            </tr>
                                                        )
                                                    })
                                                };
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div hidden={(this.state.Retrasos.length == 0 ? false : true)}>
                                    <h3 style={{ textAlign: "center" }}>No hay información para mostrar</h3>
                                </div>
                                <div hidden={true}>
                                    <ReactHTMLTableToExcel
                                        id="btnDownloadExcel"
                                        className="download-table-xls-button"
                                        table="DownloableTable"
                                        filename={"Reporte de Retrasos " + new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                        sheet="tablexls" />
                                </div>
                                <div style={{ textAlign: "center" }} hidden={(this.state.Retrasos.length > 0 ? false : true)}>
                                    <Button onClick={() => {
                                        document.getElementById('btnDownloadExcel').click()
                                    }} >
                                        Descargar EXCEL
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(ReporteDeComisiones);