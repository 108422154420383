// core components/views for Production layout
import Produccion from 'views/Modulos/Produccion/Tablero de produccion/TableroProduccion.jsx'
import Recolecciones from 'views/Modulos/Produccion/Solicitudes de recoleccion/SolicitudesDeRecoleccion.jsx'
// @material-ui/icons
import { Streetview, Grain, ScatterPlot, LinearScale, BarChart, Whatshot, MergeType, Home,Web, Menu } from "@material-ui/icons";
import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/produccion",
    name: "Tablero de producción",
    icon: Web,
    component: Produccion,
    layout: "/prod",
    idModulo:"5d08228c198ea43abc040dc5"
  },
  {
    path: "/recolecciones",
    name: "Generación de guía",
    icon: Web,
    component: Recolecciones,
    layout: "/prod",
    idModulo:"5d08229b198ea43abc040dc6"  
  }
];

var dashboardRoutesAccess = [];
for(var keyRoutes  = 0; keyRoutes < dashboardRoutes.length; keyRoutes++){
  if(cookies.get('TipoUsuario') != undefined){
    if(cookies.get('TipoUsuario').idModulos.includes(dashboardRoutes[keyRoutes].idModulo) == true){
      dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
    }
  }
}
// console.log(dashboardRoutesAccess)
export default dashboardRoutesAccess;