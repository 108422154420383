import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import InformacionEmpresa from '../InformacionEmpresa.jsx';

class ReporteDeComisiones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            Usuarios: [],
            selected: '',
            colorNotificacion: '',
            mensaje: '',
            SelectTipoUsuario:0,    
            br: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.obtenerReporteDeUsuarios = this.obtenerReporteDeUsuarios.bind(this);
    }
    componentDidMount() {
    };

    obtenerReporteDeUsuarios() {
        fetch(Globales.Url + 'Reportes/ReporteDeUsuarios/ObtenerReporteDeUsuarios', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body:JSON.stringify({FechaFinal:this.state.FechaFinal,FechaInicial:this.state.FechaInicial,TipoDeUsuario:this.state.SelectTipoUsuario})
        }
        ).then(res => res.json())
            .then(data => {
                if(data.data.length == 0){
                    this.setState({
                        mensaje:"No se encontró información",
                        colorNotificacion:"danger"
                    });
                    this.showNotification();
                }else{
                    this.setState({
                        Usuarios:data.data
                    });
                }
            });
    }

    componentWillMount() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),

        })
    }

    limpiar() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            Usuarios: [],
            selected: '',
            colorNotificacion: '',
            mensaje: '',
            SelectTipoUsuario:0,
        });
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });  
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        if(name == "FechaInicial"){
            if(value <= this.state.FechaFinal){
                this.setState({ [name]: value });
            }
        }else if(name == "FechaFinal"){
            if(value >= this.state.FechaInicial){
                this.setState({ [name]: value });
            }
        }else{
            this.setState({ [name]: value });
        }   
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>
                                    Filtros
                            </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText="Fecha inicial"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaInicial,
                                                name: "FechaInicial",
                                                id: "FechaInicial",
                                                type: "date",
                                                error: this.state.errorFechaInicial,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <CustomInput
                                            labelText="Fecha Final"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                                error: this.state.errorFechaFinal,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem className="mt-5" xs={12} sm={12} md={3}>
                                        <FormControl
                                            fullWidth
                                            error={this.state.errorSelectTipoUsuario}
                                            className="mt-4"
                                        >
                                            <InputLabel>Tipo de usuario</InputLabel>
                                            <Select
                                                value={this.state.SelectTipoUsuario}
                                                onChange={this.handleChange}
                                                name="SelectTipoUsuario"
                                                id="SelectTipoUsuario"
                                                inputProps={{
                                                    error: this.state.errorSelectTipoUsuario
                                                }}
                                            >
                                                <MenuItem value={0}>Todos</MenuItem>
                                                <MenuItem value={1}>Clientes</MenuItem>
                                                <MenuItem value={2}>Artistas</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem className="mt-5" xs={12} sm={12} md={3}>
                                    <Button tabIndex="0" size="sm" color="info" className="mt-4" round onClick={this.obtenerReporteDeUsuarios}><Search />&nbsp;Buscar</Button>
                                    <Button tabIndex="0" size="sm" color="danger" className="mt-4" round onClick={this.limpiar}><Clear />&nbsp;Limpiar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Reporte</h4>
                            </CardHeader>
                            <CardBody>
                            <div hidden={(this.state.Usuarios.length > 0 ? false : true)} >
                                    <div className="table-wrapper-scroll-y">
                                        <table id="DownloableTable" className="col-xs-12 col-sm-12 col-md-12">
                                        <InformacionEmpresa
                                                NombreReporte="Reporte de usuarios"
                                                FechaInicial={this.state.FechaInicial}
                                                FechaFinal={this.state.FechaFinal}
                                            />
                                            <thead>
                                                <th className="center">Usuario</th>
                                                <th className="center">Fecha</th>
                                                <th className="center">Tipo de usuario</th>
                                                <th className="center">Nombre</th>
                                                <th className="center">Correo</th>
                                                <th className="center">Teléfono</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Usuarios.map(Usuario => {
                                                        return (
                                                            <tr key={Usuario._id}>
                                                                <td>{Usuario.Usuario}</td>
                                                                <td>{Usuario.FechaRegistro}</td>
                                                                <td>{Usuario.TipoUsuario}</td>
                                                                <td>{Usuario.Nombre}</td>
                                                                <td>{Usuario.Correo}</td>
                                                                <td>{Usuario.Telefono}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div hidden={(this.state.Usuarios.length == 0 ? false : true)}>
                                    <h3 style={{ textAlign: "center" }}>No hay información para mostrar</h3>
                                </div>
                                <div hidden={true}>
                                    <ReactHTMLTableToExcel
                                        id="btnDownloadExcel"
                                        className="download-table-xls-button"
                                        table="DownloableTable"
                                        filename={"Reporte de usuarios " + new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                        sheet="tablexls" />
                                </div>
                                <div  hidden={(this.state.Usuarios.length > 0 ? false : true)}>
                                    <h4>
                                    <b style={{textAlign:"right"}}>
                                        Total: {this.state.Usuarios.length}
                                    </b>
                                    </h4>
                                    
                                </div>                 
                                <div  style={{ textAlign: "center" }}  hidden={(this.state.Usuarios.length > 0 ? false : true)}>
                                    <Button onClick={() => {
                                        document.getElementById('btnDownloadExcel').click()
                                    }} >
                                        Descargar EXCEL
                                    </Button>
                                </div>                 
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(ReporteDeComisiones);