import UsuariosWeb from "views/Modulos/AtencionUsuarios/UsuariosWeb/AdministracionUsuariosWeb";
import { ContactsTwoTone } from "@material-ui/icons";
import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

const dashboardRoutes = [
    {
        path: "/adminUsuariosWeb",
        name: "Administración de Usuarios Web",
        icon: ContactsTwoTone,
        component: UsuariosWeb,
        layout: "/atencionUsuarios",
        idModulo: "64fb7194706c33ada58ed4d7"
    }
];


var dashboardRoutesAccess = [];
for (var keyRoutes = 0; keyRoutes < dashboardRoutes.length; keyRoutes++) {
    if (cookies.get('TipoUsuario') !== undefined) {
        if (cookies.get('TipoUsuario').idModulos.includes(dashboardRoutes[keyRoutes].idModulo) == true) {
            dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
        }
    }
}

export default dashboardRoutesAccess;