import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui icons
import { Modal } from 'reactstrap';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import AgregarDescuentos from "views/Modulos/Administracion/Descuentos/AgregarDescuentos.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Save } from "@material-ui/icons";
import ReactLoading from "react-loading";

class Descuentos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Estatus: 0,
            NombreMembresia: "",
            Descripcion: '',
            PorcentajeDescuento: '',
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            MesesDePromocion: 1,
            _id: '',
            Membresias: [],
            NombreMembresiaBusqueda:"",
            TituloModalAgregar: "Agregar membresía",
            AbrirModal: false,
            errorNombreMembresia: false,
            errorDescripcion: false,
            errorFechaInicial: false,
            br: false,
            errorFechaFinal: false,
            errorPorcentajeDescuento: false,
            errorMesesDePromocion: false,
            colorNotificacion: "",
            Metodo:"RegistrarMembresia",
            mensaje: "",
            FechaInicialSistema: '',
            Contador:5,
            SoloConsulta:false,
            show:false,
            idPorEliminar:null
            
        };
        //Variable global para indicar el total de registros que se obtendra de la base de datos
        this.handleChange = this.handleChange.bind(this);
        this.ObtenerMembresias = this.ObtenerMembresias.bind(this);
        this.registrarMembresia = this.registrarMembresia.bind(this);
        this.obtenerFechaConFormatoListado = this.obtenerFechaConFormatoListado.bind(this);
        this.eliminarMembresia = this.eliminarMembresia.bind(this);
        this.eventoModalEliminar = this.eventoModalEliminar.bind(this)
        this.verMas = this.verMas.bind(this);
    }

    componentDidMount() {
        this.ObtenerMembresias();
    }

    obtenerFechaConFormatoListado(fecha){
        //return new Date(new Date(fecha).setDate(new Date(fecha).getDate()+2)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
        return new Date(fecha).toISOString().substr(0, 10);

    };
    verMas(){
        this.setState({
            Contador:this.state.Contador+5
        },()=>{
            this.ObtenerMembresias();
        });
    };

    registrarMembresia() {
        var formValido = true;
        var ObjetoState = new Object();
        if (this.state.NombreMembresia == "") {
            formValido = false;
            ObjetoState["errorNombreMembresia"] = true;
        }
        if (this.state.Descripcion == "") {
            formValido = false;
            ObjetoState["errorDescripcion"] = true;

        }
        const porcentajeDescuento = parseFloat(this.state.PorcentajeDescuento);
        if (porcentajeDescuento === "" || isNaN(porcentajeDescuento) || porcentajeDescuento < 0 || porcentajeDescuento > 100) {
            formValido = false;
            ObjetoState["errorPorcentajeDescuento"] = true;
        }
        if (this.state.MesesDePromocion == "") {
            formValido = false;
            ObjetoState["errorMesesDePromocion"] = true;

        }
        if(this.state.Metodo == "ActualizarMembresia"){

        }else{
            if(this.state.FechaInicial < this.state.FechaInicialSistema){
                formValido = false;
                ObjetoState["errorFecha1"] = true;
            }
        }
        if(this.state.FechaFinal < this.state.FechaInicial){
            formValido = false;
            ObjetoState["errorFecha2"] = true;
        }

        if (formValido == true) {
            fetch(Globales.Url + 'Membresias/'+this.state.Metodo, {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({
                    _id:(this.state.Metodo == "ActualizarMembresia"?this.state._id:null),
                    NombreMembresia: this.state.NombreMembresia,
                    Descripcion: this.state.Descripcion,
                    PorcentajeDescuento: this.state.PorcentajeDescuento,
                    FechaFinal: this.state.FechaFinal,
                    FechaInicial: this.state.FechaInicial,
                    MesesDePromocion: this.state.MesesDePromocion
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.error == true) {
                        this.setState({
                            br: true,
                            colorNotificacion: "danger",
                            mensaje: data.mensaje,
                        });
                        this.showNotification();
                    } else {
                        this.setState({
                            br: true,
                            colorNotificacion: "success",
                            mensaje: data.mensaje,
                            AbrirModal: false,
                            NombreMembresia: "",
                            Descripcion: '',
                            PorcentajeDescuento: '',
                            MesesDePromocion: 1,
                        });
                        this.showNotification();
                        this.ObtenerMembresias();
                    }
                });
        } else {
            if(ObjetoState["errorFecha1"]){
                ObjetoState["colorNotificacion"] = "danger";
                ObjetoState["mensaje"] = "No se puede crear la membresia, porque la Fecha inicial es menor que la fecha final de la ultima membresia activa";
                ObjetoState["br"] = true;
                this.setState(ObjetoState);
                this.showNotification();
            }else if(ObjetoState["errorFecha2"]){
                ObjetoState["colorNotificacion"] = "danger";
                ObjetoState["mensaje"] = "La fecha final es menor que la fecha inicial";
                ObjetoState["br"] = true;
                this.setState(ObjetoState);
                this.showNotification();
            }else{
                ObjetoState["colorNotificacion"] = "danger";
                ObjetoState["mensaje"] = "Complete los campos requeridos";
                ObjetoState["br"] = true;
                this.setState(ObjetoState);
                this.showNotification();
            }
        }
    };

    ObtenerMembresias() {
        fetch(Globales.Url + 'Membresias/ObtenerMembresias', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body:JSON.stringify({Fin:this.state.Contador})
        })
            .then(res => res.json())
            .then(data => {
                if(this.state.Contador > data.data.length && this.state.Contador > 5){
                    this.setState({
                        mensaje:"No hay más registros",
                        colorNotificacion:"danger"
                    });
                    this.showNotification()
                }
                var fechaMostrar;
                var fechaActual = new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
                console.log(data.fechaProxima)
                if(data.fechaProxima < fechaActual){
                    fechaMostrar = fechaActual
                }else{
                    fechaMostrar = data.fechaProxima;
                }
                this.setState({
                    Membresias: data.data,
                    FechaInicialSistema: data.fechaProxima,
                    FechaInicial: fechaMostrar,
                    FechaFinal: fechaMostrar
                })
            });
    };

    //Función que habilita la notificación
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            2000
        );
    };

    eliminarMembresia(){
        fetch(Globales.Url + 'Membresias/CancelarMembresia', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({
                _id:this.state.idPorEliminar
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error == true) {
                    this.setState({
                        br: true,
                        colorNotificacion: "danger",
                        mensaje: data.mensaje,
                        show:false,
                        idPorEliminar:null
                    });
                    this.showNotification();
                } else {
                    this.setState({
                        br: true,
                        colorNotificacion: "success",
                        mensaje: data.mensaje,
                        show:false,
                        idPorEliminar:null
                    });
                    this.showNotification();
                    this.ObtenerMembresias();
                }
            });
    };

    filtrarMembresias(){
        if(this.state.NombreMembresiaBusqueda == ""){
            this.setState({
                mensaje:"Favor de llenar el campo",
                colorNotificacion:"warning",
                errorNombreMembresiaBusqueda:true
            });
            this.showNotification()
        }else{
            fetch(Globales.Url + 'Membresias/FiltrarMembresias', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({
                    NombreMembresia:this.state.NombreMembresiaBusqueda
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.error == true) {
                        this.setState({
                            br: true,
                            colorNotificacion: "danger",
                            mensaje: data.mensaje,
                        });
                        this.showNotification();
                    } else {
                        if(data.data.length == 0){
                            this.setState({
                                br: true,
                                Membresias:[],
                                colorNotificacion: "danger",
                                mensaje: "No se encontraron registros",
                            });
                            this.showNotification();
                        }else{
                            var fechaMostrar;
                            var fechaActual = new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
                            if(data.fechaProxima < fechaActual){
                                fechaMostrar = fechaActual
                            }else{
                                fechaMostrar = data.fechaProxima;
                            }
                            this.setState({
                                Membresias: data.data,
                                FechaInicialSistema: data.fechaProxima,
                                FechaInicial: fechaMostrar,
                                FechaFinal: fechaMostrar
                            })
                        }
                    }
                });
        }
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        if (name == "PorcentajeDescuento") {
            if (value <= 100) {
                this.setState({
                    [name]: value
                });
            }
        } /* else if (name == "FechaFinal") {
            if (value < this.state.FechaInicial) {
            } else {
                this.setState({
                    [name]: value
                });
            }
        } */ /* else if (name == "FechaInicial") {
            if (value < this.state.FechaInicialSistema) {
            } else {
                if (value > this.state.FechaInicial) {
                    this.setState({
                        [name]: value,
                        FechaFinal: value
                    });
                } else {
                    this.setState({
                        [name]: value
                    });
                }
            }
        }  */else if(name =="MesesDePromocion") {
            this.setState({
                [name]: value
            });
            /*if(value >0){
                this.setState({
                    [name]: value
                });
            }*/
           
        }else{
            this.setState({
                [name]: value
            });
        }
    };

    //Función para volver los valores por default
    limpiar() {
        this.setState({
            NombreMembresiaBusqueda:""
        },()=>{
            this.ObtenerMembresias()
        })
    };

    abrirParaEdicion(membresia, event) {
        const fechaBuenaFinal = new Date(new Date(membresia.FechaFinal).setDate(new Date(membresia.FechaFinal).getDate() + 1)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const fechaBuenaInicial = new Date(new Date(membresia.FechaInicial).setDate(new Date(membresia.FechaInicial).getDate() + 1)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
        console.log(fechaBuenaFinal)
        console.log(this.state.Membresias)
        console.log(membresia)

        this.setState({
            NombreMembresia: membresia.NombreMembresia,
            _id: membresia._id,
            SoloConsulta:(this.state.Membresias[0]._id== membresia._id?false:true),
            PorcentajeDescuento: membresia.PorcentajeDescuento,
            FechaFinal: fechaBuenaFinal,
            FechaInicial: fechaBuenaInicial,
            MesesDePromocion: membresia.MesesDePromocion,
            AbrirModal: true,
            Descripcion: membresia.Descripcion,
            Metodo:"ActualizarMembresia"
        })
    };

    eventoModalEliminar(e) {
        if (!e) {
          this.setState({ show: false });
          return;
        }
        //Eliminar el elemento
        this.eliminarMembresia()
      }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>

                    <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Membresías</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Buscar membresia"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreMembresiaBusqueda,
                        name: "NombreMembresiaBusqueda",
                        id: "NombreMembresiaBusqueda",
                        type: "text",
                        error: this.state.errorNombreMembresiaBusqueda
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="info" round 
                    onClick={() => this.filtrarMembresias()}
                    ><Search />Buscar</Button>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round 
                    onClick={() => this.limpiar()}
                    ><Clear />limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.setState({
                                AbrirModal: true
                            })}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
                        {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={this.verMas.bind(this)}><Filter9Plus />Ver Más</Button>
                            <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.setState({
                                AbrirModal: true
                            })}><AddCircle />Agregar</Button>
                        </GridItem> */}
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    {/* Tabla de descuentos */}
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4 className="pull-left">Lista de membresías</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <div  hidden={(this.state.Membresias.length > 0 ? false : true)} className="table-wrapper-scroll-y">
                                    <table className="col-xs-12 col-sm-12 col-md-12">
                                        <thead>
                                            <th className="center">Nombre de membresía</th>
                                            <th className="center">Descuento</th>
                                            <th className="center">Vigencia</th>
                                            <th className="pull-right">Acciones</th>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.Membresias.map((membresia) => {
                                                    return (
                                                        <tr key={membresia._id}>
                                                            <td>{membresia.NombreMembresia}</td>
                                                            <td>{membresia.PorcentajeDescuento}</td>
                                                            <td>{ this.obtenerFechaConFormatoListado(membresia.FechaFinal)}</td>
                                                            <td className="pull-right">
                                                                <Button hidden={(membresia._id == this.state.Membresias[this.state.Membresias.length-1]._id)?false:true} color="danger" round justIcon size="sm" onClick={()=>this.setState({show:true,idPorEliminar:membresia._id})}><Close /></Button>
                                                                <Button  color="warning"  round justIcon size="sm" onClick={this.abrirParaEdicion.bind(this, membresia)}><Edit /></Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ textAlign: "center" }} hidden={(this.state.Membresias.length == 0 ? false : true)}>
                                    <h4>No hay membresías registradas</h4>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <Modal
                    isOpen={this.state.AbrirModal}
                    size="lg"
                >
                    <Card >
                        <CardHeader color="info">
                            {this.state.TituloModalAgregar}
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <CustomInput
                                        labelText="Nombre membresía"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange.bind(this),
                                            value: this.state.NombreMembresia,
                                            name: "NombreMembresia",
                                            id: "NombreMembresia",
                                            type: "text",
                                            disabled:this.state.SoloConsulta,
                                            error: this.state.errorNombreMembresia

                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <CustomInput
                                        labelText="Descripción"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange.bind(this),
                                            value: this.state.Descripcion,
                                            name: "Descripcion",
                                            disabled:this.state.SoloConsulta,
                                            id: "Descripcion",
                                            type: "text",
                                            error: this.state.errorDescripcion
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <CustomInput
                                        labelText="Fecha inicial"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange,
                                            value: this.state.FechaInicial,
                                            name: "FechaInicial",
                                            disabled:this.state.SoloConsulta,
                                            id: "FechaInicial",
                                            type: "date",
                                            error: this.state.errorFechaInicial,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <CustomInput
                                        labelText="Fecha final"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange,
                                            value: this.state.FechaFinal,
                                            name: "FechaFinal",
                                            id: "FechaFinal",
                                            type: "date",
                                            disabled:this.state.SoloConsulta,
                                            error: this.state.errorFechaFinal,
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <CustomInput
                                        labelText="Porcentaje de descuento"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange.bind(this),
                                            value: this.state.PorcentajeDescuento,
                                            name: "PorcentajeDescuento",
                                            id: "PorcentajeDescuento",
                                            disabled:this.state.SoloConsulta,
                                            type: "number",
                                            error: this.state.errorPorcentajeDescuento
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <CustomInput
                                        labelText="Meses de promoción"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            onChange: this.handleChange.bind(this),
                                            value: this.state.MesesDePromocion,
                                            name: "MesesDePromocion",
                                            id: "MesesDePromocion",
                                            disabled:this.state.SoloConsulta,
                                            type: "number",
                                            error: this.state.errorMesesDePromocion
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridItem>
                                <Button disabled={this.state.SoloConsulta} tabIndex="0" size="sm" color="info" className="pull-right" round onClick={() => { this.registrarMembresia() }}><Save />&nbsp;GUARDAR</Button>
                                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.setState({
                                    AbrirModal: false,
                                    NombreMembresia: "",
                                    Descripcion: '',
                                    PorcentajeDescuento: '',
                                    MesesDePromocion: 1,
                                    Metodo:"RegistrarMembresia"
                                },()=>{
                                    this.ObtenerMembresias();
                                })
                                }><Clear />&nbsp;CANCELAR</Button>
                            </GridItem>
                        </CardBody>
                    </Card>
                </Modal>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                <div id="">
          <ModalPregunta
            Titulo={"Eliminar membresía"}
            Cuerpo={"¿Está seguro que desea eliminar la membresía?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          
        </div>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(Descuentos);