import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';

export default class ModalAgregarUnidadMedida extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreUnidad: '',
      Abreviacion: '',
      _id: '',
      errorNombre: false,
      MostrarModal: false,
      errorAbreviacion: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disable: false
    }
    this.registrarUnidadMedida = this.registrarUnidadMedida.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreUnidad: this.props.NombreUnidad,
        NombreGuardado: this.props.NombreUnidad,
        Abreviacion: this.props.Abreviacion
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarUnidadMedida() {
    this.setState({
      MostrarModal: true,
      disable: true
    })
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      fetch(Globales.Url + 'UnidadMedida/UnidadMedida/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreUnidad: this.state.NombreUnidad,_id:this.props._id  })
      }
      ).then(res => res.json())
        .then(data => {
        console.log("🍺 / file: ModalAgregarUnidadMedida.jsx / line 75 / data", data)
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true,MostrarModal:false });
            this.showNotification();
          } else {
            var valor1 = 0;
            var valor2 = 0;
            //Validar que el input no este vacio
            if (this.state.NombreUnidad.trim() == '' || this.state.errorNombre == true) {
              this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreUnidad: '', MostrarModal: false, disable: false });
              valor1 = 1;
            } else {
              this.setState({ errorNombre: false });
              valor1 = 0;
            }
            if (this.state.Abreviacion.trim() == '') {
              this.setState({ errorAbreviacion: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", Abreviacion: '', MostrarModal: false, disable: false });
              valor2 = 1;
            } else {
              this.setState({ errorAbreviacion: false });
              valor2 = 0;
            }
            if (valor1 > 0 || valor2 > 0) {
              this.setState({ colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disable: false });
              this.showNotification("tr");
            } else {
              fetch(Globales.Url + 'UnidadMedida/UnidadMedida/ActualizarUnidadMedida', {
                method: 'POST',
                headers: {
                  Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify({ _id: this.props._id, NombreUnidad: this.state.NombreUnidad, Abreviacion: this.state.Abreviacion, MostrarModal: false, disable: false })
              }
              ).then(res => res.json())
                .then(data => {
                console.log("🍺 / file: ModalAgregarUnidadMedida.jsx / line 110 / data", data)

                  if (data.error) {
                    this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false, MostrarModal: false, disable: false });
                    this.showNotification();
                  } else {
                    this.setState({ colorNotificacion: 'success', NombreUnidad: '', disable: false, Abreviacion: '', MostrarModal: false, disable: false, errorNombre: false, errorAbreviacion: false });
                    this.closeModal(data.mensaje, this.state.colorNotificacion);
                  }
                })
                .catch(err => console.error(err));
            }
          }
        })
        .catch(err => console.error(err));

    } else {
      fetch(Globales.Url + 'UnidadMedida/UnidadMedida/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreUnidad: this.state.NombreUnidad })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true,MostrarModal:false });
            this.showNotification();
          } else {
            var valor1 = 0;
            var valor2 = 0;
            //Validar que el input no este vacio
            if (this.state.NombreUnidad.trim() == '' || this.state.errorNombre == true) {
              this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreUnidad: '', MostrarModal: false, disable: false });
              valor1 = 1;
            } else {
              this.setState({ errorNombre: false });
              valor1 = 0;
            }
            if (this.state.Abreviacion.trim() == '') {
              this.setState({ errorAbreviacion: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", Abreviacion: '', MostrarModal: false, disable: false });
              valor2 = 1;
            } else {
              this.setState({ errorAbreviacion: false });
              valor2 = 0;
            }
            if (valor1 > 0 || valor2 > 0) {
              this.setState({ colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disable: false });
              this.showNotification("tr");
            } else {
              fetch(Globales.Url + 'UnidadMedida/UnidadMedida/RegistrarUnidadMedida', {
                method: 'POST',
                headers: {
                  Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
                , body: JSON.stringify(this.state)
              }
              ).then(res => res.json())
                .then(data => {
                  if (data.error) {
                    this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false, MostrarModal: false, disable: true });
                    this.showNotification();
                  } else {
                    this.setState({ colorNotificacion: 'success', NombreUnidad: '', Abreviacion: '', MostrarModal: false, disable: false, disable: false, errorNombre: false, errorAbreviacion: false });
                    this.closeModal(data.mensaje, this.state.colorNotificacion);
                  }
                })
                .catch(err => console.error(err));
  
            }

           
          }
        })
        .catch(err => console.error(err));
    }

    // var valor1 = 0;
    // var valor2 = 0;
    // //Validar que el input no este vacio
    // if (this.state.NombreUnidad.trim() == '' || this.state.errorNombre == true) {
    //   this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreUnidad: '', MostrarModal: false, disable: false });
    //   valor1 = 1;
    // } else {
    //   this.setState({ errorNombre: false });
    //   valor1 = 0;
    // }
    // if (this.state.Abreviacion.trim() == '') {
    //   this.setState({ errorAbreviacion: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", Abreviacion: '', MostrarModal: false, disable: false });
    //   valor2 = 1;
    // } else {
    //   this.setState({ errorAbreviacion: false });
    //   valor2 = 0;
    // }
    // //Validar si hay campos requeridos
    // if (valor1 > 0 || valor2 > 0 || this.state.disable == true) {
    //   this.setState({ colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disable: false });
    //   this.showNotification("tr");
    // } else {
    //   this.setState({ disable: true })
    //   if (this.props.op == 1) {

    //   } else {

    //   }
    // }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }
  validarNombre = event => {
    console.log(this.props)
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== event.target.value || this.props.op === 1) {
        fetch(Globales.Url + 'UnidadMedida/UnidadMedida/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreUnidad: event.target.value,_id:this.props._id })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
              this.showNotification(); 

            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombre: false, disable: false });
        this.registrarUnidadMedida();
      }
    } else {
      fetch(Globales.Url + 'UnidadMedida/UnidadMedida/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreUnidad: event.target.value })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
            this.showNotification();

          }
        })
        .catch(err => console.error(err));
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreUnidad') {
      //Validar si es una actualización

    }
  }
  ////
  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Unidad de Medida"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  onBlur: this.validarNombre,
                  value: this.state.NombreUnidad,

                  name: "NombreUnidad",
                  id: "NombreUnidad",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <CustomInput
                labelText="Abreviacion"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.Abreviacion,
                  name: "Abreviacion",
                  id: "Abreviacion",
                  type: "text",
                  error: this.state.errorAbreviacion
                }}
              />
              <GridItem>
                <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarUnidadMedida.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
            show={this.state.MostrarModal}
            Cuerpo="Cargando"
          />

        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}