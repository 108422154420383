import React, { Component } from 'react';
import { Modal } from 'reactstrap';

import "react-datepicker/dist/react-datepicker.css";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// @material-ui/core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Save, Clear } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Table from "components/Table/Table.jsx";
import Enumerable from 'linq'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';


class AgregarDescuentos extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      _id: '',
      NombreDescuento: '',
      Descripcion: '',
      Vigencia: '',
      TabSeleccionada: 0,
      Estatus:0,
      idArtistas: [],
      idClientes: [],
      MostrarModalDiseños: false,
      CantidadUnidades: 0,
      TipoDescuento: 0,
      ProductoAplicar: '',
      errorNombreDescuento: false,
      ClientesAgregados: false,
      errorDescripcion: false,
      errorVigencia: false,
      FiltroDisenoArtistaPorNombre:'',
      FiltroDisenoArtistaPorDiseno:'',
      FiltroDisenoArtistaPorProducto:'',
      idsUsuariosClientesSeleccionados: [],
      DisenosArtistas: [],
      DisenosArtistasFiltrado: [],
      idsDisenosSeleccionados: [],
      valueSelect: 0,
      idsUsuariosArtistasSeleccionados: [],
      erroridArtistas: false,
      erroridClientes: false,
      errorCantidadUnidades: false,
      errorTipoDescuento: false,
      ImporteDescuento: 0,
      PorcentajeDescuento: 0,
      FechaInicial: '2019-12-05',
      FechaInicial: '2019-12-05',
      errorProductoAplicar: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      SeleccionarTodosClientes:false,
      UsuariosClientes: [],
      UsuariosClientesFiltrado:[],
      UsuariosArtistas: [],
      UsuariosArtistasFiltrado:[],
      MostrarModalArtistas: false,
      ProductosTerco: [],
      idsDisenosArtistasSeleccionados:[],
      idsProductosTercoSeleccionados:[],
      FiltroPorNombreClientes:'',
      FiltroPorNombre:'',
      SeleccionarTodosDiseno:false,
      FiltroDisenoArtistaPorNombre:'',
      SeleccionarTodos:false,
      SeleccionarTodosDisenoTerco:false,
      FiltroDisenoArtistaPorNombreTerco:'',
      FiltroDisenoArtistaPorDisenoTerco:'',
      FiltroDisenoArtistaPorProductoTerco:'',
      ProductosTercoFiltrado:[],
      CantidadDisenosArtistas:5,
      CantidadDisenosArtistasTerco:5,
      CantidadUsuariosArtistas:5,
      CantidadUsuariosClientes:5
    }
    this.registrarDescuento = this.registrarDescuento.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.filtrarArtistas = this.filtrarArtistas.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getTableBodyFromDisenosArtistas = this.getTableBodyFromDisenosArtistas.bind(this);
    this.filtrarArtistas = this.filtrarArtistas.bind(this);
    this.obtenerUsuariosArtistas = this.obtenerUsuariosArtistas.bind(this)
    this.obtenerUsuariosClientes = this.obtenerUsuariosClientes.bind(this)
    this.GetTableBodyUsuariosClientes = this.GetTableBodyUsuariosClientes.bind(this)
    this.seleccionarTodos = this.seleccionarTodos.bind(this)
    this.GetTableBodyArtistasClientes = this.GetTableBodyArtistasClientes.bind(this)
    this.obtenerProductosTerco = this.obtenerProductosTerco.bind(this);
    this.obtenerDisenosArtistas = this.obtenerDisenosArtistas.bind(this);
    this.filtrarDisenosArtistasTerco = this.filtrarDisenosArtistasTerco.bind(this);
    this.filtrarDisenosArtistas = this.filtrarDisenosArtistas.bind(this);
    this.getTableBodyFromProductosTerco = this.getTableBodyFromProductosTerco.bind(this);
    

  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //this.props.eventClick('esc', 'esc');
    }
  }
  componentWillMount() {
    this.obtenerProductosTerco();
    this.obtenerUsuariosArtistas();
    this.obtenerUsuariosClientes();
    this.obtenerDisenosArtistas();
    this.setState({
      FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FiltroPorNombreClientes:''
    });
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      var idsUsuariosClientesSeleccionados = this.props.idsUsuariosClientesSeleccionados;
      var ArrayUsuariosClientesSeleccionados = [];
      var idsUsuariosArtistasSeleccionados = this.props.idsUsuariosArtistasSeleccionados;
      var idsProductosTercoSeleccionados = this.props.idsProductosTercoSeleccionados;
      var idsDisenosArtistasSeleccionados = this.props.idsDisenosArtistasSeleccionados;
      for(var keyUsuariosClientes = 0;keyUsuariosClientes< this.state.UsuariosClientes; keyUsuariosClientes++){
          if(this.state.UsuariosClientes.includes(this.props.idsUsuariosClientesSeleccionados[keyUsuariosClientes])){
            
          }
      }
      this.setState({
            NombreDescuento:this.props.NombreDescuento,
            Descripcion:this.props.Descripcion,
            FechaInicial:this.props.FechaInicial,
            FechaFinal:this.props.FechaFinal,
            CantidadUnidades:Number.parseInt(this.props.CantidadUnidades),
            idsUsuariosClientesSeleccionados:this.props.idsUsuariosClientesSeleccionados,
            idsUsuariosArtistasSeleccionados:this.props.idsUsuariosArtistasSeleccionados,
            idsProductosTercoSeleccionados:this.props.idsProductosTercoSeleccionados,
            idsDisenosArtistasSeleccionados:this.props.idsDisenosArtistasSeleccionados,
            valueSelect:this.props.TipoDescuento,
            ClientesAgregados:true,
            ImporteDescuento:this.props.ImporteDescuento,
            PorcentajeDescuento:this.props.PorcentajeDescuento,
            Estatus:this.props.Estatus
      });
    }
  }
  obtenerUsuariosClientes() {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerUsuariosPorTipo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ TipoUsuario: "Cliente" })
    }
    ).then(res => res.json())
      .then(data => {
        this.setState({
          UsuariosClientes: data.data
        })
      })
  }

  obtenerUsuariosArtistas() {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerUsuariosPorTipo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ TipoUsuario: "Artista" })
    }
    ).then(res => res.json())
      .then(data => {
        this.setState({
          UsuariosArtistas: data.data
        })
      })
  }

  obtenerDisenosArtistas() {
    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/ObtenerNombresDeDisenos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
       
        this.setState({
          DisenosArtistas: data.data
        });
      });
  }
  
  obtenerProductosTerco() {
    fetch(Globales.Url + 'DisenoProductos/DisenoProductos/ObtenerNombresDeDisenosTerco', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        this.setState({
          ProductosTerco: data.data,
         
        })
      })
  }

  

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  filtrarArtistas(event){
    let regex = new RegExp(event.target.value, 'gi');
    let aux = Enumerable.from(this.state.UsuariosArtistas).where(function (x) { return x.Nombre.match(regex) }).toArray();
    this.setState({
      UsuariosArtistasFiltrado:aux,
      FiltroPorNombre:event.target.value
    });
  }

  filtrarClientes(event){
    let regex = new RegExp(event.target.value, 'gi');
    let aux = Enumerable.from(this.state.UsuariosClientes).where(function (x) { return x.Nombre.match(regex) }).toArray();
    this.setState({
      UsuariosClientesFiltrado:aux,
      FiltroPorNombreClientes:event.target.value
    })
  }

  filtrarDisenosArtistas(event){
    let regex = new RegExp(event.target.value, 'gi');
    this.setState({
      [event.target.name]:event.target.value
    },()=>{
        let StringNombreArtista = this.state.FiltroDisenoArtistaPorNombre;
        let StringNombreDiseno =this.state.FiltroDisenoArtistaPorDiseno;
        let StringNombreProducto = this.state.FiltroDisenoArtistaPorProducto;

        let NombreArtistatFiltro = new RegExp(this.state.FiltroDisenoArtistaPorNombre, 'gi');
        let NombreDisenoFiltro = new RegExp(this.state.FiltroDisenoArtistaPorDiseno, 'gi');
        let NombreProductoFiltro =new RegExp( this.state.FiltroDisenoArtistaPorProducto,'gi');
        var funcion = function (x){
          if(StringNombreArtista != ""){
              if(StringNombreDiseno != ""){
                if(StringNombreProducto != ""){
                  return (
                    (x.idCliente.Nombre.match(NombreArtistatFiltro) && x.nombre.match(NombreDisenoFiltro)  && x.idModelo.NombreProducto.match(NombreProductoFiltro)
                    )
                  );
                }else{
                  return (
                    (x.idCliente.Nombre.match(NombreArtistatFiltro) && x.nombre.match(NombreDisenoFiltro) 
                    )
                  );
                }
              }else if(StringNombreProducto != ""){
                return(x.idCliente.Nombre.match(NombreArtistatFiltro)  && x.idModelo.NombreProducto.match(NombreProductoFiltro)
                );
              }else{
                return(x.idCliente.Nombre.match(NombreArtistatFiltro) 
                );
              }
          }else if(StringNombreDiseno != ""){
            if(StringNombreProducto != ""){
              return (
                (
                   x.nombre.match(NombreDisenoFiltro)   && x.idModelo.NombreProducto.match(NombreProductoFiltro)
                )
              );
            }else{
              return (
                (
                  x.nombre.match(NombreDisenoFiltro) 
                )
              );
            }
          }else{
            return (
              (
                x.idModelo.NombreProducto.match(NombreProductoFiltro) 
              )
            );
          }
      } 
      console.log(funcion)
      let aux = Enumerable.from((this.state.DisenosArtistasFiltrado.length == 0? this.state.DisenosArtistas:this.state.DisenosArtistas)).where(funcion).toArray();
      this.setState({
        DisenosArtistasFiltrado:aux,
      });
    });
  } 

  filtrarDisenosArtistasTerco(event){
    let regex = new RegExp(event.target.value, 'gi');
    this.setState({
      [event.target.name]:event.target.value
    },()=>{
      console.log(this.state)
        let StringNombreArtista = this.state.FiltroDisenoArtistaPorNombreTerco;
        let StringNombreDiseno =this.state.FiltroDisenoArtistaPorDisenoTerco;
        let StringNombreProducto = this.state.FiltroDisenoArtistaPorProductoTerco;

        let NombreArtistatFiltro = new RegExp(this.state.FiltroDisenoArtistaPorNombreTerco, 'gi');
        let NombreDisenoFiltro = new RegExp(this.state.FiltroDisenoArtistaPorDisenoTerco, 'gi');
        let NombreProductoFiltro =new RegExp( this.state.FiltroDisenoArtistaPorProductoTerco,'gi');
        var funcion = function (x){
          if(StringNombreArtista != ""){
              if(StringNombreDiseno != ""){
                if(StringNombreProducto != ""){
                  return (
                    (x.idCliente.Nombre.match(NombreArtistatFiltro) && x.nombre.match(NombreDisenoFiltro)  && x.idModelo.NombreProducto.match(NombreProductoFiltro)
                    )
                  );
                }else{
                  return (
                    (x.idCliente.Nombre.match(NombreArtistatFiltro) && x.nombre.match(NombreDisenoFiltro) 
                    )
                  );
                }
              }else if(StringNombreProducto != ""){
                return(x.idCliente.Nombre.match(NombreArtistatFiltro)  && x.idModelo.NombreProducto.match(NombreProductoFiltro)
                );
              }else{
                return(x.idCliente.Nombre.match(NombreArtistatFiltro) 
                );
              }
          }else if(StringNombreDiseno != ""){
            if(StringNombreProducto != ""){
              return (
                (
                   x.nombre.match(NombreDisenoFiltro)  && x.idModelo.NombreProducto.match(NombreProductoFiltro)
                )
              );
            }else{
              return (
                (
                  x.nombre.match(NombreDisenoFiltro) 
                )
              );
            }
          }else{

            return (
              (                
                x.idModelo.NombreProducto.match(NombreProductoFiltro)
              )
            );
          }
      } 
      let aux = Enumerable.from((this.state.ProductosTerco.length == 0? this.state.ProductosTerco:this.state.ProductosTerco)).where(funcion).toArray();
      this.setState({
        ProductosTercoFiltrado:aux,
      });
    });
  } 

  registrarDescuento() {

    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    var valor6 = 0;
    var valor7 = 0;
    var valor8 = 0;
    var valor9 = 0; //Fecha Inicial
    var valor10 = 0; //Fecha Final
    const formatoFecha = /^\d{4}-\d{2}-\d{2}$/;

    if (this.state.NombreDescuento.trim() === '') {
      this.setState({ errorNombreDescuento: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido" });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombreDescuento: false });
      valor1 = 0;
    }
    if (this.state.Descripcion.trim() === '') {
      this.setState({ errorDescripcion: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido' });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ errorDescripcion: false });
      valor2 = 0;
    } 
    if (this.state.CantidadUnidades===0) {
      this.setState({ errorCantidadUnidades: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido' });
      this.showNotification();
      valor6 = 1;
    } else {
      this.setState({ errorCantidadUnidades: false });
      valor6 = 0;
    }
    if(this.state.ImporteDescuento === 0 && this.state.valueSelect == 1){
      this.setState({ errorImporteDescuento: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido' });
      this.showNotification();
      valor3 = 1;
    }else{
      this.setState({
        errorImporteDescuento:false
      });
      valor3 = 0;
    }
    if(this.state.PorcentajeDescuento === 0){
      this.setState({ errorPorcentajeDescuento: true, colorNotificacion: 'danger', mensaje: 'Complete el campo requerido' });
      this.showNotification();
      valor7 = 1;
    }else{
      this.setState({
        errorImporteDescuento:false
      });
      valor7 = 0;
    }
    if(this.state.idsUsuariosClientesSeleccionados.length == 0 && this.state.idsUsuariosArtistasSeleccionados.length == 0){
      this.setState({ colorNotificacion: 'danger', mensaje: 'Debes registrar al menos un cliente' });
      this.showNotification();
      valor4 = 1;
    }else{
      valor4 = 0;
    }
    if(this.state.idsDisenosArtistasSeleccionados.length == 0 && this.state.idsProductosTercoSeleccionados.length == 0){
      this.setState({ colorNotificacion: 'danger', mensaje: 'Debes seleccionar al menos un diseño/producto' });
      this.showNotification();
      valor5 = 1;
    }else{
      valor5 = 0;
    }

    if (formatoFecha.test(this.state.FechaInicial)) {
      this.setState({
        errorFechaInicial:false
      });
      valor9 = 0;
    }else{
      this.setState({ errorFechaInicial: true, colorNotificacion: 'danger', mensaje: 'La fecha inicial no tiene el formato correcto' });
      this.showNotification();
      valor9 = 1;
    }
    if (this.state.FechaInicial < new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })) {
      this.setState({ errorFechaInicial: true, colorNotificacion: 'danger', mensaje: 'La fecha inicial es menor a la fecha actual' });
      this.showNotification();
      valor9 = 1;
    }else{
      this.setState({
        errorFechaInicial:false
      });
      valor9 = 0;
    }

    if (formatoFecha.test(this.state.FechaFinal)) {
      this.setState({
        errorFechaFinal:false
      });
      valor10 = 0;
    }else{
      this.setState({ errorFechaFinal: true, colorNotificacion: 'danger', mensaje: 'La fecha final no tiene el formato correcto' });
      this.showNotification();
      valor10 = 1;
    }
    if (this.state.FechaFinal < this.state.FechaInicial) {
      this.setState({ errorFechaFinal: true, colorNotificacion: 'danger', mensaje: 'La fecha final es menor que la fecha inicial' });
      this.showNotification();
      valor10 = 1;
    }else{
      this.setState({
        errorFechaFinal:false
      });
      valor10 = 0;
    }

    //Validar si hay campos requeridos
    if (valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 + valor8 + valor9 + valor10 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Descuentos/Descuentos/ActualizarDescuentos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({
            NombreDescuento:this.state.NombreDescuento,
            Descripcion:this.state.Descripcion,
            FechaInicial:this.state.FechaInicial,
            FechaFinal:this.state.FechaFinal,
            _id:this.props._id,
            CantidadUnidades:Number.parseInt(this.state.CantidadUnidades),
            idsUsuariosClientesSeleccionados:this.state.idsUsuariosClientesSeleccionados,
            idsUsuariosArtistasSeleccionados:this.state.idsUsuariosArtistasSeleccionados,
            idsProductosTercoSeleccionados:this.state.idsProductosTercoSeleccionados,
            idsDisenosArtistasSeleccionados:this.state.idsDisenosArtistasSeleccionados,
            TipoDescuento:this.state.valueSelect,
            ImporteDescuento:this.state.ImporteDescuento,
            PorcentajeDescuento:this.state.PorcentajeDescuento
          })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              if (data.tipo) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreDescuento: true });
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
                this.showNotification();
              }
            } else {
              this.setState({ colorNotificacion: 'success', NombreDescuento: '', Descripcion: '', Vigencia: '', idArtistas: '', idClientes: '', CantidadUnidades: '', TipoDescuento: '', ProductoAplicar: '', errorNombreDescuento: false, errorDescripcion: false, errorVigencia: false, erroridArtistas: false, erroridClientes: false, errorCantidadUnidades: false, errorTipoDescuento: false, errorProductoAplicar: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {  
        fetch(Globales.Url + 'Descuentos/Descuentos/RegistrarDescuentos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({
            NombreDescuento:this.state.NombreDescuento,
            Descripcion:this.state.Descripcion,
            FechaInicial:this.state.FechaInicial,
            FechaFinal:this.state.FechaFinal,
            CantidadUnidades:Number.parseInt(this.state.CantidadUnidades),
            idsUsuariosClientesSeleccionados:this.state.idsUsuariosClientesSeleccionados,
            idsUsuariosArtistasSeleccionados:this.state.idsUsuariosArtistasSeleccionados,
            idsProductosTercoSeleccionados:this.state.idsProductosTercoSeleccionados,
            idsDisenosArtistasSeleccionados:this.state.idsDisenosArtistasSeleccionados,
            TipoDescuento:this.state.valueSelect,
            ImporteDescuento:this.state.ImporteDescuento,
            PorcentajeDescuento:this.state.PorcentajeDescuento
          })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreDescuento: true });
                this.showNotification();
              
            } else {
              this.setState({ colorNotificacion: 'success', NombreDescuento: '', Descripcion: '', Vigencia: '', idArtistas: [], idClientes: [], CantidadUnidades: '', TipoDescuento: '', ProductoAplicar: '', errorNombreDescuento: false, errorDescripcion: false, errorVigencia: false, erroridArtistas: false, erroridClientes: false, errorCantidadUnidades: false, errorTipoDescuento: false, errorProductoAplicar: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  seleccionarTodos(origen,event){
    if(origen =="artistas"){
      if(this.state.SeleccionarTodos == false){
        var idsSeleccion = [];
        if(this.state.UsuariosArtistasFiltrado.length >0){
          this.state.UsuariosArtistasFiltrado.map((artista)=>{
            idsSeleccion.push(artista._id)
          })
        }else{
          this.state.UsuariosArtistas.map((artista)=>{
            idsSeleccion.push(artista._id)
          });
        }
        this.setState({
          idsUsuariosArtistasSeleccionados:idsSeleccion,
          SeleccionarTodos:true
        })
      }else{
        this.setState({
          idsUsuariosArtistasSeleccionados:[],
          SeleccionarTodos:false
        })
      }
    }else if (origen == "clientes"){
      if(this.state.SeleccionarTodosClientes == false){
        var idsSeleccion = [];
        if(this.state.UsuariosClientesFiltrado.length >0){
          this.state.UsuariosClientesFiltrado.map((artista)=>{
            idsSeleccion.push(artista._id)
          })
        }else{
          this.state.UsuariosClientes.map((artista)=>{
            idsSeleccion.push(artista._id)
          });
        }
        this.setState({
          idsUsuariosClientesSeleccionados:idsSeleccion,
          SeleccionarTodosClientes:true
        })
      }else{
        this.setState({
          idsUsuariosClientesSeleccionados:[],
          SeleccionarTodosClientes:false
        })
      }
    }else if(origen == "disenoartistas"){
      if(this.state.SeleccionarTodosDiseno == false){
        var idsSeleccion = [];
        if(this.state.DisenosArtistasFiltrado.length >0){
          this.state.DisenosArtistasFiltrado.map((artista)=>{
            idsSeleccion.push(artista._id)
          })
        }else{
          this.state.DisenosArtistas.map((artista)=>{
            idsSeleccion.push(artista._id)
          });
        }
        this.setState({
          idsDisenosArtistasSeleccionados:idsSeleccion,
          SeleccionarTodosDiseno:true
        })
      }else{
        this.setState({
          idsDisenosArtistasSeleccionados:[],
          SeleccionarTodosDiseno:false
        })
      }
    }else if (origen =="disenosterco"){
      if(this.state.SeleccionarTodosDisenoTerco == false){
        var idsSeleccion = [];
        if(this.state.ProductosTercoFiltrado.length >0){
          this.state.ProductosTercoFiltrado.map((artista)=>{
            idsSeleccion.push(artista._id)
          })
        }else{
          this.state.ProductosTerco.map((artista)=>{
            idsSeleccion.push(artista._id)
          });
        }
        this.setState({
          idsProductosTercoSeleccionados:idsSeleccion,
          SeleccionarTodosDisenoTerco:true
        })
      }else{
        this.setState({
          idsDisenosArtistasSeleccionados:[],
          SeleccionarTodosDisenoTerco:false
        })
      }
    }
   
  }

  handleChangeRadio(idCliente, event) {
    if (event.target.name == "clientes") {
      var arrayStateIdsPorActualzar = this.state.idsUsuariosClientesSeleccionados;
      if (this.state.idsUsuariosClientesSeleccionados.includes(idCliente) == true) {
        document.getElementById(idCliente).setAttribute('checked', false);
        var arrayNuevo = []
        for (var keyArray = 0; keyArray < arrayStateIdsPorActualzar.length; keyArray++) {
          if (idCliente == arrayStateIdsPorActualzar[keyArray]) {
          } else {
            arrayNuevo.push(arrayStateIdsPorActualzar[keyArray]);
          }
        }
        this.setState({
          idsUsuariosClientesSeleccionados: arrayNuevo
        })
      } else {
        arrayStateIdsPorActualzar.push(idCliente)
        this.setState({
          idsUsuariosClientesSeleccionados: arrayStateIdsPorActualzar
        })
      }
    } else if (event.target.name == "artistas") {
      var arrayStateIdsPorActualzar = this.state.idsUsuariosArtistasSeleccionados;

      if (this.state.idsUsuariosArtistasSeleccionados.includes(idCliente) == true) {
        document.getElementById(idCliente).setAttribute('checked', false);
        var arrayNuevo = []
        for (var keyArray = 0; keyArray < arrayStateIdsPorActualzar.length; keyArray++) {
          if (idCliente == arrayStateIdsPorActualzar[keyArray]) {
          } else {
            arrayNuevo.push(arrayStateIdsPorActualzar[keyArray]);
          }
        }
        this.setState({
          idsUsuariosArtistasSeleccionados: arrayNuevo
        })
      } else {
        arrayStateIdsPorActualzar.push(idCliente)
        this.setState({
          idsUsuariosArtistasSeleccionados: arrayStateIdsPorActualzar
        });
      }
    } else if(event.target.name =="disenosartistas"){
      var arrayStateIdsPorActualzar = this.state.idsDisenosArtistasSeleccionados;
      if (this.state.idsDisenosArtistasSeleccionados.includes(idCliente) == true) {
        document.getElementById(idCliente).setAttribute('checked', false);
        var arrayNuevo = []
        for (var keyArray = 0; keyArray < arrayStateIdsPorActualzar.length; keyArray++) {
          if (idCliente == arrayStateIdsPorActualzar[keyArray]) {
          } else {
            arrayNuevo.push(arrayStateIdsPorActualzar[keyArray]);
          }
        }
        this.setState({
          idsDisenosArtistasSeleccionados: arrayNuevo
        })
      } else {
        arrayStateIdsPorActualzar.push(idCliente)
        this.setState({
          idsDisenosArtistasSeleccionados: arrayStateIdsPorActualzar
        });
      }
    }else{
      var arrayStateIdsPorActualzar = this.state.idsProductosTercoSeleccionados;
      if (this.state.idsProductosTercoSeleccionados.includes(idCliente) == true) {
        document.getElementById(idCliente).setAttribute('checked', false);
        var arrayNuevo = []
        for (var keyArray = 0; keyArray < arrayStateIdsPorActualzar.length; keyArray++) {
          if (idCliente == arrayStateIdsPorActualzar[keyArray]) {
          } else {
            arrayNuevo.push(arrayStateIdsPorActualzar[keyArray]);
          }
        }
        this.setState({
          idsProductosTercoSeleccionados: arrayNuevo
        })
      } else {
        arrayStateIdsPorActualzar.push(idCliente)
        this.setState({
          idsProductosTercoSeleccionados: arrayStateIdsPorActualzar
        });
      }
    }
  }

  GetTableBodyUsuariosClientes(classes) {
    var response = [];
    if(this.state.UsuariosClientesFiltrado.length > 0){
      this.state.UsuariosClientesFiltrado.map((usuarioCliente,key) => {
        if(key <= this.state.CantidadUsuariosClientes){
          response.push(
            [
              <Checkbox
                checked={(this.state.idsUsuariosClientesSeleccionados.includes(usuarioCliente._id) ? true : false)}
                tabIndex={-1}
                id={usuarioCliente._id}
                onClick={this.handleChangeRadio.bind(this, usuarioCliente._id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                name="clientes"
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />,
              "",
              usuarioCliente.Nombre,
            ]
          )
        }
        
      });
    }else if(this.state.FiltroPorNombreClientes ==""){
      this.state.UsuariosClientes.map((usuarioCliente,key) => {
        if(key <= this.state.CantidadUsuariosClientes){
          response.push(
            [
              <Checkbox
                checked={(this.state.idsUsuariosClientesSeleccionados.includes(usuarioCliente._id) ? true : false)}
                tabIndex={-1}
                id={usuarioCliente._id}
                onClick={this.handleChangeRadio.bind(this, usuarioCliente._id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                name="clientes"
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />,
              "",
              usuarioCliente.Nombre,
            ]
          )
        }
        
      });
    }
    response[response.length] = [
      "",
      "",
      <div style={{textAlign:"left",alignContent:"left"}}> 
      <Button onClick={()=>{
        this.setState({
          CantidadUsuariosClientes:(this.state.CantidadUsuariosClientes+5)
        },()=>{
          if((this.state.UsuariosClientesFiltrado.length > 0 && this.state.CantidadUsuariosClientes > this.state.UsuariosClientesFiltrado.length) || this.state.UsuariosClientes.length <this.state.CantidadUsuariosClientes){
            this.setState({
              colorNotificacion:"danger",
              mensaje:"No hay más registros"
            },()=>{this.showNotification();})
          }
        })
      }}> Ver más</Button>
      </div>,
      ""
    ];
    return response;
  }

  GetTableBodyArtistasClientes(classes) {
    var response = [];
    if(this.state.UsuariosArtistasFiltrado.length > 0 ){
      this.state.UsuariosArtistasFiltrado.map((usuarioArtista,key) => {
        if(key <= this.state.CantidadUsuariosArtistas){
          response.push(
            [
              <Checkbox
                checked={(this.state.idsUsuariosArtistasSeleccionados.includes(usuarioArtista._id) ? true : false)}
                tabIndex={-1}
                id={usuarioArtista._id}
                onClick={this.handleChangeRadio.bind(this, usuarioArtista._id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                name="artistas"
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />,
              "",
              usuarioArtista.Nombre,
            ]
          )
        }
        
      });
    }else if(this.state.FiltroPorNombre == '' || this.state.FiltroPorNombreClientes){
      this.state.UsuariosArtistas.map((usuarioArtista,key) => {
        if(key<= this.state.CantidadUsuariosArtistas){
          response.push(
            [
              <Checkbox
                checked={(this.state.idsUsuariosArtistasSeleccionados.includes(usuarioArtista._id) ? true : false)}
                tabIndex={-1}
                id={usuarioArtista._id}
                onClick={this.handleChangeRadio.bind(this, usuarioArtista._id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                name="artistas"
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />,
              "",
              usuarioArtista.Nombre,
            ]
          )
        }
        
      });
    }
    response[response.length] = [
      "",
      "",
      <div style={{textAlign:"left",alignContent:"left"}}> 
      <Button onClick={()=>{
        this.setState({
          CantidadUsuariosArtistas:(this.state.CantidadUsuariosArtistas+5)
        },()=>{
          if((this.state.UsuariosArtistasFiltrado.length > 0 && this.state.CantidadUsuariosArtistas > this.state.UsuariosArtistasFiltrado.length) || this.state.UsuariosArtistas.length <this.state.CantidadUsuariosArtistas){
            this.setState({
              colorNotificacion:"danger",
              mensaje:"No hay más registros"
            },()=>{this.showNotification();})
          }
        })
      }}> Ver más</Button>
      </div>,
      ""
    ]
    return response;
  }

  getTableBodyFromDisenosArtistas(classes) {
    var response = [];
    if(this.state.DisenosArtistasFiltrado.length > 0){
      this.state.DisenosArtistasFiltrado.map(disenoArtista => {
        console.log(disenoArtista)
        response.push(
          [
            <Checkbox
              checked={(this.state.idsDisenosArtistasSeleccionados.includes(disenoArtista._id) ? true : false)}
              tabIndex={-1}
              id={disenoArtista._id}
              disabled={(this.props.op==1?false:disenoArtista.Descuento)}
              onClick={this.handleChangeRadio.bind(this, disenoArtista._id)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              name="disenosartistas"
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />,
            "",
            (disenoArtista.Descuento== true?<label> {disenoArtista.nombre} : Diseño con descuento vigente</label>:disenoArtista.nombre),
            (disenoArtista.imagen != null ? 
              <img style={{ textAlign: "center" }} width="150px" src={`data:image/png;base64,${disenoArtista.imagen}`}></img> :
              <Button size="sm" className="pull-right mt-1" color="info" onClick={() => { this.buscarImagen(disenoArtista,1) }}>
                Ver Imagen
              </Button>
            )
          ]
        )
      });
    }else if ( this.state.ProductosTercoFiltrado.length == 0 && this.state.FiltroDisenoArtistaPorNombre =="" && this.state.FiltroDisenoArtistaPorDiseno == "" && this.state.FiltroDisenoArtistaPorProducto == ""){
      this.state.DisenosArtistas.map((disenoArtista,key) => {        
        if(this.state.CantidadDisenosArtistas -1 < key){
        }else{
          response.push(
            [
              <Checkbox
                checked={(this.state.idsDisenosArtistasSeleccionados.includes(disenoArtista._id) ? true : false)}
                tabIndex={-1}
                id={disenoArtista._id}
                disabled={(this.props.op==1?false:disenoArtista.Descuento)}
                onClick={this.handleChangeRadio.bind(this, disenoArtista._id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                name="disenosartistas"
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />,
              "",
              (disenoArtista.Descuento== true?<label> {disenoArtista.nombre} : Diseño con descuento vigente</label>:disenoArtista.nombre),
              //<img style={{ textAlign: "center" }} width="250px" src={Globales.Url + "Productos/" + disenoArtista.RutaImagen}></img>
              (disenoArtista.imagen != null ? 
                <img style={{ textAlign: "center" }} width="150px" src={`data:image/png;base64,${disenoArtista.imagen}`}></img> :
                <Button size="sm" className="pull-right mt-1" color="info" onClick={() => { this.buscarImagen(disenoArtista,0) }}>
                  Ver Imagen
                </Button>
              )
            ]
          )
        }
        
      });
    }else{
      response.push(
        [ "",
          "",
          <div style={{textAlign:"center"}}>No hay productos</div>,
          "",
        ]
      )
    }
    response[response.length] = [
      "",
      "",
      <div style={{textAlign:"right",alignContent:"right"}}> 
      <Button onClick={()=>{
        this.setState({
          CantidadDisenosArtistas:(this.state.CantidadDisenosArtistas+5)
        },()=>{
          if((this.state.DisenosArtistasFiltrado.length > 0 && this.state.CantidadDisenosArtistas > this.state.DisenosArtistasFiltrado.length) || this.state.DisenosArtistas.length <this.state.CantidadDisenosArtistas){
            this.setState({
              colorNotificacion:"danger",
              mensaje:"No hay más registros"
            },()=>{this.showNotification();})
          }
        })
      }}> Ver más</Button>
      </div>,
      ""
    ]
    return response;
  }

  getTableBodyFromProductosTerco(classes) {
    var response = [];
    if(this.state.ProductosTercoFiltrado.length > 0){
      this.state.ProductosTercoFiltrado.map(productoTerco => {
        response.push(
          [
            <Checkbox
              checked={(this.state.idsProductosTercoSeleccionados.includes(productoTerco._id) ? true : false)}
              tabIndex={-1}
              disabled={(this.props.op == 1 && this.state.idsProductosTercoSeleccionados.includes(productoTerco._id) == true?false:productoTerco.Descuento) }
              id={productoTerco._id}
              onClick={this.handleChangeRadio.bind(this, productoTerco._id)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              name="productosterco"
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />,
            "",
            (productoTerco.Descuento == true?<label> {productoTerco.nombre} : Producto con descuento vigente</label>:productoTerco.nombre ),
            <img style={{ textAlign: "center" }} width="100px" src={Globales.Url+"Productos/" + productoTerco.RutaImagen}></img>
          ]
        )
      });
    }else{
      if(this.state.ProductosTercoFiltrado.length == 0 && (this.state.FiltroDisenoArtistaPorDisenoTerco || this.state.FiltroDisenoArtistaPorProductoTerco || this.state.FiltroDisenoArtistaPorProductoTerco) != "" || this.state.ProductosTerco.length == 0){
        response.push(
          [ "",
            "",
            <div style={{textAlign:"center"}}>No hay productos</div>,
            "",
          ]
        )
      }else{
        this.state.ProductosTerco.map(productoTerco => {
          response.push(
            [
              <Checkbox
                checked={(this.state.idsProductosTercoSeleccionados.includes(productoTerco._id) ? true : false)}
                tabIndex={-1}
                disabled={(this.props.op == 1 && this.state.idsProductosTercoSeleccionados.includes(productoTerco._id) == true?false:productoTerco.Descuento) }
                id={productoTerco._id}
                onClick={this.handleChangeRadio.bind(this, productoTerco._id)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                name="productosterco"
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />,
              "",
              (productoTerco.Descuento == true?<label> {productoTerco.nombre} : Producto con descuento vigente</label>:productoTerco.nombre ),
              <img style={{ textAlign: "center" }} width="100px" src={Globales.Url+"Productos/" + productoTerco.RutaImagen}></img>
            ]
          )
        });
      }
    }
    // if(this.state.ProductosTerco.length == 0){
    //   response.push(
    //     [ "",
    //       "",
    //       <div style={{textAlign:"center"}}>No hay productos</div>,
    //       "",
    //     ]
    //   )
    // }else{
    //   this.state.ProductosTerco.map(productoTerco => {
    //     response.push(
    //       [
    //         <Checkbox
    //           checked={(this.state.idsProductosTercoSeleccionados.includes(productoTerco._id) ? true : false)}
    //           tabIndex={-1}
    //           disabled={(this.props.op == 1 && this.state.idsProductosTercoSeleccionados.includes(productoTerco._id) == true?false:productoTerco.Descuento) }
    //           id={productoTerco._id}
    //           onClick={this.handleChangeRadio.bind(this, productoTerco._id)}
    //           checkedIcon={<Check className={classes.checkedIcon} />}
    //           name="productosterco"
    //           icon={<Check className={classes.uncheckedIcon} />}
    //           classes={{
    //             checked: classes.checked,
    //             root: classes.checkRoot
    //           }}
    //         />,
    //         "",
    //         (productoTerco.Descuento == true?<label> {productoTerco.nombre} : Producto con descuento vigente</label>:productoTerco.nombre ),
    //         <img style={{ textAlign: "center" }} width="100px" src={Globales.Url + productoTerco.RutaImagen}></img>
    //       ]
    //     )
    //   });
    // }
    response[response.length] = [
      "",
      "",
      <div style={{textAlign:"right",alignContent:"right"}}> 
      <Button onClick={()=>{
        this.setState({
          CantidadDisenosArtistasTerco:(this.state.CantidadDisenosArtistasTerco+5)
        },()=>{
          if((this.state.ProductosTercoFiltrado.length > 0 && this.state.CantidadDisenosArtistasTerco > this.state.ProductosTercoFiltrado.length) || this.state.ProductosTerco.length <this.state.CantidadDisenosArtistasTerco){
            this.setState({
              colorNotificacion:"danger",
              mensaje:"No hay más registros"
            },()=>{
              this.showNotification();
            })
          }
        })
      }}> Ver más</Button>
      </div>,
      ""
    ]
    return response;
  }

  handleChange(e) {
    const { name, value } = e.target;
   if (name == "PorcentajeDescuento") {
      if (value < 100) {
        this.setState({
          [name]: value
        });
      }
    } /*else if (name == "FechaFinal") {
      if (value < this.state.FechaInicial) {
      } else {
        this.setState({
          [name]: value
        });
      }
    } else if (name == "FechaInicial") {
      if (value < new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })) {
      } else {
        if (value > this.state.FechaInicial) {
          this.setState({
            [name]: value,
            FechaFinal: value
          });
        } else {
          this.setState({
            [name]: value
          });
        }
      }
    } */else {
      this.setState({
        [name]: value
      });
    }

  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  buscarImagen(disenoArtista,op) {
    fetch(Globales.Url + 'Compras/ObtenerImagenCompra', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
      body: JSON.stringify({ id: disenoArtista._id, idCliente: disenoArtista.idCliente._id })
  })
      .then(res => res.json())
      .then(data => {
          if (data.error) {

          } else {
            if(op ==1){
              var aux = this.state.DisenosArtistasFiltrado;
              aux.map(disenos => {
                  if (disenos._id === disenoArtista._id && disenos.idCliente._id === disenoArtista.idCliente._id){ 
                    disenos.imagen = data.data;
                  }
              })
              this.setState({
                DisenosArtistasFiltrado: aux
              });
            }else{
              var aux = this.state.DisenosArtistas;
              aux.map(disenos => {
                  if (disenos._id === disenoArtista._id && disenos.idCliente._id === disenoArtista.idCliente._id){ 
                    disenos.imagen = data.data;
                  }
              })
              this.setState({
                DisenosArtistas: aux
              });
            }
          }
      });
  }

  render() {
    var classes = this.props
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Nombre Descuento"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.NombreDescuento,
                      name: "NombreDescuento",
                      id: "NombreDescuento",
                      type: "text",
                      error: this.state.errorNombreDescuento
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Descripción"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Descripcion,
                      name: "Descripcion",
                      id: "Descripcion",
                      type: "text",
                      error: this.state.errorDescripcion
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="FechaInicial"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.FechaInicial,
                      name: "FechaInicial",
                      id: "FechaInicial",
                      type: "date",
                      error: this.state.errorFechaInicial,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Fecha final"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.FechaFinal,
                      name: "FechaFinal",
                      id: "FechaFinal",
                      type: "date",
                      error: this.state.errorFechaFinal,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => this.setState({ MostrarModalArtistas: true })}>Seleccionar artistas</Button>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => this.setState({ MostrarModalClientes: true }, () => {  })}>Seleccionar clientes</Button>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                &nbsp;
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText="Cantidad de Unidades"
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      required: true,
                      value: this.state.CantidadUnidades,
                      onChange: this.handleChange.bind(this),
                      name: "CantidadUnidades",
                      id: "CantidadUnidades",
                      type: "number",
                      error: this.state.errorCantidadUnidades
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  &nbsp;
                <InputLabel>Opción</InputLabel>
                  <Select
                    formControlProps={{
                      fullWidth: true
                    }}
                    onClick={this.handleChange.bind(this)}
                    value={this.state.valueSelect}
                    name="valueSelect"
                    onChange={() => {
                      this.setState({
                        ImporteDescuento: 0,
                        PorcentajeDescuento: 0,
                      })
                    }}
                    style={{ width: "360px" }}
                    id="selectOpcion">
                    <MenuItem value={0}>Porcentaje</MenuItem>
                    <MenuItem value={1}>Importe</MenuItem>
                  </Select>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div hidden={(this.state.valueSelect == 1 ? true : false)}>
                    <CustomInput
                      labelText="Porcentaje"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange.bind(this),
                        value: this.state.PorcentajeDescuento,
                        name: "PorcentajeDescuento",
                        id: "PorcentajeDescuento",
                        max: 100,
                        type: "number",
                        error: this.state.errorPorcentajeDescuento
                      }}
                    />
                  </div>
                  <div hidden={(this.state.valueSelect == 1 ? false : true)}>
                    <CustomInput
                      labelText="Importe"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange.bind(this),
                        value: this.state.ImporteDescuento,
                        name: "ImporteDescuento",
                        id: "ImporteDescuento",
                        type: "number",
                        error: this.state.errorImporteDescuento
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div style={{ textAlign: "center" }}>
                    <Button onClick={() => this.setState({ MostrarModalDiseños: true })}>Seleccionar diseños</Button>
                  </div>
                </GridItem>
              </GridContainer>
              <GridItem>
                <Button tabIndex="0"  disabled={this.props.op == 1 && this.state.Estatus == 2?true:false} size="sm" color="info" className="pull-right" round onClick={this.registrarDescuento.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Modal
          isOpen={this.state.MostrarModalClientes}
        >
          <Card>
            <CardHeader color="info">
              Usuarios Clientes
            </CardHeader>
            <CardBody>
            <CustomInput
                      labelText="Filtrar por nombre"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarClientes.bind(this),
                        value: this.state.FiltroPorNombreClientes,
                        name: "FiltroPorNombreClientes",
                        id: "FiltroPorNombreClientes",
                        type: "text",
                        error: this.state.errorFiltroPorNombreClientes
                      }}
                    />
                    <FormControlLabel
                        control={
                          <Radio
                            checked={this.state.SeleccionarTodosClientes}
                            onClick={this.seleccionarTodos.bind(this,"clientes")}
                            value={"true"}
                            name="Retraso"
                            color="primary"
                            aria-label='A'
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord className={classes.radioChecked} />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label='Seleccionar todos'
                      />
              <div className="table-wrapper-scroll-y">
                <Table
                  striped
                  tableHead={["#", "", "Usuario",]}

                  tableData={
                    this.GetTableBodyUsuariosClientes(classes)}
                  customCellClasses={[
                    classes.textCenter,
                    classes.padding0,
                    classes.textRight,
                    classes.textRight
                  ]}
                  customClassesForCells={[0, 1, 5, 6]}
                  customHeadCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                  ]}
                  customHeadClassesForCells={[0, 5, 6]}
                />
              </div>

              &nbsp;
              &nbsp;
              &nbsp;
    <GridItem>
                <Button tabIndex="0" size="sm" color="info" className="pull-right" round onClick={() => { this.setState({ ClientesAgregados: true, MostrarModalClientes: false }) }}><Save />&nbsp;Aceptar</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.setState({ MostrarModalClientes: false, idsUsuariosClientesSeleccionados: (this.state.ClientesAgregados == true ? this.state.idsUsuariosClientesSeleccionados : []) })}><Clear />&nbsp;CANCELAR</Button>

              </GridItem>
            </CardBody>
          </Card>

        </Modal>
        <Modal
          isOpen={this.state.MostrarModalArtistas}
        >
          <Card >
            <CardHeader color="info">
              Usuarios Artistas
            </CardHeader>
            <CardBody>
            <CustomInput
                      labelText="Filtrar por nombre"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarArtistas.bind(this),
                        value: this.state.FiltroPorNombre,
                        name: "FiltroPorNombre",
                        id: "FiltroPorNombre",
                        type: "text",
                        error: this.state.errorFiltroPorNombre
                      }}
                    />
                     <FormControlLabel
                        control={
                          <Radio
                            checked={this.state.SeleccionarTodos}
                            onClick={this.seleccionarTodos.bind(this,"artistas")}
                            value={"true"}
                            name="Retraso"
                            color="primary"
                            aria-label='A'
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord className={classes.radioChecked} />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label='Seleccionar todos'
                      />
                     
              <Table
                striped
                tableHead={["#", "", "Usuario",]}

                tableData={this.GetTableBodyArtistasClientes(classes)}
                customCellClasses={[
                  classes.textCenter,
                  classes.padding0,
                  classes.textRight,
                  classes.textRight
                ]}
                customClassesForCells={[0, 1, 5, 6]}
                customHeadCellClasses={[
                  classes.textCenter,
                  classes.textRight,
                  classes.textRight
                ]}
                customHeadClassesForCells={[0, 5, 6]}
              />
              &nbsp;
              &nbsp;
              &nbsp;
                <GridItem>
                <Button tabIndex="0" size="sm" color="info" className="pull-right" round onClick={() => { this.setState({ ClientesAgregados: true, MostrarModalArtistas: false }) }}><Save />&nbsp;Aceptar</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.setState({ MostrarModalArtistas: false, idsUsuariosArtistasSeleccionados: (this.state.ClientesAgregados == true ? this.state.idsUsuariosArtistasSeleccionados : []) })}><Clear />&nbsp;CANCELAR</Button>

              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <Modal
          isOpen={this.state.MostrarModalDiseños}
          size="lg"
        >
          <Card >
            <CardHeader color="info">
              Seleccionar diseños
            </CardHeader>
            <CardBody>
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.TabSeleccionada}
                  indicatorColor="primary"
                  textColor="black"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label={"Diseños de artistas"} onClick={() => this.setState({ TabSeleccionada: 0 })} />
                  <Tab label={"Productos TERCO"} onClick={() => this.setState({ TabSeleccionada: 1 })} />
                </Tabs>
                <GridItem>
                  <div hidden={(this.state.TabSeleccionada == 0 ? false : true)}>
                    <div style ={{textAlign:"center",alignContent:"center"}}>
                    <CustomInput
                      labelText="Filtrar por artista"
                      formControlProps={{
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarDisenosArtistas.bind(this),
                        value: this.state.FiltroDisenoArtistaPorNombre,
                        name: "FiltroDisenoArtistaPorNombre",
                        id: "FiltroDisenoArtistaPorNombre",
                        type: "text",
                        error: this.state.errorFiltroDisenoArtistaPorNombre
                      }}
                    />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                     <CustomInput
                      labelText="Filtrar por diseño"
                      formControlProps={{
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarDisenosArtistas.bind(this),
                        value: this.state.FiltroDisenoArtistaPorDiseno,
                        name: "FiltroDisenoArtistaPorDiseno",
                        id: "FiltroDisenoArtistaPorDiseno",
                        type: "text",
                        error: this.state.errorFiltroDisenoArtistaPorDiseno
                      }}
                    />  
                    &nbsp;
                    &nbsp;
                     <CustomInput
                      labelText="Filtrar por producto"
                      formControlProps={{
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarDisenosArtistas.bind(this),
                        value: this.state.FiltroDisenoArtistaPorProducto,
                        name: "FiltroDisenoArtistaPorProducto",
                        id: "FiltroDisenoArtistaPorProducto",
                        type: "text",
                        error: this.state.errorFiltroDisenoArtistaPorProducto
                      }}
                    />
                    </div>
                    <FormControlLabel
                        control={
                          <Radio
                            checked={this.state.SeleccionarTodosDiseno}
                            onClick={this.seleccionarTodos.bind(this,"disenoartistas")}
                            value={"true"}
                            name="Retraso"
                            color="primary"
                            aria-label='A'
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord className={classes.radioChecked} />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label='Seleccionar todos'
                      />
                    <Table
                      striped
                      tableHead={["#", "", "Nombre diseño", "Vista previa"]}

                      tableData={this.getTableBodyFromDisenosArtistas(classes)}
                      customCellClasses={[
                        classes.textCenter,
                        classes.padding0,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customClassesForCells={[0, 1, 5, 6]}
                      customHeadCellClasses={[
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customHeadClassesForCells={[0, 5, 6]}
                    />
                  </div>
                  <div hidden={(this.state.TabSeleccionada == 1 ? false : true)}>
                  <div style ={{textAlign:"center",alignContent:"center"}}>
                    <CustomInput
                      labelText="Filtrar por artista"
                      formControlProps={{
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarDisenosArtistasTerco.bind(this),
                        value: this.state.FiltroDisenoArtistaPorNombreTerco,
                        name: "FiltroDisenoArtistaPorNombreTerco",
                        id: "FiltroDisenoArtistaPorNombreTerco",
                        type: "text",
                        error: this.state.errorFiltroDisenoArtistaPorNombre
                      }}
                    />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                     <CustomInput
                      labelText="Filtrar por diseño"
                      formControlProps={{
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarDisenosArtistasTerco.bind(this),
                        value: this.state.FiltroDisenoArtistaPorDisenoTerco,
                        name: "FiltroDisenoArtistaPorDisenoTerco",
                        id: "FiltroDisenoArtistaPorDisenoTerco",
                        type: "text",
                        error: this.state.errorFiltroDisenoArtistaPorDiseno
                      }}
                    />  
                    &nbsp;
                    &nbsp;
                     <CustomInput
                      labelText="Filtrar por producto"
                      formControlProps={{
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.filtrarDisenosArtistasTerco.bind(this),
                        value: this.state.FiltroDisenoArtistaPorProductoTerco,
                        name: "FiltroDisenoArtistaPorProductoTerco",
                        id: "FiltroDisenoArtistaPorProductoTerco",
                        type: "text",
                        error: this.state.errorFiltroDisenoArtistaPorProducto
                      }}
                    />
                    </div>
                    <FormControlLabel
                        control={
                          <Radio
                            checked={this.state.SeleccionarTodosDisenoTerco}
                            onClick={this.seleccionarTodos.bind(this,"disenosterco")}
                            value={"true"}
                            name="Retraso"
                            color="primary"
                            aria-label='A'
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord className={classes.radioChecked} />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label='Seleccionar todos'
                      />
                    <Table
                      striped
                      tableHead={["#", "", "Nombre producto", "Vista previa"]}

                      tableData={this.getTableBodyFromProductosTerco(classes)}
                      customCellClasses={[
                        classes.textCenter,
                        classes.padding0,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customClassesForCells={[0, 1, 5, 6]}
                      customHeadCellClasses={[
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customHeadClassesForCells={[0, 5, 6]}
                    />
                  </div>
                  <Button tabIndex="0" size="sm" color="info" className="pull-right" round onClick={() => { this.setState({ ClientesAgregados: true, MostrarModalDiseños: false }) }}><Save />&nbsp;Aceptar</Button>
                  <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.setState({
                     MostrarModalDiseños: false, 
                     idsProductosTercoSeleccionados: (this.state.ClientesAgregados == true ? this.state.idsProductosTercoSeleccionados : []), 
                     idsDisenosArtistasSeleccionados: (this.state.ClientesAgregados == true ? this.state.idsDisenosArtistasSeleccionados : []) 
                     })}><Clear />&nbsp;CANCELAR</Button>
                </GridItem>
              </AppBar>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  }
}

export default withStyles(withStyles)(AgregarDescuentos);