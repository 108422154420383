import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import cookies from 'js-cookie';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Cargando from '../../../../components/Generales/ModalCargando';
import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Table from '@material-ui/core/Table';
import Radio from '@material-ui/core/Radio';
import imageBackground from "assets/img/sidebar-3.jpg";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Select from "@material-ui/core/Select";
import TableRow from '@material-ui/core/TableRow'; //
import Paper from '@material-ui/core/Paper';
import Card from "components/Card/Card.jsx";
import SwipeableViews from 'react-swipeable-views';
import CardHeader from "components/Card/CardHeader.jsx";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalAgregarUsuarioAdministrativo from "views/Modulos/Administracion/Usuarios/ModalAgregarUsuarioAdministrativo.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, Warning, KeyboardTab } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import JSZip from 'jszip';
import CargandoImg from '../../../../components/Generales/ModalCargandoImg';


//Subiendo

class usuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Producto: '',
            idVenta: null,
            TabSeleccionada: 0,
            valueSelect: 1,
            mensajeNotificacion: '',
            errorProducto: '',
            EstatusPedidos: [],
            MostrarNotificacion: false,
            statusConProducto: 0,
            MensajeConfirmacion: "",
            Titulo: "",
            ProductosModal: [],
            MotivoRetraso: '',
            FolioFiltro: '',
            MostrarCargando: true,
            idsPorActualizar: [],
            colorNotificacion: '',
            mostrarModal: false,
            Productos: [],
            MostarModalDias: false,
            Dias: 0,
            valueSelectNuevoStatusPedido: 0,
            MotivoActualizacionPedido: '',
            NombreClienteFiltro: '',
            prueba: "",
            checksTodos: [],
            MotivosDeRetraso: [],
            selectMotivoDeRetraso: '',
            checkEnviarCorreoCliente: false,
            MostrarCargandoImg: false
        };
        this.ObtenerComprasProduccion = this.ObtenerComprasProduccion.bind(this);
        this.ObtenerEstatusDePedidos = this.ObtenerEstatusDePedidos.bind(this);
        this.ObtenerCollapse = this.ObtenerCollapse.bind(this);
        this.eventCerrarModal = this.eventCerrarModal.bind(this);
        this.abrirModal = this.abrirModal.bind(this);
        this.cambiarStatusPedidoVentas = this.cambiarStatusPedidoVentas.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.descargarArchivo = this.descargarArchivo.bind(this);
        this.descargarTemplete = this.descargarTemplete.bind(this);
        this.concluirVenta = this.concluirVenta.bind(this)
        this.filtrarCompras = this.filtrarCompras.bind(this);
        this.mostrarModalConcluirVenta = this.mostrarModalConcluirVenta.bind(this)
        this.ConfirmarRetraso = this.ConfirmarRetraso.bind(this);
        this.limpiarTodoOhSi = this.limpiarTodoOhSi.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.ConvertirFechaListado = this.ConvertirFechaListado.bind(this)
        this.abrirModalDias = this.abrirModalDias.bind(this);
        this.obtenerFechaListado = this.obtenerFechaListado.bind(this);
        this.ObtenerMotivosDeRetraso = this.ObtenerMotivosDeRetraso.bind(this);
        this.descargarTodosArchivos = this.descargarTodosArchivos.bind(this);
        this.handleChangeRadioNotificarCliente = this.handleChangeRadioNotificarCliente.bind(this);
        this.buscarImagen = this.buscarImagen.bind(this);
    }

    componentDidMount() {
        this.ObtenerComprasProduccion();
        this.ObtenerEstatusDePedidos();
        this.ObtenerMotivosDeRetraso();
    }

    //Obtener todos los registros guardados
    ObtenerComprasProduccion() {
        fetch(Globales.Url + 'Compras/ObtenerCompras', {
            method: 'GET',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) {

                } else {
                    console.log("los productos->", data.data)
                    this.setState({
                        Productos: data.data,
                        MostrarCargando: false
                    });
                }
            });
    };

    descargarArchivo(NombreArchivo, event) {
        console.log(NombreArchivo)
        fetch(Globales.Url + 'downloadFile', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ RutaArchivo: ("/Files/Productos/" + NombreArchivo) })
        })
            .then(res => res.blob()).then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = NombreArchivo;
                a.click();
            });
    }

    ObtenerEstatusDePedidos() {
        fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/', {
            method: 'GET',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) { //Error al obtener los registros
                    this.setState({ estatusPedidos: [] });
                } else { //Obtuvo los registros correctamente
                    console.log("es el data--->", data.mensaje)
                    this.setState({
                        EstatusPedidos: data.mensaje,
                        valueSelectNuevoStatusPedido: data.mensaje[0]._id
                    });
                }
            });
    }

    ObtenerMotivosDeRetraso() {
        fetch(Globales.Url + 'Retrasos/Retrasos/ListaRetrasos', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) { //Error al obtener los registros
                    this.setState({ estatusPedidos: [] });
                } else { //Obtuvo los registros correctamente
                    console.log("retrasos--->", data.data)
                    if (data.data.length > 0) {
                        this.setState({
                            MotivosDeRetraso: data.data,
                            selectMotivoDeRetraso: data.data[0].NombreRetraso
                        }, () => {
                            console.log(this.state)
                        });
                    }
                }
            });
    }

    mostrarModalConcluirVenta() {
        this.setState({
            mostrarModal: true,
            MensajeConfirmacion: (this.state.idsPorActualizar.length > 1 ? "¿Seguro que desea concluir las ventas" : "¿Seguro que desea concluir la venta?"),
            Titulo: (this.state.idsPorActualizar.length > 1 ? "Concluir ventas" : "¿Concluir venta")
        });
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    abrirModalDias() {
        this.setState({
            MostarModalDias: true
        });
    }

    ConfirmarRetraso() {
        this.state.idsPorActualizar.map((idCompra, key) => {
            fetch(Globales.Url + 'RetrasoDePedido/RegistrarRetrasoDePedido', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({ idCompra: idCompra, Dias: this.state.Dias, idUsuarioRegistro: cookies.get('id'), MotivoRetraso: this.state.selectMotivoDeRetraso })
            }).then(res => res.json()).then(res => {
                if (this.state.idsPorActualizar.length - 1 == key) {
                    this.setState({
                        colorNotificacion: 'success',
                        MostrarNotificacion: true,
                        mensajeNotificacion: (this.state.idsPorActualizar.length > 0 ? 'Retrasos registrados' : 'Retraso registrado'),
                        MostarModalDias: false,
                    });
                    this.alertTimeout = setTimeout(

                        function () {
                            this.setState({
                                MostrarNotificacion: false,
                                colorNotificacion: '',
                                Dias: '',
                                MotivoRetraso: '',
                                MostrarNotificacion: false,
                                mensajeNotificacion: '',
                                MostarModalDias: false,
                                valueSelect: 1
                            });

                        }.bind(this),
                        4000
                    );
                    this.state.idsPorActualizar.forEach((idName) => {
                        document.getElementsByName(idName)[0].setAttribute("checked", false)
                    })
                    this.setState({
                        idsPorActualizar: []
                    }, () => {
                        this.ObtenerComprasProduccion();
                        this.ObtenerEstatusDePedidos();
                    })
                }
            });
        });
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        var { name, value } = e.target;
        // if(name == "TabSeleccionada"){
        //     if(this.state.TabSeleccionada == this.state.EstatusPedidos.length-1 && this.state.valueSelect == 0){
        //         value = 0;
        //     }
        // }
        this.setState({ [name]: value });
    }

    filtrarCompras() {
        if (this.state.FolioFiltro.trim() == "" && this.state.NombreClienteFiltro.trim() == "") {
            this.setState({
                colorNotificacion: 'danger',
                MostrarNotificacion: true,
                mensajeNotificacion: "Proporcionar al menos un filtro",
            });
            this.alertTimeout = setTimeout(
                function () {
                    this.setState({
                        MostrarNotificacion: false,
                        colorNotificacion: '',
                        Dias: '',
                        MotivoActualizacionPedido: '',
                        MotivoRetraso: '',
                        MostrarNotificacion: false,
                        mensajeNotificacion: '',
                        MostarModalDias: false,
                        valueSelect: 1
                    });
                }.bind(this),
                4000
            );
        } else {
            this.setState({ MostrarCargando: true })
            fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/FiltrarComprasConEstatusDePedido', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({ Folio: this.state.FolioFiltro, NombreCliente: this.state.NombreClienteFiltro, idUsuarioRegistro: cookies.get('id') })
            }).then(res => res.json()).then(res => {
                if (res.data.length > 0) {
                    let datos = res.data;
                    // let rutasImagen = res.rutasImagen;
                    for (let x = 0; x < datos.length; x++) {
                        let idCliente = datos[x].idCliente._id;
                        let datosCliente = res.infoClientes.filter(m => m._id == idCliente);
                        datos[x].NombreCliente = datosCliente[0].Nombre;
                        // for (let j = 0; j < datos[x].Productos.length; j++) {
                        //     let idProducto = datos[x].Productos[j]._id;
                        //     let datosProductoImagen = rutasImagen.filter(n => n.idProducto == idProducto);
                        //     datos[x].Productos[j].RutaImagen = datosProductoImagen[0].rutaImagen;
                        // }
                    }
                    console.log(res.EstatusPedidos)
                    this.setState({
                        // EstatusPedidos: res.EstatusPedidos,
                        Productos: datos,
                        TabSeleccionada: (res.EstatusPedidos.length > 0 ? res.EstatusPedidos[0].NumeroConsecutivo - 1 : 0),
                        MostrarCargando: false
                    });
                } else {
                    this.setState({
                        colorNotificacion: 'danger',
                        MostrarNotificacion: true,
                        mensajeNotificacion: res.mensaje,
                    });
                    this.setState({
                        // EstatusPedidos: res.EstatusPedidos,
                        Productos: res.data,
                        TabSeleccionada: (res.EstatusPedidos.length > 0 ? res.EstatusPedidos[0].NumeroConsecutivo - 1 : 0),
                        MostrarCargando: false,
                    });
                }
            });
        }
    }

    ObtenerCollapse(estatus) {
        return (
            <ExpansionPanel  >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {estatus.NombreEstatus}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    Información de prueba
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    eventCerrarModal() {
        this.setState({
            MostrarModalDetalleProduccion: false
        });
    }

    concluirVenta(e) {
        if (!e) {
            this.setState({
                mostrarModal: false
            });
            return;
        }
        this.state.idsPorActualizar.map((idCompra, key) => {
            fetch(Globales.Url + 'Compras/ConcluirPedidoProduccion', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({ idCompra: idCompra, idUsuarioRegistro: cookies.get('id') })
            }).then(res => res.json()).then(res => {
                if (this.state.idsPorActualizar.length - 1 == key) {
                    this.setState({
                        colorNotificacion: 'success',
                        MostrarNotificacion: true,
                        mensajeNotificacion: (this.state.idsPorActualizar.length > 0 ? 'Producciones concluidas registradas' : 'Producción concluida'),
                        MostarModalDias: false,
                    });
                    this.alertTimeout = setTimeout(
                        function () {
                            this.setState({
                                MostrarNotificacion: false,
                                colorNotificacion: '',
                                Dias: '',
                                MotivoRetraso: '',
                                MostrarNotificacion: false,
                                mensajeNotificacion: '',
                                mostrarModal: false,
                                MostarModalDias: false,
                                valueSelect: 1
                            });
                        }.bind(this),
                        1000
                    );

                    this.state.idsPorActualizar.forEach((idName) => {
                        document.getElementsByName(idName)[0].setAttribute("checked", false)
                    });
                    this.setState({
                        idsPorActualizar: []
                    }, () => {
                        this.ObtenerComprasProduccion();
                        this.ObtenerEstatusDePedidos();
                    });
                }
            });
        });
    }

    handleChangeRadio(event) {
        var evento = event;
        if (event.target.name == "radioTodos") {
            if (this.state.checksTodos.includes(event.target.id) == true) {
                var idsProductos = [];
                this.state.Productos.map(producto => {
                    if (Number.parseInt(event.target.id) != producto.EstatusPedido && this.state.idsPorActualizar.includes(producto._id) == true) {
                        idsProductos.push(producto._id);
                    }
                });
                this.setState({
                    idsPorActualizar: idsProductos
                }, () => {
                    var newCheckTodos = [];
                    for (var keyRadio = 0; keyRadio < this.state.checksTodos.length; keyRadio++) {
                        if (Number.parseInt(this.state.checksTodos[keyRadio]) != this.state.TabSeleccionada + 1) {
                            newCheckTodos.push(this.state.checksTodos[keyRadio] - 1)
                        }
                    }
                    this.setState({ checksTodos: newCheckTodos }, () => {
                    })
                })
            } else {
                var idsProductos = this.state.idsPorActualizar;
                this.state.Productos.map(producto => {
                    if (Number.parseInt(event.target.id) == producto.EstatusPedido && this.state.idsPorActualizar.includes(producto._id) == false) {
                        idsProductos.push(producto._id);
                    }
                });
                var newCheckTodos = this.state.checksTodos;
                newCheckTodos.push(event.target.id)
                this.setState({
                    idsPorActualizar: idsProductos,
                    checksTodos: newCheckTodos
                }, () => {
                })
            }

        } else {
            var arrayStateIdsPorActualzar = this.state.idsPorActualizar;
            if (arrayStateIdsPorActualzar.includes(event.target.name) == false) {
                arrayStateIdsPorActualzar.push(event.target.name);
                this.setState({
                    idsPorActualizar: arrayStateIdsPorActualzar
                });
                document.getElementsByName(event.target.name)[0].setAttribute("checked", false);
            } else {
                var arrayNuevo = []
                for (var keyArray = 0; keyArray < arrayStateIdsPorActualzar.length; keyArray++) {
                    if (event.target.name == arrayStateIdsPorActualzar[keyArray]) {
                    } else {
                        arrayNuevo.push(arrayStateIdsPorActualzar[keyArray].toString());
                    }
                }
                this.setState({
                    idsPorActualizar: arrayNuevo
                });
            }
        }

    }

    handleChangeRadioNotificarCliente(event) {
        this.setState({
            checkEnviarCorreoCliente: !this.state.checkEnviarCorreoCliente
        })
    }

    abrirModal(Productos, idVenta, event) {
        this.setState({
            MostrarModalDetalleProduccion: true,
            ProductosModal: Productos,
            idVenta: idVenta
        });
    }

    //ZIP
    descargarTodosArchivos() {
        fetch(Globales.Url + 'downloadFileToZIP', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ idsPorDescargar: this.state.idsPorActualizar })
        })
            .then(res => res.blob()).then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                var fecha = new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
                a.download = (this.state.idsPorActualizar.length > 1 ? "Ventas " + fecha.toString() + ".zip" : "Ventas " + fecha.toString() + ".zip");
                a.click();
            });
    }

    /*
    var zip = new JSZip();
 
    zip.file("Hello.txt", "Hello World\n");
 
    var img = zip.folder("images");
    img.file("smile.gif", imgData, {base64: true});
 
    zip.generateAsync({type:"blob"}).then(function(content) {
        // see FileSaver.js
        saveAs(content, "example.zip");
    });
 
/*
Results in a zip containing
Hello.txt
images/
    smile.gif
*/

    limpiarTodoOhSi() {
        this.state.idsPorActualizar.forEach((idName) => {
            document.getElementsByName(idName)[0].setAttribute("checked", false)
        });
        this.setState({
            Producto: '',
            idVenta: null,
            TabSeleccionada: 0,
            valueSelect: 1,
            mensajeNotificacion: '',
            errorProducto: '',
            EstatusPedidos: [],
            MostrarNotificacion: false,
            statusConProducto: 0,
            ProductosModal: [],
            MotivoRetraso: '',
            FolioFiltro: '',
            idsPorActualizar: [],
            colorNotificacion: '',
            Productos: [],
            MostarModalDias: false,
            Dias: 0,
            valueSelectNuevoStatusPedido: 0,
            MotivoActualizacionPedido: '',
            NombreClienteFiltro: ''
        }, () => {
            this.ObtenerComprasProduccion();
            this.ObtenerEstatusDePedidos();
        });
    }

    cambiarStatusPedidoVentas() {
        fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ActualizarEstatusPedidosCompras', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify(
                {
                    idNuevoEstatusPedido: this.state.valueSelectNuevoStatusPedido,
                    idsVentasPorActualizar: this.state.idsPorActualizar,
                    idEmpleadoRegistro: cookies.get('id')
                }
            )
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    colorNotificacion: 'success',
                    MostrarNotificacion: true,
                    mensajeNotificacion: data.mensaje,
                    MostarModalDias: false,
                });
                this.alertTimeout = setTimeout(
                    function () {
                        this.setState({
                            MostrarNotificacion: false,
                            colorNotificacion: '',
                            Dias: '',
                            MotivoActualizacionPedido: '',
                            MotivoRetraso: '',
                            MostrarNotificacion: false,
                            mensajeNotificacion: '',
                            MostarModalDias: false,
                            valueSelect: 1
                        });
                    }.bind(this),
                    4000
                );

                this.state.idsPorActualizar.forEach((idName) => {
                    document.getElementsByName(idName)[0].setAttribute("checked", false);
                });
                this.setState({
                    idsPorActualizar: [],
                }, () => {
                    this.ObtenerComprasProduccion();
                    this.ObtenerEstatusDePedidos();
                });
            });
    }

    obtenerFechaListado(diasDePedido, FechaProxima) {
        diasDePedido = this.ObtenerDiasHabiles(diasDePedido)
        var dia = new Date(new Date().setDate(new Date().getDate() + diasDePedido)).getDate();
        var fechaPorTerminar = new Date(FechaProxima).getDate();
        var inicio = dia - fechaPorTerminar;
        if (inicio == 0) {
            inicio = 1;
        }
        return inicio.toString();
    }

    ObtenerDiasHabiles(dias) {
        var response = dias;
        for (var keyDia = 0; keyDia < dias; keyDia++) {
            var fechaTemporal = new Date(new Date().setDate(new Date().getDate() + (keyDia))).getDay();
            if (fechaTemporal == 0 || fechaTemporal == 6) {
                response = Number.parseInt(response) + 1;
            }
        }
        return response;
    }

    ConvertirFechaListado(fecha) {
        var fecha = new Date(fecha).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
        return fecha;
    }

    descargarTemplete(diseño) {
        fetch(Globales.Url + 'DisenoProductos/DisenoProductos/ObtenerDisenoElementoTemplete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idDiseno: diseño.Diseno._id })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson && !myJson.error) {
                var urlImg = "data:image/png;base64," + myJson.data

                // var image = new Image();
                // image.src = urlImg;
                // var w = window.open("");
                // w.document.write(image.outerHTML);

                var a = document.createElement('a');
                a.href = urlImg;
                a.download = "template.png";
                document.body.appendChild(a);
                a.click();
            }
        });
    }


    buscarImagen(productoVenta, FolioPedido) {
        this.setState({ MostrarCargandoImg: true })
        fetch(Globales.Url + 'Compras/ObtenerImagenCompra', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ id: productoVenta.Diseno._id, idCliente: productoVenta.Diseno.idCliente })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) {

                } else {
                    var aux = this.state.Productos;
                    aux.map(compras => {
                        if (compras.FolioPedido === FolioPedido)
                            for (var y = 0; y < compras.Productos.length; y++) {
                                if (compras.Productos[y].Diseno._id === productoVenta.Diseno._id && compras.Productos[y].Diseno.idCliente === productoVenta.Diseno.idCliente) {
                                    compras.Productos[y].RutaImagen = data.data;
                                }
                            }
                    })
                    this.setState({
                        Productos: aux,
                        MostrarCargandoImg: false
                    });
                }
            });
           
    }

    render() {
        let modalDetalleProduccion;

        const { classes } = this.props;
        return (
            <div>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Producción</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem>
                                                <CustomInput
                                                    labelText="Folio"
                                                    formControlProps={{

                                                    }}
                                                    inputProps={{
                                                        required: true,
                                                        onChange: this.handleChange,
                                                        value: this.state.FolioFiltro,
                                                        name: "FolioFiltro",
                                                        id: "FolioFiltro",
                                                        type: "text",
                                                        error: this.state.errorFolioFiltro
                                                    }}
                                                    className="mt-5"
                                                />
                                            </GridItem>
                                            <GridItem>
                                                <CustomInput
                                                    labelText="Nombre del cliente"
                                                    formControlProps={{
                                                    }}
                                                    inputProps={{
                                                        required: true,
                                                        onChange: this.handleChange,
                                                        value: this.state.NombreClienteFiltro,
                                                        name: "NombreClienteFiltro",
                                                        id: "NombreClienteFiltro",
                                                        type: "text",
                                                        error: this.state.errorNombreClienteFiltro
                                                    }}
                                                    className="mt-5"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={() => this.filtrarCompras()}><Search />Buscar</Button>
                                                <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.limpiarTodoOhSi()}><Clear />Limpiar</Button>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div hidden={(this.state.EstatusPedidos.length == 0 ? true : false)}>
                    <GridContainer justify="center"  >

                        <GridItem xs={12} sm={12} md={10}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.TabSeleccionada}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    // variant="fullWidth"
                                    variant="scrollable"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {this.state.EstatusPedidos.map((EstatusPedido, Key) => {
                                        return (
                                            <Tab label={EstatusPedido.NombreEstatus} onClick={() => {
                                                if (this.state.idsPorActualizar.length == 0) {
                                                    this.setState({ TabSeleccionada: EstatusPedido.NumeroConsecutivo - 1 }, () => {
                                                    })
                                                }
                                            }} />
                                        )
                                    })}
                                </Tabs>
                            </AppBar>
                            <GridItem>
                                {
                                    this.state.EstatusPedidos.map((estatus) => {
                                        if (estatus.NumeroConsecutivo - 1 === (this.state.TabSeleccionada)) {
                                            return (
                                                <GridContainer>
                                                    <br />
                                                    <br />
                                                    <Table className={classes.table} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow fullWidth>
                                                                {/* <TableCell style={{ backgroundColor: '#777575', color: 'white' }} align="center">Detalle</TableCell> */}

                                                                <TableCell fullWidth style={{ backgroundColor: '#777575', color: 'white', textAlign: "center", width: "6000px" }}>
                                                                    <GridContainer>
                                                                        <GridItem>

                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Radio
                                                                                        checked={this.state.checksTodos.includes(estatus.NumeroConsecutivo.toString())}
                                                                                        onClick={this.handleChangeRadio.bind(this)}
                                                                                        name="radioTodos"
                                                                                        id={estatus.NumeroConsecutivo}
                                                                                        aria-label='A'
                                                                                        icon={
                                                                                            <FiberManualRecord
                                                                                                className={classes.radioUnchecked}
                                                                                            />
                                                                                        }
                                                                                        checkedIcon={
                                                                                            <FiberManualRecord className={classes.radioChecked} />
                                                                                        }
                                                                                        classes={{
                                                                                            checked: classes.radio,
                                                                                            root: classes.radioRoot
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                classes={{
                                                                                    label: classes.label
                                                                                }}
                                                                                label={"Todos"}
                                                                                style={{ color: "white" }}
                                                                            />
                                                                        </GridItem>
                                                                        <GridItem xs={12} sm={12} md={8} lg={8} >
                                                                            <b style={{ textAlign: "center" }}>Pedidos e información</b>
                                                                        </GridItem>
                                                                    </GridContainer>

                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.Productos.map((producto, key) => {
                                                                if (key == 0) {
                                                                    this.state.statusConProducto = 0;
                                                                }
                                                                if ((producto.EstatusPedido - 1) == this.state.TabSeleccionada) {
                                                                    this.state.statusConProducto = 2;
                                                                    return (
                                                                        <GridContainer>
                                                                            <GridItem xs={1} sm={1} md={1} lg={1}>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Radio
                                                                                            checked={this.state.idsPorActualizar.includes(producto._id)}
                                                                                            onClick={this.handleChangeRadio.bind(this)}
                                                                                            // value={(this.state.idsPorActualizar.includes(producto._id)?true:false)}
                                                                                            name={producto._id}
                                                                                            aria-label='A'
                                                                                            icon={
                                                                                                <FiberManualRecord
                                                                                                    className={classes.radioUnchecked}
                                                                                                />
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <FiberManualRecord className={classes.radioChecked} />
                                                                                            }
                                                                                            classes={{
                                                                                                checked: classes.radio,
                                                                                                root: classes.radioRoot
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    classes={{
                                                                                        label: classes.label
                                                                                    }}
                                                                                    label={producto.FolioPedido}
                                                                                    style={{ color: "black" }}
                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={11} lg={11}>
                                                                                <ExpansionPanel>
                                                                                    <ExpansionPanelSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                        style={{ paddingRight: "100px" }}
                                                                                        fullWidth
                                                                                    >
                                                                                        <GridContainer xs={12} sm={12} md={12} lg={12}>
                                                                                            <GridItem xs={12} sm={12} md={3}>
                                                                                                Cliente:&nbsp;&nbsp;&nbsp;
                                                                                                <label className="pull-rigth"> {producto.NombreCliente}</label>
                                                                                            </GridItem >
                                                                                            <GridItem xs={12} sm={12} md={3}>
                                                                                                Fecha de compra: {this.ConvertirFechaListado(producto.FechaDeCompra)}
                                                                                            </GridItem>
                                                                                            <GridItem xs={12} sm={12} md={3}>
                                                                                                <label>
                                                                                                    {
                                                                                                        (this.obtenerFechaListado((estatus.Dias + producto.DiasDeRetraso),
                                                                                                            // (producto.FechaProximoEstatus == null ? producto.HistorialEstatusPedido[producto.HistorialEstatusPedido.length - 1].Fecha : producto.FechaProximoEstatus)) == estatus.Dias && estatus.Dias > 1 ? <Warning color="error" /> : "")
                                                                                                            (producto.FechaProximoEstatus == null ? (producto.HistorialEstatusPedido[producto.HistorialEstatusPedido.length - 1] == undefined?"":producto.HistorialEstatusPedido[producto.HistorialEstatusPedido.length - 1].Fecha) : producto.FechaProximoEstatus)) == estatus.Dias && estatus.Dias > 1 ? "" : "")
                                                                                                    }Día actual: {
                                                                                                        (producto.ConcluidoSistema == true || producto.VentaConcluida == true ? "Producción concluida" :
                                                                                                            this.obtenerFechaListado((estatus.Dias + producto.DiasDeRetraso),
                                                                                                                (producto.FechaProximoEstatus == null ? producto.HistorialEstatusPedido[producto.HistorialEstatusPedido.length - 1].Fecha : producto.FechaProximoEstatus)

                                                                                                            ) + "/" + (estatus.Dias + producto.DiasDeRetraso).toString()
                                                                                                        )}
                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </label>
                                                                                            </GridItem  >
{/*                                                                                             <GridItem xs={12} sm={12} md={3}>

                                                                                                <label className="pull-rigth">Fecha de compra: {new Date(producto.FechaDeCompra).toLocaleString('en-US', { timeZone: 'UTC' })}</label>
                                                                                            </GridItem> */}
                                                                                        </GridContainer>
                                                                                    </ExpansionPanelSummary>
                                                                                    <ExpansionPanelDetails style={{ textAlign: "center", alignContent: "center" }}>
                                                                                        <div style={{ alignContent: "center", textAlign: "center" }}>
                                                                                            {
                                                                                                producto.Productos.map((productoVenta, key) => {
                                                                                                    return (
                                                                                                        <GridContainer fullWidth >
                                                                                                            <GridItem lg={3}>
                                                                                                                <Button size="sm" className="pull-right mt-1" color="info" onClick={() => { this.buscarImagen(productoVenta, producto.FolioPedido) }} hidden={productoVenta.RutaImagen != null}>
                                                                                                                    Ver Imagen
                                                                                                                </Button>
                                                                                                                <img width={"200px"} src={"data:image/png;base64," + productoVenta.RutaImagen} hidden={productoVenta.RutaImagen == null} />
                                                                                                                <b>{productoVenta.NombreProducto} </b><br />
                                                                                                                <label>{productoVenta.Diseno.nombre}</label><br />
                                                                                                                <label>Cantidad: <b>{productoVenta.Cantidad}</b></label>
                                                                                                            </GridItem>
                                                                                                            <GridItem lg={3}>
                                                                                                                <label style={{ color: "black" }}>{productoVenta.GruposMaterial[0].NombreGrupo}</label>
                                                                                                                <table className="col-xs-12 col-sm-12 col-md-12">
                                                                                                                    <tbody>
                                                                                                                        {productoVenta.GruposMaterial[0].Materiales.map(material => {
                                                                                                                            return (<tr key={producto._id}>
                                                                                                                                <td style={{ color: "#a1a2a3" }}> {material.NombreMaterial}</td>
                                                                                                                            </tr>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </GridItem>
                                                                                                            <GridItem lg={3}>
                                                                                                                <table className="col-xs-12 col-sm-12 col-md-12">
                                                                                                                    <thead>
                                                                                                                        <th className="center"><label style={{ color: "black" }}>{productoVenta.caracteristicasAgregadas[0].NombreCaracteristica}</label></th>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        <tr key={1}>
                                                                                                                            <td style={{ color: "#a1a2a3" }}> {productoVenta.caracteristicasAgregadas[0].ValorCaracteristica}</td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </GridItem>
                                                                                                            <GridItem lg={3}>
                                                                                                                <Button size="sm" type="submit" className="pull-right mt-1" color="info" round onClick={this.descargarTemplete.bind(this, productoVenta)}><VerticalAlignBottom />Template</Button>
                                                                                                                <Button hidden={true} onClick={this.descargarArchivo.bind(this, productoVenta.Diseno.RutaImagen)} size="sm" type="submit" className="pull-right mb-5" color="info" round  ><VerticalAlignBottom />
                                                                                                                    hoja pedido
                                                                                                                </Button>
                                                                                                            </GridItem>
                                                                                                        </GridContainer>


                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </ExpansionPanelDetails>
                                                                                </ExpansionPanel>
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    )
                                                                }
                                                                if (key + 1 < this.state.Productos.length && this.state.statusConProducto != 2) {
                                                                    this.state.statusConProducto = 0;
                                                                }
                                                                if (key == this.state.Productos.length - 1 && this.state.statusConProducto != 2) {
                                                                    return (<div> <TableRow >
                                                                        <TableCell>No hay productos</TableCell>
                                                                    </TableRow></div>)
                                                                }
                                                            }
                                                            )
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                    <br />
                                                </GridContainer>)
                                        }
                                    })
                                }
                            </GridItem>
                        </GridItem>
                        <GridContainer hidden={(this.state.idsPorActualizar.length > 0 ? false : true)} style={{ marginBottom: 80 }}>
                            <div style={{ backgroundColor: "#CFCFCF", height: 70, position: 'fixed', bottom: 0, left: 0, width: '100%', right: 20, paddingLeft: "50px" }} className="border-top border-dark">
                                <div style={{ paddingLeft: "20em" }}>
                                    <InputLabel  >Opción</InputLabel>
                                    <Select onClick={this.handleChange.bind(this)} value={this.state.valueSelect} name="valueSelect" id="selectOpcion">
                                        <MenuItem hidden={(this.state.TabSeleccionada == this.state.EstatusPedidos.length - 1)} value={0}>{(this.state.idsPorActualizar.length == 1 ? "Concluir venta" : "Concluir ventas")}</MenuItem>
                                        <MenuItem value={1}>{(this.state.idsPorActualizar.length == 1 ? "Cambiar estatus de pedido" : "Cambiar estatus de pedidos")}</MenuItem>
                                        <MenuItem value={2}>Marcar retraso</MenuItem>
                                        <MenuItem value={3}>Descargar</MenuItem>
                                    </Select>

                                    <b> Cantidad: {this.state.idsPorActualizar.length}</b>

                                    <Button disabled={(this.state.idsPorActualizar.length == 0 ? true : false)}
                                        onClick={
                                            (this.state.valueSelect == 2 ? this.abrirModalDias.bind(this) :
                                                (this.state.valueSelect == 0 ? this.mostrarModalConcluirVenta.bind(this) :
                                                    this.state.valueSelect == 3 ? this.descargarTodosArchivos.bind(this) :
                                                        this.abrirModalDias.bind(this)))}

                                        size="sm" type="submit" className="pull-right mb-5" color="success" round style={{ marginRight: 10, marginTop: -10 }} >
                                        {(this.state.idsPorActualizar.length <= 1 ? <Done /> : <DoneAll />)} {this.state.idsPorActualizar.length <= 1 ? "Aplicar" : "Aplicar a varios"}</Button>
                                </div>
                            </div>
                        </GridContainer>

                    </GridContainer>
                </div>
                <div hidden={(this.state.EstatusPedidos.length == 0 ? false : true)}>
                    <GridContainer justify="center">
                        <GridItem>
                            <h4><b>{(this.state.EstatusPedidos.length == 0 ? "No se encontró información" : "Cargando")}</b></h4>
                        </GridItem>
                    </GridContainer>
                </div>

                <Modal
                    isOpen={this.state.MostarModalDias}
                    className="static-modal"
                    size="lg"
                >
                    <div hidden={(this.state.valueSelect === 2 ? false : true)}>
                        <Card >
                            <CardHeader color="info">
                                Confirmar días de retraso
                            </CardHeader>
                            <CardBody >
                                <GridContainer >
                                    <GridItem >
                                        <CustomInput
                                            labelText="Días"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.Dias,
                                                name: "Dias",
                                                id: "Dias",
                                                type: "Number",
                                                error: this.state.errorDias
                                            }}
                                            className="mt-5"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Select onClick={this.handleChange.bind(this)} value={this.state.selectMotivoDeRetraso} name="selectMotivoDeRetraso" id="selectMotivoDeRetraso">
                                            {
                                                this.state.MotivosDeRetraso.map(motivo => {
                                                    return (
                                                        <MenuItem value={motivo.NombreRetraso}>{motivo.NombreRetraso}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Button tabIndex="0" size="sm" disabled={(this.state.Dias > 0 ? false : true)} color="info" className="pull-right" round onClick={this.ConfirmarRetraso.bind(this)} ><Save />Aceptar</Button>
                                        <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.setState({ MostarModalDias: false })}><Clear />&nbsp;CANCELAR</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </div>
                    <div hidden={(this.state.valueSelect === 1 ? false : true)}>
                        <Card >
                            <CardHeader color="info">
                                {this.state.idsPorActualizar.length == 1 ? "Cambiar estatus del pedido" : "Cambiar estatus de los pedidos"}
                            </CardHeader>
                            <CardBody >
                                <GridContainer >
                                    <GridItem >
                                        <InputLabel  >Nuevo estatus de pedido</InputLabel>
                                        <Select onClick={this.handleChange.bind(this)} value={this.state.valueSelectNuevoStatusPedido} name="valueSelectNuevoStatusPedido" id="selectOpcion">
                                            {
                                                this.state.EstatusPedidos.map(estatusPedido => {
                                                    return (
                                                        <MenuItem value={estatusPedido._id}>{estatusPedido.NombreEstatus + " - " + estatusPedido.NumeroConsecutivo}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            labelText="* Motivo de actualización de estatus (mínimo 8 caracteres)"
                                            id="textarea-input"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: this.state.MotivoActualizacionPedido,
                                                name: 'MotivoActualizacionPedido',
                                                onChange: this.handleChange,
                                                multiline: true,
                                                rows: 3,
                                                error: this.state.errorComentarios
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Button tabIndex="0" size="sm" disabled={(this.state.MotivoActualizacionPedido.trim().length > 7 ? false : true)} color="info" className="pull-right" round onClick={this.cambiarStatusPedidoVentas.bind(this)} ><Save />Aceptar</Button>
                                        <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.setState({ MostarModalDias: false })}><Clear />&nbsp;CANCELAR</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensajeNotificacion}
                        open={this.state.MostrarNotificacion}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
                <div>
                    <ModalPregunta
                        Titulo={this.state.Titulo}
                        Cuerpo={this.state.MensajeConfirmacion}
                        show={this.state.mostrarModal}
                        eventClick={this.concluirVenta.bind(this)}
                        headerColor="info"
                    />
                </div>
                <Cargando
                    show={this.state.MostrarCargando}
                    Cuerpo="Cargando"
                />

                <CargandoImg
                    show={this.state.MostrarCargandoImg}
                    Cuerpo="Cargando Imagen"
                />
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(usuario);