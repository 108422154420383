import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Button from "components/CustomButtons/Button.jsx";


import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Globales from "../../utils/Globales";
import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

var usuario = JSON.parse(localStorage.getItem("NombreUsuario"));
class HeaderLinks extends React.Component {
  state = {
    open: false,
    openNotifcation: false,
    openProfile: false
  };
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleToggleProfile = () => {
    this.setState(state => ({ openProfile: !state.openProfile }));
  };
  handleCloseProfile() {
    // Cookies.remove('Usuario', { path: '' });
    // Cookies.remove('NombreUsuario', { path: '' });
    // Cookies.remove('TipoUsuario', { path: '' });
    // Cookies.remove('id', { path: '' });
    // Cookies.remove('Correo', { path: '' });
    // Cookies.remove('Nombre', { path: '' });
    // Cookies.remove('Telefono', { path: '' });
    // Cookies.remove('token', { path: ' ' });
    cookies.remove('Usuario');
    cookies.remove('NombreUsuario');
    cookies.remove('TipoUsuario');
    cookies.remove('id');
    cookies.remove('Correo');
    cookies.remove('Nombre');
    cookies.remove('Telefono');
    cookies.remove('token');
    Globales.token = ''
    window.location.href = '#/'
  };

  handleChangePassword() {
    window.location.href = '#/CambioContrasena';
  }

  handleMenuPrincipal() {
    window.location.href = '#/MenuPrincipal';
  }

  handleClosePro = event => {
    if (this.anchorProfile.contains(event.target)) {
      return;
    }
    this.setState({ openProfile: false });
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { openNotifcation, openProfile } = this.state;
    return (
      <div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorProfile = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleMenuPrincipal}
          >
            <p className={classes.linkText}>Menu Principal</p>
          </Button>
          <Button
            buttonRef={node => {
              this.anchorProfile = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggleProfile}
          >
            <p className={classes.linkText}>{cookies.get('NombreUsuario')}</p>
          </Button>
          <Poppers
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) +
              " " +
              classNames(classes.popperNav, classes.overlayPaper) 
            }
            style={{ zIndex: 9999 }} 
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClosePro}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleChangePassword}
                        className={classes.dropdownItem}
                      >
                        Cambiar Contraseña
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleCloseProfile}
                        className={classes.dropdownItem}
                      >
                        Cerrar Sesión
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>


      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
