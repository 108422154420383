import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../../components/Generales/ModalCargando'


export default class AgregarCategoriaElementosDiseno extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreCategoria: '',
      DescripcionCategoria: '',
      _id: '',
      errorNombre: false,
      errorDescripcion: false,
      colorNotificacion: 'success',
      NombreValido:false,
      mensaje: '',
      br: false,
      disable: true,
      Show:false
    }
    this.registrarCategoriaElementosDiseno = this.registrarCategoriaElementosDiseno.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        _id: this.props._id,
        NombreCategoria: this.props.NombreCategoria,
        NombreGuardado: this.props.NombreCategoria,
        DescripcionCategoria: this.props.DescripcionCategoria,
        NombreValido:true
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarCategoriaElementosDiseno() {
    this.setState({Show:true,disable:true})
    var valor1 = 0;
    var valor2 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreCategoria.trim() == '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreCategoria: '',Show:false,disable:false });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    if(this.state.NombreValido==false || valor1 == 1){
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreCategoria: '',Show:false,disable:false });
      valor2 = 1;
    }else {
      this.setState({ errorNombre: false });
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 === 0 && valor2 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'ElementosDiseno/CategoriaElementosDiseno/ActualizarCategoriaElementosDiseno', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje ,Show:false,disable:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoria: '', DescripcionCategoria: '', errorNombre: false, errorDescripcion: false,Show:false,disable:false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'ElementosDiseno/CategoriaElementosDiseno/RegistrarCategoriaElementoDiseno', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje ,Show:false,disable:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoria: '', DescripcionCategoria: '', errorNombre: false, errorDescripcion: false,Show:false,disable:false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }else{
      this.showNotification()
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreCategoria') {
      
    }
  }
  // Funcion para Validar Nombre De La Categoria
  ValidarNombre(){
    this.setState({disable:true});
    console.log("elemento diseño")
    if(this.state.NombreCategoria.trim() != ""){
      if (this.props.op === 1) {
        //Validar si se ingresa otro nombre
        if (this.state.NombreGuardado !== this.state.NombreCategoria) {
          fetch(Globales.Url + 'ElementosDiseno/CategoriaElementosDiseno/ValidarNombre', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ NombreCategoria: this.state.NombreCategoria })
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true,disable:true,NombreValido:false});
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false,disable:false,NombreValido:true});
                this.showNotification();
              
              }
            })
            .catch(err => console.error(err));
        } else {
          this.setState({ errorNombre: false, disable: false,NombreValido:true });
     
        }
      } else {
        fetch(Globales.Url + 'ElementosDiseno/CategoriaElementosDiseno/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCategoria: this.state.NombreCategoria })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true,disable:true,NombreValido:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false,disable:false,NombreValido:true });
              this.showNotification();
            }
          }).catch(err => console.error(err));
      }
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Categoría de Elemento de Diseño"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onBlur:this.ValidarNombre.bind(this),
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreCategoria,

                  name: "NombreCategoria",
                  id: "NombreCategoria",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <CustomInput
                labelText="Descripción"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.DescripcionCategoria,
                  name: "DescripcionCategoria",
                  id: "DescripcionCategoria",
                  type: "text",
                  error: this.state.errorDescripcion
                }}
              />
              <GridItem>
                <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarCategoriaElementosDiseno.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Cargando
                    show={this.state.Show}
                    Cuerpo="Cargando"
                />
      </div>
    );
  }
}