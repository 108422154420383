import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalAgregarObjeto from "views/Modulos/Administracion/Objetos/ModalAgregarObjeto.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";

class objeto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreObjeto: '',
      idCategoria: '',
      rutaImagen: '',
      Visibilidad: '',
      Titulo: '',
      objetos: [],
      categoriaObjetos: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombre: false,
      errorDescripcion: false,
      errorRutaImagen: false,
      errorVisibilidad: false,
      objetosMostrar: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 5;
global.entrar = true;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarObjeto = this.eliminarObjeto.bind(this);
    this.filtrarObjeto = this.filtrarObjeto.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerObjetos();
    this.obtenerAllObjetos();
  }

  //Obtener todos los registros guardados
  obtenerAllObjetos() {
    fetch(Globales.Url + 'Objetos/Objetos/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ objetos: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ objetos: data.mensaje });
        }
      });

    fetch(Globales.Url + 'Objetos/Categoria/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ categoriaObjetos: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ categoriaObjetos: data.mensaje });
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerObjetos() {
    fetch(Globales.Url + 'Objetos/Objetos/ListadoObjetos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Obtuvo los registros correctamente
          this.setState({ objetosMostrar: data.mensaje });
        }
      })
      .catch(err => console.error(err));
  }

  //Función de editar registro
  editarObjeto(id, Nombre, idCategoria, Visibilidad) {
    this.setState({ _id: id, NombreObjeto: Nombre, idCategoria: idCategoria, Visibilidad: Visibilidad, op: 1, showAgregar: true, Titulo: "Editar Objeto" });
  }

  //Función de eliminar registro
  eliminarObjeto(id) {
    fetch(Globales.Url + 'Objetos/Objetos/BajaObjeto', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification("tr");
        this.obtenerObjetos();
        this.limpiar();
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarObjeto(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openModalObjeto = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Objeto", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalObjeto = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Objeto", NombreObjeto: "", idCategoria: "", op: 0 })
    this.obtenerObjetos();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para realizar el filtrado de búsqueda
  filtrarObjeto() {
    var valor1 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreObjeto.trim() == '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", NombreObjeto: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) { //Hay campos requeridos
      this.showNotification();
    } else { //No hay campos requeridos
      fetch(Globales.Url + 'Objetos/Objetos/FiltrarObjetoNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.mensaje.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning", objetosMostrar:[],mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', objetosMostrar: data.mensaje, entroFiltrar: true })
          }
        })
        .catch(err => console.error(err));
    }
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreObjeto: '', entroFiltrar: false });
    global.Fin = 5;
global.entrar = true;
    this.obtenerObjetos();
    this.obtenerAllObjetos();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.objetos.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerObjetos();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.objetos.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.objetos.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerObjetos();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más objetos para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  //Obtener el nombre de la categoría de objetpo
  obtenerNombreCategoria(idCategoria) {
    var nombre = '';
    this.state.categoriaObjetos.map(cat => {
      if (idCategoria === cat._id) {
        nombre = cat.NombreCategoria
      }
    });
    return nombre;
  }

  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalAgregarObjeto
          Titulo={this.state.Titulo}
          NombreObjeto={this.state.NombreObjeto}
          idCategoria={this.state.idCategoria}
          rutaImagen={this.state.rutaImagen}
          Visibilidad={this.state.Visibilidad}
          objetos={this.state.objetos}
          categoriaObjetos={this.state.categoriaObjetos}
          _id={this.state._id}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalObjeto}
          op={this.state.op}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Objeto</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Buscar objeto"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreObjeto,
                        name: "NombreObjeto",
                        id: "NombreObjeto",
                        type: "text",
                        error: this.state.errorNombre
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={() => this.filtrarObjeto()}><Search />Buscar</Button>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.limpiar()}><Clear />Limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalObjeto()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de objetos */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <h4 className="pull-left">Lista de Objetos</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12">
                    <thead>
                      <th className="center">Nombre Objeto</th>
                      <th className="center">Categoría Objeto</th>
                      <th className="center">Estatus</th>
                      <th className="pull-right">Acciones</th>
                    </thead>
                    <tbody>
                      {this.state.objetosMostrar.map(objeto => {
                        return (
                          <tr key={objeto._id}>
                            <td>{objeto.NombreObjeto}</td>
                            <td>{this.obtenerNombreCategoria(objeto.idCategoria)}</td>
                            <td>{objeto.Visibilidad}</td>
                            <td className="pull-right">
                              <Button color="warning" round justIcon size="sm" onClick={() => this.editarObjeto(objeto._id, objeto.NombreObjeto, objeto.idCategoria, objeto.Visibilidad)}><Edit /></Button>
                              <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(objeto._id)}><Close /></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Objeto"}
            Cuerpo={"¿Está seguro que desea eliminar el objeto?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(objeto);