import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import Globales from 'utils/Globales';
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../../components/Generales/ModalCargando';

class AgregarUsuarioWeb extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      Usuario: '',
      Contrasena: '',
      ConfirmacionContrasena: '',
      disabled:false,
      MostrarModal:false,
      Nombre: '',
      IndicadorTerco: true,
      _id: '',
      UsuariosWeb: [],
      errorUsuario: false,
      errorContrasena: false,
      errorConfirmacionContrasena: false,
      errorNombre: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false
    }
    this.registrarUsuarioWeb = this.registrarUsuarioWeb.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        Usuario: this.props.Usuario,
        Contrasena: '',
        ConfirmacionContrasena: '',
        Nombre: this.props.Nombre,
        TipoUsuario: this.props.TipoUsuario,
        _id: this.props._id
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  registrarUsuarioWeb() {
    this.setState({
      disabled:true,
      MostrarModal:true,
    })
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    if (this.state.Usuario.trim() === '') {
      this.setState({ errorUsuario: true, colorNotificacion: 'danger', mensaje: 'Ingrese los datos requeridos',disabled:false,MostrarModal:false });
      valor1 = 1;
      this.showNotification();
    } else {
      this.setState({ errorUsuario: false });
      valor1 = 0;
    }
    if (this.state.Contrasena.trim() === '') {
      this.setState({ errorContrasena: true, colorNotificacion: 'danger', mensaje: 'Ingrese los datos requeridos',disabled:false,MostrarModal:false });
      valor2 = 1;
      this.showNotification();
    } else {
      this.setState({ errorContrasena: false });
      valor2 = 0;
    }
    if (this.state.ConfirmacionContrasena.trim() === '') {
      this.setState({ errorConfirmacionContrasena: true, colorNotificacion: 'danger', mensaje: 'Ingrese los datos requeridos',disabled:false,MostrarModal:false  });
      valor3 = 1;
      this.showNotification();
    } else {
      this.setState({ errorConfirmacionContrasena: false });
      valor3 = 0;
    }
    if (this.state.Nombre.trim() === '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: 'Ingrese los datos requeridos',disabled:false,MostrarModal:false  });
      valor4 = 1;
      this.showNotification();
    } else {
      this.setState({ errorNombre: false });
      valor4 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 + valor3 + valor4 === 0) {
      if (this.props.op == 1) {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ActualizarUsuarioWeb', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,disabled:false,MostrarModal:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', Usuario: '', Contrasena: '',disabled:false,MostrarModal:false , ConfirmacionContrasena: '', Nombre: '', errorUsuario: false, errorContrasena: false, errorConfirmacionContrasena: false, errorNombre: false });
              setTimeout(()=>{
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              },2000);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/RegistrarUsuarioWeb', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,disabled:false,MostrarModal:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', Usuario: '',disabled:false,MostrarModal:false , Contrasena: '', ConfirmacionContrasena: '', Nombre: '', errorUsuario: false, errorContrasena: false, errorConfirmacionContrasena: false, errorNombre: false });
              setTimeout(()=>{
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              },2000);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className='static-modal' size='lg'>
          <Card >
            <CardHeader color='info'>
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText='Usuario'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Usuario,
                      name: 'Usuario',
                      id: 'Usuario',
                      type: 'text',
                      error: this.state.errorUsuario
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText='Nombre'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Nombre,
                      name: 'Nombre',
                      id: 'Nombre',
                      type: 'text',
                      error: this.state.errorNombre
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText='Contraseña'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.Contrasena,
                      name: 'Contrasena',
                      id: 'Contrasena',
                      type: 'password',
                      error: this.state.errorContrasena
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <CustomInput
                    labelText='Confirmación Contraseña'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange.bind(this),
                      value: this.state.ConfirmacionContrasena,
                      name: 'ConfirmacionContrasena',
                      id: 'ConfirmacionContrasena',
                      type: 'password',
                      error: this.state.errorConfirmacionContrasena
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridItem>
                <Button size='sm' color='info' className='pull-right' disabled={this.state.disabled} round onClick={this.registrarUsuarioWeb.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />

        </Modal>
        <div id=''>
          <Snackbar
            place='tr'
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(AgregarUsuarioWeb);