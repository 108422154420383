import React, { Component } from 'react';

import { Card, CardBody, CardGroup, Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';

import { css } from 'emotion';
// First way to import
import { PulseLoader, SyncLoader } from 'react-spinners';
export default class ModalCargandoDatos extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true
    }
  }



  render() {
    return (
      <div>

        <Modal isOpen={this.props.show} className="static-modal modal-dialog-centered">

          <ModalBody  >

            <Row className="justify-content-center" style={{margin:"-55px"}}>
              <Col md="11">
                <CardGroup>

                  <Card className="text-white bg-secondary " >
                    <CardBody className="text-center">
                      <div className="mt-2">
                        <h4>{this.props.Cuerpo}</h4>

                      </div>
                    </CardBody>
                  </Card>
                  <Card className=" border-secondary">
                    <CardBody >

                      <PulseLoader
                        className={override}
                        sizeUnit={"px"}
                        size={18}
                        color={'gray'}
                        loading={this.state.loading}

                        className="text-center mt-3"
                      />

                    </CardBody>
                  </Card>

                </CardGroup>
              </Col>
            </Row>





          </ModalBody>

        </Modal>
      </div>
    );
  }
}
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;