import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../../components/Generales/ModalCargando'

export default class ModalAgregarCaracteristica extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      NombreCaracteristica: '',
      _id: '',
      errorNombreCaracteristica: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disable: false,
      Show:false
    }
    this.registrarCaracteristica = this.registrarCaracteristica.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreCaracteristica: this.props.NombreCaracteristica,
        NombreGuardado: this.props.NombreCaracteristica,
        _id: this.props._id
      })
    }
  }

  componentWillUnmount() {
}

  registrarCaracteristica() {
    setTimeout(()=>{
      this.setState({ disable: true })
      var valor1 = 0;
      if (this.state.NombreCaracteristica.trim() == '' || this.state.errorNombreCaracteristica == true) {
        this.setState({ errorNombreCaracteristica: true });
        this.setState({ colorNotificacion: 'danger' });
        this.setState({ mensaje: "Complete el campo requerido" ,disable:false});
        this.showNotification("tr");
        valor1 = 1;
      } else {
        this.setState({ errorNombreCaracteristica: false });
        valor1 = 0;
      }
      //Validar si hay campos requeridos
      if (valor1 > 0) {
        this.setState({ colorNotificacion: 'danger' });
        this.setState({ mensaje: "Complete el campo requerido",disable:false });
        this.showNotification("tr");
      } else {
        this.setState({Show:true})
        if (this.props.op == 1) {
          this.setState({ _id: this.props._id });
          fetch(Globales.Url + 'Caracteristicas/Caracteristicas/ActualizarCaracteristica', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify(this.state)
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger' });
                this.setState({ mensaje: data.mensaje, disable: false,Show:false });
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'success' });
                this.setState({ NombreCaracteristica: '', errorNombreCaracteristica: false, disable: false,Show:false });
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              }
            })
            .catch(err => console.error(err));
        } else {
          fetch(Globales.Url + 'Caracteristicas/Caracteristicas/RegistrarCaracteristica', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify(this.state)
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger' });
                this.setState({ mensaje: data.mensaje, disable: false,Show:false });
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'success' });
                this.setState({ NombreCaracteristica: '', errorNombreCaracteristica: false, disable: false ,Show:false});
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              }
            })
            .catch(err => console.error(err));
        }
      }
    },2000);
    
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }


  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Funcion para validar el nombre de la caracteristica
  ValidarNombre() {
    //Validar si es una actualización
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreCaracteristica) {
        fetch(Globales.Url + 'Caracteristicas/Caracteristicas/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCaracteristica: this.state.NombreCaracteristica })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCaracteristica: true,disable:true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreCaracteristica: false,disable:false });
              this.showNotification();

            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombreCaracteristica: false ,disable:false});
      }
    } else {
      fetch(Globales.Url + 'Caracteristicas/Caracteristicas/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreCaracteristica: this.state.NombreCaracteristica })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCaracteristica: true,disable:true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreCaracteristica: false,disable:false});
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreCaracteristica') {

    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                required
                focused
                labelText="Nombre Característica"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onBlur:this.ValidarNombre.bind(this),
                  onChange: this.handleChange.bind(this),

                  value: this.state.NombreCaracteristica,
                  name: "NombreCaracteristica",
                  id: "NombreCaracteristica",
                  type: "text",
                  error: this.state.errorNombreCaracteristica
                }}
              />
              <GridItem>
                <Button disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarCaracteristica.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Cargando
                    show={this.state.Show}
                    Cuerpo="Cargando"
                />
      </div>
    );
  }
}