import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Cargando from '../../../../components/Generales/ModalCargando'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';

export default class ModalAgregarModulo extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreModulo: '',
      TipoModulo: '',
      _id: '',
      errorNombreModulo: false,
      errorTipoModulo: false,
      colorNotificacion: 'success',
      disabled:false,
      MostrarModal:false,
      mensaje: '',
      br: false
    }
    this.registrarModulo = this.registrarModulo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreModulo: this.props.NombreModulo,
        TipoModulo: this.props.TipoModulo
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarModulo() {
    var valor1 = 0;
    var valor2 = 0;
    this.setState({
      MostrarModal:true,
      disabled:true
    })
    //Validar que el input no este vacio
    if (this.state.NombreModulo.trim() == '') {
      this.setState({ errorNombreModulo: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreModulo: '',MostrarModal:false,disabled:false });
      valor1 = 1;
    } else {
      this.setState({ errorNombreModulo: false, });
      valor1 = 0;
    }
    if (this.state.TipoModulo.trim() == '') {
      this.setState({ errorTipoModulo: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", TipoModulo: '',MostrarModal:false,disabled:false });
      valor2 = 1;
    } else {
      this.setState({ errorTipoModulo: false });
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Usuario/Modulos/ActualizarModulo', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,MostrarModal:false,disabled:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreModulo: '', TipoModulo: '', errorNombreModulo: false, errorTipoModulo: false,MostrarModal:false,disabled:false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Usuario/Modulos/RegistrarModulo', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,MostrarModal:false,disabled:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreModulo: '', TipoModulo: '', errorNombreModulo: false, errorTipoModulo: false,MostrarModal:false,disabled:false},()=>{
                setTimeout(()=>{
                  this.closeModal(data.mensaje, this.state.colorNotificacion);
                },2000);
              });
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Módulo"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreModulo,
                  name: "NombreModulo",
                  id: "NombreModulo",
                  type: "text",
                  error: this.state.errorNombreModulo
                }}
              />
              <FormControl
                fullWidth
                error={this.state.errorTipoModulo}
              >
                <InputLabel>Tipo de Módulo</InputLabel>
                <Select
                  value={this.state.TipoModulo}
                  onChange={this.handleSimple}
                  name="TipoModulo"
                  id="TipoModulo"
                  inputProps={{
                    error: this.state.errorTipoModulo
                  }}
                >
                  <MenuItem disabled>Tipo de Módulo</MenuItem>
                  <MenuItem value='Administracion'>Administración</MenuItem>
                  <MenuItem value='Inventario'>Inventario</MenuItem>
                  <MenuItem value='Produccion'>Producción</MenuItem>
                  <MenuItem value='Reportes'>Reportes</MenuItem>
                  <MenuItem value='AdministracionWebTerco'>Administración Web-Terco</MenuItem>
                  <MenuItem value='MisProductos'>Mis Productos</MenuItem>
                  <MenuItem value='MisVentas'>Mis Ventas</MenuItem>
                </Select>
              </FormControl>
              <GridItem>
              <Button tabIndex="0" size="sm" color="info" disabled={this.state.disabled} className="pull-right" round onClick={this.registrarModulo.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>                
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}