import React, { Component } from 'react';

import {Modal, ModalBody } from 'reactstrap';

import { css } from 'emotion';
// First way to import
import { FadeLoader } from 'react-spinners';
import { FlashAutoRounded } from '@material-ui/icons';
export default class ModalCargandoDatos extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    render() {
        return (
            <div>

                <Modal isOpen={this.props.show} className="static-modal modal-dialog-centered" style={{width:`175px`, borderRadius:`3px`}}>
                    <ModalBody style={{ textAlign: `center`, margin:`0 auto`, background:`black`, width:`175px`, borderRadius:`3px`}}>
                        <p style={{fontSize:`16px`, color:`white`}}>{this.props.Cuerpo}</p>
                        <p style={{display:`inline-flex`}}>
                        <FadeLoader
                            className={override}
                            color={'gray'}
                            loading={this.state.loading}
                            style={{margin:`0 auto`, backgorund:`red`}}
                        />
                        </p>  
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const override = css`
    display: block;
    margin: 0 auto;
`;