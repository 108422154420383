// core components/views for Admin layout
import Empresa from "views/Modulos/Administracion/Empresa/Empresa.jsx";
import Atributos from "views/Modulos/Administracion/Atributos/Atributos.jsx";
import Productos from "views/Modulos/Administracion/Productos/Productos.jsx";
import Caracteristicas from "views/Modulos/Administracion/Caracteristicas/Caracteristicas.jsx";
import EstatusPedidos from "views/Modulos/Administracion/EstatusPedidos/EstatusPedidos.jsx"
import UnidadMedida from "views/Modulos/Administracion/UnidadMedida/UnidadMedida.jsx"
import CategoriaObjeto from "views/Modulos/Administracion/Objetos/CategoriaObjeto.jsx"
import Objetos from "views/Modulos/Administracion/Objetos/Objetos.jsx"
import MateriaPrima from "views/Modulos/Administracion/MateriaPrima/MateriaPrima.jsx";
import Material from "views/Modulos/Administracion/Material/Material.jsx";
import Estados from "views/Modulos/Administracion/Direccion/Estados.jsx";
import Municipios from "views/Modulos/Administracion/Direccion/Municipios.jsx";
import Localidades from "views/Modulos/Administracion/Direccion/Localidades.jsx";
import Colonias from "views/Modulos/Administracion/Direccion/Colonias.jsx";
import Modelos from "views/Modulos/Administracion/Modelos/Modelos.jsx";
import Modulos from "views/Modulos/Administracion/Usuarios/Modulos.jsx";
import TiposUsuarios from "views/Modulos/Administracion/Usuarios/TipoUsuarios.jsx";
import Usuarios from "views/Modulos/Administracion/Usuarios/UsuariosAdministrativos.jsx";
import CategoriaModelo from "views/Modulos/Administracion/Modelos/CategoriaModelo.jsx";
import Elementos from '../views/Modulos/Administracion/ElementosDiseno/ElementosDiseno';
import CategoriaElementos from '../views/Modulos/Administracion/ElementosDiseno/CategoriaElementosDiseno';
import Tipografias from '../views/Modulos/Administracion/Tipografias/Tipografias';
import MotivosRetrasos from '../views/Modulos/Administracion/Retrasos/Retrasos';
import CajasEnvio from "../views/Modulos/Administracion/CajasEnvio/CajasEnvio";
import CategoriaDiseno from '../views/Modulos/Administracion/CategoriaDiseno/CategoriaDiseno';
import Membresias from '../views/Modulos/Administracion/Membresias/Membresias';
import Descuentos from '../views/Modulos/Administracion/Descuentos/Descuentos';
import UsuariosWeb from '../views/Modulos/Administracion/UsuariosWeb/UsuariosWeb';
// import Inventario from './views/Modulos/Administracion/inventario/Inventario';
// import Inventario from '../views/Modulos/Administracion/Inventario/Inventario';
import MenuPrincipal from '../views/MenuPrincipal/MenuPrincipal';
import DashboardPage from "views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import { Streetview, Grain, ScatterPlot, LinearScale, BarChart, Whatshot, MergeType, Home, Menu,WalletMembership,Contacts } from "@material-ui/icons";

import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [
  {
    path: "/atributos",
    name: "Atributos",
    icon: ScatterPlot,
    component: Atributos,
    layout: "/admin",
    idModulo:"5d08217a198ea43abc040db4"
  },
  {
    path: "/CajasEnvios",
    name: "Cajas de Envío",
    icon: ScatterPlot,
    component: CajasEnvio,
    layout: "/admin",
    idModulo:"5d082254198ea43abc040dc1"
  },
  {
    path: "/CategoriaDiseno",
    name: "Categoría de Diseño",
    icon: LinearScale,
    component: CategoriaDiseno,
    layout: "/admin",
    idModulo:"5e25ddca75c1cd7ff50bb398"
  },
  {
    path: "/CategoriaModelos3D",
    name: "Categoría de Modelos 3D",
    icon: Grain,
    component: CategoriaModelo,
    layout: "/admin",
    idModulo:"5d082192198ea43abc040db5"
  },
  {
    path: "/categoriaElementosDiseño",
    name: "Categoría Elementos de Diseño",
    icon: LinearScale,
    component: CategoriaElementos,
    layout: "/admin",
    idModulo:"5d0821e2198ea43abc040db8"
  },
  {
    path: "/caracteristicas",
    name: "Características",
    icon: LinearScale,
    component: Caracteristicas,
    layout: "/admin",
    idModulo:"5d0821ad198ea43abc040db7"
  },
  {
        path: "/empresa",
        name: "Configuración Empresa",
        icon: Home,
        component: Empresa,
        layout: "/admin",
        idModulo:"5d08213b198ea43abc040db1"
  },
  {
    path: "/Descuentos",
    name: "Descuentos",
    icon: LinearScale,
    component: Descuentos,
    layout: "/admin",
    idModulo:"5d082248198ea43abc040dc0"
  },
  {
    path: "/elementosDiseño",
    name: "Elementos de Diseño",
    icon: ScatterPlot,
    component: Elementos,
    layout: "/admin",
    idModulo:"5d0821f0198ea43abc040db9"
  },

 
  {
    path: "/estatusPedidos",
    name: "Estatus de Producción",
    icon: BarChart,
    component: EstatusPedidos,
    layout: "/admin",
    idModulo:"5e14d7566445defbedce9dae"
  },
  // {
  //   path: "/Inventario",
  //   name: "Inventario",
  //   icon: LinearScale,
  //   component: Inventario,
  //   layout: "/admin"
  // },
  {
    path: "/material",
    name: "Material",
    icon: Whatshot,
    component: Material,
    layout: "/admin",
    idModulo:"5d08220b198ea43abc040dbc"
  },
  {
    path: "/materiaPrima",
    name: "Materia Prima",
    icon: Whatshot,
    component: MateriaPrima,
    layout: "/admin",
    idModulo:"5d082203198ea43abc040dbb"
  },
  {
    path: "/membresias",
    name: "Membresías",
    icon: Contacts,
    component: Membresias,
    layout: "/admin",
    idModulo:"5e14d7c16445defbedce9e20"
  },
  {
    path: "/MotivosRetrasos",
    name: "Motivos de Retrasos",
    icon: Whatshot,
    component: MotivosRetrasos,
    layout: "/admin",
    idModulo:"5e14d7f56445defbedce9e59"

  },
  {
    path: "/Productos",
    name: "Productos",
    icon: Grain,
    component: Productos,
    layout: "/admin",
    idModulo:"5d08221d198ea43abc040dbd"
  },
  {
    path: "/tiposUsuarios",
    name: "Tipos de Usuarios",
    icon: Home,
    component: TiposUsuarios,
    layout: "/admin",
    idModulo:"5d082170198ea43abc040db3"
  },
  {
    path: "/unidadMedida",
    name: "Unidad de Medida",
    icon: MergeType,
    component: UnidadMedida,
    layout: "/admin",
    idModulo:"5d0821f9198ea43abc040dba"
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: Home,
    component: Usuarios,
    layout: "/admin",
    idModulo:"5d08215a198ea43abc040db2"
  },
  // {
  //   path: "/UsuariosWeb",
  //   name: "Usuarios Web Terco",
  //   icon: Home,
  //   component: UsuariosWeb,
  //   layout: "/admin"
  // },
  // {
  //   path: "/Modelo3D",
  //   name: "Modelos 3D",
  //   icon: Grain,
  //   component: Modelos,
  //   layout: "/admin"
  // },
  // {
  //   path: "/Tipografias",
  //   name: "Tipografías",
  //   icon: Grain,
  //   component: Tipografias,
  //   layout: "/admin"
  // }
  // {
  //   name: 'Estados',
  //   path: '/estados',
  //   icon: LinearScale,
  //   component: Estados,
  //   layout: "/admin"
  // }
  // {
  //   path: "/empresa",
  //   name: "Configuración Empresa",
  //   icon: Home,
  //   component: Empresa,
  //   layout: "/admin"
  // },
  // {
  //   path: "/modulos",
  //   name: "Módulos",
  //   icon: Home,
  //   component: Modulos,
  //   layout: "/admin"
  // },
]

var dashboardRoutesAccess = [];
for(var keyRoutes  = 0; keyRoutes < dashboardRoutes.length; keyRoutes++){
  if(cookies.get('TipoUsuario') != undefined){
    if(cookies.get('TipoUsuario').idModulos.includes(dashboardRoutes[keyRoutes].idModulo) == true){
      dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
    }
  }
}

export default dashboardRoutesAccess;