import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';

export default class ModalAgregarCategoriaObjeto extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreCategoria: '',
      DescripcionCategoria: '',
      _id: '',
      errorNombre: false,
      errorDescripcion: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false
    }
    this.registrarCategoriaObjeto = this.registrarCategoriaObjeto.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc','esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        NombreCategoria: this.props.NombreCategoria,
        DescripcionCategoria: this.props.DescripcionCategoria
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarCategoriaObjeto() {
    var valor1 = 0;
    var valor2 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreCategoria.trim() == '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreCategoria: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    if (this.state.DescripcionCategoria.trim() == '') {
      this.setState({ errorDescripcion: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", DescripcionCategoria: '' });
      valor2 = 1;
    } else {
      this.setState({ errorDescripcion: false });
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0 || valor2 > 0) {
      this.setState({ colorNotificacion: 'danger', mensaje: "Complete el campo requerido" });
      this.showNotification("tr");
    } else {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Objetos/Categoria/ActualizarCategoriaObjeto', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoria: '', DescripcionCategoria: '', errorNombre: false, errorDescripcion: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Objetos/Categoria/RegistrarCategoriaObjeto', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            
            
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoria: '', DescripcionCategoria: '', errorNombre: false, errorDescripcion: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Categoría de Objeto"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreCategoria,
                  name: "NombreCategoria",
                  id: "NombreCategoria",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <CustomInput
                labelText="Descripción Categoría"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.DescripcionCategoria,
                  name: "DescripcionCategoria",
                  id: "DescripcionCategoria",
                  type: "text",
                  error: this.state.errorDescripcion
                }}
              />
              <GridItem>
                <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
                <Button size="sm" color="info" className="pull-right" round onClick={this.registrarCategoriaObjeto.bind(this)}><Save />&nbsp;GUARDAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}