import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, CancelPresentation, Clear } from '@material-ui/icons';

export default class ModalPregunta extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreEstado: '',
      _id: '',
      errorNombreEstado: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false
    }
    this.registrarEstado = this.registrarEstado.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc','esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar el nombre del estado
    if (this.props.op == 1) {
      this.setState({
        NombreEstado: this.props.NombreEstado
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }


  registrarEstado() {
    var valor1 = 0;
    if (this.state.NombreEstado.trim() == '') {
      this.setState({ errorNombreEstado: true });
      this.setState({ colorNotificacion: 'danger' });
      this.setState({ mensaje: "Complete el campo requerido" });
      this.showNotification("tr");
      valor1 = 1;
    } else {
      this.setState({ errorNombreEstado: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) {
      this.setState({ colorNotificacion: 'danger' });
      this.setState({ mensaje: "Complete el campo requerido" });
      this.showNotification("tr");
    } else {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Direcciones/Estados/ActualizarEstado', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {            
            if (data.error) {
              this.setState({ colorNotificacion: 'danger' });
              this.setState({mensaje: data.mensaje});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success' });
              this.setState({ NombreEstado: '', errorNombreEstado: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }                      
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Direcciones/Estados/RegistrarEstado', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger' });
              this.setState({mensaje: data.mensaje});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success' });
              this.setState({ NombreEstado: '', errorNombreEstado: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje,colorNotificacion);
  }

  showNotification() {
    var x = [];
    //x[place] = true;
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        //x[place] = false;
        this.setState({ br: false });
        //this.setState({ colorNotificacion: 'success' });
        this.setState(x);
      }.bind(this),
      4000
    );
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Estado"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreEstado,
                  name: "NombreEstado",
                  id: "NombreEstado",
                  type: "text",
                  error: this.state.errorNombreEstado
                }}
              />
              <GridItem>
                <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear/>&nbsp;CANCELAR</Button>
                <Button size="sm" color="info" className="pull-right" round onClick={this.registrarEstado.bind(this)}><Save/>&nbsp;GUARDAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}