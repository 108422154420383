import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Cargando from '../../../../components/Generales/ModalCargando'
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import { Save, Edit } from '@material-ui/icons';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ModalPregunta from "components/Generales/ModalPregunta"
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

class Empresa extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      RazonSocial: '',
      NombreCortoEmpresa: '',
      Telefono: '',
      RFC: '',
      PrecioMembresia:0,
      errorPrecioMembresia:false,
      Calle: '',
      Colonia: '',
      Numero: '',
      TipoDeRenovacion:0,
      CodigoPostal: '',
      Municipio: '',
      Estado: '',
      Pais: '',
      CorreoDeContacto:'',
      MostrarModal:false,
      CorreoMaterialExistenciaMinima:"",
      disableMembresia:true,
      CorreoAutorizacionPrecios: '',
      CorreoRetrasoPedidos: '',
      ValorIVA: '',
      CuerpoCorreoRetraso:'',
      PorcentajeUtilidad: '',
      errorRazonSocial: false,
      errorNombreCortoEmpresa: false,
      errorTelefono: false,
      errorRFC: false,
      errorCalle: false,
      errorColonia: false,
      errorNumero: false,
      errorCodigoPostal: false,
      errorMunicipio: false,
      errorEstado: false,
      errorPais: false,
      errorCorreoAutorizacionPrecios: false,
      errorCorreoRetrasoPedidos: false,
      errorValorIVA: false,
      errorPorcentajeUtilidad: false,
      disable: true,
      Empresa: [],
      mensaje: '',
      colorNotificacion: 'success',
      HoraRecoleccion:'12:12'
    };
    this.registrarEmpresa = this.registrarEmpresa.bind(this);
    this.editarEmpresa = this.editarEmpresa.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editarMembresia = this.editarMembresia.bind(this)
    this.registrarDatosMembresia = this.registrarDatosMembresia.bind(this)
  } 

  componentDidMount() {
    this.obtenerEmpresa();
  }

  obtenerEmpresa() {
    fetch(Globales.Url + 'Empresa/Empresa', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          this.setState({ Empresa: [] });
        } else {
          this.setState({ Empresa: data.data });
          //Validar si tiene datos
          if (data.data.length !== 0) {
            this.setState({ _id: data.data[0]._id,CuerpoCorreoRetraso:data.data[0].CuerpoCorreoRetraso, HoraRecoleccion:data.data[0].HoraRecoleccion,TipoDeRenovacion:data.data[0].TipoDeRenovacion,PrecioMembresia:data.data[0].PrecioMembresia, CorreoDeContacto:data.data[0].CorreoDeContacto, RazonSocial: data.data[0].RazonSocial,CorreoMaterialExistenciaMinima:data.data[0].CorreoMaterialExistenciaMinima, NombreCortoEmpresa: data.data[0].NombreCortoEmpresa, Telefono: data.data[0].Telefono, RFC: data.data[0].RFC, Calle: data.data[0].Calle, Colonia: data.data[0].Colonia, Numero: data.data[0].Numero, CodigoPostal: data.data[0].CodigoPostal, Municipio: data.data[0].Municipio, Estado: data.data[0].Estado, Pais: data.data[0].Pais, CorreoAutorizacionPrecios: data.data[0].CorreoAutorizacionPrecios, CorreoRetrasoPedidos: data.data[0].CorreoRetrasoPedidos, ValorIVA: data.data[0].ValorIVA, PorcentajeUtilidad: data.data[0].PorcentajeUtilidad })
          } else {
            this.setState({ colorNotificacion: 'danger', mensaje: 'Ingrese los campos requeridos' });
            this.showNotification();
          }
        }
      });
  }

  registrarEmpresa() {
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    var valor6 = 0;
    var valor7 = 0;
    var valor8 = 0;
    var valor9 = 0;
    var valor10 = 0;
    var valor11 = 0;
    var valor12 = 0;
    var valor13 = 0;
    var valor14 = 0;
    var valor15 = 0;
    var valor16 = 0;
    var valor17 = 0;
    this.setState({
      MostrarModal:true,
      disable:true
    })
    if (this.state.RazonSocial.trim() === '') {
      this.setState({ errorRazonSocial: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos', MostrarModal:false,disable:false });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorRazonSocial: false });
      valor1 = 0;
    }
    if (this.state.NombreCortoEmpresa.trim() === '') {
      this.setState({ errorNombreCortoEmpresa: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor2 = 1;
    } else {
      this.setState({ errorNombreCortoEmpresa: false });
      valor2 = 0;
    }
    if (isNaN(this.state.Telefono)) {
      this.setState({ errorTelefono: true, colorNotificacion: 'danger', mensaje: 'Tiene que ser un número de teléfono',MostrarModal:false,disable:false });
      this.showNotification();
      valor3 = 1;
    } else {
      this.setState({ errorTelefono: false });
      valor3 = 0;
    }
    if (this.state.Telefono.trim() === '') {
      this.setState({ errorTelefono: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor3 = 1;
    } else {
      this.setState({ errorTelefono: false });
      valor3 = 0;
    }
    if (this.state.RFC.trim() === '') {
      this.setState({ errorRFC: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor4 = 1;
    } else {
      this.setState({ errorRFC: false });
      valor4 = 0;
    }
    if (this.state.Calle.trim() === '') {
      this.setState({ errorCalle: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor5 = 1;
    } else {
      this.setState({ errorCalle: false });
      valor5 = 0;
    }
    if (this.state.Colonia.trim() === '') {
      this.setState({ errorColonia: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor6 = 1;
    } else {
      this.setState({ errorColonia: false });
      valor6 = 0;
    }
    if (this.state.Numero.trim() === '') {
      this.setState({ errorNumero: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor7 = 1;
    } else {
      this.setState({ errorNumero: false });
      valor7 = 0;
    }
    if (this.state.CodigoPostal.trim() === '') {
      this.setState({ errorCodigoPostal: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor8 = 1;
    } else {
      this.setState({ errorCodigoPostal: false });
      valor8 = 0;
    }
    if (this.state.Municipio.trim() === '') {
      this.setState({ errorMunicipio: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor9 = 1;
    } else {
      this.setState({ errorMunicipio: false });
      valor9 = 0;
    }
    if (this.state.Estado.trim() === '') {
      this.setState({ errorEstado: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor10 = 1;
    } else {
      this.setState({ errorEstado: false });
      valor10 = 0;
    }
    if (this.state.Pais.trim() === '') {
      this.setState({ errorPais: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor11 = 1;
    } else {
      this.setState({ errorPais: false });
      valor11 = 0;
    }
    if (this.state.CorreoAutorizacionPrecios.trim() === ''|| !this.validarEmail(this.state.CorreoAutorizacionPrecios)) {
      this.setState({ errorCorreoAutorizacionPrecios: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor12 = 1;
    } else {
      this.setState({ errorCorreoAutorizacionPrecios: false });
      valor12 = 0;
    }
    if (this.state.CorreoRetrasoPedidos.trim() === '' || !this.validarEmail(this.state.CorreoRetrasoPedidos)) {
      this.setState({ errorCorreoRetrasoPedidos: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor13 = 1;
    } else {
      this.setState({ errorCorreoRetrasoPedidos: false });
      valor13 = 0;
    }
    if (this.state.CorreoMaterialExistenciaMinima.trim() === '' || !this.validarEmail(this.state.CorreoMaterialExistenciaMinima)) {
      this.setState({ errorCorreoMaterialExistenciaMinima: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor13 = 1;
    } else {
      this.setState({ errorCorreoMaterialExistenciaMinima: false });
      valor13 = 0;
    }
    if (this.state.ValorIVA.trim() === '') {
      this.setState({ errorValorIVA: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false });
      this.showNotification();
      valor14 = 1;
    } else {
      this.setState({ errorValorIVA: false });
      valor14 = 0;
    }
    if (this.state.PorcentajeUtilidad.trim() === '') {
      this.setState({ errorPorcentajeUtilidad: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false});
      this.showNotification();
      valor15 = 1;
    } else {
      this.setState({ errorPorcentajeUtilidad: false });
      valor15 = 0;
    }
    if(this.state.CuerpoCorreoRetraso.trim() == ""){
      this.setState({ errorComentarios: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false});
      this.showNotification();
      valor16 = 1;
    }else{
       valor16= 0
    }
    if(this.state.CorreoDeContacto.trim() == ""|| !this.validarEmail(this.state.CorreoDeContacto)){
      this.setState({ errorCorreoDeContacto: true, colorNotificacion: 'danger', mensaje: 'Complete los campos requeridos',MostrarModal:false,disable:false});
      this.showNotification();
      valor17 = 1;
    }else{
       valor17= 0
    }
    //Validar si por lo menos uno cambio a true
    if ((valor1 + valor2 + valor3 + valor4 + valor5 + valor6 + valor7 + valor8 + valor9 + valor10 + valor11 + valor12 + valor13 + valor14 + valor15 + valor16 +valor17) > 0) {
      // this.setState({ colorNotificacion: 'danger' });
      // this.setState({ mensaje: "Complete los campos requeridos" });
      //this.showNotification();
    } else {
      if (this.state._id) {
       
        fetch(Globales.Url + 'Empresa/Empresa/ActualizarEmpresa', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.state._id,CuerpoCorreoRetraso:this.state.CuerpoCorreoRetraso, HoraRecoleccion:this.state.HoraRecoleccion,CorreoDeContacto:this.state.CorreoDeContacto, RazonSocial: this.state.RazonSocial,CorreoMaterialExistenciaMinima:this.state.CorreoMaterialExistenciaMinima, NombreCortoEmpresa: this.state.NombreCortoEmpresa, Telefono: this.state.Telefono, RFC: this.state.RFC, Calle: this.state.Calle, Colonia: this.state.Colonia, Numero: this.state.Numero, CodigoPostal: this.state.CodigoPostal, Municipio: this.state.Municipio, Estado: this.state.Estado, Pais: this.state.Pais, CorreoAutorizacionPrecios: this.state.CorreoAutorizacionPrecios, CorreoRetrasoPedidos: this.state.CorreoRetrasoPedidos, ValorIVA: this.state.ValorIVA, PorcentajeUtilidad: this.state.PorcentajeUtilidad })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger' });
            } else {
              this.setState({ colorNotificacion: 'success' });
            }
            this.setState({ _id: '', RazonSocial: '',MostrarModal:false,disable:false, NombreCortoEmpresa: '', Telefono: '', RFC: '', Calle: '', Colonia: '', Numero: '', CodigoPostal: '', Municipio: '', Estado: '', Pais: '', CorreoAutorizacionPrecios: '', CorreoRetrasoPedidos: '', ValorIVA: '', PorcentajeUtilidad: '', mensaje: data.mensaje, errorColor: false, errorDias: false, errorNombre: false });
            this.showNotification();
            this.obtenerEmpresa();
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Empresa/Empresa/RegistrarEmpresa', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ RazonSocial: this.state.RazonSocial,HoraRecoleccion:this.state.HoraRecoleccion, NombreCortoEmpresa: this.state.NombreCortoEmpresa,CorreoMaterialExistenciaMinima:this.state.CorreoMaterialExistenciaMinima, Telefono: this.state.Telefono, RFC: this.state.RFC, Calle: this.state.Calle, Colonia: this.state.Colonia, Numero: this.state.Numero, CodigoPostal: this.state.CodigoPostal, Municipio: this.state.Municipio, Estado: this.state.Estado, Pais: this.state.Pais, CorreoAutorizacionPrecios: this.state.CorreoAutorizacionPrecios, CorreoRetrasoPedidos: this.state.CorreoRetrasoPedidos, ValorIVA: this.state.ValorIVA, PorcentajeUtilidad: this.state.PorcentajeUtilidad })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger' });
            } else {
              this.setState({ colorNotificacion: 'success' });
            }
            this.obtenerEmpresa();
            this.setState({ _id: '', RazonSocial: '',MostrarModal:false,disable:false, NombreCortoEmpresa: '', Telefono: '', RFC: '', Calle: '', Colonia: '', Numero: '', CodigoPostal: '', Municipio: '', Estado: '', Pais: '', CorreoAutorizacionPrecios: '', CorreoRetrasoPedidos: '', ValorIVA: '', PorcentajeUtilidad: '', mensaje: data.mensaje, errorColor: false, errorDias: false, errorNombre: false });
            this.showNotification();
          })
          .catch(err => console.error(err));
      }
    }
  }

  editarEmpresa() {

    this.setState({ disable: !this.state.disable });
    this.obtenerEmpresa();    
  }
  editarMembresia(){
    this.setState({ disableMembresia: !this.state.disableMembresia })
    this.obtenerEmpresa();

  }
  registrarDatosMembresia(){
    if(this.state.PrecioMembresia == "" || this.state.PrecioMembresia == 0){
      this.setState({
        mensaje:"Favor completar los campos",
        errorPrecioMembresia:true,
        colorNotificacion:"danger"
      });
    }else{
      fetch(Globales.Url + 'Empresa/Empresa/ActualizarEmpresaMembresia', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ 
          _id:this.state._id,
          PrecioMembresia:this.state.PrecioMembresia,
          TipoDeRenovacion:this.state.TipoDeRenovacion
        })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger' });
          } else {
            this.setState({ colorNotificacion: 'success' });
          }
          this.setState({ _id: '',errorPrecioMembresia:false,disableMembresia:true, RazonSocial: '',MostrarModal:false,disable:false, NombreCortoEmpresa: '', Telefono: '', RFC: '', Calle: '', Colonia: '', Numero: '', CodigoPostal: '', Municipio: '', Estado: '', Pais: '', CorreoAutorizacionPrecios: '', CorreoRetrasoPedidos: '', ValorIVA: '', PorcentajeUtilidad: '', mensaje: data.mensaje, errorColor: false, errorDias: false, errorNombre: false });
          this.showNotification();
          this.obtenerEmpresa();
        });
    }
  };

  validarNumeroTelefono(numero){
    return /^\d{10}$/.test(numero)
  }

  validarEmail(correo) {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(correo)){
      return true;
    } else {
      return false  
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    if(name == "CodigoPostal"){
      if(value.length < 6 && /^[0-9]*$/.test(value)){
        this.setState({
          [name]: value
        });
      }
    }else if(name =="RFC"){
      if(value.length <= 12){
        this.setState({
          [name]: value
        });
      }
    }else if(name == "Estado" || name == "Municipio" || name == "Pais"){
      if(!/\d/.test(value)){
        this.setState({
          [name]: value
        });
      }      
    }else{
      this.setState({
        [name]: value
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Configuración Empresa</h4>
              </CardHeader>
              <CardBody>
                <div>
                  <GridContainer>
                    {/* Razón Social */}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Razón Social'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.RazonSocial,
                          name: 'RazonSocial',
                          id: 'RazonSocial',
                          type: 'text',
                          error: this.state.errorRazonSocial
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                    {/* Nombre Empresa */}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Nombre Empresa'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.NombreCortoEmpresa,
                          name: 'NombreCortoEmpresa',
                          id: 'NombreCortoEmpresa',
                          type: 'text',
                          error: this.state.errorNombreCortoEmpresa
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {/* RFC */}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='RFC'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.RFC,
                          name: 'RFC',
                          id: 'RFC',
                          type: 'text',
                          error: this.state.errorRFC
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                    {/* Teléfono */}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Teléfono'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Telefono,
                          name: 'Telefono',
                          id: 'Telefono',
                          type: 'number',
                          error: this.state.errorTelefono
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {/* Calle */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Calle'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Calle,
                          name: 'Calle',
                          id: 'Calle',
                          type: 'text',
                          error: this.state.errorCalle
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                    {/* Colonia */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Colonia'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Colonia,
                          name: 'Colonia',
                          id: 'Colonia',
                          type: 'text',
                          error: this.state.errorColonia
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                    {/* Número */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Número'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Numero,
                          name: 'Numero',
                          id: 'Numero',
                          type: 'text',
                          error: this.state.errorNumero
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                    {/* Código Postal */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Código Postal'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.CodigoPostal,
                          name: 'CodigoPostal',
                          id: 'CodigoPostal',
                          type: 'text',
                          error: this.state.errorCodigoPostal
                        }}
                      >
                      </CustomInput>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {/* Municipio */}
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText='Municipio'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Municipio,
                          name: 'Municipio',
                          id: 'Municipio',
                          type: 'text',
                          error: this.state.errorMunicipio
                        }}>
                      </CustomInput>
                    </GridItem>
                    {/* Estado */}
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText='Estado'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Estado,
                          name: 'Estado',
                          id: 'Estado',
                          type: 'text',
                          error: this.state.errorEstado
                        }}>
                      </CustomInput>
                    </GridItem>
                    {/* País */}
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText='País'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.Pais,
                          name: 'Pais',
                          id: 'Pais',
                          type: 'text',
                          error: this.state.errorPais
                        }}>
                      </CustomInput>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    {/* Correo Autorización Precios */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Correo Autorización Precios'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.CorreoAutorizacionPrecios,
                          name: 'CorreoAutorizacionPrecios',
                          id: 'CorreoAutorizacionPrecios',
                          type: 'text',
                          error: this.state.errorCorreoAutorizacionPrecios
                        }}>
                      </CustomInput>
                    </GridItem>
                    {/* Correo Retraso Pedidos */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Correo Retraso Pedidos'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.CorreoRetrasoPedidos,
                          name: 'CorreoRetrasoPedidos',
                          id: 'CorreoRetrasoPedidos',
                          type: 'text',
                          error: this.state.errorCorreoRetrasoPedidos
                        }}>
                      </CustomInput>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Correo existencia material'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.CorreoMaterialExistenciaMinima,
                          name: 'CorreoMaterialExistenciaMinima',
                          id: 'CorreoMaterialExistenciaMinima',
                          type: 'text',
                          error: this.state.errorCorreoRetrasoPedidos
                        }}>
                      </CustomInput>
                    </GridItem>
                    {/* Valor IVA */}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Correo de contacto'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.CorreoDeContacto,
                          name: 'CorreoDeContacto',
                          id: 'CorreoDeContacto',
                          type: 'text',
                          error: this.state.errorCorreoDeContacto
                        }}>
                      </CustomInput>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Valor IVA'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.ValorIVA,
                          name: 'ValorIVA',
                          id: 'ValorIVA',
                          type: 'number',
                          error: this.state.errorValorIVA
                        }}>
                      </CustomInput>
                    </GridItem>
                  
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText='Porcentaje Utilidad'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disable,
                          onChange: this.handleChange,
                          value: this.state.PorcentajeUtilidad,
                          name: 'PorcentajeUtilidad',
                          id: 'PorcentajeUtilidad',
                          type: 'number',
                          error: this.state.errorPorcentajeUtilidad
                        }}>
                      </CustomInput>
                    </GridItem>
                    
                  </GridContainer>
                  <GridItem  >
                    <CustomInput
                    labelText="Cuerpo de correo de retraso al cliente"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.CuerpoCorreoRetraso,
                      name: 'CuerpoCorreoRetraso',
                      onChange: this.handleChange.bind(this),
                      multiline: true,
                      disabled: this.state.disable,
                      rows: 3,
                      error: this.state.errorComentarios
                    }}
                  />
                    </GridItem>
                </div>
                <div className={classes.space50} />
                <div>
                  <GridItem>
                    <Button tabIndex="0" disabled={this.state.disable} color="success" type="submit" className="pull-right" onClick={this.registrarEmpresa.bind(this)}><Save />Guardar</Button>
                    <Button color="warning" type="submit" className="pull-right" onClick={this.editarEmpresa.bind(this)}><Edit />{this.state.disable == true ?"Editar":"Cancelar"}</Button>
                  </GridItem>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem>
          <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Configuración membresía</h4>
              </CardHeader>
              <CardBody>
               <div style={{alignContent:"center", textAlign:"center"}}>
                 
               <CustomInput
                        labelText='Precio membresía'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableMembresia,
                          onChange: this.handleChange,
                          value: this.state.PrecioMembresia,
                          name: 'PrecioMembresia',
                          id: 'PrecioMembresia',
                          type: 'number',
                          error: this.state.errorPrecioMembresia
                        }}>
                      </CustomInput>
                <InputLabel>Precio de membresía para renovación</InputLabel>
                  <Select
                    formControlProps={{
                      fullWidth: true
                    }}
                    onClick={this.handleChange.bind(this)}
                    value={this.state.TipoDeRenovacion}
                    name="TipoDeRenovacion"
                    disabled={this.state.disableMembresia}
                    style={{ width: "360px" }}
                    id="selectOpcion">
                    <MenuItem value={0}>Costo promoción</MenuItem>
                    <MenuItem value={1}>Costo original</MenuItem>
                  </Select>
                 
                 
               </div>
              
                <div className={classes.space50} />
                <div>
                  <GridItem>
                    <Button tabIndex="0" disabled={this.state.disableMembresia} color="success" type="submit" className="pull-right" onClick={this.registrarDatosMembresia.bind(this)}><Save />Guardar</Button>
                    <Button color="warning" type="submit" className="pull-right" onClick={this.editarMembresia.bind(this)}><Edit />{this.state.disableMembresia == true?"Editar":"Cancelar"}</Button>
                  </GridItem>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <div id="">
            <ModalPregunta
              Titulo={"Eliminar Estatus de Pedido"}
              Cuerpo={"¿Está seguro que desea eliminar el estatus de pedido?"}
              show={this.state.show}
              eventClick={this.eventoModalEliminar}
            />
          </div>
          <div id="">
            <Snackbar
              place="tr"
              color={this.state.colorNotificacion}
              message={this.state.mensaje}
              open={this.state.br}
              closeNotification={() => this.setState({ br: false })}
              close
            />
          </div>
          {/* {agregar} */}
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(basicsStyle)(Empresa);