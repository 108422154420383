import React from "react";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import InformacionEmpresa from '../InformacionEmpresa.jsx';

class ReporteDeProductos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            colorNotificacion: '',
            mensaje: '',
            br: false,
            dataAutocomplete: [],
            dataAutocompleteArtistas: [],
            Productos: [],
            CheckDescuento: false,
            FolioPedido: '',
            selected: [],
            tipoFiltro: false,
            selectedArtistas: [],
            CheckTodos: true,
            CheckProductosTerco: false,
            CheckArtistas: false,
            CheckArtistasFiltrado: false,
            MasVendidos:false,
            CheckModelos:false,
            CatProductos:[],
            selectedModelos:[],
            dataAutocompleteModelo:[]
        };
        this.handleChange = this.handleChange.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.obtenerFormatoDeFechaListado = this.obtenerFormatoDeFechaListado.bind(this)
        this.filtrarClientes = this.filtrarClientes.bind(this);
        this.obtenerNombreVendedores = this.obtenerNombreVendedores.bind(this);
        this.filtrarClientesArtistas = this.filtrarClientesArtistas.bind(this);
        this.ObtenerReporteDeProductos = this.ObtenerReporteDeProductos.bind(this);
        this.obtenerFechaConFormatoListado = this.obtenerFechaConFormatoListado.bind(this);
        this.obtenerCatalogoProductos = this.obtenerCatalogoProductos.bind(this);
    }
    
    componentDidMount() {
        this.filtrarClientes();
        this.filtrarClientesArtistas();
        this.obtenerCatalogoProductos();
    };

    ObtenerReporteDeProductos() {
        if (this.state.CheckArtistasFiltrado == true && this.state.selectedArtistas.length == 0) {
            this.setState({ mensaje: "Favor de seleccionar un artista", colorNotificacion: "danger" });
            this.showNotification()
        } 
        else if(this.state.CheckModelos == true && this.state.selectedModelos.length == 0){
            this.setState({ mensaje: "Favor de seleccionar un modelo", colorNotificacion: "danger" });
            this.showNotification()
        }
        else {
            fetch(Globales.Url + 'Reportes/ReporteDeProductos/ObtenerReporteDeProductos', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({
                    FechaFinal: this.state.FechaFinal, 
                    FechaInicial: this.state.FechaInicial, 
                    idCliente: (this.state.selectedArtistas.length > 0 && this.state.CheckArtistasFiltrado == true ? this.state.selectedArtistas[0].id:{}),
                    Todos: this.state.CheckTodos,
                    ProductosTerco: this.state.CheckProductosTerco,
                    ProductosArtistas: this.state.CheckArtistas,
                    MasVendidos:(this.state.MasVendidos == true?{Vendidos:-1}:null),
                    idCategoriaDiseno: (this.state.selectedModelos.length > 0 && this.state.CheckModelos == true ? this.state.selectedModelos[0].id:{}),
                })
            }
            ).then(res => res.json())
                .then(data => {
                console.log("🍺 / file: ReporteDeProductos.jsx / line 90 / data", data)
                    if (data.data.length == 0) {
                        this.setState({
                            mensaje: "No se encontró información",
                            colorNotificacion: "danger"
                        });
                        this.showNotification();
                    } else {
                        var datosCompletos = []
                        data.data.map(prod =>{
                            let modelo
                            this.CatProductos.map(
                                catalogo =>{
                                    for(let i = 0;catalogo.Producto.length>i; i++){
                                        if(catalogo.Producto[i]._id === prod.idModelo){
                                            modelo = catalogo.Producto[i].TipoProducto
                                        }
                                    }
                            })        
                            datosCompletos.push({
                                nombre: prod.nombre,
                                descripcion: prod.descripcion,
                                idCategoriaDiseno: prod.idCategoriaDiseno,
                                idCliente: prod.idCliente,
                                FechaRegistro: prod.FechaRegistro,
                                Vendidos: prod.Vendidos,
                                Modelo: modelo
                            })
                        })
                        this.setState({
                            Productos: datosCompletos
                        },()=>{
                        });
                    }
                });
        }
    }

    componentWillMount() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        });
    }

    limpiar() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            colorNotificacion: '',
            mensaje: '',
            br: false,
            dataAutocomplete: [],
            dataAutocompleteArtistas: [],
            Productos: [],
            CheckDescuento: false,
            FolioPedido: '',
            selected: [],
            tipoFiltro: false,
            selectedArtistas: [],
            CheckTodos: true,
            CheckProductosTerco: false,
            CheckArtistas: false,
            CheckArtistasFiltrado: false,
            MasVendidos:false,
            CheckModelos:false,
            selectedModelos:[]
        });
    };

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    };
    
    filtrarClientes() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuariosClientes', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ NombreUsuario: this.state.NombreUsuarioFiltro, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal })
        }
        ).then(res => res.json())
            .then(data => {
                var usuariosClientes = []
                data.data.map((usuario) => {
                    usuariosClientes.push({
                        id: usuario._id,
                        label: usuario.Nombre
                    })
                })
                this.setState({
                    Usuarios: [],
                    dataAutocomplete: usuariosClientes
                });
            });
    };

    filtrarClientesArtistas() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuariosArtistas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ NombreUsuario: this.state.NombreUsuarioFiltro, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal })
        }
        ).then(res => res.json())
            .then(data => {
                var usuariosClientes = []
                data.data.map((usuario) => {
                    usuariosClientes.push({
                        id: usuario._id,
                        label: usuario.Nombre
                    });
                });
                this.setState({
                    dataAutocompleteArtistas: usuariosClientes
                });
            });
    };

    obtenerFechaConFormatoListado(fecha) {
        return new Date(new Date(fecha).setDate(new Date(fecha).getDate())).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        if (name == "FechaInicial") {
            if (value <= this.state.FechaFinal) {
                this.setState({ [name]: value });
            }
        } else if (name == "FechaFinal") {
            if (value >= this.state.FechaInicial) {
                this.setState({ [name]: value });
            }
        } else if (name == "CheckDescuento") {
            this.setState({
                "CheckDescuento": !this.state.CheckDescuento
            })
        } else {
            this.setState({ [name]: value });

        }
    };

    handleChangeRadio(e) {
        const { name, value } = e.target;
        this.setState({
            CheckTodos: false,
            CheckProductosTerco: false,
            CheckArtistas: false,
            CheckArtistasFiltrado: false,
            CheckModelos:false
        }, () => {
            this.setState({
                [name]: true
            });
        });
    };

    obtenerNombreVendedores(Productos) {
        var response = "";
        if (Productos.length == 1) {
            Productos.map(producto => {
                response += producto.Diseno.idCliente.Nombre
            });
        } else {
            Productos.map(producto => {
                response += producto.Diseno.idCliente.Nombre + " - "
            });
        }
        return response;
    };

    obtenerFormatoDeFechaListado(venta){
        return new Date( new Date(venta.FechaRegistro)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    }

    obtenerNombreDisenos(Productos) {
        var response = "";
        if (Productos.length == 1) {
            Productos.map(producto => {
                response += producto.Diseno.nombre
            });
        } else {
            Productos.map(producto => {
                response += producto.Diseno.nombre + " - "
            });
        }
        return response;
    };

    obtenerCatalogoProductos(){
        fetch(Globales.Url + 'Productos/Productos/ListadoProductosWeb', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ Fin: global.Fin })
          }
          ).then(res => res.json())
          .then(data => {
            var mod = []
            data.mensaje.map(modelos=>{
                // for(let i = 0;catalogo.Producto.length>i; i++){
                    mod.push({
                        id: modelos.Producto[0].idCategoria,
                        label: modelos.Producto[0].NombreProducto
                    })
                // }
            })
            this.CatProductos = data.mensaje
            this.setState({
                dataAutocompleteModelo: mod
            })
            console.log(this.CatProductos);
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Filtros</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.CheckTodos
                                                        }
                                                        name="CheckTodos"
                                                        onClick={this.handleChangeRadio}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}

                                                    />
                                                }
                                                label={"Todos"}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.CheckProductosTerco
                                                        }
                                                        name="CheckProductosTerco"
                                                        onClick={this.handleChangeRadio}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}

                                                    />
                                                }
                                                label={"Productos TERCO"}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.CheckArtistas
                                                        }
                                                        name="CheckArtistas"
                                                        onClick={this.handleChangeRadio}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}

                                                    />
                                                }
                                                label={"Solo artistas"}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.CheckArtistasFiltrado
                                                        }
                                                        name="CheckArtistasFiltrado"
                                                        onClick={this.handleChangeRadio}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                    />
                                                }
                                                label={"Nombre de artista"}
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem hidden={!this.state.CheckArtistasFiltrado} className="mt-5" xs={12} sm={12} md={3}>

                                        <div style={{ marginTop: "37px" }} >
                                            <Typeahead
                                                onChange={(selectedArtistas) => {
                                                    this.setState({ selectedArtistas });
                                                }}
                                                options={this.state.dataAutocompleteArtistas}
                                                selected={this.state.selectedArtistas}
                                                placeholder="Seleccionar artista"
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha inicial"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaInicial,
                                                name: "FechaInicial",
                                                id: "FechaInicial",
                                                type: "date",
                                                error: this.state.errorFechaInicial,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha final"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                                error: this.state.errorFechaFinal,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    // tabIndex={-1}
                                                    checked={this.state.MasVendidos}
                                                    onClick={() => this.setState({
                                                        MasVendidos:!this.state.MasVendidos
                                                    })
                                                            }
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{ label: "Holis"}}
                                            label={"Más vendidos primero"}
                                            style={{marginTop:"35px"}}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    // tabIndex={-1}
                                                    checked={this.state.CheckModelos}
                                                    name="CheckModelos"
                                                    onClick={this.handleChangeRadio}
                                                    checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                />
                                            }
                                            label={"Modelos"}
                                            style={{marginTop:"35px"}}
                                        />
                                    </GridItem>
                                    <GridItem hidden={!this.state.CheckModelos} className="mt-5" xs={12} sm={12} md={3}>

                                        <div style={{ marginTop: "37px" }} >
                                            <Typeahead
                                                onChange={(selectedModelos) => {
                                                    this.setState({ selectedModelos });
                                                }}
                                                options={this.state.dataAutocompleteModelo}
                                                selected={this.state.selectedModelos}
                                                placeholder="Seleccionar Modelo"
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem>
                                        <Button tabIndex="0" size="sm" color="danger" className="mt-5 pull-right" round onClick={this.limpiar}><Clear />&nbsp;Limpiar</Button>
                                        <Button tabIndex="0" size="sm" color="info" className="mt-5 pull-right" round onClick={this.ObtenerReporteDeProductos}><Search />&nbsp;Buscar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Reporte</h4>
                            </CardHeader>
                            <CardBody>
                                <div hidden={(this.state.Productos.length > 0 ? false : true)} >
                                    <div className="table-wrapper-scroll-y">
                                        <table id="DownloableTable" className="col-xs-12 col-sm-12 col-md-12">
                                        <InformacionEmpresa
                                                NombreReporte="Reporte de diseños"
                                                FechaInicial={this.state.FechaInicial}
                                                FechaFinal={this.state.FechaFinal}
                                                NombreArtista={this.state.CheckArtistasFiltrado == true ? (this.state.selectedArtistas.length == 0?undefined:this.state.selectedArtistas[0].label):undefined}
                                            />
                                            <thead>
                                                <th className="center">Nombre producto</th>
                                                <th className="center">Descripción</th>
                                                <th className="center">Categoría de diseño</th>
                                                <th className="center">Autor</th>
                                                <th className="right">Fecha de creación</th>
                                                <th className="right">Ventas</th>
                                                <th className="center">Modelo</th> 
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Productos.map(venta => {
                                                        return (
                                                            <tr key={venta._id}>
                                                                <td>{venta.nombre}</td>
                                                                <td>{venta.descripcion}</td>
                                                                <td>{venta.idCategoriaDiseno == null ? "" : venta.idCategoriaDiseno.NombreCategoria }</td>
                                                                <td>{venta.idCliente.Nombre}/{(venta.idCliente.IndicadorTerco == true ? "Terco" : "Artista")}</td>
                                                                <td>{ this.obtenerFormatoDeFechaListado(venta)}</td>
                                                                <td>{venta.Vendidos}</td>
                                                                <td>{venta.Modelo}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div hidden={(this.state.Productos.length == 0 ? false : true)}>
                                    <h3 style={{ textAlign: "center" }}>No hay información para mostrar</h3>
                                </div>
                                <div hidden={true}>
                                    <ReactHTMLTableToExcel
                                        id="btnDownloadExcel"
                                        className="download-table-xls-button"
                                        table="DownloableTable"
                                        filename={"Reporte de diseños " + new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                        sheet="tablexls" />
                                </div>
                                <div style={{ textAlign: "center" }} hidden={(this.state.Productos.length > 0 ? false : true)}>
                                    <Button onClick={() => {
                                        document.getElementById('btnDownloadExcel').click()
                                    }} >
                                        Descargar EXCEL
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(ReporteDeProductos);