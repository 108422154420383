import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import TagsInput from 'react-tagsinput';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Danger from 'components/Typography/Danger.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import Globales from 'utils/Globales';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../../components/Generales/ModalCargando';
import 'react-tagsinput/react-tagsinput.css'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx';

class ModalAgregarAtributo extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      NombreAtributo: "",
      Tipo: '',
      _id: '',
      MostrarModal:false,
      errorNombre: false,
      errorTipo: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      tags: [],
      habilitado: false,
      Tipos: null,
      selectedEnabled: 'b',
      disable: true,
      NombreGuardado: ''
    }
    global.Disabled = false;
    global.Nombre = '';
    global.validacion = null;
    global.operacion = null
    this.registrarAtributo = this.registrarAtributo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleTags = this.handleTags.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    //Verificar si es editar
    if (this.props.op == 1) {
      //Validación de que tipo es el atributo
      if (this.props.Tipo.length === 0) { //Tipo Color
        this.setState({ selectedEnabled: 'a', habilitado: true })
        global.Disabled = false;
      } else { //Tipo Desplegable
        this.setState({ tags: this.props.Tipo[0].Desplegable[0].Valor });
      }
      this.setState({ NombreAtributo: this.props.NombreAtributo, Tipos: this.props.Tipo, NombreGuardado: this.props.NombreAtributo });
      global.operacion = 1;
    }
  }

  componentWillUnmount() {
  }

  registrarAtributo() {
    ///DESACTIVO BOTON
    this.setState({ disable: true,MostrarModal:true });
    document.getElementById("btnGuardar").setAttribute("disabled", true);
    //Verificando que sea color
    if (this.state.selectedEnabled == 'a') {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Atributos/Atributos/ActualizarAtributos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.props._id, NombreAtributo: this.state.NombreAtributo, Tipo: [] })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false,MostrarModal:false });
              document.getElementById("btnGuardar").setAttribute("disabled", false);

              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreAtributo: '', Tipo: '', errorNombre: false, errorTipo: false,disable: false,MostrarModal:false});
              document.getElementById("btnGuardar").setAttribute("disabled",false);

              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Atributos/Atributos/RegistrarAtributo', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreAtributo: this.state.NombreAtributo })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false,MostrarModal:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreAtributo: '', Tipo: '', errorNombre: false, errorTipo: false, disable: false,MostrarModal:false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    } else {//Es desplegable
      var valor1 = 0;
      var valor2 = 0;
      //Validar que el input no este vacio
      if (this.state.NombreAtributo.trim() == '') {
        this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: 'Ingrese nombre de atributo', NombreAtributo: '',disable: false,MostrarModal:false });
        this.showNotification();
        valor1 = 1;
        document.getElementById("btnGuardar").setAttribute("disabled", false);
      } else {
        this.setState({ errorNombre: false });
        valor1 = 0;
      }
      //Validar que no venga el arreglo vació
      if (this.state.Tipos === null || this.state.Tipos[0].Desplegable[0].Valor.length === 0) {
        this.setState({ errorTipo: true, colorNotificacion: 'danger', mensaje: 'Agregue valores desplegables',disable: false,MostrarModal:false });
        this.showNotification();
        document.getElementById("btnGuardar").setAttribute("disabled", false);

        valor2 = 1;
      } else {
        this.setState({ errorTipo: false });
        valor2 = 0;
      }
      //Validar si hay campos requeridos
      if (valor1 + valor2 === 0) {
        if (this.props.op == 1) {
          this.setState({ _id: this.props._id });
          fetch(Globales.Url + 'Atributos/Atributos/ActualizarAtributos', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ _id: this.props._id, NombreAtributo: this.state.NombreAtributo, Tipo: this.state.Tipos,disable: false,MostrarModal:false })
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false ,MostrarModal:false});
                document.getElementById("btnGuardar").setAttribute("disabled",false);
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'success', NombreAtributo: '', Tipo: '', errorNombre: false, errorTipo: false, disable: false,MostrarModal:false });
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              }
            })
            .catch(err => console.error(err));
        } else {
          fetch(Globales.Url + 'Atributos/Atributos/RegistrarAtributo', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ NombreAtributo: this.state.NombreAtributo, Tipo: this.state.Tipos })
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false,MostrarModal:false });
                this.showNotification();
                document.getElementById("btnGuardar").setAttribute("disabled",false);
              } else {
                this.setState({ colorNotificacion: 'success', NombreAtributo: '', Tipo: '', errorNombre: false, errorTipo: false, disable: false ,MostrarModal:false});
                this.closeModal(data.mensaje, this.state.colorNotificacion);
              }
            })
            .catch(err => console.error(err));
        }
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }


  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
    if (event.target.value == 'b') {
      this.setState({ habilitado: false, NombreAtributo: '', tags: [] });
      global.Disabled = false;

    } else {
      this.setState({ habilitado: true, NombreAtributo: '', tags: [] });
      global.Disabled = false;
    }
  }

  handleTags(regularTags) {
    this.setState({ tags: regularTags, Tipos: [{ Desplegable: [{ Valor: regularTags }] }] });
  };

  validarNombre = event => {
    console.log(global.operacion,"vic")
    this.setState({ disable: true });
    if (global.operacion === 1) {
      //Validar si se ingresa otro nombre      
      if (this.state.NombreGuardado !== event.target.value && event.target.value!="") {
        fetch(Globales.Url + 'Atributos/Atributos/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreAtributo: event.target.value })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      } else {
        //validar si es vacio
        console.log(event.target.value)
        if(event.target.value!=""){
          this.setState({ errorNombre: false, disable: false });
        }else{
          this.setState({ colorNotificacion: 'danger', mensaje: "El nombre no puede quedar vacío", errorNombre: true, disable: true });
          this.showNotification();
        }
      }
    } else {
      fetch(Globales.Url + 'Atributos/Atributos/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreAtributo: event.target.value })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sections}>
        <div className={classes.container}>
          <Modal isOpen={this.props.showAgregar} className='static-modal' >
            <Card >
              <CardHeader color='info'>
                {this.props.Titulo}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {/* Tipo */}
                  <GridItem xs={12} sm={12} md={6} lg={8}>
                    <div>Tipo de Atributo</div>
                  </GridItem>
                  {/* Color o Desplegabble */}
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadio}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={this.state.selectedEnabled === 'a'}
                            onChange={this.handleChangeEnabled}
                            value='a'
                            name='radio button enabled'
                            aria-label='A'
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord className={classes.radioChecked} />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label='Color'
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={this.state.selectedEnabled === 'b'}
                            onChange={this.handleChangeEnabled}
                            value='b'
                            name='radio button enabled'
                            aria-label='B'
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord className={classes.radioChecked} />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label='Desplegable'
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {/* Nombre de atributo */}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                      labelText='Nombre Atributo'
                      className='mb-2'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: global.Disabled,
                        required: true,
                        onChange: this.handleChange.bind(this),
                        onBlur: this.validarNombre,
                        value: this.state.NombreAtributo,
                        name: 'NombreAtributo',
                        id: 'NombreAtributo',
                        type: 'text',
                        error: this.state.errorNombre,
                        success: !this.state.errorNombre
                      }}
                    />
                  </GridItem>
                  {/* Atributos */}
                  {!this.state.habilitado ?
                    <GridItem xs={15} sm={15} md={15} lg={15}>
                      {this.state.errorTipo ?
                        <Danger>
                          <div className='mt-3'>Agregar valores desplegables</div>
                        </Danger>
                        :
                        <div className='mt-3'>Agregar valores desplegables</div>}
                      <div className='mt-2' />
                      <TagsInput
                        style={{ background: '#808080' }}
                        className='react-tagsinput-tag'
                        value={this.state.tags}
                        onChange={this.handleTags}
                        // tagProps={{ className: 'react-tagsinput-tag rose' }}
                        inputProps={{
                          className: 'react-tagsinput-input',
                          placeholder: 'Enter o Tab',
                          disabled: this.state.habilitado
                        }}
                      />
                    </GridItem>
                    : null}
                </GridContainer>
                {/* Botones */}
                <div className='mt-4'>
                  <GridItem>
                    <Button tabIndex="0" disabled={this.state.disable} id="btnGuardar" size='sm' color='info' className='pull-right' round onClick={this.registrarAtributo.bind(this)}><Save />&nbsp;GUARDAR</Button>
                    <Button tabIndex="1" size='sm' color='warning' className='pull-right' round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>

                  </GridItem>
                </div>
              </CardBody>
            </Card >
            <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
          </Modal >
          <div id=''>
            <Snackbar
              place='tr'
              color={this.state.colorNotificacion}
              message={this.state.mensaje}
              open={this.state.br}
              closeNotification={() => this.setState({ br: false })}
              close
            />
          </div>
        </div >
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAgregarAtributo);
