import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import InformacionEmpresa from '../InformacionEmpresa.jsx';
import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

class ReporteDeComisiones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            NombreArtista: '',
            Compras: [],
            dataAutocomplete: [],
            NombreUsuarioFiltro: '',
            selected: '',
            colorNotificacion: '',
            mensaje: '',
            br: false,
            idsProductosNoEntregarComision:[],
            idsProductosNoEntregarComisionRender:[],
            checkPagosGenerados: false,
            Comisiones:"",
            TablaConComisiones:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.obtenerFechaConFormatoListado = this.obtenerFechaConFormatoListado.bind(this);
        this.obtenerCompras = this.obtenerCompras.bind(this);
        this.filtrarClientes = this.filtrarClientes.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.obtenerComprasFiltro = this.obtenerComprasFiltro.bind(this);
        this.handleChangeAutoComplete = this.handleChangeAutoComplete.bind(this);
        this.marcarComprasComision = this.marcarComprasComision.bind(this);
        this.obtenerTableBodyComisiones = this.obtenerTableBodyComisiones.bind(this);
    }
    componentDidMount() {
        this.filtrarClientes()
    };

    obtenerCompras() {
        fetch(Globales.Url + 'Reportes/ReportesDeComisiones/ObtenerReporteDeComisiones', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },

        }
        ).then(res => res.json())
            .then(data => {
                if (data.data.length == 0) {
                    this.setState({
                        colorNotificacion: "danger",
                        mensaje: "No se encontró información",
                        Compras: []
                    });
                    this.showNotification()
                } else {
                    this.setState({
                        Compras: data.data
                    });
                }

            });
    }
    obtenerComprasFiltro() {
        // if (this.state.selected == '') {
        //     this.setState({ colorNotificacion: 'danger', mensaje: "Seleccionar un filtro", MostrarModal: false, disable: false });
        //     this.showNotification();
        // } else {
            //Obtener el artista
            var idArtista=null;
            if (this.state.selected != '') {
                idArtista= this.state.selected[0].id;
            }
            fetch(Globales.Url + 'Reportes/ReportesDeComisiones/ObtenerReporteDeComisiones', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({
                    idCliente: idArtista, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal,
                    TipoPagos: (this.state.checkPagosGenerados == true ? { $ne: null } : null)
                })
            }
            ).then(res => res.json())
                .then(data => {
                    if (data.data.length == 0) {
                        this.setState({
                            colorNotificacion: "danger",
                            mensaje: "No se encontró información",
                            Compras: []
                        });
                        this.showNotification()
                    } else {
                        
                    console.log("compras---vic",data.data)
                        this.setState({
                            Compras: data.data
                        });
                    }
                });
        
    }

    marcarComprasComision(comisionTotal) {
        if (this.state.checkPagosGenerados == false) {
            var idsCompras = [];
            this.state.Compras.map(compra => {
                idsCompras.push(compra._id)
            })

            fetch(Globales.Url + 'Compras/MarcarComisionesEntregadas', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                },
                body: JSON.stringify({ idsCompras: idsCompras, idUsuario: cookies.get('id'), comisionTotal: comisionTotal })
            }
            ).then(res => res.json())
                .then(data => {
                    this.setState({ colorNotificacion: "success", mensaje: "Comisiones generadas y marcadas", "Compras": [] });
                    this.showNotification();
                });
        }
    }

    filtrarClientes() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuarios', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ NombreUsuario: this.state.NombreUsuarioFiltro, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal })
        }
        ).then(res => res.json())
            .then(data => {
                var usuariosClientes = []
                data.data.map((usuario) => {
                    usuariosClientes.push({
                        id: usuario._id,
                        label: usuario.Nombre
                    })
                });
                this.setState({
                    dataAutocomplete: usuariosClientes
                });
            });
    }

    componentWillMount() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        })
    }

    limpiar() {
        this.setState({
            NombreArtista: '',
            Compras: [],
            NombreUsuarioFiltro: '',
            selected: [],
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        }, () => {
        });
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }

    obtenerTotal(datosCompra) {
        var total = 0;
        for (var keyDatos = 0; keyDatos < datosCompra.length; keyDatos++) {
            total += (datosCompra[keyDatos].unit_price * datosCompra[keyDatos].quantity) / 100;
        }
        return total;
    }

    obtenerPrecioComision(Productos, datosCompra) {
        var total = 0;
        for (var keyProductos = 0; keyProductos < Productos.length; keyProductos++) {
            for (var keyDatosCompra = 0; keyDatosCompra < datosCompra.length; keyDatosCompra++) {
                if (Productos[keyProductos].NombreProducto == datosCompra[keyDatosCompra].name) {
                    total += Productos[keyProductos].ImporteComision * datosCompra[keyDatosCompra].quantity;
                }
            }
        }
        return total;
    }

    obtenerFechaConFormatoListado(fecha) {
        return new Date(new Date(fecha).setDate(new Date(fecha).getDate() + 1)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        if (name == "FechaInicial") {
            if (value <= this.state.FechaFinal) {
                this.setState({ [name]: value });
            }
        } else if (name == "FechaFinal") {
            if (value >= this.state.FechaInicial) {
                this.setState({ [name]: value });
            }
        } else {
            this.setState({ [name]: value });
        }
    }
    handleChangeRadio(event){
        if(this.state.idsProductosNoEntregarComision.includes(event.target.name) == true){
            var newArray = [];
            for(var keyProductosIds = 0; keyProductosIds < this.state.idsProductosNoEntregarComision.length; keyProductosIds++){
                if(event.target.name != this.state.idsProductosNoEntregarComision[keyProductosIds]){
                    newArray.push(this.state.idsProductosNoEntregarComision[keyProductosIds]);
                }
            }
            this.setState({idsProductosNoEntregarComision:newArray})
           
        }else{
            console.log(document.getElementsByName(event.target.name.toString() ))
            document.getElementsByName(event.target.name.toString() )[0].setAttribute("checked", false)
            var arrayProductos = this.state.idsProductosNoEntregarComision;
            arrayProductos.push(event.target.name)
            this.setState({
                idsProductosNoEntregarComision :arrayProductos
            })
        }
    }//wer

    handleChangeAutoComplete(e) { 
        if (e.target.value.length > 3) {
        } else {
        }
    }

    obtenerTableBodyComisiones(Productos, Folio, FechaCompra, DatosCompra) {
        if (Productos.length > 1) {
            var response;
            for (var keyProducto = 0; keyProducto < Productos.length; keyProducto++) {
                response += <tr key={Productos[keyProducto]._id}>
                    <td>{(Productos[keyProducto].Diseno.idClienteOriginal == null ? Productos[keyProducto].Diseno.idCliente : Productos[keyProducto].Diseno.idClienteOriginal)}</td>
                    <td>{Folio}</td>
                    <td>{this.obtenerFechaConFormatoListado(FechaCompra)}</td>
                    <td>
                        {(Productos[keyProducto].Diseno.idClienteOriginal == null ?
                            (Productos[keyProducto].Diseno.idCliente.TokenTarjeta != null ? Productos[keyProducto].Diseno.idCliente.TokenTarjeta : "No. de tarjeta no capturado") :
                            Productos[keyProducto].Diseno.idClienteOriginal.TokenTarjeta != null && Productos[keyProducto].Diseno.idClienteOriginal.TokenTarjeta != "" ? Productos[keyProducto].Diseno.idClienteOriginal.TokenTarjeta : "No. de tarjeta no capturado")}
                    </td>
                    <td>${this.obtenerTotal(DatosCompra)}</td>
                    <td>${Productos[keyProducto].GruposMaterial[keyProducto].ImporteComision * Productos[keyProducto].Cantidad}</td>
                </tr>
            }
            return response;
        } else {
            return (
                <tr key={Productos[0]._id}>
                    <td>{(Productos[0].Diseno.idClienteOriginal == null ? Productos[0].Diseno.idCliente : Productos[0].Diseno.idClienteOriginal)}</td>
                    <td>{Folio}</td>
                    <td>{this.obtenerFechaConFormatoListado(FechaCompra)}</td>
                    <td>
                        {(Productos[0].Diseno.idClienteOriginal == null ?
                            (Productos[0].Diseno.idCliente.TokenTarjeta != null ? Productos[0].Diseno.idCliente.TokenTarjeta : "No. de tarjeta no capturado") :
                            Productos[0].Diseno.idClienteOriginal.TokenTarjeta != null && Productos[0].Diseno.idClienteOriginal.TokenTarjeta != "" ? Productos[0].Diseno.idClienteOriginal.TokenTarjeta : "No. de tarjeta no capturado")}
                    </td>
                    <td>${this.obtenerTotal(DatosCompra)}</td>
                    <td>${Productos[0].GruposMaterial[0].ImporteComision * Productos[0].Cantidad}</td>
                </tr>
            )
        }
    }
    render() {
        const { classes } = this.props;
        let comisionTotal=0;

        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info" text>
                                <h4 className={classes.cardTitle}>Filtros</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <div style={{ marginTop: "38px" }} >
                                            <Typeahead
                                                onChange={(selected) => {
                                                    this.setState({ selected });
                                                }}
                                                options={this.state.dataAutocomplete}
                                                selected={this.state.selected}
                                                placeholder={"Seleccionar artista"}
                                                emptyLabel={"No hay información"}

                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha inicial"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaInicial,
                                                name: "FechaInicial",
                                                id: "FechaInicial",
                                                type: "date",
                                                error: this.state.errorFechaInicial,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha final"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                                error: this.state.errorFechaFinal,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    // tabIndex={-1}
                                                    checked={
                                                        this.state.checkPagosGenerados
                                                    }
                                                    onClick={() => this.setState({
                                                        checkPagosGenerados: !this.state.checkPagosGenerados
                                                    }, () => {
                                                        this.limpiar();
                                                    })

                                                    }
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{ label: "Holis" }}
                                            label={"Pagos generados"}
                                            style={{ marginTop: "35px" }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                    <Button tabIndex="0" size="sm" color="info" className="mt-5" round onClick={this.obtenerComprasFiltro}><Search />&nbsp;Buscar</Button>
                                    <Button tabIndex="0" size="sm" color="danger" className="mt-5" round onClick={this.limpiar}><Clear />&nbsp;Limpiar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                Resultado
                    </CardHeader>
                            <CardBody>
                                <div hidden={(this.state.Compras.length > 0 ? false : true)} >
                                    <div className="table-wrapper-scroll-y">
                                        <table id="DownloableTable" className="col-xs-12 col-sm-12 col-md-12">

                                            <InformacionEmpresa
                                                NombreReporte="Reporte de pago de comisiones"
                                                FechaInicial={this.state.FechaInicial}
                                                FechaFinal={this.state.FechaFinal}
                                            />
                                            <thead>
                                                {/* <th className="center">Marcar</th> */}
                                                <th className="center">Nombre</th>
                                                <th className="center">Folio de pedido</th>
                                                <th className="center">Fecha</th>
                                                <th className="center">No. tarjeta</th>
                                                <th className="center">Total</th>
                                                <th className="center">Comisión</th>
                                            </thead>
{/*     //5e39c2ee4adae6169d467216 */}
                                            <tbody>
                                                {
                                                    
                                                    this.state.Compras.map((Compra,key) => {
                                                        return Compra.Productos.map((producto) => {
                                                            if(this.state.Compras.length-1 == key && Compra.idCliente._id == (producto.Diseno.idClienteOriginal == null?producto.Diseno.idCliente._id:producto.Diseno.idClienteOriginal._id) && this.state.TablaConComisiones == false){
                                                                if(this.state.TablaConComisiones == false){
                                                                    this.setState({
                                                                        colorNotificacion: "danger",
                                                                        mensaje: "No se encontró información",
                                                                        Compras: []
                                                                    });
                                                                    this.showNotification()
                                                                }
                                                            }   
                                                            var idCientejajaa = (producto.Diseno.idClienteOriginal == null?producto.Diseno.idCliente._id.toString():producto.Diseno.idClienteOriginal._id.toString());
                                                            debugger
                                                            //if(Compra.idCliente._id.toString() == idCientejajaa && this.state.idsProductosNoEntregarComisionRender.includes(producto._id) == false  ){
                                                                this.state.TablaConComisiones = true;
                                                                console.log("comision--->",producto.GruposMaterial[0].ImporteComision * producto.Cantidad)
                                                                comisionTotal = comisionTotal + (producto.GruposMaterial[0].ImporteComision * producto.Cantidad);
                                                                return (
                                                                
                                                                    <tr key={Compra._id}>
                                                                        {/* <td>{<FormControlLabel
                                                                                    control={
                                                                                        <Radio
                                                                                            checked={!this.state.idsProductosNoEntregarComision.includes(producto._id)}
                                                                                            onClick={this.handleChangeRadio.bind(this)}
                                                                                            name={producto._id}
                                                                                            hidden={true}
                                                                                            id={producto._id}
                                                                                            aria-label='A'
                                                                                            icon={
                                                                                                <FiberManualRecord
                                                                                                    className={classes.radioUnchecked}
                                                                                                />
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <FiberManualRecord className={classes.radioChecked} />
                                                                                            }
                                                                                            classes={{
                                                                                                checked: classes.radio,
                                                                                                root: classes.radioRoot
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    classes={{
                                                                                        label: classes.label
                                                                                    }}
                                                                                    label={"Todos"}
                                                                                    style={{ color: "white" }}
                                                                                />}</td> */}
                                                                        <td>{(producto.Diseno.idClienteOriginal == null ? producto.Diseno.idCliente.Nombre : producto.Diseno.idClienteOriginal.Nombre)}</td>
                                                                        <td>{Compra.FolioPedido}</td>
                                                                        <td>{this.obtenerFechaConFormatoListado(Compra.FechaDeCompra)}</td>
                                                                        <td>
                                                                            {(producto.Diseno.idClienteOriginal == null ?
                                                                                (producto.Diseno.idCliente.TokenTarjeta != null ? producto.Diseno.idCliente.TokenTarjeta : "No. de tarjeta no capturado") :
                                                                                producto.Diseno.idClienteOriginal.TokenTarjeta != null && producto.Diseno.idClienteOriginal.TokenTarjeta != "" ? producto.Diseno.idClienteOriginal.TokenTarjeta : "No. de tarjeta no capturado")}
                                                                        </td>
                                                                        <td>${this.obtenerTotal(Compra.DatosCompra)}</td>
                                                                        <td>${producto.GruposMaterial[0].ImporteComision * producto.Cantidad}</td>
                                                                    </tr>)
                                                            //}
                                                           });
                                                           
                                                    })
                                                    }
                                                    <tr >
                                                                        
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td style={{fontWeight:"bold"}}>Total Comisión:</td>
                                                                        <td style={{fontWeight:"bold"}}>{(new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(comisionTotal))}</td>
                                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div hidden={this.state.TablaConComisiones}>
                                    <h3 style={{ textAlign: "center" }}>No hay información para mostrar</h3>
                                </div>
                                <div hidden={true}>
                                    <ReactHTMLTableToExcel
                                        id="btnDownloadExcel"
                                        className="download-table-xls-button"
                                        table="DownloableTable"
                                        filename={"Reporte de pago de comisiones  " + new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                        sheet="tablexls" />
                                </div>
                                <div style={{ textAlign: "center" }} hidden={!this.state.TablaConComisiones}>
                                    <div hidden={this.state.checkPagosGenerados} class="alert alert-danger" role="alert">
                                        Al descargar, las comisiones se marcaran en proceso de pago
                                      <br />
                                        <Button onClick={() => {
                                            this.setState({
                                                mensaje: ""
                                            }, () => {
                                                document.getElementById('btnDownloadExcel').click();
                                                this.marcarComprasComision(comisionTotal)
                                            })
                                        }} >
                                            Descargar EXCEL y marcar pagos
                                    </Button>
                                    </div>
                                    <div hidden={this.state.checkPagosGenerados}>
                                        <Button onClick={() => {
                                            this.setState({
                                                mensaje: "",
                                                idsProductosNoEntregarComisionRender:this.state.idsProductosNoEntregarComision
                                            }, () => {
                                                document.getElementById('btnDownloadExcel').click();
                                            })
                                        }} >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Solo descargar EXCEL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Button>
                                    </div>
                                    <div hidden={!this.state.checkPagosGenerados} class="alert alert-danger" role="alert">
                                        <Button onClick={() => {
                                            this.setState({
                                                mensaje: ""
                                            }, () => {
                                                document.getElementById('btnDownloadExcel').click();
                                                this.marcarComprasComision()
                                            })
                                        }} >
                                            Descargar EXCEL
                                    </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(ReporteDeComisiones);