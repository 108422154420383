import React, { Component, useEffect } from 'react';
import { Modal } from 'reactstrap';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { CancelPresentation, Save, Clear } from '@material-ui/icons';
import Snackbar from "components/Snackbar/Snackbar.jsx";

export default class ModalAgregarFolioGuia extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            Ventas: [],
            erroresNumerosGuias:[],
            colorNotificacion:"danger",
            mensajeNotificacion:"Complete los campos",
            MostrarNotificacion:false
        }
        this.escFunction = this.escFunction.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.eventClick()
        }
    }
    handleChange(e) {
        const { name, value } = e.target;
        let arrayVentasState = this.state.Ventas.slice();
        arrayVentasState[name]["NumeroDeGuia"] = value;
        this.setState({
            Ventas:arrayVentasState
        });
        
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    
    componentWillReceiveProps(){
        var arrayErrores = new Array();
        this.state.Ventas = this.props.Ventas;
        for(var venta of this.props.Ventas){
           arrayErrores.push(false);
        }
        this.setState({
            erroresNumerosGuias:arrayErrores
        })
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false); 
    }
    
    eventClickAccept(){
        this.props.eventClick(this.state.Ventas)
        /* if(this.validarNumerosDeGuia() == true){
        }else{
            this.showNotification()
        } */
        // console.log(this.state)
    }
    showNotification() {
        var x = [];
        this.setState({ MostrarNotificacion: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ MostrarNotificacion: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }
    validarNumerosDeGuia(){
        var bandera = true;
        for(var key = 0; key < this.state.Ventas.length; key++){
            if(this.state.Ventas[key].NumeroDeGuia == "" || this.state.Ventas[key].NumeroDeGuia == null){
                bandera = false;
                var arrayErrores = this.state.erroresNumerosGuias;
                arrayErrores[key] = true;
                this.setState({
                    erroresNumerosGuias:arrayErrores
                },()=>{
                });
            }else{
                var arrayErrores = this.state.erroresNumerosGuias;
                arrayErrores[key] = false;
                this.setState({
                    erroresNumerosGuias:arrayErrores
                });
            }
        }
        return bandera;
    }


    render() {
        return (
            <div>
                <Modal isOpen={this.props.show} className="static-modal">
                    <Card >
                        <CardHeader color={(this.props.headerColor == null || this.props.headerColor == undefined ? "success" : this.props.headerColor)}>
                            Generar número de guía
            </CardHeader>
                        <CardBody>
                                <h4 style={{alignContent:"center"}}> Clic en Generar para obtener el número de guía</h4>
                            <GridContainer justify="center">
                                {this.state.Ventas.map((venta, index) => {
                                    return<GridItem >
{/*                                         <CustomInput
                                            labelText={venta.FolioPedido}
                                            formControlProps={{
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.Ventas[index].NumeroDeGuia,
                                                name: index,
                                                id: index, 
                                                type: "text",
                                                error: this.state.erroresNumerosGuias[index]
                                            }}
                                            className="mt-5"
                                        /> */}
                                    </GridItem>
                                })}
                            </GridContainer>

                            <GridItem>
                                <Button size="sm" color="danger" round className="pull-right" onClick={() => this.props.eventClick(false)}><Clear />&nbsp;CANCELAR</Button>
                                <Button size="sm" color="success" round className="pull-right" onClick={() => this.eventClickAccept()}><Save />&nbsp;GENERAR</Button>
                            </GridItem>
                        </CardBody>
                    </Card>
                </Modal>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensajeNotificacion}
                        open={this.state.MostrarNotificacion}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>
        );
    }
}