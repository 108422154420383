import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class InformacionEmpresa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NombreReporte: "",
            FechaInicial: "",
            FechaFinal:"",
            RazonSocial:"",
            RFC:"",
            Telefono:"",
            NombreArtista:""
        };
        this.obtenerInformacionEmpresa = this.obtenerInformacionEmpresa.bind(this);
        this.validarCambios =this.validarCambios.bind(this);
    }
    componentDidMount() {
        this.obtenerInformacionEmpresa()
    };
    componentDidUpdate(){
        this.obtenerInformacionEmpresa()
    }
    obtenerInformacionEmpresa() {
        if(this.validarCambios() == true){
            fetch(Globales.Url + 'Empresa/Empresa/ObtenerInformacionReportes', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }
            }
            ).then(res => res.json())
                .then(data => {
                    this.setState({
                        NombreReporte:this.props.NombreReporte,
                        FechaInicial:this.props.FechaInicial,
                        FechaFinal:this.props.FechaFinal,
                        NombreArtista:this.props.NombreArtista,
                        RazonSocial:data.data.RazonSocial,
                        RFC:data.data.RFC,
                        Telefono:data.data.Telefono
                    },()=>{
                    });
                });
        }else{
        }
        
    }

    validarCambios(){
        var response = false;
        var key;
        var state = this.state;
        for( key in state){
            if(this.props[key] != this.state[key] && this.props[key] != undefined){
                response = true;
            }
        }
        return response;
    }
    render() {
        const { classes } = this.props;

        return (
                <thead  hidden={true}>
                    <b>{this.state.NombreReporte}</b> <br/>
               { 
               this.state.FechaFinal != undefined?<b>Periodo del <b>{this.state.FechaInicial}</b> { " al "} <b>{ this.state.FechaFinal}</b><br/></b>    
               :<div hidden={true}></div>
               }
               { 
                this.state.NombreArtista != undefined?<b>Nombre del artista: {this.state.NombreArtista}</b>
               :<div hidden={true}></div>
               }
                     <br/><b> RFC: {this.state.RFC}</b> <br/>  
                   <b> Número de teléfono: {this.state.Telefono}</b>
                </thead>

        )
    }
}

export default withStyles(headerLinksStyle)(InformacionEmpresa);