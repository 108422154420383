import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { CancelPresentation, Save, Clear } from '@material-ui/icons';

export default class ModalInformacion extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }
  componentDidMount() {

    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
            
              <div>  {this.props.Cuerpo}</div>
              <div> Usuario registro: {this.props.Usuario}</div>
             
              <GridItem>
                <Button size="sm" color="danger" round className="pull-right" onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  }
}