import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import { Upload, message, Icon } from 'antd';
import Danger from "components/Typography/Danger.jsx";
import FileBase64 from 'react-file-base64';
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ButtonAnt from 'antd/lib/button/button'
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import CookiesU from 'universal-cookie';
import Cargando from '../../../../components/Generales/ModalCargando'
import { isThisSecond } from 'date-fns';
const cookies = new CookiesU();

export default class ModalAgregarCategoriaDiseno extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreCategoria: '',
      _id: '',
      errorNombreCategoria: false,
      colorNotificacion: 'success',
      mensaje: '',
      base64:'',
      br: false,
      disable: true,
      Show:false
    }
    this.registrarCategoriaDiseño = this.registrarCategoriaDiseño.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.obtenerBaseImagen = this.obtenerBaseImagen.bind(this)
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      var imagenToRead =[Globales.Url+this.props.RutaImagen]
      console.log(imagenToRead)
      this.setState({
        NombreCategoria: this.props.NombreCategoria,
        NombreGuardado: this.props.NombreCategoria,
        _id: this.props._id,
        base64:imagenToRead[0]
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarCategoriaDiseño() {
    this.setState({Show:true});
    var valor1 = 0;
    
    if (this.state.NombreCategoria.trim() === '' || this.state.errorNombreCategoria == true) {
      this.setState({ errorNombreCategoria: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido" ,Show:false });
      this.showNotification();
      valor1 = 1;
    } else {
      this.setState({ errorNombreCategoria: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if(this.state.base64.trim() =="" &&  this.props.op != 1){
      this.setState({  colorNotificacion: 'danger', mensaje: "Adjuntar imagen" ,Show:false });
      this.showNotification();
      valor1 = 1;
    }
    if (valor1 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/ActualizarCategoriaDiseno', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.state._id, NombreCategoria: this.state.NombreCategoria, idUsuario: cookies.get('id'),base64:this.state.base64 })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              if (data.tipo) {
                this.setState({ colorNotificacion: 'danger',Show:false, errorNombreCategoria: true, mensaje: data.mensaje })
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'danger',Show:false, errorNombreCategoria: false, mensaje: data.mensaje });
                this.showNotification();
              }
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoria: '',Show:false, errorNombreCategoria: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/RegistrarCategoriaDiseno', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCategoria: this.state.NombreCategoria, idUsuario: cookies.get('id'),base64:this.state.base64 })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              if (data.tipo) {
                this.setState({ colorNotificacion: 'danger', errorNombreCategoria: true, mensaje: data.mensaje,Show:false })
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'danger', errorNombreCategoria: false, mensaje: data.mensaje,Show:false });
                this.showNotification();
              }
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoria: '', errorNombreCategoria: false ,Show:false});
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreCategoria') {
      //Validar si es una actualización
     
    }
  }
  //Funcion para validar que no se repita el NombreCategoria
  ValidarNombre(){
    this.setState({ disable: true });
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreCategoria) {
        fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCategoria: this.state.NombreCategoria })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCategoria: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreCategoria: false, disable: false});
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombreCategoria: false, disable: false });
      }
    } else {
      fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreCategoria: this.state.NombreCategoria })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreCategoria: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreCategoria: false, disable: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }
  obtenerBaseImagen(image){
    this.setState({
      base64:image.base64
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Categoría de Diseño"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onBlur:this.ValidarNombre.bind(this),
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreCategoria,
                  name: "NombreCategoria",
                  id: "NombreCategoria",
                  type: "text",
                  error: this.state.errorNombreCategoria
                }}
              />
              <div className={classes.typo + ' ' + 'mt-4'}>
                {this.state.errorImagen ?
                  <Danger>
                    <h5>Imagen</h5>
                  </Danger> : <h5>Imagen</h5>}
                {/* Imagen */}
              </div>
              <div>
                <FileBase64
                  multiple={false}
                  onDone={this.obtenerBaseImagen.bind(this)} 
                  
                  />
              </div>
              <div hidden={(this.state.base64 != ''?false:true)}>
              <img  src={this.state.base64} defaultValue="" style={{width:"300px"}}></img>
              </div>             

              <GridItem>
                  <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarCategoriaDiseño.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Cargando
                    show={this.state.Show}
                    Cuerpo="Cargando"
                />
      </div>
    );
  }
}