import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalAgregarTipoUsuario from "views/Modulos/Administracion/Usuarios/ModalAgregarTipoUsuario.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";

class TiposUsuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreTipoUsuario: '',
      idModulos: [],
      Titulo: '',
      tiposUsuarios: [],
      modulos: [],
      modulosAdministrativos: [],
      modulosInventarios: [],
      modulosProduccion: [],
      modulosReportes: [],
      modulosAdministrativosWebTerco: [],
      modulosMisProductos: [],
      modulosMisVentas: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombreTipoUsuario: false,
      errorIdModulos: false,
      tipoUsuariosMostrar: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 5;
    global.entrar = true;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarTipoUsuario = this.eliminarTipoUsuario.bind(this);
    this.filtrarTipoUsuario = this.filtrarTipoUsuario.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerTiposUsuarios();
    this.obtenerAllTiposUsuarios();
  }

  //Obtener todos los registros guardados
  obtenerAllTiposUsuarios() {
    fetch(Globales.Url + 'Usuario/TiposUsuarios/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ tiposUsuarios: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ tiposUsuarios: data.mensaje });
        }
      });

    fetch(Globales.Url + 'Usuario/Modulos/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ modulos: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ modulos: data.mensaje });
          //Separación de modulos y submódulos
          data.mensaje.map(mod => {
            if (mod.TipoModulo === 'Administracion') {
              if (this.state.modulosAdministrativos.length === 0) {
                this.state.modulosAdministrativos.push(mod)
              } else {
                var array1 = []
                this.state.modulosAdministrativos.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosAdministrativos.push(mod)
                }
              }
            } else if (mod.TipoModulo === 'Inventario') {
              if (this.state.modulosInventarios.length === 0) {
                this.state.modulosInventarios.push(mod)
              } else {
                var array1 = [];
                this.state.modulosInventarios.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosInventarios.push(mod)
                }
              }
            } else if (mod.TipoModulo === 'Produccion') {
              if (this.state.modulosProduccion.length === 0) {
                this.state.modulosProduccion.push(mod)
              } else {
                var array1 = [];
                this.state.modulosProduccion.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosProduccion.push(mod)
                }
              }
            } else if (mod.TipoModulo === 'Reportes') {
              if (this.state.modulosReportes.length === 0) {
                this.state.modulosReportes.push(mod)
              } else {
                var array1 = [];
                this.state.modulosReportes.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosReportes.push(mod)
                }
              }
            } else if (mod.TipoModulo === 'AdministracionWebTerco') {
              if (this.state.modulosAdministrativosWebTerco.length === 0) {
                this.state.modulosAdministrativosWebTerco.push(mod)
              } else {
                var array1 = [];
                this.state.modulosAdministrativosWebTerco.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosAdministrativosWebTerco.push(mod)
                }
              }
            } else if (mod.TipoModulo === 'MisProductos') {
              if (this.state.modulosMisProductos.length === 0) {
                this.state.modulosMisProductos.push(mod)
              } else {
                var array1 = [];
                this.state.modulosMisProductos.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosMisProductos.push(mod)
                }
              }
            } else if (mod.TipoModulo === 'MisVentas') {
              if (this.state.modulosMisVentas.length === 0) {
                this.state.modulosMisVentas.push(mod)
              } else {
                var array1 = [];
                this.state.modulosMisVentas.map(m => {
                  array1.push(Object.values(m)[0])
                });
                if (array1.indexOf(mod._id) === -1) {
                  this.state.modulosMisVentas.push(mod)
                }
              }
            }
          });
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerTiposUsuarios() {
    fetch(Globales.Url + 'Usuario/TiposUsuarios/ListaTiposUsuarios', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Obtuvo los registros correctamente
          this.setState({ tipoUsuariosMostrar: data.mensaje });
        }
      })
      .catch(err => console.error(err));
  }

  //Función de editar registro
  editarTipoUsuario(id, Nombre, idModulos) {
    this.setState({ _id: id, NombreTipoUsuario: Nombre, idModulos: idModulos, op: 1, showAgregar: true, Titulo: "Editar Módulo" });
  }

  //Función de eliminar registro
  eliminarTipoUsuario(id) {
    fetch(Globales.Url + 'Usuario/TiposUsuarios/BajaTipoUsuario', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification("tr");
        this.obtenerTiposUsuarios();
        this.limpiar();
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarTipoUsuario(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openModalTipoUsuario = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Tipo de Usuario", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalTipoUsuario = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Tipo de Usuario", NombreTipoUsuario: "", idModulos: "", op: 0 })
    this.obtenerTiposUsuarios();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para realizar el filtrado de búsqueda
  filtrarTipoUsuario() {
    var valor1 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreTipoUsuario.trim() == '') {
      this.setState({ errorNombreTipoUsuario: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", NombreTipoUsuario: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombreTipoUsuario: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) { //Hay campos requeridos
      this.showNotification();
    } else { //No hay campos requeridos
      fetch(Globales.Url + 'Usuario/TiposUsuarios/FiltrarTipoUsuario', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.mensaje.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning",tipoUsuariosMostrar:[], mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', tipoUsuariosMostrar: data.mensaje, entroFiltrar: true })
          }
        })
        .catch(err => console.error(err));
    }
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreTipoUsuario: '', entroFiltrar: false });
    global.Fin = 5;
    global.entrar = true;
    this.obtenerTiposUsuarios();
    this.obtenerAllTiposUsuarios();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      var mostrarMensaje = false;
      if(this.state.tiposUsuarios.length <= global.Fin){
        mostrarMensaje = true;
      }
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.tiposUsuarios.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerTiposUsuarios();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.tiposUsuarios.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.tiposUsuarios.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerTiposUsuarios();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (mostrarMensaje) {
        this.setState({ mensaje: 'No hay más tipos de usuarios que mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalAgregarTipoUsuario
          Titulo={this.state.Titulo}
          NombreTipoUsuario={this.state.NombreTipoUsuario}
          idModulos={this.state.idModulos}
          _id={this.state._id}
          Modulos={this.state.modulos}
          modulosAdministrativos={this.state.modulosAdministrativos}
          modulosInventarios={this.state.modulosInventarios}
          modulosProduccion={this.state.modulosProduccion}
          modulosReportes={this.state.modulosReportes}
          modulosAdministrativosWebTerco={this.state.modulosAdministrativosWebTerco}
          modulosMisProductos={this.state.modulosMisProductos}
          modulosMisVentas={this.state.modulosMisVentas}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalTipoUsuario}
          op={this.state.op}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Tipos de Usuarios</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Buscar tipo de usuario"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreTipoUsuario,
                        name: "NombreTipoUsuario",
                        id: "NombreTipoUsuario",
                        type: "text",
                        error: this.state.errorNombreTipoUsuario
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={() => this.filtrarTipoUsuario()}><Search />Buscar</Button>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.limpiar()}><Clear />Limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalTipoUsuario()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de tiposUsuarios */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <h4 className="pull-left">Lista de Módulos</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12">
                    <thead>
                      <th className="center">Nombre Tipo de Usuario</th>
                      <th className="pull-right">Acciones</th>
                    </thead>
                    <tbody>
                      {this.state.tipoUsuariosMostrar.map(TiposUsuarios => {
                        return (
                          <tr key={TiposUsuarios._id}>
                            <td>{TiposUsuarios.NombreTipoUsuario}</td>
                            <td className="pull-right">
                              <Button color="warning" round justIcon size="sm" onClick={() => this.editarTipoUsuario(TiposUsuarios._id, TiposUsuarios.NombreTipoUsuario, TiposUsuarios.idModulos)}><Edit /></Button>
                              <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(TiposUsuarios._id)}><Close /></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Tipo de Usuario"}
            Cuerpo={"¿Está seguro que desea eliminar el tipo de usuario?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(TiposUsuarios);