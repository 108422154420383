import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Danger from "components/Typography/Danger.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FileBase64 from 'react-file-base64';
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import Cargando from '../../../../components/Generales/ModalCargando'
import { isThisSecond } from 'date-fns';


export default class ModalAgregarElementoDiseno extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreElemento: '',
      idCategoria: '',
      Visibilidad: '',
      ImagenBase: [],
      _id: '',
      CategoriaElementos: [],
      errorNombre: false,
      errorIdCategoria: false,
      errorVisibilidad: false,
      colorNotificacion: 'success',
      NombreValidado:false,
      Show:false,
      mensaje: '',
      br: false,
      showImage:true,
      imageBase64:"",
      disable: true
    }
    this.registrarElementoDiseno = this.registrarElementoDiseno.bind(this);
    
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      //Es edicion ir por la imagen original
      var imagenToRead =[Globales.Url+this.props.RutaImagen]
      // imagenToRead.forEach((inex,asd)=>{
      //     console.log(inex)
      //     console.log(asd)
      // });
      this.obtenerImagenOriginalS3()
      this.setState({
        NombreElemento: this.props.NombreElemento,
        NombreGuardado: this.props.NombreElemento,
        idCategoria: this.props.idCategoria,
        Visibilidad: this.props.Visibilidad,
        showImage:false,
        //imageBase64:imagenToRead[0]
      },()=>{
        //console.log(this.state)
      });
    }
    this.setState({ CategoriaElementos: this.props.CategoriaElementos });
  }


  //Obtener el total de registros que se indican
  obtenerImagenOriginalS3() {
    fetch(Globales.Url + 'ElementosDiseno/ElementosDiseno/ObtenerImagenPegatinaOriginalTerco', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idPegatina: this.props._id })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          if (data.error) { //Error al obtener
            this.setState({ colorNotificacion: 'danger', mensaje: "No se pudo obtener la imagen" });
            this.showNotification();
          } else { //xObtuvo los registros correctamente
            
            let auxPeg = "data:image/png;base64," + data.data.pegatinaOriginal
              this.setState({ imageBase64: auxPeg })
          }
        })
        .catch(err => console.error(err));
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarElementoDiseno() {
    this.setState({Show:true})
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreElemento.trim() == '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", NombreElemento: '',Show:false});
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    if (this.state.idCategoria.trim() == '') {
      this.setState({ errorIdCategoria: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", idCategoria: '',Show:false });
      valor2 = 1;
    } else {
      this.setState({ errorIdCategoria: false });
      valor2 = 0;
    }
    if(this.state.Visibilidad.trim() == ""){
      this.setState({ errorVisibilidad: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", idCategoria: '',Show:false });
      valor2 = 1;
    }else{
      valor2 = 0;
    }
    if (this.state.imageBase64.length === 0) {
      this.setState({ errorImagen: true, colorNotificacion: 'danger', mensaje: "Completo el campo requerido", Visibilidad: '' ,Show:false});
      valor4 = 1;
    } else {
      this.setState({ errorImagen: false });
      valor4 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 + valor4+valor3 === 0) {
      this.setState({disable:true})
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'ElementosDiseno/ElementosDiseno/ActualizarElementosDiseno', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.props._id, NombreElemento: this.state.NombreElemento, idCategoria: this.state.idCategoria, ImagenBase: this.state.imageBase64, Visibilidad: this.state.Visibilidad })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable:false,Show:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreElemento: '',Show:false, idCategoria: '', Visibilidad: '', errorNombre: false, errorIdCategoria: false, errorVisibilidad: false, disable:false});
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'ElementosDiseno/ElementosDiseno/RegistrarElementosDiseno', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreElemento: this.state.NombreElemento, idCategoria: this.state.idCategoria, ImagenBase: this.state.imageBase64, Visibilidad: this.state.Visibilidad })
        }
        ).then(res => res.json())
          .then(data => {


            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable:false,Show:false});
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreElemento: '',Show:false, idCategoria: '', Visibilidad: '', errorNombre: false, errorIdCategoria: false, errorVisibilidad: false , disable:false});
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }
  ///Funcion para validar Nombre del elemento
  //this.registrarCategoriaDiseño();
  ValidarNombre(){
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreElemento) {
        fetch(Globales.Url + 'ElementosDiseno/ElementosDiseno/ValidarNombreActualizar', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreElemento: this.state.NombreElemento,_id:this.props._id })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true,disable:true,NombreValidado:false  });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false ,disable:false,NombreValidado:true });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombre: false, disable:false,NombreValidado:true });
      }
    } else {
      fetch(Globales.Url + 'ElementosDiseno/ElementosDiseno/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreElemento: this.state.NombreElemento })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true,disable:true,NombreValidado:false});
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false,disable:false,NombreValidado:true });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreElemento') {
      //Validar si es una actualización
     
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
    if(event.target.name == "idCategoria" || event.target.name == "Visibilidad"){
      this.setState({disable:false});
    }
  };

  obtenerBaseImagen(imagen) {
    var img = imagen;
    if(imagen.file.type =="image/png" || imagen.file.type =="image/jpeg"){
      this.setState({
        ImagenBase: img,
        showImage:false,
        imageBase64:imagen.base64
      });
    }else{
      this.setState({
        mensaje:"Solo se aceptan imagenes formato PNG y JPG",
        colorNotificacion:"danger"
      },()=>{
        imagen =null;
        this.showNotification()
      })
    }
   
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Elemento de Diseño"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  onBlur:this.ValidarNombre.bind(this),
                  value: this.state.NombreElemento,

                  name: "NombreElemento",
                  id: "NombreElemento",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <FormControl
                fullWidth
                className={classes.selectFormControl}
                error={this.state.errorIdCategoria}
              >
                <InputLabel htmlFor="cbbUnidadMedida" className={classes.selectLabel}>Categoría de Elementos de Diseño</InputLabel>
                <Select
                  value={this.state.idCategoria}
                  onChange={this.handleSimple}
                  name="idCategoria"
                  id="cbbUnidadMedida"
                  inputProps={{
                    error: this.state.errorIdCategoria
                  }}
                >
                  <MenuItem disabled={true}>Categoría</MenuItem>
                  {this.state.CategoriaElementos.map(elemento =>
                    <MenuItem value={elemento._id}>{elemento.NombreCategoria}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
                error={this.state.errorVisibilidad}
              >
                <InputLabel htmlFor="cbbUnidadMedida" className={classes.selectLabel}>Estatus</InputLabel>
                <Select
                  value={this.state.Visibilidad}
                  onChange={this.handleSimple}
                  name="Visibilidad"
                  inputProps={{
                    error: this.state.errorVisibilidad
                  }}
                >
                  <MenuItem disabled={true}>Estatus</MenuItem>
                  <MenuItem value="Visible">Visible</MenuItem>
                  <MenuItem value="Oculto">Oculto</MenuItem>
                </Select>
              </FormControl>
              {/* <div className={classes.space50} /> */}
              <div className={classes.typo + ' ' + 'mt-4'}>
                {this.state.errorImagen ?
                  <Danger>
                    <h5>Imagen</h5>
                  </Danger> : <h5>Imagen</h5>}
                {/* Imagen */}
              </div>
              <div>
                <FileBase64
                  // key
                  multiple={false}
                  onDone={
                    this.obtenerBaseImagen.bind(this)
                  }
                  />
              </div>
              <div hidden={false}>
              <img  src={this.state.imageBase64} defaultValue="" style={{width:"300px"}}></img>
              </div>
              <GridItem>
                <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarElementoDiseno.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
        <Cargando
                    show={this.state.Show}
                    Cuerpo="Cargando"
                />
      </div>
    );
  }
}