import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import AgregarDescuentos from "views/Modulos/Administracion/Descuentos/AgregarDescuentos.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";
import ReactLoading from "react-loading";

class Descuentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreDescuento: '',
      Descripcion: '',
      NombreDescuentoFiltro:'',
      errorNombreDescuentoFiltro:false,
      Vigencia: '',
      idArtistas: [],
      FechaInicial:"",
      FechaFinal:"",
      idClientes: [],
      CantidadUnidades: '',

      TipoDescuento: '',
      ProductoAplicar: '',
      Titulo: '',
      descuentos: [],
      clientes: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombreDescuentos: false,
      errorDescripcion: false,
      errorVigencia: false,
      erroridArtistas: false,
      erroridClientes: false,
      errorCantidadUnidades: false,
      errorTipoDescuentos: false,
      errorProductoAplicar: false,
      descuentosMostrar: [],
      entroFiltrar: false,
      notificacion: false,
      idsUsuariosClientesSeleccionados:[],
      idsUsuariosArtistasSeleccionados:[],
      Estatus:0,
      idsProductosTercoSeleccionados:[],
      idsDisenosArtistasSeleccionados:[],
      ImporteDescuento:0,
      CodigoDescuentoFiltro:0,
      errorCodigoDescuentoFiltro:false,
      PorcentajeDescuento:0
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 5;
    global.entrar = true;
    global.response = null;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.obtenerFechaConFormatoListado = this.obtenerFechaConFormatoListado.bind(this); 
    this.eliminarDescuento = this.eliminarDescuento.bind(this);
    this.filtrarDescuentos = this.filtrarDescuentos.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerDescuentosFin();
    this.obtenerAllDescuentos();
    this.obtenerClientes();
  }

  //Obtener todos los registros guardados
  obtenerAllDescuentos() {
    fetch(Globales.Url + 'Descuentos/Descuentos/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ descuentos: [] });
        } else { //Obtuvo los registros correctamente
          if(data.data != null){
            this.setState({ descuentos: data.data });
          }else{
            this.setState({ descuentos: [] });
          }
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerDescuentosFin() {
    fetch(Globales.Url + 'Descuentos/Descuentos/ListaDescuentos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else { //Obtuvo los registros correctamente
            if(data.data != null){
              this.setState({ descuentosMostrar: data.data });
            }else{
              this.setState({ descuentosMostrar: [] });
            }
          }
        })
        .catch(err => console.error(err));
    });
  }

  obtenerClientes() {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ clientes: [] });
        } else { //Obtuvo los registros correctamente
          if(data.data != null){
            this.setState({ clientes: data.data });
          }else{
            this.setState({ clientes: [] });
          }
        }
      });
  }

  //Función de editar registro
  editarDescuento(id, NombreDescuento, Descripcion, FechaInicial, FechaFinal, CantidadUnidades, idsUsuariosClientesSeleccionados, idsUsuariosArtistasSeleccionados, idsProductosTercoSeleccionados, idsDisenosArtistasSeleccionados,
    TipoDescuento, ImporteDescuento, PorcentajeDescuento,Estatus) {
    this.setState({
      _id: id, NombreDescuento: NombreDescuento, Descripcion: Descripcion, FechaInicial: FechaInicial, FechaFinal: FechaFinal, 
      idsUsuariosClientesSeleccionados: idsUsuariosClientesSeleccionados,
      idsUsuariosArtistasSeleccionados: idsUsuariosArtistasSeleccionados,
      idsProductosTercoSeleccionados: idsProductosTercoSeleccionados,
      idsDisenosArtistasSeleccionados: idsDisenosArtistasSeleccionados,
      TipoDescuento: TipoDescuento,
      CantidadUnidades:CantidadUnidades,
      op: 1,
      ImporteDescuento:ImporteDescuento,
      showAgregar: true,
      Titulo: "Editar Descuento",
      PorcentajeDescuento:PorcentajeDescuento,
      Estatus:Estatus
    });
  }

  // Función de eliminar registro
  eliminarDescuento(id) {
    fetch(Globales.Url + 'Descuentos/Descuentos/BajaDescuento', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification();
        this.obtenerDescuentosFin();
        this.limpiar();
      });
  }

  // Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  // Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }else{
      //Eliminar el elemento
      this.eliminarDescuento(this.seleccionado);
    }
  }

  //Función para abrir el modal de agregar registro
  openModalDescuento = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Descuento", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalDescuento = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Descuento", NombreDescuento: "", op: 0 })
    this.obtenerDescuentosFin();
    this.obtenerAllDescuentos();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
      this.limpiar();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    if(name == "CodigoDescuentoFiltro"){
      if(value >=0){
        this.setState({ [name]: value });
      }
    }else{
      this.setState({ [name]: value });
    }
  }

  //Función para obtener el id del combo
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  //FiltrarDescuentos
  filtrarDescuentos(){
    fetch(Globales.Url + 'Descuentos/Descuentos/FiltrarDescuentos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ NombreDescuento: this.state.NombreDescuentoFiltro,CodigoDescuento:this.state.CodigoDescuentoFiltro })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else { //Obtuvo los registros correctamente
            if(data.data != null){
              this.setState({ descuentosMostrar: data.data });
            }else{
              this.setState({ descuentosMostrar: [] });
            }
          }
        })
        .catch(err => console.error(err));
    });
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreDescuento: '', Descripcion: '', entroFiltrar: false,NombreDescuentoFiltro:'',CodigoDescuentoFiltro:'' });
    global.Fin = 5;
    global.entrar = true;
    global.response = null;
    this.obtenerDescuentosFin();
    this.obtenerAllDescuentos();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.descuentos.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerDescuentosFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.descuentos.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.descuentos.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerDescuentosFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más descuentos para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  obtenerFechaConFormatoListado(fecha){
    return new Date(new Date(fecha).setDate(new Date(fecha).getDate()+2)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
  }
  
  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <AgregarDescuentos
          Titulo={this.state.Titulo}
          NombreDescuento={this.state.NombreDescuento}
          Descripcion={this.state.Descripcion}
          FechaFinal={this.state.FechaFinal}
          FechaInicial={this.state.FechaInicial}
          idsUsuariosClientesSeleccionados={this.state.idsUsuariosClientesSeleccionados}
          idsUsuariosArtistasSeleccionados={this.state.idsUsuariosArtistasSeleccionados}
          idsProductosTercoSeleccionados={this.state.idsProductosTercoSeleccionados}
          idsDisenosArtistasSeleccionados={this.state.idsDisenosArtistasSeleccionados}
          TipoDescuento={this.state.TipoDescuento}
          CantidadUnidades={this.state.CantidadUnidades}
          _id={this.state._id}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalDescuento}
          op={this.state.op}
          PorcentajeDescuento={this.state.PorcentajeDescuento}
          classes={this.props}
          ImporteDescuento={this.state.ImporteDescuento}
          Estatus={this.state.Estatus}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem  xs={12} sm={12} md={10}>
          <Card >
            <CardHeader color="info">
              Descuentos
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Nombre descuento"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreDescuentoFiltro,
                        name: "NombreDescuentoFiltro",
                        id: "NombreDescuentoFiltro",
                        type: "text",
                        error: this.state.errorNombreDescuentoFiltro
                      }}
                      className="mt-5"
                    />
                    <CustomInput
                      labelText="Código de descuento"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.CodigoDescuentoFiltro,
                        name: "CodigoDescuentoFiltro",
                        id: "CodigoDescuentoFiltro",
                        type: "number",
                        error: this.state.errorCodigoDescuentoFiltro
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
              <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={() => this.filtrarDescuentos()}><Search />Buscar</Button>
              <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.limpiar()}><Clear />Limpiar</Button>
              
              <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
              <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalDescuento()}><AddCircle />Agregar</Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              
            </GridItem>
              </GridContainer>
           
            </CardBody>
          </Card>
          </GridItem>
        </GridContainer>



        <GridContainer justify="center"> 
          {/* Tabla de descuentos */}
        
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de Descuentos</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                  <div className="table-wrapper-scroll-y" hidden={!this.state.descuentosMostrar.length >0}>
                    <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>
                        <th className="center">Código</th>
                        <th className="center">Nombre Descuento</th>
                        <th className="center">Vigencia</th>
                        <th className="center">Cantidad de Unidades</th>
                        <th className="center">Estado</th>
                        <th className="pull-right">Acciones</th>
                      </thead>
                      <tbody>
                        {this.state.descuentosMostrar.map(Descuentos => {
                          return (
                            <tr key={Descuentos._id}>
                              <td>{Descuentos.Codigo}</td>
                              <td>{Descuentos.NombreDescuento}</td>
                              <td>{this.obtenerFechaConFormatoListado(Descuentos.FechaFinal)}</td>
                              <td>{Descuentos.CantidadUnidades}</td>
                              <td>{Descuentos.Estatus==2?"Vencido":"Activo"}</td>
                              <td className="pull-right">
                                <Button color="warning" round justIcon size="sm" onClick={() => this.editarDescuento(
                                  Descuentos._id,
                                  Descuentos.NombreDescuento,
                                  Descuentos.Descripcion,
                                  Descuentos.FechaInicial,
                                  Descuentos.FechaFinal,
                                  Descuentos.CantidadUnidades,
                                  Descuentos.idsUsuariosClientesSeleccionados,
                                  Descuentos.idsUsuariosArtistasSeleccionados,
                                  Descuentos.idsProductosTercoSeleccionados,
                                  Descuentos.idsDisenosArtistasSeleccionados,
                                  Descuentos.TipoDescuento,
                                  Descuentos.ImporteDescuento,
                                  Descuentos.PorcentajeDescuento,
                                  Descuentos.Estatus
                                )}><Edit /></Button>
                                <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(Descuentos._id)}><Close /></Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div> 
                  <div  hidden={this.state.descuentosMostrar.length >0}>
                        <h3 style={{textAlign:"center"}}>
                              No hay información para mostrar
                        </h3>
                  </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Descuento"}
            Cuerpo={"¿Está seguro que desea eliminar el descuento?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Descuentos);