import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import Danger from "components/Typography/Danger.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { SwatchesPicker } from 'react-color'
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import Globales from "utils/Globales";
import { Save, Clear, Publish } from '@material-ui/icons';
import { createStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Checkbox from "@material-ui/icons/CheckBox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Loader from 'react-loader-spinner';
const useStyles = createStyles(styles);

export default class ModalAgregarEstatusPedido extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      NombreEstatus: '',
      Dias: '',
      Color: '',
      _id: '',
      errorNumeroConsecutivo: false,
      MostrarModal: false,
      StringColor: '',
      showPaletaDeColores: true,
      textoBotonColor: "Seleccionar color...",
      Retraso: "true",
      errorNombre: false,
      NumeroConsecutivo: '',
      errorDias: false,
      errorColor: true,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disable: false,
      checked: [],
      errorImagenEstatus: false,
      ImagenEstatusSubida: false,
      NombreImagenEstatus: "",
      ImagenEstatus : "",

    }
    this.registrarEstatusPedido = this.registrarEstatusPedido.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this)
    this.closeModal = this.closeModal.bind(this);
    this.state.DeshacerSeleccionColor = this.DeshacerSeleccionColor.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.validarSoloNumero = this.validarSoloNumero.bind(this);
    this.getFiles = this.getFiles.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  handleToggle = value => {
    console.log(value)
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    console.log(this.props)
    if (this.props.op == 1) {
      this.setState({
        NombreEstatus: this.props.NombreEstatus,
        NombreGuardado: this.props.NombreEstatus,
        Dias: this.props.Dias,
        _id: this.props._id,
        Color: this.props.Color,
        showPaletaDeColores: true,
        Retraso: this.props.Retraso.toString(),
        NumeroConsecutivo: this.props.NumeroConsecutivo,
        textoBotonColor: "Cambiar color",
        ImagenEstatus: this.props.Icono
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarEstatusPedido() {
    this.setState({
      MostrarModal: true,
      disable: true
    })
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreEstatus.trim() == '' || this.state.errorNombre == true) {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", NombreEstatus: '', MostrarModal: false, disable: false });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    if (this.state.Dias == 0) {
      this.setState({ errorDias: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", Dias: '', MostrarModal: false, disable: false });
      valor2 = 1;
    } else {
      this.setState({ errorDias: false });
      valor2 = 0;
    }
    if (this.state.Color.trim() == '') {
      this.setState({ errorColor: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", Color: '', MostrarModal: false, disable: false });
      valor3 = 1;
    } else {
      this.setState({ errorColor: false });
      valor3 = 0;
    }
    if (this.state.NumeroConsecutivo == '' || this.state.NumeroConsecutivo == 0) {
      var mensaje = "";
      if (this.state.NumeroConsecutivo == 0) {
        mensaje = "El consecutivo debe ser mayor a cero"
      } else {
        mensaje = "Complete el campo requerido"
      }
      this.setState({ errorNumeroConsecutivo: true, colorNotificacion: 'danger', mensaje: mensaje, Color: '', MostrarModal: false, disable: false });
      valor4 = 1;
    } else {
      this.setState({ errorNumeroConsecutivo: false });
      valor4 = 0;
    }
    if(this.state.ImagenEstatus == '' || this.state.ImagenEstatus == null){
      valor5 = 1;
      mensaje = "Es necesario subir una imagen del estatus";
      this.setState({ errorImagenEstatus: true ,colorNotificacion: 'danger', mensaje: mensaje, Color: '', MostrarModal: false, disable: false });
    }else{
      this.setState({ errorImagenEstatus: false });
      valor5 = 0;
    }
    console.log(this.state.ImagenEstatusSubida)
    console.log(this.state.ImagenEstatus)
    console.log(this.state.NombreImagenEstatus)
    //Validar si hay campos requeridos
    if (valor1 > 0 || valor2 > 0 || valor3 > 0 || valor4 > 0 || valor5 > 0) {
      this.setState({ colorNotificacion: 'danger', mensaje: "Complete el campo requerido", MostrarModal: false, disable: false });
      this.showNotification("tr");
    } else {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        console.log(this.state)
        fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ActualizarEstatusPedidos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {

            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModal: false, disable: false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreEstatus: '', MostrarModal: false, disable: false, Dias: '', Color: '', errorNombre: false, errorDias: false, errorColor: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/RegistrarEstatusPedidos', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {


            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, MostrarModal: false, disable: false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreEstatus: '', MostrarModal: false, disable: false, Dias: '', Color: '', errorNombre: false, errorDias: false, errorColor: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }
  DeshacerSeleccionColor() {
    this.setState({
      Color: '',
      showPaletaDeColores: false
    })
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  validarSoloNumero = (e) => {
    const pattern = /^[0-9]+$/;
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  }

  handleChangeComplete = (color) => {
    this.setState({ Color: color.hex, showPaletaDeColores: true });
  };
  handleChange(e) {
    const { name, value } = e.target;
/*     if (value.includes('e')) {
      return;
    } */
    this.setState({
      [name]: value
    });
    if (name === 'NombreEstatuws') {
      //Validar si es una actualización
      if (this.props.op === 1) {
        //Validar si se ingresa otro nombre
        if (this.state.NombreGuardado !== value) {
          fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ValidarNombre', {
            method: 'POST',
            headers: {
              Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            }
            , body: JSON.stringify({ NombreEstatus: value })
          }
          ).then(res => res.json())
            .then(data => {
              if (data.error) {
                this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
                this.showNotification();
              } else {
                this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
                this.showNotification();
              }
            })
            .catch(err => console.error(err));
        } else {
          this.setState({ errorNombre: false, disable: false });
        }
      } else {
        fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreEstatus: value })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
              this.showNotification();
            }
          })
          .catch(err => console.error(err));
      }

    }
  }

  validarNombre(value) {
    if (value.target.value != "") {
      fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreEstatus: value.target.value })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }


  validarNumeroConsecutivo(value) {
    if (value.target.value != "") {
      fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ValidarNumeroConsecutivo', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NumeroConsecutivo: value.target.value })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNumeroConsecutivo: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNumeroConsecutivo: false, disable: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  getFiles(event) {
    var origen = event.target.name;
    this.setState({
      ImagenEstatusSubida: true
    })
    var preview = document.getElementById(origen + "1");
    var file = document.getElementById(origen).files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      var base64 = reader.result;
      if (origen == "ImagenEstatus") {
        preview.src = base64;        
        this.setState({ NombreImagenEstatus: file.name, ImagenEstatus: base64 });
      }      
      this.setState({
        [origen + "Subida"]: false
      }, () => {
      })
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      preview.src = "";
    }
  }



  render() {
    const classes = this.props


    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>

              <CustomInput
                labelText="Nombre Estatus de Producción"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreEstatus,
                  onBlur: this.validarNombre.bind(this),
                  name: "NombreEstatus",
                  id: "NombreEstatus",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <CustomInput
                labelText="Días"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  value: this.state.Dias,
                  name: "Dias",
                  onChange: this.handleChange.bind(this),
                  onKeyPress: (event) => {
                    if (event.key === 'e') {
                      event.preventDefault(); // Prevenir la acción si la tecla es "e"
                    }
                  },
                  id: "Dias",
                  type: "number",
                  error: this.state.errorDias

                }}
              />
              <CustomInput
                labelText="Número consecutivo"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.NumeroConsecutivo,
                  name: "NumeroConsecutivo",
                  id: "NumeroConsecutivo",
                  onBlur: this.validarNumeroConsecutivo.bind(this),
                  onKeyPress: this.validarSoloNumero.bind(this),
                  type: "number",
                  error: this.state.errorNumeroConsecutivo
                }}
              />
              <div style={{ alignContent: 'center' }}>
                <span style={{ fontSize: "18px" }}>
                  Notificar retraso
                </span>
                <br />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.Retraso === "true"}
                      onChange={this.handleChange}
                      value={"true"}
                      name="Retraso"
                      color="primary"
                      aria-label='A'
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label='Sí'
                />
                <FormControlLabel
                  control={
                    <Radio

                      checked={this.state.Retraso === "false"}
                      onChange={this.handleChange}
                      value={"false"}
                      name="Retraso"
                      aria-label='B'
                      color='primary'

                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label='No'
                />


              </div>
              <GridItem >
                <div hidden={this.state.showPaletaDeColores}>
                  Selecciona tu color
                  <SwatchesPicker width="-100px" onChangeComplete={this.handleChangeComplete} />
                </div>
              </GridItem>
              <div>
                <CustomInput
                  labelText="Color"
                  formControlProps={{
                    fullWidth: false
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange.bind(this),
                    value: this.state.Color,
                    name: "Color",
                    disabled: true,
                    id: "StringColor",
                    type: "string",
                    error: this.state.errorColor
                  }}
                />
                <div hidden={!this.state.showPaletaDeColores}>
                  <div style={{ backgroundColor: this.state.Color }}>&nbsp;<br></br></div>
                  <div><Button size="sm" onClick={this.DeshacerSeleccionColor.bind(this)} color="info">{(this.state.Color != "" ? "Cambiar color" : "Seleccionar corlor...")}</Button></div>
                </div>

                {/* <div hidden={!this.state.showPaletaDeColores} style={{backgroundColor:this.state.StringColor}}></div> */}
                {/* <Button>Cambiar de color</Button> */}
              </div>
              <GridItem xs={12} sm={12} md={12} lg={12} formControlProps={{ fullWidth: true }}>
                {this.state.errorImagenEstatus ?
                  <div className={classes.typo}>
                    <Danger>
                      <h5>Imagen del estatus</h5>
                    </Danger> </div> :
                  <div className={classes.typo}>
                    <h5>Imagen del estatus</h5>
                  </div>
                }
                <div id="prueba1">
                  <input id="ImagenEstatus" name="ImagenEstatus" className="inputfile" type="file" accept='.png,.jpg,.jpeg' onChange={this.getFiles.bind(this)} />
                  {
                    this.state.ImagenEstatusSubida == true ?
                      <label for="ImagenEstatus"><Publish />
                        Subiendo
                        &nbsp; <Loader style={{ float: 'right' }} visible={true} type="Oval" color="#212121" height={12} width={12} />
                      </label>
                      :
                      <label for="ImagenEstatus"><Publish /> Seleccione</label>
                  }
                  <label style={{ color: 'black' }}>{this.state.NombreImagenEstatus}</label>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <img width="125" height="125" id="ImagenEstatus1" src={this.state.ImagenEstatus !=null ? this.state.ImagenEstatus : ""} alt="Esperando imagen..." />
                <label style={{ color: 'black', maxWidth: '170px' }}>{this.state.NombreImagenEstatus}</label>
              </GridItem>
              {/* <CustomInput
                labelText="Color"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ 
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.Color,
                  name: "Color",
                  id: "Color",
                  type: "text",
                  error: this.state.errorColor
                }}
              /> */}
              {/* <div style={popover}>
                                      <div style={cover} onClick={this.handleClose} />
                                      <SwatchesPicker onChangeComplete={this.handleChangeComplete} />
                                    </div> */}
              <GridItem>
                {this.state.disable ? <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round><Save />&nbsp;GUARDAR</Button> :
                  <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarEstatusPedido.bind(this)}><Save />&nbsp;GUARDAR</Button>}
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
            show={this.state.MostrarModal}
            Cuerpo="Cargando"
          />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}