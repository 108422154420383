// core components/views for Production layout
import Inventario from 'views/Modulos/Inventario/Inventario/Inventario'
// @material-ui/icons
import { Streetview, Grain, ScatterPlot, Assignment, BarChart, Whatshot, MergeType, Home,Web, Menu } from "@material-ui/icons";
import CookiesU from 'universal-cookie'
const cookies = new CookiesU();

const dashboardRoutes = [

  {
    path: "/admininventario",
    name: "Administración de inventario",
    icon: Assignment,
    component: Inventario,
    layout: "/inventario",
    idModulo:"5d08227e198ea43abc040dc4"
  }
];



var dashboardRoutesAccess = [];
for(var keyRoutes  = 0; keyRoutes < dashboardRoutes.length; keyRoutes++){
  if(cookies.get('TipoUsuario') !== undefined){
    if(cookies.get('TipoUsuario').idModulos.includes(dashboardRoutes[keyRoutes].idModulo) == true){
      dashboardRoutesAccess.push(dashboardRoutes[keyRoutes]);
    }
  }
}


export default dashboardRoutesAccess;