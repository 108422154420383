import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

class ModalAgregarTipoUsuario extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      checked: [24, 22],
      NombreTipoUsuario: '',
      idModulos: [],
      _id: '',
      modulosAdministrativos: [],
      modulosInventarios: [],
      MostrarModal:false,
      modulosProduccion: [],
      modulosReportes: [],
      modulosAdministrativosWebTerco: [],
      modulosMisProductos: [],
      modulosMisVentas: [],
      errorNombreTipoUsuario: false,
      disabled:false,
      errorIdModulos: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false
    }
    this.registrarTipoUsuarios = this.registrarTipoUsuarios.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        _id: this.props._id,
        NombreTipoUsuario: this.props.NombreTipoUsuario,
        idModulos: this.props.idModulos
      })
    }
    this.setState({
      modulosAdministrativos: this.props.modulosAdministrativos,
      modulosInventarios: this.props.modulosInventarios,
      modulosProduccion: this.props.modulosProduccion,
      modulosReportes: this.props.modulosReportes,
      modulosAdministrativosWebTerco: this.props.modulosAdministrativosWebTerco,
      modulosMisProductos: this.props.modulosMisProductos,
      modulosMisVentas: this.props.modulosMisVentas
    })
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarTipoUsuarios() {
    var valor1 = 0;
    var valor2 = 0;
    this.setState({
      MostrarModal:true,
      disabled:true
    });
    //Validar que el input no este vacio
    if (this.state.NombreTipoUsuario.trim() == '') {
      this.setState({ errorNombreTipoUsuario: true, colorNotificacion: 'danger', mensaje: "Complete el campo del nombre",MostrarModal:false,disabled:false });
      valor1 = 1;
      this.showNotification();
    } else {
      this.setState({ errorNombreTipoUsuario: false });
      valor1 = 0;
    }
    if (this.state.idModulos.length === 0) {
      this.setState({ errorIdModulos: true, colorNotificacion: 'danger', mensaje: "Agregue por lo menos un módulo",MostrarModal:false,disabled:false });
      valor2 = 1;
      this.showNotification();

    } else {
      this.setState({ errorIdModulos: false ,MostrarModal:false,disabled:false});
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 === 0) {
      if (this.props.op == 1) {
        fetch(Globales.Url + 'Usuario/TiposUsuarios/ActualizarTipoUsuarios', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,MostrarModal:false,disabled:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreTipoUsuario: '', idModulos: '', errorNombreTipoUsuario: false, errorIdModulos: false,MostrarModal:false,disabled:false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Usuario/TiposUsuarios/RegistrarTipoUsuarios', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            console.log(data)
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,MostrarModal:false,disabled:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreTipoUsuario: '', idModulos: [], errorNombreTipoUsuario: false, errorIdModulos: false,MostrarModal:true,disabled:false },()=>{
                setTimeout(()=>{
                  this.closeModal(data.mensaje, this.state.colorNotificacion);
                },2000);
              });
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleToggle(value) {
    //Validación si existe el módulo
    if (this.state.idModulos.indexOf(value.trim()) === -1) { //Agregar Módulo
      this.state.idModulos.push(value)
      this.setState({ a: '' })
    } else {
      var posicionModulo = this.state.idModulos.findIndex(f => f === value)
      this.state.idModulos.splice(posicionModulo, 1);
      this.setState({ a: '' })
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                  labelText="Nombre Tipo Usuario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange.bind(this),
                    value: this.state.NombreTipoUsuario,
                    name: "NombreTipoUsuario",
                    id: "NombreTipoUsuario",
                    type: "text",
                    error: this.state.errorNombreTipoUsuario
                  }}
                />
              </GridItem>
              {/* Módulo Administrativo */}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.title}>
                  <h5>Módulos Administrativos</h5>
                </div>
              </GridItem>
              <GridContainer>
                {this.state.modulosAdministrativos.map(mod => {
                  return (
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // tabIndex={-1}
                              checked={
                                this.state.idModulos.indexOf(mod._id) !== -1 ? true : null
                              }
                              onClick={() => this.handleToggle(mod._id)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{ label: classes.label }}
                          label={mod.NombreModulo}
                        />
                      </div>
                    </GridItem>

                  )
                })}
              </GridContainer>
              {/* Módulo de Inventarios */}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.title}>
                  <h5>Módulos de Inventarios</h5>
                </div>
              </GridItem>
              <GridContainer>
                {this.state.modulosInventarios.map(mod => {
                  return (
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // tabIndex={-1}
                              checked={
                                this.state.idModulos.indexOf(mod._id) !== -1 ? true : null
                              }
                              onClick={() => this.handleToggle(mod._id)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{ label: classes.label }}
                          label={mod.NombreModulo}
                        />
                      </div>
                    </GridItem>

                  )
                })}
              </GridContainer>
              {/* Módulos de Producción */}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.title}>
                  <h5>Módulos de Producción</h5>
                </div>
              </GridItem>
              <GridContainer>
                {this.state.modulosProduccion.map(mod => {
                  return (
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // tabIndex={-1}
                              checked={
                                this.state.idModulos.indexOf(mod._id) !== -1 ? true : null
                              }
                              onClick={() => this.handleToggle(mod._id)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{ label: classes.label }}
                          label={mod.NombreModulo}
                        />
                      </div>
                    </GridItem>

                  )
                })}
              </GridContainer>
              {/* Módulos de Reportes */}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.title}>
                  <h5>Módulos de Reportes</h5>
                </div>
              </GridItem>
              <GridContainer>
                {this.state.modulosReportes.map(mod => {
                  return (
                    <GridItem xs={6} sm={6} md={6} lg={6}>
                      <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // tabIndex={-1}
                              checked={
                                this.state.idModulos.indexOf(mod._id) !== -1 ? true : null
                              }
                              onClick={() => this.handleToggle(mod._id)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{ label: classes.label }}
                          label={mod.NombreModulo}
                        />
                      </div>
                    </GridItem>
                  )
                })}
              </GridContainer>
              <GridItem>
                <Button tabIndex="0" size="sm" disabled={this.state.disabled} color="info" className="pull-right" round onClick={this.registrarTipoUsuarios.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAgregarTipoUsuario);