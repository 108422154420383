import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import withStyles from "@material-ui/core/styles/withStyles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import { Save, Clear } from '@material-ui/icons';

// class ModalAgregarMaterial extends Component {
export default class ModalAgregarMaterial extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      idMateriaPrima: '',
      idUnidadMedida: '',
      NombreMaterial: '',
      DescripcionMaterial: '',
      CostoUnidad: '',
      Comportamiento: '',
      Existencia: 0,
      ExistenciaMinima: '',
      MostrarModal:false,
      NombreUnidadMedida: '',
      NombreMateriaPrima: '',
      _id: '',
      UnidadesMedidas: [],
      MateriasPrimas: [],
      errorNombreMaterial: false,
      errorDescripcionMaterial: false,
      errorCostoUnidad: false,
      errorComportamiento: false,
      errorMateriaPrima: false,
      errorUnidadMedida: false,
      errorExistencia: false,
      errorExistenciaMinima: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      desactivar: false,
      disable: true,
    }
    global.NombreUnidadMedida = '';
    global.NombreMateriaPrima = '';
    this.registrarMaterial = this.registrarMaterial.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick('esc', 'esc');
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.setState({
      UnidadesMedidas: this.props.UnidadesMedidas,
      MateriasPrimas: this.props.MateriasPrimas,
      desactivar: true
    });
    //Verificar si es editar
    if (this.props.op === 1) {
      this.setState({
        _id: this.props._id,
        idMateriaPrima: this.props.idMateriaPrima,
        idUnidadMedida: this.props.idUnidadMedida,
        NombreMaterial: this.props.NombreMaterial,
        NombreGuardado: this.props.NombreMaterial,
        DescripcionMaterial: this.props.DescripcionMaterial,
        CostoUnidad: this.props.CostoUnidad,
        Comportamiento: this.props.Comportamiento,
        Existencia: this.props.Existencia,
        ExistenciaMinima: this.props.ExistenciaMinima,
      });
      this.obtenerNombreUnidad();
    }
  }

  obtenerNombreUnidad() {
    var idUnidad = "";
    var nombreUnidadMedida = "";

    //Obteniendo el nombre de la unidad de medida
    this.props.MateriasPrimas.map(materiasPrimas => {
      if (this.props.idMateriaPrima === materiasPrimas._id) {
        idUnidad = materiasPrimas.idUnidadMedida;
      }
    });
    //Recorrriendo las unidades de medida para obtener el nombre
    this.props.UnidadesMedidas.map(unidades => {
      if (idUnidad === unidades._id) {
        nombreUnidadMedida = unidades.NombreUnidad
      }
    });
    global.NombreUnidadMedida = nombreUnidadMedida
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  registrarMaterial() {
    this.setState({disable:true,MostrarModal:true})
    var valor1 = 0;
    var valor3 = 0;
    var valor4 = 0;
    var valor5 = 0;
    var valor6 = 0;
    var valor8 = 0;
    //Validar input de los parametros
    if (this.state.NombreMaterial.trim() === ''|| this.state.errorNombreMaterial == true) {
      this.setState({ errorNombreMaterial: true, colorNotificacion: 'danger', mensaje: "Complete los campos requeridos" ,disable:false,MostrarModal:false});
      this.showNotification("tr");
      valor1 = 1;
    } else {
      this.setState({ errorNombreMaterial: false });
      valor1 = 0;
    }
    if (this.state.idUnidadMedida.trim() === '') {
      this.setState({ errorUnidadMedida: true, colorNotificacion: 'danger', mensaje: "Complete los campos requeridos",disable:false,MostrarModal:false });
      this.showNotification("tr");
      valor3 = 1;
    } else {
      this.setState({ errorUnidadMedida: false });
      valor3 = 0;
    }
    if (this.state.CostoUnidad === '') {
      this.setState({ errorCostoUnidad: true, colorNotificacion: 'danger', mensaje: "Complete los campos requeridos",disable:false,MostrarModal:false });
      this.showNotification("tr");
      valor4 = 1;
    } else {
      this.setState({ errorCostoUnidad: false });
      valor4 = 0;
    }
    if (this.state.idMateriaPrima.trim() === '') {
      this.setState({ errorMateriaPrima: true, colorNotificacion: 'danger', mensaje: "Complete los campos requeridos" ,disable:false,MostrarModal:false});
      this.showNotification("tr");
      valor5 = 1;
    } else {
      this.setState({ errorMateriaPrima: false });
      valor5 = 0;
    }
    
    if (this.state.ExistenciaMinima === '') {
      this.setState({ errorExistenciaMinima: true, colorNotificacion: 'danger', mensaje: "Complete los campos requeridos" ,disable:false,MostrarModal:false});
      this.showNotification("tr");
      valor8 = 1;
    } else {
      this.setState({ errorExistenciaMinima: false });
      valor8 = 0;
    }
    //Validar si por lo menos uno cambio a true
    if (valor1 + valor3 + valor4 + valor5 + valor6 + valor8 === 0) {
      this.setState({disable:true})
      if (this.state._id) {
        fetch(Globales.Url + 'Material/Material/ActualizarMaterial', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger',MostrarModal:false, mensaje: data.mensaje, errorNombreMaterial: true,disable:false, errorDescripcionMaterial: true, errorCostoUnidad: true, errorComportamiento: true, errorMateriaPrima: true, errorUnidadMedida: true, errorExistencia: true, errorExistenciaMinima: true });
              this.showNotification("tr");
              this.obtenerMaterial();
            } else {
              this.setState({ colorNotificacion: 'success',MostrarModal:false, idMateriaPrima: '', NombreMaterial: '',disable:false, DescripcionMaterial: '', CostoUnidad: '', Comportamiento: '', Existencia: 0, ExistenciaMinima: '', NombreUnidadMedida: '', mensaje: data.mensaje, errorNombreMaterial: false, errorDescripcionMaterial: false, errorCostoUnidad: false, errorComportamiento: false, errorMateriaPrima: false, errorUnidadMedida: false, errorExistencia: false, errorExistenciaMinima: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Material/Material/RegistrarMaterial', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify(this.state)
        }
        ).then(res => res.json())
          .then(data => {
          console.log("🍺 / file: ModalAgregarMaterial.jsx / line 199 / data", data)
            if (data.error) {
              this.setState({ colorNotificacion: 'danger',MostrarModal:false, errorNombreMaterial: true,disable:false, errorDescripcionMaterial: true, errorCostoUnidad: true, errorComportamiento: true, errorMateriaPrima: true, errorUnidadMedida: true, errorExistencia: true, errorExistenciaMinima: true, mensaje: data.mensaje });
              this.showNotification("tr");
            } else {
              this.setState({ colorNotificacion: 'success',MostrarModal:false, errorNombreMaterial: false,disable:false, errorDescripcionMaterial: false, errorCostoUnidad: false, errorComportamiento: false, errorMateriaPrima: false, errorUnidadMedida: false, errorExistencia: false, errorExistenciaMinima: false, mensaje: data.mensaje, idMateriaPrima: '', NombreMaterial: '', DescripcionMaterial: '', CostoUnidad: '', Comportamiento: '', Existencia: 0, ExistenciaMinima: '', NombreUnidadMedida: '' });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreMaterial') {
     
    }
  }

  ///Funcion Que Valida El Nombre
   ValidarNombre(){
     //Validar si es una actualización
     this.setState({ disable:true });
     if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreMaterial) {
        fetch(Globales.Url + 'Material/Material/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreMaterial: this.state.NombreMaterial })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreMaterial: true,disable:true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreMaterial: false,disable:false });
              this.showNotification();
             
            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombreMaterial: false,disable:false });
       

      }
    } else {
      fetch(Globales.Url + 'Material/Material/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreMaterial: this.state.NombreMaterial })
      }
      ).then(res => res.json())
        .then(data => {
          console.log(data)
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombreMaterial: true,disable:true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombreMaterial: false,disable:false});
            this.showNotification();
           

          }
        })
        .catch(err => console.error(err));
    }
   }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
    var idUnidad = "";
    var nombreUnidadMedida = "";

    if (event.target.name === "idMateriaPrima") {
      //Recorriendo las materias primas para obtener el ID de unidad de medida
      this.state.MateriasPrimas.map(materiasPrimas => {
        if (event.target.value === materiasPrimas._id) {
          idUnidad = materiasPrimas.idUnidadMedida;
          global.NombreMateriaPrima = materiasPrimas.NombreMateria;
        }
      });
      //Recorrriendo las unidades de medida para obtener el nombre
      this.state.UnidadesMedidas.map(unidades => {
        if (idUnidad === unidades._id) {
          nombreUnidadMedida = unidades.NombreUnidad
        }
      });
    }
    this.setState({ idUnidadMedida: idUnidad });
    //this.setState({ NombreUnidadMedida: nombreUnidadMedida });
    global.NombreUnidadMedida = nombreUnidadMedida
  };

  handleComportamiento = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal" size="lg">
          <Card >
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* ComboBox de nombre de materias primas */}
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.errorMateriaPrima}
                    className="mt-4"
                  >
                    <InputLabel
                      className={classes.selectLabel}>
                      Materia Prima
                        </InputLabel>
                    <Select
                      value={this.state.idMateriaPrima}
                      onChange={this.handleSimple}
                      name="idMateriaPrima"
                      inputProps={{
                      }}
                    >
                      <MenuItem disabled>Materia Prima</MenuItem>
                      {this.state.MateriasPrimas.map(materiaPrima =>
                        <MenuItem value={materiaPrima._id}>{materiaPrima.NombreMateria}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                {/* Label de nombre de la unidad de medida */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Unidad de Medida"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      onChange: this.handleSimple,
                      value: global.NombreUnidadMedida,
                      name: "idUnidadMedida",
                      id: "idUnidadMedida",
                      type: "text",
                      error: this.state.errorUnidadMedida
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/* Label del nombre del material */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Nombre Material"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur:this.ValidarNombre.bind(this),
                      onChange: this.handleChange,
                      value: this.state.NombreMaterial,

                      name: "NombreMaterial",
                      id: "NombreMaterial",
                      type: "text",
                      error: this.state.errorNombreMaterial
                    }} />
                </GridItem>
                {/* Label de descripción de material */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Descripción"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.DescripcionMaterial,
                      name: "DescripcionMaterial",
                      id: "DescripcionMaterial",
                      type: "text",
                      error: this.state.errorDescripcionMaterial
                    }} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/* Label de costo de unidad */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Costo Unidad"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      value: this.state.CostoUnidad,
                      name: "CostoUnidad",
                      id: "CostoUnidad",
                      type: "number",
                      error: this.state.errorCostoUnidad
                    }} />
                </GridItem>
                {/* Label de existencia */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Existencia"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.desactivar,
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.Existencia,
                      name: "Existencia",
                      id: "Existencia",
                      type: "number",
                      error: this.state.errorExistencia
                    }} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/* Label de existencia mínima */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Existencia Mínima"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      value: this.state.ExistenciaMinima,
                      name: "ExistenciaMinima",
                      id: "ExistenciaMinima",
                      type: "number",
                      error: this.state.errorExistenciaMinima
                    }} />
                </GridItem>
                {/* ComboBox de comportamiento de existencia */}
                <GridItem hidden={true  } xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    error={this.state.errorComportamiento}
                    className="mt-4"
                  >
                    <InputLabel
                      className={classes.selectLabel}>
                      Comportamiento de Existencia
                        </InputLabel>
                    <Select
                      value={this.state.Comportamiento}
                      onChange={this.handleComportamiento}
                      name="Comportamiento"
                      inputProps={{
                      }}
                    >
                      <MenuItem disabled>Comportamiento de Existencia</MenuItem>
                      <MenuItem value="Permitir Venta">Permitir Venta</MenuItem>
                      <MenuItem value="Denegar Venta">Denegar Venta</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridItem>
                  <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarMaterial.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="1" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
                    show={this.state.MostrarModal}
                    Cuerpo="Cargando"
                />
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}