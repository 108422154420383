import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/HeaderLogin.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import Navbar from "components/Navbars/Navbar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import image from "assets/img/gris2.jpg";
import Globales from "utils/Globales";
import routes from "routes/routesAdministracion.js";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import CookiesU from 'universal-cookie';
const cookies = new CookiesU();

class CambiarContrasena extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      ContrasenaAnterior: '',
      errorContrasenaAnterior: false,
      NuevaContrasena: '',
      errorNuevaContrasena: false,
      ConfirmacionContrasena: '',
      errorConfirmacionContrasena: false,
      _id: '',
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
    }
    this.cambiarContrasena = this.cambiarContrasena.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.setState({ _id: cookies.get('id') })
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  cambiarContrasena() {
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    if (this.state.ContrasenaAnterior.trim() === '') {
      this.setState({ errorContrasenaAnterior: true, colorNotificacion: 'danger', mensaje: 'Ingrese la contraseña' });
      valor1 = 1;
      this.showNotification();
    } else {
      this.setState({ errorContrasenaAnterior: false });
      valor1 = 0;
    }
    if (this.state.NuevaContrasena.trim() === '') {
      this.setState({ errorNuevaContrasena: true, colorNotificacion: 'danger', mensaje: 'Ingrese la contraseña' });
      valor2 = 1;
      this.showNotification();
    } else {
      this.setState({ errorNuevaContrasena: false });
      valor2 = 0;
    }
    if (this.state.ConfirmacionContrasena.trim() === '') {
      this.setState({ errorConfirmacionContrasena: true, colorNotificacion: 'danger', mensaje: 'Ingrese la contraseña' });
      valor3 = 1;
      this.showNotification();
    } else {
      this.setState({ errorConfirmacionContrasena: false });
      valor3 = 0;
    }
    if (valor1 + valor2 + valor3 === 0) {
      fetch(Globales.Url + 'Usuario/Usuario/CambiarContrasena', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', ContrasenaAnterior: '', NuevaContrasena: '', ConfirmacionContrasena: '', errorNuevaContrasena: false, errorNuevaContrasena: false, errorConfirmacionContrasena: false });            
            cookies.set('mensaje', data.mensaje);
            window.location.href = '#/MenuPrincipal';
          }
        })
        .catch(err => console.error(err));
    }
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Navbar
          routes={routes}
        />
        <div>
          <div className={classes.space50} />
          <div className={classes.space50} />
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="info"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4>Cambiar Contraseña</h4>
                    </CardHeader>
                    <CardBody signup>
                      <CustomInput
                        labelText="Contraseña Anterior"
                        id="first"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          onChange: this.handleChange.bind(this),
                          value: this.state.ContrasenaAnterior,
                          name: "ContrasenaAnterior",
                          id: "ContrasenaAnterior",
                          type: "password",
                          error: this.state.errorContrasenaAnterior
                        }}
                      />
                      <CustomInput
                        labelText="Nueva Contraseña"
                        id="first"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          onChange: this.handleChange.bind(this),
                          value: this.state.NuevaContrasena,
                          name: "NuevaContrasena",
                          id: "NuevaContrasena",
                          type: "password",
                          error: this.state.errorNuevaContrasena
                        }}
                      />
                      <CustomInput
                        labelText="Confirmación Contraseña Nueva"
                        id="first"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          onChange: this.handleChange.bind(this),
                          value: this.state.ConfirmacionContrasena,
                          name: "ConfirmacionContrasena",
                          id: "ConfirmacionContrasena",
                          type: "password",
                          error: this.state.errorConfirmacionContrasena
                        }}
                      />
                    </CardBody>
                    <GridItem>
                      <Button round color="info" className='pull-right' onClick={this.cambiarContrasena.bind(this)} >Aceptar</Button>
                      <Button round color="danger" className='pull-right' onClick={()=> {window.location.href = '#/MenuPrincipal'}}>Cancelar</Button>
                    </GridItem>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(CambiarContrasena);
