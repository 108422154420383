import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import AgregarCategoriaDiseno from "views/Modulos/Administracion/CategoriaDiseno/AgregarCategoriaDiseno.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";
import ReactLoading from "react-loading";

class CategoriaDiseno extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreCategoria: '',
      idArtista: '',
      idUsuario: '',
      Titulo: '',
      categoriaDiseno: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombreCategoria: false,
      RutaImagen:'',
      categoriaDisenoMostrar: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    global.Fin = 5;
    global.response = null;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarCategoriaDiseno = this.eliminarCategoriaDiseno.bind(this);
    this.filtrarCategoriaDiseno = this.filtrarCategoriaDiseno.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerCategoriaDisenoFin();
    this.obtenerAllCategoriaDiseno();
  }

  //Obtener todos los registros guardados
  obtenerAllCategoriaDiseno() {
    fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ categoriaDiseno: [] });
        } else { //Obtuvo los registros correctamente
          if(data.data != null){
            this.setState({ categoriaDiseno: data.data });
          }else{
            this.setState({ categoriaDiseno: [] });
          }
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerCategoriaDisenoFin() {
    fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/ListaCategoriaDiseno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else { //Obtuvo los registros correctamente
            if(data.data != null){
              this.setState({ categoriaDisenoMostrar: data.data });
            }else{
              this.setState({ categoriaDisenoMostrar: [] });
            }
          }
        })
        .catch(err => console.error(err));
    });
  }

  //Función de editar registro
  editarCategoriaDiseno(id, Nombre,RutaImagen) {
    console.log(id,Nombre,RutaImagen)
    this.setState({ _id: id, NombreCategoria: Nombre, op: 1,RutaImagen:RutaImagen, showAgregar: true, Titulo: "Editar Categoría de Diseño" });
  }

  //Función de eliminar registro
  eliminarCategoriaDiseno(id) {
    fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/BajaCategoriaDiseno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification();
        this.obtenerCategoriaDisenoFin();
        this.obtenerAllCategoriaDiseno();
        this.limpiar();
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarCategoriaDiseno(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openModalAgregarCategoriaDiseno = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Categoría de Diseño", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalCategoriaDiseno = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Categoría de Diseño", NombreCategoria: "", op: 0 })
    this.obtenerCategoriaDisenoFin();
    this.obtenerAllCategoriaDiseno();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para realizar el filtrado de búsqueda
  filtrarCategoriaDiseno() {
    var valor1 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreCategoria.trim() == '') {
      this.setState({ errorNombreCategoria: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", NombreCategoria: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombreCategoria: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) { //Hay campos requeridos
      this.showNotification();
    } else { //No hay campos requeridos
      fetch(Globales.Url + 'CategoriaDiseno/CategoriaDiseno/FiltrarCategoriaDiseno', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.data.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning",categoriaDisenoMostrar:[], mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', categoriaDisenoMostrar: data.data, entroFiltrar: true, mensaje: data.mensaje, colorNotificacion: 'success' });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreCategoria: '', entroFiltrar: false });
    global.entrar = true;
    global.Fin = 5;
    global.response = null;
    this.obtenerCategoriaDisenoFin();
    this.obtenerAllCategoriaDiseno();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.categoriaDiseno.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerCategoriaDisenoFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.categoriaDiseno.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.categoriaDiseno.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerCategoriaDisenoFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más categorías de diseño para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <AgregarCategoriaDiseno
          Titulo={this.state.Titulo}
          NombreCategoria={this.state.NombreCategoria}
          _id={this.state._id}
          RutaImagen={this.state.RutaImagen}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalCategoriaDiseno}
          op={this.state.op}
          classes = {this.props}
        />
    }

    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Categorías de Diseño</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Buscar categoría de diseño"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreCategoria,
                        name: "NombreCategoria",
                        id: "NombreCategoria",
                        type: "text",
                        error: this.state.errorNombreCategoria
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={() => this.filtrarCategoriaDiseno()}><Search />Buscar</Button>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.limpiar()}><Clear />limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openModalAgregarCategoriaDiseno()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de categoriaDiseno */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de Categorías de Diseño</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {
                  global.response === 200 ?
                  <div className="table-wrapper-scroll-y">
                    <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>
                        <th className="center">Nombre Categoría</th>
                        <th className="pull-right">Acciones</th>
                      </thead>
                      <tbody>
                        {
                          this.state.categoriaDisenoMostrar.map(CategoriaDiseno => {
                          return (
                            <tr key={CategoriaDiseno._id}>
                              <td>{CategoriaDiseno.NombreCategoria}</td>
                              <td className="pull-right">
                                <Button color="warning" round justIcon size="sm" onClick={() => this.editarCategoriaDiseno(CategoriaDiseno._id, CategoriaDiseno.NombreCategoria,CategoriaDiseno.RutaImagen)}><Edit /></Button>
                                <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(CategoriaDiseno._id)}><Close /></Button>
                              </td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  </div> :
                  <GridContainer justify='center'>
                    <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                  </GridContainer>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Categoría de Diseño"}
            Cuerpo={"¿Está seguro que desea eliminar la categoría de diseño?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(CategoriaDiseno);