import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalAgregarEstatusPedido from "views/Modulos/Administracion/EstatusPedidos/ModalAgregarEstatusPedido.jsx";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from "@material-ui/icons";
import ReactLoading from "react-loading";

class EstatusPedido extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      NombreEstatus: '',
      Dias: '',
      Color: '',
      Titulo: '',
      estatusPedidos: [],
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      NumeroConsecutivo:0,
      errorNombre: false,
      errorDias: false,
      Retraso:true,
      errorColor: false,
      
      estatusPedidoMostrar: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 5;
    global.entrar = true;
    global.response = null;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarEstatusPedido = this.eliminarEstatusPedido.bind(this);
    this.filtrarEstatusPedido = this.filtrarEstatusPedido.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerEstatusFin();
    this.obtenerAllEstatusPedido();
  }

  //Obtener todos los registros guardados
  obtenerAllEstatusPedido() {
    fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ estatusPedidos: [] });
        } else { //Obtuvo los registros correctamente
          if(data.mensaje != null){
            this.setState({ estatusPedidos: data.mensaje });
          }else{
            this.setState({ estatusPedidos: [] });
          }
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerEstatusFin() {
    fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/ListaEstatusPedido', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => {
      global.response = res.status;
      res.json()
        .then(data => {
          console.log(global.Fin)
          if (data.error) { //Error al obtener los registros
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje,estatusPedidoMostrar:[] });
            this.showNotification();
          } else { //Obtuvo los registros correctamente+}
            if(data.data.length > 0){
              this.setState({ estatusPedidoMostrar: data.data });
            }else{
              this.setState({ estatusPedidoMostrar: [] });
            }
          }
        })
        .catch(err => console.error(err));
    });
  }

  //Función de editar registro
  editarEstatusPedido(id, Nombre, Dias, Color,Retraso,NumeroConsecutivo,Icono) {
    this.setState({ _id: id, NombreEstatus: Nombre, Dias: Dias, Color: Color, op: 1, showAgregar: true, Titulo: "Editar Estatus de producción",Retraso:Retraso,NumeroConsecutivo:NumeroConsecutivo,Icono:Icono });
  }

  //Función de eliminar registro
  eliminarEstatusPedido(id) {
    fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/BajaEstatusPedidos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
          this.setState({ mensaje: data.mensaje, show: false });
          this.showNotification("tr");
 
          this.obtenerEstatusFin();
          this.obtenerAllEstatusPedido();
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });  
          this.setState({ mensaje: data.mensaje, show: false });
          this.showNotification("tr");  
          this.obtenerEstatusFin();
          this.obtenerAllEstatusPedido();
         
        }
      });
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarEstatusPedido(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openeModalAgregarEstatusPedidox = () => {
    this.setState({ showAgregar: true, Titulo: "Nuevo Estatus de Producción", op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalEstatusPedido = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: "Nuevo Estatus de Producción", NombreEstatus: "", op: 0 })
    this.obtenerEstatusFin();
    this.obtenerAllEstatusPedido();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para realizar el filtrado de búsqueda
  filtrarEstatusPedido() {
    var valor1 = 0;
    var valor2 = 0;
    var valor3 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreEstatus.trim() == '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", NombreEstatus: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) { //Hay campos requeridos
      this.showNotification();
    } else { //No hay campos requeridos
      fetch(Globales.Url + 'EstatusPedidos/EstatusPedidos/FiltrarEstatusPedido', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify(this.state)
      }
      ).then(res => res.json())
        .then(data => {

          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.mensaje.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning",estatusPedidoMostrar:[], mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', estatusPedidoMostrar: data.mensaje, entroFiltrar: true })
          }
        })
        .catch(err => console.error(err));
    }
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ NombreEstatus: '', entroFiltrar: false },()=>{
      console.log(this.state)
      this.setState({
        a:"ay dios"
      },()=>{
        global.Fin = 5;
        global.entrar = true;
        global.response = null;
      });    
      global.Fin = 5;
      this.obtenerEstatusFin()
    });
  
   
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      var mostrarMensaje = false;
      if(this.state.estatusPedidos.length <= global.Fin){
        mostrarMensaje = true;
      }
      //this.obtenerEstatusFin();
      //console.log(this.state.estatusPedidoMostrar)
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.estatusPedidos.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerEstatusFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.estatusPedidos.length && global.entrar) {
        
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.estatusPedidos.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerEstatusFin();
        //this.setState({ mensaje: 'No hay más estatus de producción para mostrar', colorNotificacion: 'warning' });
        //this.showNotification();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (mostrarMensaje) {
        this.setState({ mensaje: 'No hay más estatus de producción para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <ModalAgregarEstatusPedido
          Titulo={this.state.Titulo}
          NombreEstatus={this.state.NombreEstatus}
          Dias={this.state.Dias}
          NumeroConsecutivo={this.state.NumeroConsecutivo}
          Color={this.state.Color}    
          _id={this.state._id}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalEstatusPedido}
          op={this.state.op}
          Retraso={this.state.Retraso}
          Icono = {this.state.Icono}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Estatus de Producción</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Buscar estatus de producción"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.NombreEstatus,
                        name: "NombreEstatus",
                        id: "NombreEstatus",
                        type: "text",
                        error: this.state.errorNombre
                      }}
                      className="mt-5"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="info" round onClick={() => this.filtrarEstatusPedido()}><Search />Buscar</Button>
                    <Button size="sm" type="submit" className="pull-left mt-5" color="danger" round onClick={() => this.limpiar()}><Clear />Limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="info" round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size="sm" type="submit" className="pull-right mt-5" color="success" round onClick={() => this.openeModalAgregarEstatusPedidox()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {/* Tabla de estatusPedidos */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <h4 className="pull-left">Lista de Estatus de Producción</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {global.response === 200 ?
                  <div className="table-wrapper-scroll-y">
                    <table className="col-xs-12 col-sm-12 col-md-12">
                      <thead>
                        <th className="center">Número consecutivo</th>
                        <th className="center">Nombre</th>
                        <th className="center">Días</th>
                        <th className="center">Color</th>
                        <th className="center">Notifica retraso</th>
                        <th className="pull-right">Acciones</th>
                      </thead>
                      <tbody>
                        {this.state.estatusPedidoMostrar.map(EstatusPedido => {
                          return (
                            <tr key={EstatusPedido._id}>
                              <td>{EstatusPedido.NumeroConsecutivo}</td>
                              <td>{EstatusPedido.NombreEstatus}</td>
                              <td>{EstatusPedido.Dias}</td>
                              <td><div style={{backgroundColor:EstatusPedido.Color,marginRight:"70px"}}>&nbsp;<br></br></div></td>
                              <td>{(EstatusPedido.Retraso== true?"Sí":"No")}</td>
                              <td className="pull-right">
                                <Button color="warning" round justIcon size="sm" onClick={() => this.editarEstatusPedido(EstatusPedido._id, EstatusPedido.NombreEstatus, EstatusPedido.Dias, EstatusPedido.Color,EstatusPedido.Retraso,EstatusPedido.NumeroConsecutivo,EstatusPedido.Icono)}><Edit /></Button>
                                <Button color="danger" round justIcon size="sm" onClick={() => this.deleteEvent(EstatusPedido._id)}><Close /></Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div> :
                  <GridContainer justify='center'>
                    <ReactLoading type={'bubbles'} color={'#CFCFCF'} height={'10%'} width={'10%'} />
                  </GridContainer>}

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar Estatus de Producción"}
            Cuerpo={"¿Está seguro que desea eliminar el estatus de producción?"}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(EstatusPedido);