import Globales from "utils/Globales";

//Función que realiza la petición a la api del envio de mail
export function EnviarMail(to, subject, contenido) {
	fetch(Globales.Url + 'EnvioMail', {
		method: 'POST',
		headers: {
			Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
		}
		, body: JSON.stringify({ to: to, subject: subject, contenido: contenido })
	})
	console.log("Función envió de mail");
}

//Función que realiza el contenido del mail
export function ContenidoMail(Producto, Iva, NombreUsuario) {
	console.log("TCL: Producto", Producto)
	var grupos ="";

	Producto.GruposMaterial.map(m =>{
	console.log("TCL: m", m)
	var materiales ="";

	m.Materiales.map((p,index) =>{
		if(index == 0){
			materiales+=p.NombreMaterial;

		}else{
			materiales+=", " +p.NombreMaterial;

		}
		})
	

	grupos += "<tr>"+
	"<td>"+ m.NombreGrupo + "</td>"+
	"<td>"+ materiales+"</td> "+
	"<td>" + m.CostoMaterial + "</td> "+
	"<td>" + m.CostoMaquila + "</td> "+
	"<td>" + m.PrecioPublico +"</td> "+
	"<td>" + m.valorIva +"</td> "+
	"<td>" +m.PorcentajeUtilidad +"</td> "+
	"</tr>";
	})
	//Creando el contenido del mail
	var mensaje='<!DOCTYPE html>'+
	 "<html>"+
	 "<head>"+
	 "<style>"+
	 "#customers {font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;border-collapse: collapse;width: 70%;}"+
	 "#customers td, #customers th {border: 1px solid #ddd;padding: 8px;}"+
	 "#customers tr:nth-child(even){background-color: #777575;}"+
	 "#customers tr:hover {background-color: #ddd;}"+
	//  "#customers td{width:50%;}"+
	 "#customers th {width:50%;padding-top: 10px;padding-bottom: 10px;text-align: left;background-color: #777575;color: white;}"+
	 "#Grupos {font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;border-collapse: collapse;width: 70%;}"+
	 "#Grupos td, #customers th {border: 1px solid #ddd;padding: 8px;}"+
	 "#Grupos tr:nth-child(even){background-color: #777575;}"+
	 "#Grupos tr:hover {background-color: #ddd;}"+
	//  "#Grupos td{width:100%;}"+
	 "#Grupos th {width:50%;padding-top: 10px;padding-bottom: 10px;text-align: left;background-color: #777575;color: white;}"+
	 "</style>"+
	 "</head>"+
	 "<body>"+
	 "<h2>Existe un producto que requiere revisión. Porcentaje de utilidad menor al configurado en el sistema!</h2>"+
	 "</body>"+
	 "<table id='customers'>"+
	 "<tr><th>Nombre del producto:</th>"+
	 "<td>" + Producto.NombreProducto + "</td>"+
	 "</tr>"+
	 "<tr>"+
	 "<th>Usuario que realizó el registro:</th>"+
	 "<td>" + NombreUsuario + "</td>"+
	 "</tr>"+
	 "</table>"+
	"<table id='Grupos'>"+
	"<thead>"+
	"<tr>"+
	 "<td> Nombre grupo </td>"+	
	 "<td> Materiales </td>"+
	 "<td> Costo Material </td>"+
	 "<td> Costo Maquila </td>"+
	 "<td> Precio Público </td>"+
	 "<td> IVA </td>"+
	 "<td> Porcentaje Utilidad </td>"+
	"</tr>"+
	"</thead>"+
	"<tbody>"+
	grupos+
	"</tbody>" +
	"</table>";
	
	//Regresando el contenido del mail
	return mensaje
}


// var contenido = "<!DOCTYPE html>\n"
// 	contenido += "<html>\n"
// 	contenido += "<head>\n"
// 	contenido += "<style>\n"
// 	contenido += "#customers {\n"
// 	contenido += "font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;\n"
// 	contenido += "border-collapse: collapse;\n"
// 	contenido += "width: 50%;\n"
// 	contenido += "}\n"
// 	contenido += "#customers td, #customers th {\n"
// 	contenido += "border: 1px solid #ddd;\n"
// 	contenido += "padding: 8px;\n"
// 	contenido += "}\n"
// 	contenido += "#customers tr:nth-child(even){background-color: #777575;}\n"
// 	contenido += "#customers tr:hover {background-color: #ddd;}\n"
// 	contenido += "#customers td{"
// 	contenido += "width:50%;"
// 	contenido += "}"
// 	contenido += "#customers th {\n"
// 	contenido += "width:50%;"
// 	contenido += "padding-top: 10px;\n"
// 	contenido += "padding-bottom: 10px;\n"
// 	contenido += "text-align: left;\n"
// 	contenido += "background-color: #777575;\n"
// 	contenido += "color: white;\n"
// 	contenido += "}\n"
// 	contenido += "</style>\n"
// 	contenido += "</head>\n"
// 	contenido += "<body>\n"
// 	contenido += "<h2>Existe un producto que requiere revisión. Porcentaje de utilidad menor al configurado en el sistema!</h2>\n"
// 	contenido += "<table id='customers'>\n"
// 	contenido += "<tr>\n"
// 	contenido += "<th>Nombre del producto:</th>\n"
// 	contenido += "<td>" + Producto.NombreProducto + "</td>\n"
// 	contenido += "</tr>\n"
// 	contenido += "<tr>\n"
// 	contenido += "<th>Usuario que realizó el registro:</th>\n"
// 	contenido += "<td>" + NombreUsuario + "</td>\n"
// 	contenido += "</tr>\n"
// 	contenido += "</table>\n"
// 	contenido +="<table id='customers'>\n"
// 	contenido +="<thead>\n"
// 	contenido +="<tr>\n"
// 	contenido += "<td> Nombre grupo </td>\n"	
// 	contenido += "<td> precio </td>\n"	
// 	contenido +="</tr>\n"
// 	contenido +="</thead>\n"
// 	contenido +="<tbody>\n"
// 	contenido +="<tr>\n"
// 	contenido += "<td> grupo 1 </td>\n"	
// 	contenido += "<td> $100 </td>\n"	
// 	contenido +="</tr>\n"
// 	contenido +="</tbody>\n"
// 	contenido +="</table>\n"
// 	contenido += "</body>\n"
// 	contenido += "</html>\n"

	// contenido += "<tr>\n"
	// contenido += "<th>Cantidad Material:</th>\n"
	// contenido += "<td>" + Producto.materialesAgregados.length + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Costo Material:</th>\n"
	// contenido += "<td>" + "$" + Producto.CostoMaterial + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Costo Maquila por objeto:</th>\n"
	// contenido += "<td>" + "$" + Producto.CostoMaquila + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Precio Público:</th>\n"
	// contenido += "<td>" + "$" + Producto.PrecioPublico + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>IVA:</th>\n"
	// contenido += "<td>" + Iva + "</td>\n"
	// contenido += "</tr>\n"
	// contenido += "<tr>\n"
	// contenido += "<th>Porcentaje Utilidad:</th>\n"
	// contenido += "<td>" + Producto.PorcentajeUtilidad + "%" + "</td>\n"
	// contenido += "</tr>\n"