import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Label from '@material-ui/icons/Label';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from '@material-ui/core/Typography';
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx"; import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Card from "components/Card/Card.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import CardHeader from "components/Card/CardHeader.jsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CardBody from "components/Card/CardBody.jsx";
import TreeView from '@material-ui/lab/TreeView';
import { TreeItem } from '@material-ui/lab'
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import { Save, Clear } from '@material-ui/icons';
import seleccionado from '@material-ui/icons/TrendingFlat';
import finales from '@material-ui/icons/LinearScale'
const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

export default class AgregarCategoriaModelo extends Component {
  
  constructor(props, context) {
    super(props, context);
    this.state = {
      NombreCategoriaModelo: '',
      selectedEnabled: 'a',
      CategoriaPadre: '',
      MostrarModal: false,
      idCategoriaPadre: '',
      Descripcion: '',
      _id: null,
      NombreCategoriaSeleccionado:'',
      categoriasModelos3D: [],
      errorNombre: false,
      categoriasModelosTreeView:[],
      errorCategoriaPadre: false,
      errorDescripcion: false,
      colorNotificacion: 'success',
      mensaje: '',
      br: false,
      disable: false
    }
    global.NombreCategoria = '';
    this.registrarCategoriasModelos3D = this.registrarCategoriasModelos3D.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.obtenerAllCategoriasModelos = this.obtenerAllCategoriasModelos.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.obtenerCategoriasTreeView = this.obtenerCategoriasTreeView.bind(this);
    this.handleChangeTreeView = this.handleChangeTreeView.bind(this);
  }
   componentDidMount() {
    //Verificar si es editar
    if (this.props.op == 1) {
      this.setState({
        MostrarModal: true,
        NombreCategoriaModelo: this.props.NombreCategoriaModelo,
        NombreGuardado: this.props.NombreCategoriaModelo,
        idCategoriaPadre: this.props.idCategoriaPadre,
        _id:this.props._id,
        Descripcion: this.props.Descripcion,
        selectedEnabled: (this.props.idCategoriaPadre == null ? 'a' : 'b')
      })
    }
    this.obtenerAllCategoriasModelos();
    this.obtenerCategoriasTreeView();
    // var response =[]
    // for(var keyArrayCategoria = 0; keyArrayCategoria < arrayCategorias.length; keyArrayCategoria++){
    // response.push(this.obtenerEtiquetaItem(arrayCategorias[keyArrayCategoria]));
    // }
  }

  componentWillUnmount() {
  };

  obtenerAllCategoriasModelos() {
    fetch(Globales.Url + 'Modelos/Categoria/ObtenerTodasCategorias', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ categoriasModelos3D: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ categoriasModelos3D: data.data, MostrarModal: false });
        }
      });
  }
  obtenerCategoriasTreeView() {
    fetch(Globales.Url + 'Modelos/Categoria/ObtenerTreeViewCategoriaModelos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
          this.setState({ categoriasModelosTreeView: data });
        
      });
  }
  registrarCategoriasModelos3D() {
    this.setState({ disable: true, MostrarModal: true });
    var valor1 = 0;
    var valor2 = 0;
    //Validar que el input no este vacio
    if (this.state.NombreCategoriaModelo.trim() === '') {
      this.setState({ errorNombre: true, colorNotificacion: 'danger', mensaje: "Complete el campo requerido", NombreCategoriaModelo: '', disable: false, MostrarModal: false });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    if (this.state.selectedEnabled == "b" && this.state.idCategoriaPadre == "" || (this.state._id == this.state.idCategoriaPadre && this.props.op == 1)) {
      global.NombreCategoria = 'Sin Categoría';
      this.setState({ mensaje: "Complete el campo requerido", errorCategoriaPadre: true, idCategoriaPadre: null, disable: false, MostrarModal: false });
      valor2 = 1;
    } else {
      global.NombreCategoria = this.state.idCategoriaPadre;
      this.setState({ errorCategoriaPadre: false });
      valor2 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 + valor2 === 0) {
      if (this.props.op == 1) {
        this.setState({ _id: this.props._id });
        fetch(Globales.Url + 'Modelos/Categoria/ActualizarCategoriaModelo', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ _id: this.props._id, NombreCategoriaModelo: this.state.NombreCategoriaModelo, idCategoriaPadre: global.NombreCategoria, Descripcion: this.state.Descripcion })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false,MostrarModal:false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoriaModelo: '', disable: false, CategoriaPadre: '', Descripcion: '', MostrarModal: false, errorNombre: false, errorCategoriaPadre: false, errorDescripcion: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err));
      } else {
        fetch(Globales.Url + 'Modelos/Categoria/RegistrarCategoriaModelo', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCategoriaModelo: this.state.NombreCategoriaModelo, idCategoriaPadre: (global.NombreCategoria ==''?null:global.NombreCategoria), Descripcion: this.state.Descripcion })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, disable: false });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', NombreCategoriaModelo: '', MostrarModal: false, disable: false, CategoriaPadre: '', Descripcion: '', errorNombre: false, errorCategoriaPadre: false, errorDescripcion: false });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch(err => console.error(err)); 
      }
    }else{
      this.showNotification();
    }
  }

  closeModal(mensaje, colorNotificacion) {
    this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    if (name === 'NombreCategoriaModelo') {

    }
  }

  //Validar Nombre
  ValidarNombre() {
    //Validar si es una actualización
    if (this.props.op === 1) {
      //Validar si se ingresa otro nombre
      if (this.state.NombreGuardado !== this.state.NombreCategoriaModelo) {
        fetch(Globales.Url + 'Modelos/Categoria/ValidarNombre', {
          method: 'POST',
          headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
          }
          , body: JSON.stringify({ NombreCategoriaModelo: this.state.NombreCategoriaModelo,idCategoriaPadre:this.state.idCategoriaPadre })
        }
        ).then(res => res.json())
          .then(data => {
            if (data.error) {
              this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
              this.showNotification();
            } else {
              this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
              this.showNotification();

            }
          })
          .catch(err => console.error(err));
      } else {
        this.setState({ errorNombre: false, disable: false, disable: false });

      }
    } else {
      fetch(Globales.Url + 'Modelos/Categoria/ValidarNombre', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ NombreCategoriaModelo: this.state.NombreCategoriaModelo,idCategoriaPadre:this.state.idCategoriaPadre  })
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) {
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, errorNombre: true, disable: true });
            this.showNotification();
          } else {
            this.setState({ colorNotificacion: 'success', mensaje: data.mensaje, errorNombre: false, disable: false });
            this.showNotification();
          }
        })
        .catch(err => console.error(err));
    }
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
    if (event.target.value == 'a') {
      this.setState({
        idCategoriaPadre: null
      });
    }
  }
  handleChangeTreeView(idCategoria,NombreCategoria,event){
    this.setState({
      idCategoriaPadre:idCategoria,
      NombreCategoriaSeleccionado:NombreCategoria
    },()=>{
      if(this.state.NombreCategoriaModelo != ''){
        this.ValidarNombre()
      }
    });
    

  }
  DataTreeView (treeItems){
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {this.getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };
  
   getTreeItemsFromData(treeItems){
    return treeItems.map(treeItemData => {
      let children = undefined;
      if (treeItemData.CategoriaHijo.length > 0) {
        children =this.getTreeItemsFromData(treeItemData.CategoriaHijo);
      }
      if(this.props.op == 1 && this.state.idCategoriaPadre == treeItemData.idCategoria){
      this.state.NombreCategoriaSeleccionado = treeItemData.NombreCategoria
      }
      return (
        <StyledTreeItem 
          nodeId={treeItemData.idCategoria} 
          labelText={treeItemData.NombreCategoria}
          labelIcon={(this.state.idCategoriaPadre == treeItemData.idCategoria?seleccionado:Label)}
          children={children}
          onClick={this.handleChangeTreeView.bind(this,treeItemData.idCategoria,treeItemData.NombreCategoria)}
        />
      );
    });
  }

  render() {  
    const { classes } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.showAgregar} className="static-modal">
          <Card>
            <CardHeader color="info">
              {this.props.Titulo}
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Nombre Categoría"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onBlur: this.ValidarNombre.bind(this),
                  onChange: this.handleChange.bind(this),
                  value: this.state.NombreCategoriaModelo,
                  name: "NombreCategoriaModelo",
                  id: "NombreCategoriaModelo",
                  type: "text",
                  error: this.state.errorNombre
                }}
              />
              <div className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedEnabled === 'a'}
                      onChange={this.handleChangeEnabled}
                      value='a'
                      name='radio button enabled'
                      aria-label='A'
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label='Sin categoría'
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedEnabled === 'b'}
                      onChange={this.handleChangeEnabled}
                      value='b'
                      name='radio button enabled'
                      aria-label='B'
                      icon={
                        <FiberManualRecord
                          className={classes.radioUnchecked}
                        />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label='Seleccionar categoría...'
                />
              </div>
              {/* <div hidden={(this.state.selectedEnabled === 'a' ? true : false)}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                  error={this.state.errorCategoriaPadre}
                >
                  <InputLabel htmlFor="cbbUnidadMedida" className={classes.selectLabel}>Categoría Padre</InputLabel>
                  <Select
                    value={this.state.idCategoriaPadre}
                    onChange={this.handleSimple}
                    name="idCategoriaPadre"
                    id="cbbUnidadMedida"
                    inputProps={{
                      error: this.state.errorCategoriaPadre
                    }}
                  >
                    {this.state.categoriasModelos3D.map(categorias =>
                      <MenuItem value={categorias._id.toString()}>{categorias.NombreCategoriaModelo}</MenuItem>
                    )}
                  </Select>
                </FormControl>

              </div> */}
              <div hidden={(this.state.selectedEnabled === 'a' ? true : false)}>
              {
                this.DataTreeView(this.state.categoriasModelosTreeView)
              }
                Categoria seleccionada: {(this.state.NombreCategoriaSeleccionado == ''?"Sin categoría":this.state.NombreCategoriaSeleccionado)}
              </div>
         
              <CustomInput
                labelText="Descripción"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  onChange: this.handleChange.bind(this),
                  value: this.state.Descripcion,
                  name: "Descripcion",
                  id: "Descripcion",
                  type: "text",
                  error: this.state.errorDescripcion
                }}
              />
              <GridItem>
                <Button tabIndex="0" disabled={this.state.disable} size="sm" color="info" className="pull-right" round onClick={this.registrarCategoriasModelos3D.bind(this)}><Save />&nbsp;GUARDAR</Button>
                <Button tabIndex="0" size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc', 'esc')}><Clear />&nbsp;CANCELAR</Button>
              </GridItem>
            </CardBody>
          </Card>
          <Cargando
            show={this.state.MostrarModal}
            Cuerpo="Cargando"
          />

        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}