import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import routes from "routes/routesAtencionUsuarios.js";
import image from "assets/img/gris1.jpg"; 
import imageTerco from "assets/img/logoTercoBlanco16x16.png";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import withStyles from "@material-ui/core/styles/withStyles";
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { Container } from 'reactstrap';
import logoProduccion from "assets/img/menuPrincipal/produccion.png";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/atencionUsuarios") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      })}
    </Switch>
  );

  class Dashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        image: image,
        imageTerco:imageTerco,
        color: "blue",
        hasImage: true,
        fixedClasses: "dropdown show",
        mobileOpen: false
      };
    }
    handleImageClick = image => {
      this.setState({ image: image });
    };
    handleColorClick = color => {
      this.setState({ color: color });
    };
    handleFixedClick = () => {
      if (this.state.fixedClasses === "dropdown") {
        this.setState({ fixedClasses: "dropdown show" });
      } else {
        this.setState({ fixedClasses: "dropdown" });
      }
    }; 
    handleDrawerToggle = () => {
      this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    getRoute() {
      return this.props.location.pathname !== "/prod";
    }
    resizeFunction = () => {
      if (window.innerWidth >= 960) {
        this.setState({ mobileOpen: false });
      }
    };
    componentDidMount() {
      if (navigator.platform.indexOf("Win") > -1) {
        const ps = new PerfectScrollbar(this.refs.mainPanel);
      }
      window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
      if (e.history.location.pathname !== e.location.pathname) {
        this.refs.mainPanel.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }
      }
    }
    componentWillUnmount() {
      window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
      const { classes, ...rest } = this.props;
      return (
        <div className={classes.wrapper}>
          <Sidebar
            routes={routes}
            // logoImage={this.state.imageTerco}
            logo={this.state.imageTerco}
            image={this.state.image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color={this.state.color}
            {...rest}
          />
          <div className={classes.mainPanel} ref="mainPanel">
            <Navbar
              routes={routes}
              handleDrawerToggle={this.handleDrawerToggle}
              
              {...rest}
            />
           
           {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
                <Container>
                  <div className="row" style={{ justifyContent: 'center', margin: '25% auto' }}>
                    <div className='form-group col-lg-4 col-md-4 col-sm-6'>
                      <div class="hovereffect">
                        <center><img class="img-responsive" width='100%' src={logoProduccion} alt="" /></center>
                        <div class="overlay"></div>
                      </div>
                    </div>
                  </div>
                </Container>
              )}
          </div>
        </div>
      );
    }
  }

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
  };

export default withStyles(dashboardStyle)(Dashboard);
