import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import headerLinksStyle from 'assets/jss/material-dashboard-react/components/headerLinksStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
// material-ui icons
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import ModalPregunta from 'components/Generales/ModalPregunta';
import AgregarUsuarioWeb from 'views/Modulos/Administracion/UsuariosWeb/AgregarUsuarioWeb.jsx';
import Globales from 'utils/Globales';
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus } from '@material-ui/icons';

class UsuarioWeb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      Nombre: '',
      Usuario: '',
      Contrasena: '',
      ConfirmacionContrasena: '',
      IndicadorTerco: '',
      mensaje: '',
      br: false,
      colorNotificacion: 'success',
      errorNombre: false,
      errorUsuario: false,
      errorContrasena: false,
      errorConfirmacionContrasena: false,
      errorIndicadorTerco: false,
      UsuariosWebMostrar: [],
      UsuariosWeb: [],
      entroFiltrar: false,
      notificacion: false
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.Fin = 5;
    global.entrar = true;
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eliminarUsuarioWeb = this.eliminarUsuarioWeb.bind(this);
    // this.filtrarUsuarioWeb = this.filtrarUsuarioWeb.bind(this);
    this.seleccionado = {};
  }

  componentDidMount() {
    this.obtenerUsuariosWebFin();
    this.obtenerUsuariosWeb();
  }

  //Obtener todos los registros guardados
  obtenerUsuariosWeb() {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/', {
      method: 'GET',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ UsuariosWeb: [] });
        } else { //Obtuvo los registros correctamente
          this.setState({ UsuariosWeb: data.data });
        }
      });
  }

  //Obtener el total de registros que se indican
  obtenerUsuariosWebFin() {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ListaUsuariosWeb', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ Fin: global.Fin })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error al obtener los registros
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Obtuvo los registros correctamente
          this.setState({ UsuariosWebMostrar: data.data });
        }
      })
      .catch(err => console.error(err));
  }

  //Función de editar registro
  editarUsuarioWeb(id, Usuario, Contrasena, Nombre) {
    this.setState({ _id: id, Usuario: Usuario, Contrasena: Contrasena, Nombre: Nombre, op: 1, showAgregar: true, Titulo: 'Editar Usuario Web Terco' });
  }

  //Función de eliminar registro
  eliminarUsuarioWeb(id) {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/BajaUsuarioWeb', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {//Error al obtener los registros
          this.setState({ colorNotificacion: 'danger' });
        } else {//Obtuvo los registros correctamente
          this.setState({ colorNotificacion: 'success' });
        }
        this.setState({ mensaje: data.mensaje, show: false });
        this.showNotification();
        this.obtenerUsuariosWebFin();
        this.limpiar();
      });
  }

  //Funcion para Filtrar UsuarioWeb
  filtrarUsuarioWeb(){
    
    var valor1 = 0;
    //Validar que el input no este vacio
    if (this.state.Usuario.trim() == '') {
      this.setState({ errorUsuario: true, colorNotificacion: 'danger', mensaje: "Complete el campo de búsqueda", Usuario: '' });
      valor1 = 1;
    } else {
      this.setState({ errorNombre: false });
      valor1 = 0;
    }
    //Validar si hay campos requeridos
    if (valor1 > 0) { //Hay campos requeridos
      this.showNotification();
    } else { //No hay campos requeridos
      fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuarioWeb', {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({UsuarioWeb:this.state.Usuario})
      }
      ).then(res => res.json())
        .then(data => {
          if (data.error) { //Error en la respuesta
            this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
            this.showNotification();
          } else if (data.mensaje.length == 0) { //Ninguna coincidencia
            this.setState({ colorNotificacion: "warning", UsuariosWebMostrar: [], mensaje: "No hay ninguna coincidencia", entroFiltrar: true })
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ colorNotificacion: 'success', UsuariosWebMostrar: data.mensaje, entroFiltrar: true })
          }
        })
        .catch(err => console.error(err));
    }
  }

  //Función para el evento de eliminar
  deleteEvent = (values) => {
    this.seleccionado = values;
    this.setState({ show: true });
  }

  //Función para habilitar el modal de eliminar
  eventoModalEliminar(e) {
    if (!e) {
      this.setState({ show: false });
      return;
    }
    //Eliminar el elemento
    this.eliminarUsuarioWeb(this.seleccionado);
  }

  //Función para abrir el modal de agregar registro
  openModalUsuario = () => {
    this.setState({ showAgregar: true, Titulo: 'Nuevo Usuario Web', op: 0 });
  }

  //Función que recupera valores para el modal de agregar
  eventoModalUsuario = (mensaje, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, Titulo: 'Nuevo Usuario Web', Usuario: '', Contrasena: '', Nombre: '', op: 0 })
    this.obtenerUsuariosWebFin();
    this.limpiar();
    //Validar que el mensaje venga diferente de vacio
    if (mensaje !== 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: colorNotificacion })
      this.showNotification();
    }
  }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  //Función para volver los valores por default
  limpiar() {
    this.setState({ Usuario: '', entroFiltrar: false });
    global.Fin = 5;
    global.entrar = true;
    this.obtenerUsuariosWebFin();
    this.obtenerUsuariosWeb();
  }

  //Función para mostrar mas registros en la tabla
  verMas() {
    //Validar si entro a filtrar
    if (this.state.entroFiltrar) {
      this.limpiar();
    } else {
      //Creando variable con mas valor que la variable global
      var num = global.Fin + 5;
      //Validación de el valor de la variable global sea menor o igual que el total de registros obtenidos
      if (num <= this.state.UsuariosWeb.length && global.entrar) {
        //Cambiando el valor de la variable global
        global.Fin = num;
        //Obteniendo el número de registros requeridos
        this.obtenerUsuariosWebFin();
      };
      //Validación de que el número total de registros requiridos sea mayor al total de registros y cambio de valor de variables
      if (num > this.state.UsuariosWeb.length && global.entrar) {
        //Cambiando el valor de la variable para que no vuelva a traer mas registros
        global.entrar = false;
        //Cambiando el valor de la variable global
        global.Fin = this.state.UsuariosWeb.length;
        //Obteniendo el total de los registros requeridos
        this.obtenerUsuariosWebFin();
      };
      //Validación de que ya no hay mas registros que mostrar
      if (!global.entrar) {
        this.setState({ mensaje: 'No hay más usuarios de web Terco para mostrar', colorNotificacion: 'warning' });
        this.showNotification();
      };
    }
  }

  render() {
    let agregar;
    if (this.state.showAgregar == true) {
      agregar =
        <AgregarUsuarioWeb
          Titulo={this.state.Titulo}
          Nombre={this.state.Nombre}
          Usuario={this.state.Usuario}
          Contrasena={this.state.Contrasena}
          ConfirmacionContrasena={this.state.ConfirmacionContrasena}
          IndicadorTerco={this.state.IndicadorTerco}
          UsuariosWeb={this.state.UsuariosWeb}
          _id={this.state._id}
          showAgregar={this.state.showAgregar}
          eventClick={this.eventoModalUsuario}
          op={this.state.op}
        />
    }
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color='info' text>
                <h4 className={classes.cardTitle}>Usuarios Web Terco</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText='Buscar Usuarios Web'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        onChange: this.handleChange,
                        value: this.state.Usuario,
                        name: 'Usuario',
                        id: 'Usuario',
                        type: 'text',
                        error: this.state.errorUsuario
                      }}
                      className='mt-5'
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button size='sm' type='submit' className='pull-left mt-5' color='info' round onClick={() => this.filtrarUsuarioWeb()}><Search />Buscar</Button>
                    <Button size='sm' type='submit' className='pull-left mt-5' color='danger' round onClick={() => this.limpiar()}><Clear />Limpiar</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <Button size='sm' type='submit' className='pull-right mt-5' color='info' round onClick={() => this.verMas()}><Filter9Plus />Ver Más</Button>
                    <Button size='sm' type='submit' className='pull-right mt-5' color='success' round onClick={() => this.openModalUsuario()}><AddCircle />Agregar</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify='center'>
          {/* Tabla de tiposUsuarios */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color='info'>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <h4 className='pull-left'>Lista de Usuarios Web Terco</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className='table-wrapper-scroll-y'>
                  <table className='col-xs-12 col-sm-12 col-md-12'>
                    <thead>
                      <th className='center'>Usuario</th>
                      <th className='center'>Nombre</th>
                      <th className='pull-right'>Acciones</th>
                    </thead>
                    <tbody>
                      {this.state.UsuariosWebMostrar.map(usuario => {
                        return (
                          <tr key={usuario._id}>
                            <td>{usuario.Usuario}</td>
                            <td>{usuario.Nombre}</td>
                            <td className='pull-right'>
                              <Button color='warning' round justIcon size='sm' onClick={() => this.editarUsuarioWeb(usuario._id, usuario.Usuario, usuario.Contrasena, usuario.Nombre)}><Edit /></Button>
                              <Button color='danger' round justIcon size='sm' onClick={() => this.deleteEvent(usuario._id)}><Close /></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id=''>
          <ModalPregunta
            Titulo={'Eliminar Usuario Web Terco'}
            Cuerpo={'¿Está seguro que desea eliminar el Usuario Web Terco?'}
            show={this.state.show}
            eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id=''>
          <Snackbar
            place='tr'
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(UsuarioWeb);