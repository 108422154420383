import React from "react";
// core components
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Globales from "utils/Globales";
import { Clear, Save, Publish } from "@material-ui/icons";
import Danger from "components/Typography/Danger.jsx";
import Loader from 'react-loader-spinner';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Cargando from '../../../../components/Generales/ModalCargando';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';




class AgregarCapaProducto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idCapa: null,
            idProducto: null,
            NombreCapa: "",
            errorNombreCapa: false,
            Modelo3D: null,
            Modelo3DSubida: false,
            errorModelo3D: false,
            NombreModelo: "",
            ProporcionX: 0,
            errorProporcionX: false,
            ProporcionY: 0,
            errorProporcionY: false,
            GradosRotacion: 0,
            errorGradosRotacion: false,
            Templete2D: null,
            Templete2DSubida: false,
            NombreTemplete2D: "",
            errorTemplate: false,
            PosicionX: 0,
            PosicionY: 0,
            errorPosicionX: false,
            errorPosicionY: false,
            mensaje: "",
            colorNotificacion: "",
            br: false,
            BotonDisabled: false,
            MostrarModal: false,
            //Variables para controlar la edicion
            editoModelo3D: false,
            editoTemplete2D: false,
            checkPrincipal: false,
            checkPermitirMultimedia: true,
            checkPermitirCambioColor: true
            
        };

        this.registrarCapa = this.registrarCapa.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeRdMultimedia = this.handleChangeRdMultimedia.bind(this);
        this.handleChangeRdColor = this.handleChangeRdColor.bind(this);
    }

    componentDidMount() {
        //Validar si es nueva o edición
        console.log("entro")
        if (this.props.Titulo == "Nueva Capa") {
            console.log("entro1")
            this.setState({ idProducto: this.props.idProducto })
        }
        else {
            console.log("entro2", this.props.Capa)
            this.setState({
                idCapa: this.props.Capa._id,
                idProducto: this.props.idProducto,
                NombreCapa: this.props.Capa.NombreCapa,
                ProporcionX: this.props.Capa.ProporcionX,
                ProporcionY: this.props.Capa.ProporcionY,
                PosicionX: this.props.Capa.PosicionX,
                PosicionY: this.props.Capa.PosicionY,
                GradosRotacion: this.props.Capa.GradosRotacion,
                NombreTemplete2D: this.props.Capa.NombreTemplete2D,
                NombreModelo: this.props.Capa.NombreModelo,
                checkPrincipal: this.props.Capa.EsPrincipal,
                checkPermitirCambioColor: this.props.Capa.PermitirCambiarColor,
                checkPermitirMultimedia: this.props.Capa.PermitirMultimedia
            })
        }
    }

    handleChange(e) {
        console.log(12122)
        var name = ""; var value = "";
        name = e.target.name;
        value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    onBlur(e) {
        var name = ""; var value = "";
        name = e.target.name;
        value = e.target.value;
        if (name != "NombreCapa" && value == "") {
            value = "0";
        }
        this.setState({
            [name]: value
        })
    }

    onChangeValoresDecimales(e) {
        var name = ""; var value = "";
        name = e.target.name;
        value = e.target.value;
        //Validar si es es un dato correcto

    }

    handleChangeRadio(event) {
        var evento = event;
                          
                    this.setState({ checkPrincipal: !this.state.checkPrincipal })                              
           
    }
    handleChangeRdMultimedia(event) {
        var evento = event;
                          
                    this.setState({ checkPermitirMultimedia: !this.state.checkPermitirMultimedia })                              
           
    }
    handleChangeRdColor(event) {
        var evento = event;
                          
                    this.setState({ checkPermitirCambioColor: !this.state.checkPermitirCambioColor })                              
           
    }

    registrarCapa() {
        console.log(this.state)
        if (this.validarCampos()) {
            this.setState({
                MostrarModal: true,
                BotonDisabled: true
            });
            fetch(Globales.Url + 'Productos/Productos/RegistrarCapaProducto', {
                method: 'POST',
                headers: {
                    Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
                }, body: JSON.stringify({
                    NombreCapa: this.state.NombreCapa, ProporcionX: this.state.ProporcionX, ProporcionY: this.state.ProporcionY,
                    PosicionX: this.state.PosicionX, PosicionY: this.state.PosicionY, GradosRotacion: this.state.GradosRotacion,
                    Templete2D: this.state.Templete2D, Modelo3D: this.state.Modelo3D, idProducto: this.state.idProducto, idCapa: this.state.idCapa,
                    editoModelo3D: this.state.editoModelo3D, editoTemplete2D: this.state.editoTemplete2D, NombreModelo: this.state.NombreModelo, 
                    NombreTemplete2D: this.state.NombreTemplete2D, EsPrincipal: this.state.checkPrincipal,PermitirMultimedia: this.state.checkPermitirMultimedia,
                    PermitirCambiarColor: this.state.checkPermitirCambioColor
                })
            }
            ).then(res => res.json()).then(data => {            
                if (data.error) {
                    this.setState({ colorNotificacion: 'danger', BotonDisabled: false, mensaje: data.mensaje });
                    this.showNotification();
                } else {
                    this.setState({ colorNotificacion: 'success', BotonDisabled: false });
                    this.closeModal(data.mensaje, this.state.colorNotificacion);
                }
                this.setState({ MostrarModal: false, BotonDisabled: false });

            }).catch(err => console.error(err));
        }
    }

    validarCampos() {
        var valido = true;
        console.log(this.state)
        if (this.state.NombreCapa.trim() == '') {
            this.setState({ errorNombreCapa: true });
            valido = false;
            this.setState({ colorNotificacion: 'danger', mensaje: "Proporcione el nombre de la capa" });
            this.showNotification();
        }
        else {
            this.setState({ errorNombreCapa: false });
        }
        if (valido) {
            //Validar el modelo
            if (this.state.Modelo3D == null && this.state.idCapa == null) {
                this.setState({ colorNotificacion: 'danger', mensaje: "Proporcione el Modelo 3D de la capa" });
                this.showNotification();
                valido = false;
            } else {
                if (this.state.Templete2D == null && this.state.idCapa == null) {
                    this.setState({ colorNotificacion: 'danger', mensaje: "Proporcione el Template 2D de la capa" });
                    this.showNotification();
                    valido = false;
                }
            }
        }
        return valido;
    }

    closeModal(mensaje, colorNotificacion) {
        this.props.eventClick(mensaje, colorNotificacion);
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false });
                this.setState(x);
            }.bind(this),
            3000
        );
    }

    cerrarModal(mensaje, colorNotificacion) {
        this.props.eventClick(mensaje, colorNotificacion);
    }

    getFiles(event) {
        console.log(event.target.name)
        var origen = event.target.name;
        this.setState({
            [origen + "Subida"]: true
        })
        var preview = document.getElementById(origen + "1");
        var file = document.getElementById(origen).files[0];
        var reader = new FileReader();
        reader.onloadend = () => {
            var base64 = reader.result;
            var mimeType = this.getMimeType(file);
            base64 = base64.replace(/^data:[^;]+/, 'data:' + mimeType);
           // base64 = base64.replace(/^data:application\/octet-stream;base64,/, "data:image;base64,");
           // console.log(base64);
            if (origen == "Modelo3D") {
                this.setState({ Modelo3D: base64, NombreModelo: file.name, editoModelo3D: true })
            }
            if (origen == "Templete2D") {
                this.setState({ Templete2D: base64, NombreTemplete2D: file.name, editoTemplete2D: true })
            }
            this.setState({
                [origen + "Subida"]: false
            }, () => {

            })
        }

        if (file) {
            reader.readAsDataURL(file);
            //reader.readAsText(file);


        } else {
            preview.src = "";
        }
    }

    getMimeType(file) {
        const mimeTypeMap = {
          '.obj': 'image/vnd.obj',
          '.jpg': 'image/jpeg',
          '.png': 'image/png',
          // Agrega más extensiones y tipos MIME según sea necesario
        };
      
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        return mimeTypeMap[extension] || 'application/octet-stream';
      }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <Modal isOpen={this.props.showAgregar} className="static-modal" style={{ width: '900px' }} size="xl">
                    <Cargando
                        show={this.state.MostrarModal}
                        Cuerpo="Cargando"
                    />
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardHeader color="info" text>
                                    <h4>{this.props.Titulo}</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3} lg={3}>
                                            <CustomInput
                                                labelText="Nombre Capa"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    onChange: this.handleChange.bind(this),
                                                    value: this.state.NombreCapa,
                                                    name: "NombreCapa",
                                                    id: "NombreCapa",
                                                    type: "text",
                                                    error: this.state.errorNombreCapa
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3} lg={3}>
                                            <h5>Es principal</h5>
                                            <Radio
                                                checked={this.state.checkPrincipal}
                                                onClick={this.handleChangeRadio.bind(this)}
                                                name="checkPrincipal"
                                                id="checkPrincipal"
                                                aria-label='A'
                                                content
                                                icon={
                                                    <FiberManualRecord
                                                        className={classes.radioUnchecked}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FiberManualRecord className={classes.radioChecked} />
                                                }
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4} lg={4}>
                                            {this.state.errorModelo3D ?
                                                <div className={classes.typo}>
                                                    <Danger>
                                                        <h5>Modelo 3D </h5>
                                                    </Danger> </div> :
                                                <div className={classes.typo}>
                                                    <h5>Modelo 3D </h5>
                                                </div>
                                            }
                                            <div id="prueba1">
                                                <input id="Modelo3D" name="Modelo3D" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />
                                                {
                                                    this.state.Modelo3DSubida == true ?
                                                        <label for="Modelo3D"><Publish />
                                                            Subiendo
                                                            &nbsp; <Loader style={{ float: 'right' }} visible={true} type="Oval" color="#212121" height={12} width={12} />
                                                        </label>
                                                        :
                                                        <label for="Modelo3D"><Publish /> Seleccione</label>
                                                }
                                                <label style={{ color: 'black' }}>{this.state.NombreModelo}</label>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <label style={{ color: 'black', marginTop: "20px" }}>{"Información del Template"}</label>
                                            <hr />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomInput
                                                labelText="Proporcion X"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    onChange: this.handleChange.bind(this),
                                                    onBlur: this.onBlur.bind(this),
                                                    value: this.state.ProporcionX,
                                                    name: "ProporcionX",
                                                    id: "ProporcionX",
                                                    type: "number",
                                                    error: this.state.errorProporcionX
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomInput
                                                labelText="Proporcion Y"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    onChange: this.handleChange.bind(this),
                                                    onBlur: this.onBlur.bind(this),
                                                    value: this.state.ProporcionY,
                                                    name: "ProporcionY",
                                                    id: "ProporcionY",
                                                    type: "number",
                                                    error: this.state.errorProporcionY
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomInput
                                                labelText="Posición X"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    onChange: this.handleChange.bind(this),
                                                    onBlur: this.onBlur.bind(this),
                                                    value: this.state.PosicionX,
                                                    name: "PosicionX",
                                                    id: "PosicionX",
                                                    type: "number",
                                                    error: this.state.errorPosicionX
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomInput
                                                labelText="Posición Y"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    onChange: this.handleChange.bind(this),
                                                    onBlur: this.onBlur.bind(this),
                                                    value: this.state.PosicionY,
                                                    name: "PosicionY",
                                                    id: "PosicionY",
                                                    type: "number",
                                                    error: this.state.errorPosicionY
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomInput
                                                labelText="Grados Rotación"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: true,
                                                    onChange: this.handleChange.bind(this),
                                                    onBlur: this.onBlur.bind(this),
                                                    value: this.state.GradosRotacion,
                                                    name: "GradosRotacion",
                                                    id: "GradosRotacion",
                                                    type: "number",
                                                    error: this.state.errorGradosRotacion
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            {this.state.errorTemplate ?
                                                <Danger>
                                                    <h5>Template 2D</h5>
                                                </Danger> : <h5>Template 2D</h5>}
                                            <div id="prueba">
                                                <input id="Templete2D" name="Templete2D" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />
                                                {
                                                    this.state.Templete2DSubida == true ?
                                                        <label for="Templete2D"><Publish />
                                                            Subiendo
                                                            &nbsp; <Loader style={{ float: 'right' }} visible={true} type="Oval" color="#212121" height={12} width={12} />
                                                        </label>
                                                        :
                                                        <label for="Templete2D"><Publish /> Seleccione</label>
                                                }
                                                <label style={{ color: 'black', marginLeft: "10px" }}>{this.state.NombreTemplete2D}</label>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <h5>Permitir multimedia</h5>
                                            <Radio
                                                checked={this.state.checkPermitirMultimedia}
                                                onClick={this.handleChangeRdMultimedia.bind(this)}
                                                name="checkPermitirMultimedia"
                                                id="checkPermitirMultimedia"
                                                aria-label='A'
                                                content
                                                icon={
                                                    <FiberManualRecord
                                                        className={classes.radioUnchecked}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FiberManualRecord className={classes.radioChecked} />
                                                }
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <h5>Permitir cambio color</h5>
                                            <Radio
                                                checked={this.state.checkPermitirCambioColor}
                                                onClick={this.handleChangeRdColor.bind(this)}
                                                name="checkPermitirCambioColor"
                                                id="checkPermitirCambioColor"
                                                aria-label='A'
                                                content
                                                icon={
                                                    <FiberManualRecord
                                                        className={classes.radioUnchecked}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FiberManualRecord className={classes.radioChecked} />
                                                }
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                        </GridItem>

                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Button size="sm" color="info" className="pull-right" round onClick={this.registrarCapa.bind(this)}><Save />&nbsp;GUARDAR</Button>
                                            <Button size="sm" color="warning" className="pull-right" round onClick={() => this.props.eventClick('esc')}><Clear />&nbsp;CANCELAR</Button>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>

                    </GridContainer>
                    <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ br: false })}
                        close
                    />
                </div>
                </Modal>
            </div>
        )
    }
}

export default withStyles(headerLinksStyle)(AgregarCapaProducto);