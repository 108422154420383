import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Button from "components/CustomButtons/Button.jsx";
// import ModalDetalleTableroProduccion from './ModalDetalleTableroProduccion'
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CardBody from "components/Card/CardBody.jsx";
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Visibility, Label, VerticalAlignBottom, Done, DoneAll, Save, } from "@material-ui/icons";
import { borders } from "@material-ui/system";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import InformacionEmpresa from '../InformacionEmpresa.jsx';

class ReporteDeVentas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            colorNotificacion: '',
            mensaje: '',
            br: false,
            dataAutocomplete: [],
            checkVentasIncompletas: false,
            dataAutocompleteArtistas: [],
            Ventas: [],
            CheckDescuento: false,
            FolioPedido: '',
            selected: [],
            tipoFiltro: false,
            selectedArtistas: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.filtrarClientes = this.filtrarClientes.bind(this);
        this.obtenerNombreVendedores = this.obtenerNombreVendedores.bind(this);
        this.filtrarClientesArtistas = this.filtrarClientesArtistas.bind(this);
        this.obtenerReporteDeVentas = this.obtenerReporteDeVentas.bind(this);
        this.obtenerFechaConFormatoListado = this.obtenerFechaConFormatoListado.bind(this)
    }
    componentDidMount() {
        this.filtrarClientes();
        this.filtrarClientesArtistas();
    };

    obtenerReporteDeVentas() {
        fetch(Globales.Url + 'Reportes/ReporteDeVentas/ObtenerReporteDeVentas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({
                FechaFinal: this.state.FechaFinal, FechaInicial: this.state.FechaInicial, Descuento: this.state.CheckDescuento, idVendedor: (this.state.selectedArtistas.length > 0 ? this.state.selectedArtistas[0].id : null),
                idCliente: (this.state.selected.length > 0 ? this.state.selected[0].id : null),
                VentasIncompletas: this.state.checkVentasIncompletas
            })
        }
        ).then(res => res.json())
            .then(data => {
                if (data.data.length == 0) {
                    this.setState({
                        colorNotificacion: "danger",
                        mensaje: "No se encontró información",
                        Ventas: []
                    });
                    this.showNotification()
                } else {
                    this.setState({
                        Ventas: data.data
                    });
                }
            });
    }

    componentWillMount() {
        this.setState({
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),

        })
    }
    limpiar() {
        this.setState({
            Ventas: [],
            FolioPedido: '',
            selected: [],
            tipoFiltro: false,
            selectedArtistas: [],
            FechaFinal: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            FechaInicial: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            checkVentasIncompletas: false
        });
    }

    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false, notificacion: false });
                this.setState(x);
            }.bind(this),
            4000
        );
    }
    filtrarClientes() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuariosClientes', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ NombreUsuario: this.state.NombreUsuarioFiltro, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal })
        }
        ).then(res => res.json())
            .then(data => {
                var usuariosClientes = []
                data.data.map((usuario) => {
                    usuariosClientes.push({
                        id: usuario._id,
                        label: usuario.Nombre
                    })
                })
                this.setState({
                    Usuarios: [],
                    dataAutocomplete: usuariosClientes
                });
            });
    }

    filtrarClientesArtistas() {
        fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/FiltrarUsuariosArtistas', {
            method: 'POST',
            headers: {
                Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
            },
            body: JSON.stringify({ NombreUsuario: this.state.NombreUsuarioFiltro, FechaInicial: this.state.FechaInicial, FechaFinal: this.state.FechaFinal })
        }
        ).then(res => res.json())
            .then(data => {
                var usuariosClientes = []
                data.data.map((usuario) => {
                    usuariosClientes.push({
                        id: usuario._id,
                        label: usuario.Nombre
                    });
                });
                this.setState({
                    dataAutocompleteArtistas: usuariosClientes
                });
            });
    }


    obtenerFechaConFormatoListado(fecha) {
        return new Date(new Date(fecha).setDate(new Date(fecha).getDate())).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
    }

    //Función para obtener el texto de los inputs
    handleChange(e) {
        const { name, value } = e.target;
        if (name == "FechaInicial") {
            if (value <= this.state.FechaFinal) {
                this.setState({ [name]: value });
            }
        } else if (name == "FechaFinal") {
            if (value >= this.state.FechaInicial) {
                this.setState({ [name]: value });
            }
        } else if (name == "CheckDescuento") {
            this.setState({
                "CheckDescuento": !this.state.CheckDescuento
            })
        } else if (name == "checkVentasIncompletas") {
            this.setState({
                "checkVentasIncompletas": !this.state.checkVentasIncompletas
            })
        } else {
            this.setState({ [name]: value });
        }

    }
    obtenerNombreVendedores(Productos) {
        var response = "";
        if (Productos.length == 1) {
            /*Productos.map(producto => {
                response += producto.Diseno.idCliente.Nombre
            });*/
            response = Productos[0].Diseno.idCliente.Nombre;
        } else {
            response = Productos[0].Diseno.idCliente.Nombre;
/*             Productos.map(producto => {
                response += producto.Diseno.idCliente.Nombre + " - "
            }); */
        }
        return response;
    }
    obtenerNombreDisenos(Productos) {
        var response = "";
        if (Productos.length == 1) {
            Productos.map(producto => {
                response += producto.Diseno.nombre
            });
        } else {
            Productos.map(producto => {
                response += producto.Diseno.nombre + " - "
            });
        }
        return response;
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Filtros</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem className="mt-5" xs={12} sm={12} md={3}>

                                        <div style={{ marginTop: "37px" }} >
                                            <Typeahead
                                                onChange={(selectedArtistas) => {
                                                    this.setState({ selectedArtistas });
                                                }}
                                                options={this.state.dataAutocompleteArtistas}
                                                selected={this.state.selectedArtistas}
                                                placeholder="Vendedor (Todos)"
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem className="mt-5" xs={12} sm={12} md={3}>

                                        <div hidden={this.state.checkVentasIncompletas} style={{ marginTop: "37px" }} >
                                            <Typeahead
                                                onChange={(selected) => {
                                                    this.setState({ selected });
                                                }}
                                                options={this.state.dataAutocomplete}
                                                selected={this.state.selected}
                                                placeholder="Cliente (Todos)"
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha inicial"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaInicial,
                                                name: "FechaInicial",
                                                id: "FechaInicial",
                                                type: "date",
                                                error: this.state.errorFechaInicial,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                            labelText="Fecha final"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: this.handleChange,
                                                value: this.state.FechaFinal,
                                                name: "FechaFinal",
                                                id: "FechaFinal",
                                                type: "date",
                                                error: this.state.errorFechaFinal,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <div hidden={this.state.checkVentasIncompletas} className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.CheckDescuento
                                                        }
                                                        name="CheckDescuento"
                                                        onClick={this.handleChange}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}

                                                    />
                                                }
                                                label={"Solo ventas con descuento"}
                                            />
                                        </div>
                                        <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // tabIndex={-1}
                                                        checked={
                                                            this.state.checkVentasIncompletas
                                                        }
                                                        name="checkVentasIncompletas"
                                                        onClick={this.handleChange}
                                                        checkedIcon={<Check className={classes.uncheckedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}

                                                    />
                                                }
                                                label={"Solo ventas incompletas"}
                                            />
                                            {/* <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.state.checkVentasIncompletas}
                                                            onChange={()=>{
                                                                this.setState({
                                                                    checkVentasIncompletas:!this.state.checkVentasIncompletas
                                                                })
                                                            }}
                                                            value="checkedA"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label
                                                    }}
                                                    label="Solo ventas incompletas"
                                                /> */}
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <Button tabIndex="0" size="sm" color="danger" className="mt-5 pull-right" round onClick={this.limpiar}><Clear />&nbsp;Limpiar</Button>
                                        <Button tabIndex="0" size="sm" color="info" className="mt-5 pull-right" round onClick={this.obtenerReporteDeVentas}><Search />&nbsp;Buscar</Button>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4>Reporte</h4>
                            </CardHeader>
                            <CardBody>
                                <div hidden={(this.state.Ventas.length > 0 ? false : true)} >
                                    <div className="table-wrapper-scroll-y">
                                        <table id="DownloableTable" className="col-xs-12 col-sm-12 col-md-12">
                                            <InformacionEmpresa
                                                NombreReporte="Reporte de ventas"
                                                FechaInicial={this.state.FechaInicial}
                                                FechaFinal={this.state.FechaFinal}
                                            />
                                            <thead>
                                                <th className="center">Folio de pedido</th>
                                                <th className="center">Estado de pedido</th>
                                                <th className="center">Cliente</th>
                                                <th className="center">Teléfono cliente</th>
                                                <th className="center">Correo cliente</th>
                                                <th className="center">Vendedor</th>
                                                <th className="center">Nombre(s) diseño(s)</th>
                                                <th className="center">Total</th>
                                                <th hidden={true} className="center">Productos</th>

                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Ventas.map(venta => {
                                                        console.log(venta.HistorialEstatusPedido)
                                                        return (
                                                                <tr key={venta._id}>
                                                                    <td>{venta.FolioPedido}</td>
                                                                    {venta.Estatus != 0 ?
                                                                        <td>{venta.NombreEstatusPedido == null ? (venta.HistorialEstatusPedido[venta.HistorialEstatusPedido.length - 1].NombreEstatusPedidoAnterior) : venta.NombreEstatusPedido}</td>
                                                                        :
                                                                        <td>Cancelado</td>
                                                                    }
                                                                    <td>{venta.idCliente.Nombre}</td>
                                                                    <td>{venta.idCliente.Telefono}</td>
                                                                    <td>{venta.idCliente.Correo}</td>
                                                                    <td>{this.obtenerNombreVendedores(venta.Productos)}</td>
                                                                    <td>{this.obtenerNombreDisenos(venta.Productos)}</td>
                                                                    <td>${(venta.responseFromConekta._json.amount / 100)}</td>
                                                                    <td hidden={true}>
                                                                    <table>
                                                                    <thead>
                                                                        <th>Nombre</th>
                                                                        <th>Precio</th>
                                                                        <th>Cantidad</th>
                                                                        <th>Descuento</th>
                                                                        <th>Vendedor</th>
                                                                        <th>Categoría de modelo</th>
                                                                        <th>Talla</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                        venta.Productos.map(producto =>{
                                                                            return (
                                                                                <tr>
                                                                                <td>{producto.Diseno.nombre}</td>
                                                                                <td>{producto.PrecioPublico}</td>
                                                                                <td>{producto.Cantidad}</td>
                                                                                <td>{(producto.Diseno.Descuento == true?"Con descuento":"Sin descuento")}</td>
                                                                                <td>{(producto.Diseno.idClienteOriginal == null?producto.Diseno.idCliente.Nombre:producto.Diseno.idClienteOriginal.Nombre)}</td>
                                                                                <td>{producto.idCategoria}</td>
                                                                                <td>{producto.Diseno.idTalla.NombreTalla}</td>
                                                                                <td>{}</td>
                                                                            </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div hidden={(this.state.Ventas.length == 0 ? false : true)}>
                                    <h3 style={{ textAlign: "center" }}>No hay información para mostrar</h3>
                                </div>
                                <div hidden={true}>
                                    <ReactHTMLTableToExcel
                                        id="btnDownloadExcel"
                                        className="download-table-xls-button"
                                        table="DownloableTable"
                                        filename={"Reporte de Ventas " + new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                        sheet="tablexls" />
                                </div>
                                <div style={{ textAlign: "center" }} hidden={(this.state.Ventas.length > 0 ? false : true)}>
                                    <Button onClick={() => {
                                        document.getElementById('btnDownloadExcel').click()
                                    }} >
                                        Descargar EXCEL
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div id="">
                    <Snackbar
                        place="tr"
                        color={this.state.colorNotificacion}
                        message={this.state.mensaje}
                        open={this.state.br}
                        closeNotification={() => this.setState({ MostrarNotificacion: false })}
                        close
                    />
                </div>
            </div>

        )
    }
}

export default withStyles(headerLinksStyle)(ReporteDeVentas);